import akbank1 from '../img/referansImage/akbank1.jpg';
import arcelik1 from '../img/referansImage/arcelik1.jpg';
import bayer1 from '../img/referansImage/bayer1.jpg';
import beko1 from '../img/referansImage/beko1.jpg';
import bp1 from '../img/referansImage/bp1.jpg';
import eczacibasi1 from '../img/referansImage/eczacibasi1.jpg';
import honda1 from '../img/referansImage/Honda1.jpg';
import kocbank1 from '../img/referansImage/kocbank1.jpg';
import petrol_ofisi1 from '../img/referansImage/petrol_ofisi1.jpg';
import philips1 from '../img/referansImage/philips1.jpg';
import plasko1 from '../img/referansImage/plasko1.jpg';
import profilo1 from '../img/referansImage/profilo1.jpg';
import total1 from '../img/referansImage/Total1.jpg';
import tupras1 from '../img/referansImage/tupras1.jpg';
import ulker1 from '../img/referansImage/Ulker1.jpg';
import unilever1 from '../img/referansImage/Unilever1.jpg';
import varan1 from '../img/referansImage/Varan1.jpg';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useNavigate } from "react-router-dom";
function SampleNextArrow() {
    return (
      <div/>
    );
  }
  
  function SamplePrevArrow() {
   
    return (
      <div/>
    );
  }
function ReferansSlider (){
    const companies = [
        {
          id: 1,
          name: 'Akbank',
          image: akbank1
        },
        {
          id: 2,
          name: 'Arcelik',
          image: arcelik1
        },
        {
          id: 3,
          name: 'Bayer',
          image: bayer1
        },
        {
          id: 4,
          name: 'Beko',
          image: beko1
        },
        {
          id: 5,
          name: 'BP',
          image: bp1
        },
        {
          id: 6,
          name: 'Eczacibasi',
          image: eczacibasi1
        },
        {
          id: 7,
          name: 'Honda',
          image: honda1
        },
        {
          id: 9,
          name: 'Petrol Ofisi',
          image: petrol_ofisi1
        },
        {
          id: 13,
          name: 'Total',
          image: total1
        },
        {
          id: 14,
          name: 'Tupras',
          image: tupras1
        },
        {
          id: 15,
          name: 'Ulker',
          image: ulker1
        },
        {
          id: 16,
          name: 'Unilever',
          image: unilever1
        },
      ];
    const settings={
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
      infinite: true,
      slidesToShow: 6,
      slidesToScroll: 1,
      autoplay: true,
      speed:5000,
      autoplaySpeed:5000,
      cssEase: "linear",
     
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    }
    const navigate = useNavigate();
    const style = {
      textAlign: 'center', // Metni ortala
      padding: '10px 20px',
      backgroundColor: 'black', // Arka plan rengi
      color: '#fff', // Yazı rengi
      border: 'none',
      borderRadius: '5px', // Köşe yuvarlama
      cursor: 'pointer',
      textDecoration: 'none', // Metin altı çizgisi
    };
    return( 
    <div >
    <div style={{padding:20 , backgroundColor:'white' ,display:'flex',flexDirection:'column', alignItems:'center'}} >
    <h1 style={{textAlign:'center'}}>Referanslarımız</h1>
    <button onClick={()=>{navigate('/referans')}}style={style}><h6 >Ayrıntılı Görmek İçin Tıklayınız</h6></button>
    </div>
    <Slider {...settings}>
       
        {companies.map((company)=>(
             <div key={company.id}>
             <img src={company.image} alt={company.name} />
           </div>
        ))}
          
    </Slider>
    
    </div>
    )
}
export default ReferansSlider