import React, { useState } from 'react';
import banner from '../../../img/main//01- SULU SÖNDÜRME SİSTEMLERİ-YENİ/001-SULU SÖNDÜRME SİSTEMLERİ/Başlık.png';

export default function MainYazıSulu() {
  const [showFullContent, setShowFullContent] = useState(true);

  const containerStyle = {
   
    
    maxWidth: '800px',
   
    
    fontFamily: 'Arial, sans-serif',
  };

  const headingStyle = {
    fontSize: '24px',
    marginTop: '0',
    fontWeight: '800',
  };
  const divStyle={
    height: '200px',
    width: '100%',
    backgroundImage:`url(${banner})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    padding: 0,
    fontSize: "3rem",
    color: " #ffffff",
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom:20,
    textShadow: '4px 4px 4px rgba(0, 0, 0, 0.5)'
}

  const handleToggleContent = () => {
    setShowFullContent(!showFullContent);
  };

  return (
    <>
      <div style={containerStyle}>
        <div style={divStyle}>
        
        </div>
        <p style={headingStyle}>Sulu Söndürme Sistemleri</p>
        <div style={{ minHeight: 1, backgroundColor: 'red', marginBottom: 10, marginTop: 10 }}></div>
        <p>
          Konutlar dahil tüm yüksek yapılar, alışveriş merkezleri, otoparklar, konaklama, eğitim, sağlık hizmeti, ticaret,
          depolama, üretim vb. amaçlarla kullanılan yapılarda mekanların özelliklerine bağlı olmak üzere, uygun tipte
          sprinkler başlıklarını içeren sistemler kullanılmalıdır.
        </p>
        <p>
          Otomatik sprinkler sistemi ile korunacak alan ve binalar kullanım amaçlarına bağlı olarak; düşük, orta ve
          yüksek tehlike grubunda sınıflandırılır. Eğer aynı bina veya alanda birden fazla tehlike sınıfı söz konusu
          ise; en yüksek tehlikeye sahip olan sınıfa göre sistem çözülür. Sprinkler Sistemi tasarımına başlamadan önce,
          tasarım yapılacak tesisin tehlike sınıfını doğru olarak belirlemek bu anlamda çok önemlidir.
        </p>
        <p>
          Tehlike sınıfına bağlı olarak seçilen sistemin gereklerine göre sprinkler başlıkları da farklılık
          göstermektedir. Özellikle özel tehlike sınıfları için spesifik sprinkler başlıklarının geliştirilmesi ile
          su kullanarak yangına müdahale etmek daha da önem kazanmıştır. Suyun bulunabilir ve ekonomik olması su
          kullanımının önemini ayrıca arttırmıştır.
        </p>
        {showFullContent ? (
          <>
            <p>
              Sprinkler sistemleri gelişen teknolojiye ve endüstride kullanılmaya başlayan malzeme veya hammadde
              çeşitliliğine olarak bağlı olarak artan üretimi gerçekleştiren tesislerin yangın tehlikelerine karşı
              korunması için geliştirilmiş özel sistemlerdir.
            </p>
            <p>
              <strong>AKSAY Yangın</strong>, Sprinkler Sistemi konusunda; Dizayn, Projelendirme, Malzeme Temini,
              Montaj - Devreye Alma ve Kontratlı Bakım konularında hizmetinizdedir.
            </p>
            <p>
              Sprinkler sistemlerinin doğru tasarlanması mal ve can emniyetinin korunması için kaçınılmaz bir
              zorunluluktur.
            </p>
            <p>Bu amaçla;</p>
            <ul>
              <li>Saha Özelliklerinin Titizlikle İncelenmesi</li>
              <li>Riskin Doğru İrdelenmesi ve Belirlenmesi</li>
              <li>Koruma Standardının Belirlenmesi</li>
              <li>Koruma Yönteminin Belirlenmesi</li>
              <li>Sistemin Projelendirilmesi</li>
              <li>Sistemin Hidrolik Hesaplarının Yapılması</li>
              <li>Su Kaynaklarının Belirlenmesi</li>
              <li>Malzeme Seçimi ve Temini</li>
              <li>Montaj İşlemlerinin Gerçekleştirilmesi</li>
              <li>Projeye Uygun Montaj Yapıldığının Kontrolü</li>
              <li>Test ve Devreye Alma</li>
              <li>Kontratlı Bakım</li>
            </ul>
            <p>
              Sprinkler Sistemleri tasarımı için temel olarak EN 12845, NFPA ve FM Global vb. ulusal ve uluslararası
              standartlar kapsamında deneyimli mühendis kadromuz ile çalışmak ve risklerinizi doğru analiz ederek
              korumak için varız.
            </p>
            <p style={{fontWeight: '600'}}>Islak Borulu (Wet Pipe) Sprinkler Sistemleri</p>
            <p>
              En basit kuruluma sahip ve en sık kullanılan sprinkler sistemidir. Donma tehlikesinin söz konusu
              olmadığı alanlarda güvenle kullanılır. Özellikle Sosyal Binalar, Otoparklar, Ticaret Merkezleri,
              Oteller, Kamu Binaları, Fabrikalarda ve Depolarda kullanılmaktadır.
            </p>

            <p style={{fontWeight: '600'}} >Kuru Borulu (Dry Pipe) Sprinkler Sistemleri</p>
            <p>
              Donma tehlikesi bulunan ve ısıtılmayan ortamlarda veya dış ortama açık alanlarda kullanılan sistemlerdir.
              Sistemde su yerine basınçlı hava veya nitrojen kullanılır.
            </p>

            <p style={{fontWeight: '600'}} >Baskın (Deluge) Sprinkler Sistemleri</p>
            <p>
              Özellikle yüksek tehlike sınıfına giren Endüstriyel depolarda, Uçak Bakım Hangarlarında, Akaryakıt veya
              Petrol Depolama Tesislerinde, Tank Soğutma Sistemlerinde, Trafo Koruma ve özel tehlikenin söz konusu
              olduğu yangının hızla bastırılmasının gerektiği alanlarda kullanılır. Hidrolik Aktivasyonlu, Pneumatik
              Aktivasyonlu veya Yangın Algılama Sistemi üzerinden aktive edilebilme seçenekleri ile tedarik edilerek
              kurulumu yapılabilir.
            </p>

            <p style={{fontWeight: '600'}} >Ön Tepkili (Pre-Action) Sprinkler Sistemleri</p>
            <p>
              Suyun zarar verebileceği; Bilgisayar Odaları, Enerji Dağıtım Odaları, Arşiv Alanları, Kütüphaneler,
              Müzeler, Tarihi Binalar, Soğuk Hacimler, Matbaalar yada değerli ürün üretimi yapılan Fabrikaların kritik
              alanlarında kullanılır. Kilitlemesiz, Tek Kilitlemeli veya Çift Kilitlemeli seçenekleri ile kurulumu
              yapılmaktadır.
            </p>

            <p style={{fontWeight: '600'}} >Sulu Söndürme Sistemleri Ürün Yelpazesi</p>
            <ul>
              <li>Sprinkler Başlıkları</li>
              <li>Islak Alarm Vanaları ve Aksesuarları</li>
              <li>Kuru Tip Alarm Vanaları ve Aksesuarları</li>
              <li>Deluge Vanalar ve Aksesuarları</li>
              <li>Pre - Action Vanalar ve Aksesuarları</li>
              <li>Su Motor Gongları</li>
              <li>İtfaiye Bağlantı Vanaları</li>
              <li>İtfaiye Su Alma Vanaları</li>
              <li>Akış Anahtarları</li>
              <li>Basınç Anahtarları</li>
              <li>Test ve Drenaj Vanaları</li>
              <li>İzlenebilir Kelebek Vanalar</li>
              <li>O.S&Y Yükselen Milli Vanalar</li>
              <li>N.R.S Vanalar ve Post İndikatörler</li>
              <li>Çek Vanalar</li>
              <li>Küresel Vanalar</li>
            </ul>

            <p>
              Özellikle yanıcı sıvı yangınlarının söz konusu olduğu alanlarda, suyun daha etkili müdahale aracı
              olarak kullanılmasını sağlamak için köpük konsantreleri özel düzeneklerle sisteme ilave edilmektedir.
            </p>

            <p>
              <strong>AKSAY</strong> güçlü mühendislik birikimi ve alternatifli sistem seçenekleri ile hızlı ve
              güvenilir hizmet vermek için size bir telefon kadar yakındır.
            </p>
          </>
        ) : null}
      
      </div>
    </>
  );
}