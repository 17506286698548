import banner from '../../../img/main/01- SULU SÖNDÜRME SİSTEMLERİ-YENİ/010-YİVLİ BORU BAĞLANTI ELEMANLARI/Başlık.png'
import img from '../../../img/main/01- SULU SÖNDÜRME SİSTEMLERİ-YENİ/010-YİVLİ BORU BAĞLANTI ELEMANLARI/Birleşmiş.png'
export default function Yivli() {
    const containerStyle = {
      
      
        maxWidth: '800px',
      
        
        fontFamily: 'Arial, sans-serif',
      };
    
      const headingStyle = {
        fontSize: '24px',
        marginTop: '0',
        fontWeight: '800',
      };
      const divStyle={
        height: '200px',
        width: '100%',
        backgroundImage:`url(${banner})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        padding: 0,
        fontSize: "3rem",
        color: " #ffffff",
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom:20,
        textShadow: '4px 4px 4px rgba(0, 0, 0, 0.5)'
    }
    
    return (<>
  <div style={containerStyle}>
     <div  style={divStyle}>
      
     </div>
      <h2 style={headingStyle}>Yivli Boru Bağlantı Elemanları</h2>
      <div style={{minHeight:1, backgroundColor:'red',marginBottom:10,marginTop:10}}></div>
       <p>
       Yivli sistemleri kullanma avantajları arasında montaj hızı ve kolaylığı kaynağa gerek olmaması, tekrar sökülebilme.bakım kolaylığı, zamandan tasarruf ve düşük ışçilik maliyetlerı sayılabilir. Yivli borularla birlikte kullanılan kaplinler içerdikleri kauçuk contası ile birlikte tam bir sızdırmazlık sağlar. 

       </p>
       <p>
       Yivli boru bağlantıları kaynaklı imalatın yapılmasının mümkün olmadığı yerlerde sıklıkla kullanılır. Boru montaj şartlarının zor olduğu alanlarda boru güzergâhında esneklik sağlar.

       </p>
       <p>
       Boru parçalarının kolaylıkla sökülüp takılmasına olanak sağlayan bağlantı türü olduğundan tesisatta gerekli görülen noktalarda ve tesisatın depremden korunmasına yönelik uygulamalarda, kısmi olarak da kullanılmaktadır. Borulara yiv açılarak üzerinde contaları bulunan kaplinler vasıtasıyla birleştirilir.

       </p>
       <p>
       Sismik hareketlerde yivli bağlantı sistemleri esneme, genişleme, kısalma, bükülme özelliklerini içerdiğinden kaynaklı sistemlere göre daha güvenlidir Uygulanan yivli boru hatları kelepçeler ile birlikte titreşim azaltıcı özelliğe sahiptir. Yivli sistem bağlantılarıUlkemizde de hızlı bir şekilde uygulanmaya ve tercih edilmeye başlanmıştır.

       </p>
       <img  style= {{width:600,height:600}} src= {img} alt="" />
  
    </div>






    </>)
}