import { Container } from "react-bootstrap";
import PDFLink from "../../component/PDFLink";
export default function Brosür() {
    const pdfFileNames = [
        {path:'TanıtımveReferanslar',name:'Tanıtım ve Referanslarımız'},
        {path:'SuluSondurma',name:'Sulu Söndürme Sistemleri'},
        {path:'KopukluSondurma',name:'Köpüklü Söndürme Sistemleri'},
        {path:'GazliSondurma',name:'Gazlı Söndürme Sistemleri'},
        {path:'AlgılamaSistemleri',name:'Yangın Algılama ve İhbar Sistemleri'},
        {path:'Spesifik',name:'Spesifik Sistemler'},
        {path:'Monitorler',name:'Su ve Köpük Monitörleri'},
        {path:'Detex',name:'Detex Broşür'},
    
        
      
       {path:'01-AKSAY Water Extinguishing Systems Brochure',name:" Water Extinguishing Systems Brochure"},
       {path:'02-AKSAY Foam Extinguishing Sytems Brochure',name:" Foam Extinguishing Sytems Brochure"},
       {path:'03-AKSAY Gaseous Extinguishing Systems Brochure',name:" Gaseous Extinguishing Systems Brochure"},
       {path:'04-AKSAY Detection Systems Brochure',name:" Detection Systems Brochure"},
       {path:'05-AKSAY Specific Extinguishing Systems Brochure',name:" Specific Extinguishing Systems Brochure"}
      ];
    
    const divStyle = {
      height: '100px',
        width: '100%',
        background: ' rgb(0,55,105)',
        background: '   linear-gradient(90deg, rgba(0,55,105,1) 0%, rgba(0,130,226,1) 100%)',
        paddingTop:10,
        fontSize: "3rem",
        color:" #ffffff",
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textShadow: '4px 4px 4px rgba(0, 0, 0, 0.5)'
        
      };  

return(<>
<div style={divStyle}>
              <p>Broşürler</p>
        </div>
<Container style={{minHeight:600,paddingLeft:20,paddingTop:20}}>
<div>
      {pdfFileNames.map((pdfFileName, index) => (
        <PDFLink key={index} pdfFileName={pdfFileName.path} name={pdfFileName.name} />
      ))}
    </div>
</Container>

</>)
    }