import { Col, Container, Row } from "react-bootstrap";

function Uygulamalarımız (){
    const divStyle = {
      height: '100px',
      width: '100%',
      background: ' rgb(0,55,105)',
      background: '   linear-gradient(90deg, rgba(0,55,105,1) 0%, rgba(0,130,226,1) 100%)',
      paddingTop:10,
      fontSize: "3rem",
      color:" #ffffff",
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      textShadow: '4px 4px 4px rgba(0, 0, 0, 0.5)'
      
      };
return(
<>
<div style={divStyle}>
              <p>Uygulamalarımız</p>
        </div>
<Container style={{minHeight:600, paddingLeft:20,paddingTop:20}}>
<Row>
  <Col>
    <iframe 
      width="560" 
      height="315" 
      src="https://www.youtube.com/embed/VllEbOS40yE" 
      title="YouTube video player" 
      frameborder="0" 
      allow="accelerometer; 
      autoplay; 
      clipboard-write; 
      encrypted-media; 
      gyroscope; 
      picture-in-picture; 
      web-share" 
      allowfullscreen
    ></iframe>
  </Col>
  <Col>
    <iframe 
      width="560" 
      height="315" 
      src="https://www.youtube.com/embed/7DqUb3jr5UU" 
      title="YouTube video player" 
      frameborder="0" 
      allow="accelerometer; 
      autoplay; 
      clipboard-write; 
      encrypted-media; 
      gyroscope; 
      picture-in-picture; 
      web-share" 
      allowfullscreen
    ></iframe>
  </Col>
  <Col>
    <iframe 
      width="560" 
      height="315" 
      src="https://www.youtube.com/embed/zYg9IS6am1Q" 
      title="YouTube video player" 
      frameborder="0" 
      allow="accelerometer; 
      autoplay; 
      clipboard-write; 
      encrypted-media; 
      gyroscope; 
      picture-in-picture; 
      web-share" 
      allowfullscreen
    ></iframe>
  </Col>
  <Col>
    <iframe 
      width="560" 
      height="315" 
      src="https://www.youtube.com/embed/S9YamLx0Yg8" 
      title="YouTube video player" 
      frameborder="0" 
      allow="accelerometer; 
      autoplay; 
      clipboard-write; 
      encrypted-media; 
      gyroscope; 
      picture-in-picture; 
      web-share" 
      allowfullscreen
    ></iframe>
  </Col>
  <Col>
    <iframe 
      width="560" 
      height="315" 
      src="https://www.youtube.com/embed/iIv-eQ_3qkA" 
      title="YouTube video player" 
      frameborder="0" 
      allow="accelerometer; 
      autoplay; 
      clipboard-write; 
      encrypted-media; 
      gyroscope; 
      picture-in-picture; 
      web-share" 
      allowfullscreen
    ></iframe>
  </Col>
  <Col>
    <iframe 
      width="560" 
      height="315" 
      src="https://www.youtube.com/embed/BQ3c2kGq18g" 
      title="YouTube video player" 
      frameborder="0" 
      allow="accelerometer; 
      autoplay; 
      clipboard-write; 
      encrypted-media; 
      gyroscope; 
      picture-in-picture; 
      web-share" 
      allowfullscreen
    ></iframe>
  </Col>
  <Col>
    <iframe 
      width="560" 
      height="315" 
      src="https://www.youtube.com/embed/vQk6XiQWgHQ" 
      title="YouTube video player" 
      frameborder="0" 
      allow="accelerometer; 
      autoplay; 
      clipboard-write; 
      encrypted-media; 
      gyroscope; 
      picture-in-picture; 
      web-share" 
      allowfullscreen
    ></iframe>
  </Col>
  <Col>
    <iframe 
      width="560" 
      height="315" 
      src="https://www.youtube.com/embed/_4o2VhjPfyU" 
      title="YouTube video player" 
      frameborder="0" 
      allow="accelerometer; 
      autoplay; 
      clipboard-write; 
      encrypted-media; 
      gyroscope; 
      picture-in-picture; 
      web-share" 
      allowfullscreen
    ></iframe>
  </Col>
</Row>

</Container>
</>)
}
export default Uygulamalarımız