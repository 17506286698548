import { Container } from "react-bootstrap";
import img from '../../img/main/hizmetlerimiz.png'
export default function Hizmetlerimiz() {


    const divStyle = {
        height: '100px',
        width: '100%',
        background: ' rgb(0,55,105)',
        background: '   linear-gradient(90deg, rgba(0,55,105,1) 0%, rgba(0,130,226,1) 100%)',
        paddingTop:10,
        fontSize: "3rem",
        color:" #ffffff",
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textShadow: '4px 4px 4px rgba(0, 0, 0, 0.5)'
        
      };
return(<>
<div style={divStyle}>
              <p>Hizmetlerimiz</p>
        </div>
<Container style={{minHeight:600, paddingLeft:20,paddingTop:20}}>
   <img style={{width:1300 ,height:600,marginBottom:20,marginTop:20}}  src= {img} alt="" />
</Container>

</>)
    }