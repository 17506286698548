import aerosol from '../../../img/main/aerosol.png'
import banner from '../../../img/main/08-  AEROSOL SİSTEMLERİ/Başlık.png'
export default function Aerosol() {
    const containerStyle = {
    
      maxWidth: '800px',
    
      fontFamily: 'Arial, sans-serif',
    };
  
    const headingStyle = {
      fontSize: '24px',
      marginTop: '0',
      fontWeight: '800',
    };
    const divStyle={
      height: '200px',
      width: '100%',
      backgroundImage:`url(${banner})`,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      padding: 0,
      fontSize: "3rem",
      color: " #ffffff",
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginBottom:20,
      textShadow: '4px 4px 4px rgba(0, 0, 0, 0.5)'
  }
    return (<>
      <div style={containerStyle}>
        <div style={divStyle}>
          
        </div>
        <h2 style={headingStyle}>Aerosol Söndürme</h2>
        <div style={{ minHeight: 1, backgroundColor: 'red', marginBottom: 10, marginTop: 10 }}></div>
        <p>Katı Potasyum Söndürme Sistemi, roketler veya uzay mekiklerinde kullanılmak üzere tasarlanmıştır. Sağladığı artı değerler göz önüne alınarak yangın riski bulunan tüm ortamlarda kullanılmak üzere geliştirilmiştir. Sıkıştırılmış mikronize katı potasyumdan oluşur ve iletken değildir.
</p>
<p>
Sürekli insan yaşamının olmadığı kapalı alanlarda (depo, arşiv, bilişim sistem odaları ve benzeri) yangın oluşumunu önleyen ve akreditasyona tabi ulusal veya uluslararası sertifikasyon sistemine sahip oksijen azaltma sistemleri uygulanır. Aerosol söndürme sistemleri bu kapsamda Avrupa Birliği normlarına uygun olarak; Gazlı ve kuru tozlu yangın söndürme sistemleri kategorisindedir.

</p>
<p>
DSPA sabit söndürme sistemi uygulamaları için idealdir. Bilgisayar ve sunucu odaları, jeneratör ve motor odaları, elektrik dolapları ve diğer birçok oda veya kritik (üretim) süreçleri içeren alanlarda güvenerek kullanacağınız bir yangın söndürme teknolojisidir.

</p>
<p>
NFPA2010 nolu standart kapalı alanlarda kullanım şekli belirlenmiştir. CEN-15276-1 çalışmaları vardır. Katı halde bulunan madde tetiklenme sonucu kimyasal reaksiyona girerek 1-2 mikron büyüklüğündeki potasyum partiküller ortama salınır ve bu havada asılı kalan partiküller kimyasal olarak yangını söndürürler.  Bu söndürücüler de kapalı bir hacim için söndürme yapabilir. Korunacak hacim için gerekli ve onaylanmış miktarda söndürücü seçilmesi gereklidir.

</p>
<p>
Reaksiyon sırasında cihaz çevresinde ısınma oluşabilir bu nedenle cihaz seçimi ve yerleşimi bir uzmanlık gerektirir. Büyük alanlarda aynı anda birçok ünitenin devreye girmesi ile söndürme oluşturulabilir.

</p>
<img src={aerosol} alt="" srcset="" />
<p  style={{fontWeight:600}}>Aerosol Sisteminin Temel Özellikleri ve Avantajları</p>
<ul>
  <li>Basit ve ekonomik kurulum</li>
  <li>Klasik sistemlere göre yatırım maliyeti çok düşük</li>
  <li>Mevcut yangın algılama ve alarm sistemlerine kolayca entegre</li>
  <li>Binalarda, makinelerde ve ekipmanlarda kolay ve hızlı kurulum</li>
  <li>Yangın durumunda, korunan ekipmanın hizmet dışı kalma süresi minimum</li>
  <li>Sökülme, depolanma ve taşınması kolay</li>
  <li>Sistemin kolayca genişleyebilmesi mümkün</li>
  <li>Aerosol çevreye ve ekipmana zarar vermez</li>
  <li>Yangını hızla baskılar ve söndürür</li>
  <li>Halon gazı yerine kullanılabilen söndürücü ajan sınıfında</li>
  <li>Tamamen çevreci</li>
  <li>O.D.P. (Ozon delme potansiyeli) = 0</li>
  <li>G.W.P. (Küresel ısınma potansiyeli) = 0</li>
  <li>Söndürücü aerosol sadece gerektiği anda üretilir</li>
  <li>Periyodik bakım gerektirmez</li>
  <li>Servis ömrü 15 yıl olup akredite edilmiştir</li>
  <li>Mekanik kurulum için çok az yer gerektirir</li>
  <li>Bir bölme veya nesne içine kolaylıkla monte edilebilir</li>
  <li>Aktivasyon tam otomatik ve/veya manuel olarak yapılabilir</li>
  <li>Basınç içermez, basınca neden olmaz, dolayısı ile kolay taşınabilir ve monte edilebilir</li>
  <li>Elektrik enerjisi veya güç kaynağı kullanmadan çalışabilir</li>
  <li>% 98 nem ile -50 ° C ile + 75 ° C arasında değişen ortamlarda kullanılabilir</li>
  <li>Devam eden üretim tesislerinde sistemsel çok küçük kesintilerle kolay ve hızlı monte edilebilir</li>
  <li>Diğer geleneksel gazlı ve tozlu sistemlere göre çok küçük bakım bedeli ile idame edilebilir</li>
  <li>İnsanlara ve hayvanlara zararı yoktur</li>
  <li>Oksijen seviyesini düşürmez</li>
  <li>Temini ve montajı kolaydır</li>
  <li>Taşınabilir ve yer değiştirilebilir</li>
  <li>Zaman ve paradan tasarruf sağlar</li>
  <li>Söndürme gücüne oranla ağırlığı çok düşüktür</li>
  <li>Rahatlıkla algılama sistemlerine entegre edilebilir</li>
</ul>
<p style={{fontWeight:700}}>Aerosol Sistemleri Nerelerde Kullanılır</p>
<p >DSPA Aerosol Söndürme Sistemleri birçok uygulama için idealdir. 
</p>
<ul>
  <li>Bilgisayar ve Sunucu Odaları</li>
  <li>Jeneratör ve Motor Odaları</li>
  <li>Trafolar</li>
  <li>Yanıcı ve Parlayıcı Sıvı Depoları</li>
  <li>Elektrik Kabinleri</li>
  <li>Otomatik Dikey Depolar</li>
  <li>Batarya Enerji Depolama Sistemleri</li>
  <li>Açık Deniz Platformları</li>
  <li>Endüstriyel Alanlar</li>
  <li>Havacılık Sektörü</li>
  <li>Denizcilik Sektörü</li>
  <li>Arşiv Alanları</li>
  <li>Araç Yangınları</li>
</ul>
<p>ve diğer birçok oda veya kritik (üretim) süreçleri içeren alanlarda güvenerek kullanacağınız bir yangın söndürme sistemidir.  Mevcut alarm sistemlerine kolaylıkla entegre edilebilir ve uluslararası sertifikasyona sahiptir.
</p>
<p style={{fontWeight:700}}>Aerosol Jeneratörleri Kapasiteleri ve Etkin Koruma alanları;</p>
<p style={{fontWeight: '600'}}>Elektriksel Aktivasyonlu</p>
<table>
  <tr>
    <th>Model</th>
    <th>Etken Madde (gr)</th>
    <th>Etkili Hacim (m3)</th>
    <th>Çalışma Zamanı (sn)</th>
    <th>Aktivasyon</th>
  </tr>
  <tr>
    <td>DSPA 2-4-1</td>
    <td>1600</td>
    <td>15,85</td>
    <td>48-53</td>
    <td>1.3A</td>
  </tr>
  <tr>
    <td>DSPA 8-1</td>
    <td>3250</td>
    <td>31,65</td>
    <td>78</td>
    <td>1.3A</td>
  </tr>
  <tr>
    <td>DSPA 8-1-6</td>
    <td>3250</td>
    <td>31,65</td>
    <td>48-58</td>
    <td>1.3A</td>
  </tr>
  <tr>
    <td>DSPA 8-2</td>
    <td>6700</td>
    <td>65,24</td>
    <td>140</td>
    <td>1.3A</td>
  </tr>
</table>
<p style={{fontWeight: '600'}}>
Elektriksel Aktivasyon ve Thermocord
</p>
<table>
  <tr>
    <th>Model</th>
    <th>Etken Madde (gr)</th>
    <th>Etkili Hacim (m3)</th>
    <th>Çalışma Zamanı (sn)</th>
    <th>Aktivasyon</th>
  </tr>
  <tr>
    <td>DSPA 11-1</td>
    <td>110</td>
    <td>1,07</td>
    <td>6-10</td>
    <td>1.3A & Thermocord</td>
  </tr>
  <tr>
    <td>DSPA 11-2</td>
    <td>170</td>
    <td>1,66</td>
    <td>9-15</td>
    <td>1.3A & Thermocord</td>
  </tr>
  <tr>
    <td>DSPA 11-3</td>
    <td>300</td>
    <td>2,92</td>
    <td>14-26</td>
    <td>1.3A & Thermocord</td>
  </tr>
  <tr>
    <td>DSPA 12-1</td>
    <td>15</td>
    <td>1,07</td>
    <td>6-10</td>
    <td>1.3A & Thermocord</td>
  </tr>
  <tr>
    <td>DSPA 12-2</td>
    <td>30</td>
    <td>1,66</td>
    <td>9-15</td>
    <td>1.3A & Thermocord</td>
  </tr>
  <tr>
    <td>DSPA 12-3</td>
    <td>55</td>
    <td>2,92</td>
    <td>14-26</td>
    <td>1.3A & Thermocord</td>
  </tr>
  <tr>
    <td>DSPA 12-4</td>
    <td>110</td>
    <td>8,76</td>
    <td>19,31</td>
    <td>1.3A & Thermocord</td>
  </tr>
  <tr>
    <td>DSPA 12-5</td>
    <td>5</td>
    <td>13,63</td>
    <td>50</td>
    <td>1.3A & Thermocord</td>
  </tr>
</table>
<p>Söndürme Mekanizması Diğer birçok söndürücü maddenin aksine, DSPA Aerosol yangındaki zincirleme reaksiyona müdahale ederek oksijen seviyelerini bozulmadan bırakır.
</p>
<p>Yanma sonucu açığa çıkan  OH, H ve O  radikaller yanmayı destekler. DSPA Aerosol, yangın bölgesindeki bu serbest radikallere kimyasal müdahale ederek yangını öncelikle bastırır, böylece yangının devam için gerekli olan zincirleme reaksiyonunu kesintiye uğratır. 
</p>
<p>DSPA Aerosol, tipik olarak yaklaşık 2 mikron çapa sahip, esas olarak potasyum parçacıkları olmak üzere ince bölünmüş katı Parçacıklardan oluşan bir yangın söndürme aracıdır. Küçük aerosol parçacıkları, bu radikalleri yakalamak için geniş bir yüzey alanı sağlayarak onları etkili söndürücü maddeler haline getirir.
</p>
<p>
Aerolos sistemleri bulunduğunuz ülkede geçerli standart gereği UL 2775, EN15276 – 1/2 ve NFPA 2010 standartlarından biri kullanılarak tasarlanabilir. Tasarımı yaparken özellikle kullandığınız standardın gerektirdiği miktarda söndürücü maddenin %30 güvenlik katsayısı ile genişletilerek gerçek uyulama yoğunluğu belirlenerek hesaplanması gerekir. Aerosol sistemleri ile korunacak alanın çok iyi incelenmesi, sistemin koruma kurgusunun mutlaka eğitim almış uygulama mühendisi tarafından belirlenmesi, saha yerleşiminin üretici firma manueli ve tasarım standardı gereği yapılmalıdır.

</p>
<p>
Aerosol sistemleri manuel, algılama destekli veya thermocord (kendinden aktivasyonlu) kurulabilmektedir. Elektriksel aktivasyon kullanıldığında kullanılan Kontrol paneli üzerinden tesisinizin genel algılama sistemine entegre edilebilmektedir. Özellikle Her bağımsız bölüm için Aerosol Söndürme Sistemi bağımsız tasarlanmakta ve kurulumu gerçekleştirilmektedir.

</p>
<p>
Özellikle DSPA – 8 ürün grubu manual kullanım için tasarlanmıştır. İtfaiye teşkilatları ve endüstriyel tesislerin söndürme ekiplerinin ürünün pimini çekerek aktive edip yangın alanına göndermeleri için tasarlanmıştır.

</p>
<p>
AKSAY Yangın DSPA işbirliği ile Aerosol Söndürme Sistemlerinin Tasarımı, Ön Mühendislik Hesapları, Tedarik Edilmesi, Montaj-Test ve Devreye Alma ve Kontratlı Bakım hizmetlerini yetişmiş Mühendis ve Teknisyen kadrosu ile gerçekleştirmektedir.  

</p>
      </div>
  
  
  
  
  
  
    </>)
  }