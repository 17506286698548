import divStyle from '../../../css/bannerCss';
import img from '../../../img/main/01- SULU SÖNDÜRME SİSTEMLERİ-YENİ/009- SİSMİK ASKILAMA ELEMANLARI ve DİLATASYON KOMPANSATÖRLERİ/1.png'
export default function Sismik() {
    const containerStyle = {
     
      
      maxWidth: '800px',
    
      
      fontFamily: 'Arial, sans-serif',
    };
  
    const headingStyle = {
      fontSize: '24px',
      marginTop: '0',
      fontWeight: '800',
    };

    return (<>
      <div style={containerStyle}>
        <div style={divStyle} >
          
        </div>
        <h2 style={headingStyle}>Sismik Askılama Elemanları</h2>
        <div style={{ minHeight: 1, backgroundColor: 'red', marginBottom: 10, marginTop: 10 }}></div>
        <p>
        Sismik askılama (deprem askısı) sistemi, boru, kanal vb. gibi asılı olan ekipmanların deprem sırasında asılı olarak kalmasını sağlayan sistemlerdir. Buradaki amaç, bu sistemlerin depremden sonra çalışabilir durumda olmasıdır. Olası depremin etkileri tüm dünya ve ülkemiz için her geçen gün daha da önemli bir tehdit haline gelmektedir. Depremin binanın betonarme veya çelik yapısına etkisi ciddi boyutlara ulaşmasa bile, bina içerisindeki yangından korunum sistemleri ve cihazlarına etkileri umulanın üzerinde ciddi sonuçlar doğurabilir. Can ve mal kayıpları ile sonuçlanabilecek bu etkiler ciddi bir prestij kaybına da sebep olur. Özellikle yangın tesisatlarının depremin ardından çalışır durumunu yanı yapısal bütünlüğünü muhafaza etmesi son derece önemlidir. Çünkü depremin ardından çıkan yangınların doğurduğu sonuçlar depremin etkisini çok ama çok üzerine çıkarabilmektedir. Olası bir depremi yaşamadan doğru mühendislik hesaplamaları ve doğru malzeme ile yangından korunum sistemleri ve cihazlarını korumak en güvenli yöntemdir.
        
        Sismik askılama işlemi; Enine İki Yönlü, Boyuna İki Yönlü ve dikey çıkan hatlarda ise Dört Yönlü olarak planlanmakta olup aşağıda belirtilen temel şartlar dikkate alınarak yerleşimi yapılır. Askılama işlemi boru bitim noktasından başlanacak ve başlangıç noktasına kadar devam ettirilecektir. Aşağıda verilen enine, boyuna ve düşey hatlardaki askılama mesafeleri maksimum kullanılabilecek askılama aralıklarını ifade etmektedir. Pratikte bu mesafeler dikkate alınarak veya altında kalacak şekilde montaj yapılır. Sabit (rijit) ve halat tipi sismik askılama sistemleri mevcuttur. Sabit (rijit) askılama elemanları sıklıkla kullanılmaktadır. Halatlı sismik askılama sistemleri bazı sigorta grupları tarafından kabul görmemektedir.
        <ul>
            <li>Enine askılama işlemi boru bitim noktasından itibaren 1.8m ve ilk askılama yapılacak ve ikinci askılama maksimum 12.2m’de devam eden askılama işlemi yine maksimum her 12.2m aralıklarla devam edecektir. Borunun dönüş yaptığı noktalarda dirsekten 0.6m önce enine askılama işlemi yapılacaktır.</li>
            <li>Boyuna askılama işlemi boru bitim noktasından itibaren 1.8m ve ilk askılama yapılacak ve ikinci askılama maksimum 12.2m’de devam eden askılama işlemi için maksimum 24.4m aralıklarla devam edecektir. Borunun dönüş yaptığı noktalarda dirsekten 0.6m önce enine askılama işlemi yapılacaktır.</li>
            <li>Dört yönlü askılama işlemi ise sadece düşey borulara uygulanacaktır. Düşey borularda dört yönlü askılama maksimum 7.6m aralıklarla uygulanacaktır.</li>
        </ul>
        Yaşanabilecek ciddi can ve mal kayıpları yanında yangından korunum sistemleri ve cihazlarının deprem koruması için gözden çıkarılacak yatırım maliyeti çok önemsiz kalacaktır. AKSAY Yangın yetkin teknik kadrosu ile yangından korunum sistemlerinin ve cihazlarının deprem anındaki hareketini sınırlamak için sismik koruma için projelendirme, doğru malzeme seçimi ve uygulama çalışmaları yapmaktadır. Sismik askılama-koruma sisteminin, projeye özel yapılan hesaplamaları ve uygulama detayları ile yaşam tehdidi azaltılır, bunun yanı sıra uzun vadede tesisat ve ekipman hasarları ile ilgili maliyetleri de minimize edilmiş olur. Sismik askılama elemanları UL Listeli ve FM sertifikalı olarak tedarik edilmekte ve uygulanmaktadır.
        </p>
   
        <img  style= {{width:800,height:700}} src= {img} alt="" />
      </div>
  
  
  
  
  
  
    </>)
  }