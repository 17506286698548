import banner from '../../../img/main/04- GAZLI SÖNDÜRME SİSTEMLERİ-YENİ/G-01  GAZLI SÖNDÜRME SİSTEMLERİ/Başlık.jpg'
import img from '../../../img/main/04- GAZLI SÖNDÜRME SİSTEMLERİ-YENİ/G-01  GAZLI SÖNDÜRME SİSTEMLERİ/birleşik.png'
export default function MainYazıGazlı() {
  const containerStyle = {
   
    maxWidth: '800px',
    marginBottom:20
    
  };

  const headingStyle = {
    fontSize: '24px',
   
    marginTop: '0',
    fontWeight: '800',
  };
  const divStyle={
    height: '200px',
    width: '100%',
    backgroundImage:`url(${banner})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    padding: 0,
    fontSize: "3rem",
    color: " #ffffff",
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom:20,
    textShadow: '4px 4px 4px rgba(0, 0, 0, 0.5)'
}

  return (<>
    <div style={containerStyle}>
      <div style={divStyle}>
       
      </div>
      <h2 style={headingStyle}>GAZLI SÖNDÜRME SİSTEMLERİ</h2>
      <div style={{ minHeight: 1, backgroundColor: 'red', marginBottom: 10, marginTop: 10 }}></div>
      <p>
        Yangın söndürme sisteminizde kullanabileceğiniz en etkili gaz hangisidir? Bu gaz ekonomik midir? Bu gazı her zaman bulabilir miyiz? ... Sorularının tek bir yanıtı yoktur. Doğru yanıt;

      </p>
      <ul>
    <li>Uygulamanın Şekli</li>
    <li>Uygulama Yapılacak Alan</li>
    <li>Kullanım Amacı</li>
    <li>Gaz Silindirleri ile Korunan Alan ile Arasındaki Mesafe</li>
    <li>Yatırım Maliyeti</li>
    <li>Bakım Maliyeti</li>
    <li>Yeniden Dolum Maliyeti</li>
    <li>Yerel Bulunabilirlik</li>
    <li>Çevre Dostuluğu</li>
</ul>
<p>
vb. etkenlere göre değişkenlik göstermektedir. Bu sebeple; uygulama yapılmadan önce, seçim kriterleri doğru şekilde analiz edilerek, uygulamanın yapılacağı mahalin gereklerine uygun söndürücü gaz tercih edilmelidir.

</p>
<p>
Uygulamanın yapılacağı mahalde, insan bulunması durumunda kullanılacak gazın insana zarar vermemesi temel gerekliliktir.<strong>FM-200 (HFC - 227ea), Novec – 1230 (FK-5-1-12) , IG-01 (Argon) IG-55 (Argonit) IG-100 (Nitrojen) </strong>ve<strong> IG541 (İnergen) </strong> vb. gazlar, sistem tasarımının doğru yapılması koşulu ile insan bulunan hacimlerde kullanılabilmektedir.  <strong>CO2</strong> gazı ise, insan bulunan hacimlerde kullanılmaması veya şartlı kullanılması tavsiye edilir.

</p>
<p>
Gazlı yangın söndürme sistemlerinde hacim koruma esastır. Bu sebeple uygulama yapılacak mahalin net hacmi hesaplanır. Korunacak mahalin net hacmi hesaplanırken; mahal içinde yer alana sabit yapıların (kolon, kiriş vb.) hacimleri mahal hacminden düşülür. Ayrıca mahalde bulunan asma tavan ve yükseltilmiş döşeme hacimleri net hacme eklenir. Bu noktadan sonra tasarlanacak söndürme sistemi temel gereklilikleri dikkate alınarak ön hesapları, detay planlama,  projelendirme ve hesaplama işlemi mevcut duruma göre yeniden yapılır.

</p>
<p>
Gazlı söndürme sistemlerinin başarısı uygulandıkları mahal içinde gerekli söndürme konsantrasyonuna erişilmesine bağlıdır. Bunun anlamı gazlı söndürme sistemi ile korunan hacmin sızdırmazlığı en az gerekli gaz konsantrasyonunu hacim içinde belirlenen zaman için tutacak nitelikte olmalıdır. Bu kriter sağlanamadığı zaman sistemin başarılı olabilme ihtimali yoktur. Bu amaçla <strong>"Dor-Fan Test"</strong> yapılarak korunan mahalin söndürme konsantrasyonunu standardın ön gördüğü süre için sağladığını yani sızdırmaz olduğunu teyit etmemiz gereklidir. Eğer <strong>"Dor-Fan Test"</strong> yapmadan yukarıda belirtilen kimyasal veya İnert gazlarla koruduğunuz alanda gerekli söndürme konsantrasyonu yeterli süre için sağlanamıyor ise söndürme gerçekleşmeyecektir. Bu durumda alanda mimari açıdan iyileştirme yapılmalıdır. Testler yinelenir sızdırmazlık belirlenen süre için sağlanana kadar inşai iyileştirmeler ve testler tekrarlanır.

</p>
<p>
Bütün gazlı söndürme sistemlerinde <strong>"Dor-Fan Test"</strong> işlemi kadar <strong>"Puf Test"</strong> ve Boru Sızdırmazlık Testleri de yapılmadır.  <strong>"Puf Test"</strong> ile boru ağı içinde kalan tesisat artıkları basınçlı hava veya Azot gazı ile süpürülür. Boru ağı içinde tesisat artıkları kalmadığından emin olunduktan sonra <strong>"Puf Test"</strong> bitirilir. <strong>"Boru Sızdırmazlık"</strong> Testine geçilir. <strong>"Boru Sızdırmazlık"</strong>Testi (LEAK TEST) için nozul bağlantı noktaları kör tapa sıkılarak kapatılır. Boru ağına 3bar basınç uygulanır. 10 dakika beklenir ve 10. dakikanın sonunda uygulama basınının maksimum %20 oranında düşmesi yani manometre basıncının 3 bardan – 2.4bar değerine kadar düşmesi kabul edilebilir. Bu şartlarda boru ağı sızdırmaz kabul edilir. Eğer 2.4bar değerinin altında bir basınç düşümü gözleniyor ise boru ağında iyileştirmeye gidilmelidir.

</p>
<p>
Gazlı yangın söndürme sistemlerinde Lokal Koruma da yapılabilmektedir. Bu amaçla CO2 gazı kullanılmaktadır. Özellikle CO2 gazını hacim söndürme amaçlı kullandığımızda <strong>"Dor-Fan Test"</strong> zorunluluğu bulunmamaktadır. Ancak "<strong>"Puf Test"</strong>" ve <strong>"Boru Sızdırmazlık Testi"</strong> yapılması gereklidir.

</p>
<p>
AKSAY YANGIN güçlü mühendislik birikimi ve alternatifli sistem seçenekleri ile hızlı ve güvenilir hizmet vermek için size bir telefon kadar yakındır. AKSAY YANGIN yatırımlarınızı güvenle korur.

</p>
<img  style={{width:500,height:312}} src={img} alt="" />

    </div>






  </>)
}