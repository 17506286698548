import { Container } from "react-bootstrap";

export default function Missyon() {

  const divStyle = {
    height: '100px',
    width: '100%',
    background: ' rgb(0,55,105)',
    background: '   linear-gradient(90deg, rgba(0,55,105,1) 0%, rgba(0,130,226,1) 100%)',
    paddingTop:10,
    fontSize: "3rem",
    color:" #ffffff",
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textShadow: '4px 4px 4px rgba(0, 0, 0, 0.5)'
  };
return(<>
<div style={divStyle}>
            <p>Misyon Ve Vizyon</p>
      </div>
<Container style={{minHeight:600 ,paddingLeft:20,paddingTop:20}}>
  <p>
  AKSAY YANGIN ve GÜVENLİK SİSTEMLERİ SANAYİ ve TİCARET LİMİTED ŞİRKETİ  Müşteri memnuniyetini, sürekli gelişmeyi, kaliteyi, ekip çalışmasını, sosyal sorumluluğu, profesyonel yönetim anlayışını, teknolojik gelişmelere uygunluğu ve paylaşımı esas alan bir anlayışla sektöründe lider bir kuruluş olmayı kendine "VİZYON" edinmiştir. 
  </p>
  <p>
  Ayrıca belirlemiş olduğumuz bu vizyon çerçevesinde müşterilerimize, çalışanlarımıza, ülkemize kaynak ve değer yaratan örnek bir kuruluş olmayı kendimize "MİSYON" edindik. 
  </p>
</Container>

</>)

}