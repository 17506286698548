import '../../../css/footer.css'
import banner from '../../../img/main/013-ALGILAMA SİSTEMLERİ/004- PATLAYICI GAZ ALGILAMA SİSTEMLERİ/Başlık.png'
export default function Patlayıcı() {
    const containerStyle = {
      
      
        maxWidth: '800px',
     
        
        fontFamily: 'Arial, sans-serif',
      };
    
      const headingStyle = {
        fontSize: '24px',
        marginTop: '0',
        fontWeight: '800',
      };
      const divStyle={
        height: '200px',
        width: '100%',
        backgroundImage:`url(${banner})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        padding: 0,
        fontSize: "3rem",
        color: " #ffffff",
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom:20,
        textShadow: '4px 4px 4px rgba(0, 0, 0, 0.5)'
    }
    return (<>
  <div style={containerStyle}>
     <div style={divStyle}>
      
     </div>
      <h2 style={headingStyle}>Yanıcı ve Patlayıcı Gaz Algılama </h2>
      <div style={{minHeight:1, backgroundColor:'red',marginBottom:10,marginTop:10}}></div>
      <p>Endüstriyel risklerin söz konusu olduğu noktalarda gaz algılama ve ihbar sistemleri kaçınılmaz bir gereklilik olarak karşımıza çıkmaktadır. Parlayıcı ve patlayıcı gazların bir üretim prosesi içerisinde ortaya çıktığı, depolandığı ve nakledildiği ortamlarda, gaz kaçaklarının patlayıcı karışım oluşmadan algılanması ve gerekli önlemlerin alınması yaşamsal önem taşır.</p>

<p>Gazların endüstride kullanımının artması, gazlardan kaynaklanan risklere dair önlemler alınmasını zorunlu kılmaktadır. Proses sürecinde kullanılan çeşitli gazlar zehirlenme ve yanma/patlama tehlikeleri oluşturur. Bu tehlikeleri kontrol altında tutmak için gaz algılama sistemleri kullanılmalıdır. Bir gaz algılama sistemi, olası tüm tehlikeleri algılayabilmeli ve gerekli önlemlerin alınabilmesi için zamanında uyarı vermelidir. Bir gaz algılama sisteminin performansı aşağıdaki unsurlara bağlıdır.</p>

<ul>
  <li>Seçilen algılama teknolojisinin (katalitik veya Infrared) uygunluğu.</li>
  <li>Dedektörlerin sayısı, montaj yüksekliği ve yeri.</li>
  <li>Çevre koşulları (kirlilik, hava hareketleri, rüzgar vb)</li>
  <li>Açık veya Kapalı alanda kullanımı</li>
  <li>Açma-kapama veya havalandırma sistemine kumanda</li>
</ul>


<p>
Sistemin uygun şekilde tasarlanabilmesi için;

</p>
<p>
Sistem performansı her bir işlem biriminde olası tehlikeler göz önüne alınarak tanımlanmalıdır. Uygun tasarım yapmak için işlem süreçleri ve algılama teknolojileri hakkında derinlemesine bilgi gerekir. Ayrıca dedektörlerin mekanik ve elektriksel özellikleri, monte edilecekleri ortam ve konuma uygun olmalarını sağlamak gereklidir. Kullanılacak teknolojilere karar verirken olası yanlış alarm potansiyelini değerlendirmek önemlidir. Yanlış alarmlar acil kapatmaları tetikleyebileceğinden işlem sürekliliği açısından sorun oluşturacaktır. Yanlış alarmların ve istenmeyen aktivasyonların olasılığını azaltmak için performans sertifikalı algılama sistemleri uygun şekilde kurulmalıdır.

</p>
<p>
Patlayıcı özellikleri olmamakla birlikte zehirleyici veya boğucu nitelikte olan karbonmonoksit, klor, karbondioksit, amonyak gibi gazlara duyarlı dedektörler de günümüzde endüstriyel sahalarda, kapalı garajlarda, kazan dairelerinde kullanılmaktadır.

</p>
<p>
Bu gazların etkilerinden meydana gelebilecek yangın ve patlamaları önlemek çok önemlidir. Bu tür yangın ve patlama olaylarının önüne geçmek, can ve mal kaybını en aza indirmek için gaz algılama sistemlerinin kurulması temel gerekliliklerin başında gelmektedir.

</p>
<p style={{fontWeight:600}}>Endüstriyel Gaz Algılama Sistemlerin Kullanım Alanları</p>

<ul>
  <li>Gaz Dağıtım ve Dolum Odaları</li>
  <li>Otoparklar</li>
  <li>Kimya Endüstrisi</li>
  <li>Boya ve Vernik Üretim Alanları</li>
  <li>Solvent Depoları</li>
  <li>Atık Su Arıtma Tesisleri</li>
  <li>Laboratuarlar</li>
  <li>Rafineriler</li>
  <li>Tüneller</li>
  <li>Boya Kabinleri</li>
  <li>Soğuk Depolar</li>
  <li>Endüstriyel Mutfaklar</li>
  <li>Kazan Daireleri Madenler</li>
  <li>Lastik, Plastik ve Kauçuk Üretim Tesisleri</li>
</ul>

<p>Bir gaz algılama sistemi dünyanın neresine kurulursa kurulsun, tüm geçerli kural ve standartlara uygun olarak tasarlanmalıdır. Sistemlerin uyumlu olması ise kullanılan cihazların da bu standartları gözeterek üretilmiş olmasına ve gerekli sertifikalara sahip olmasına bağlıdır.</p>

<p>Kurulan her sistemin düzenli kontrol, bakım ve kalibrasyon desteğine ihtiyacı vardır. Özellikle ortamın kirliliği veya kimyasal madde ile olan temas miktarı ve süresi kalibrasyonun belirlenen planlamadan daha önce gerekmesine neden olabilir.</p>

<p><em>AKSAY Yangın özellikle Yanıcı ve Parlayıcı Gaz Algılama Sistemlerinin Tasarımı, Malzeme Seçimi, Tedarik Edilmesi, Montaj – Test - Devreye Alma ve Kontratlı Bakım hizmetlerini yetişmiş Mühendis ve Teknisyen kadrosu ile gerçekleştirmektedir.</em></p>

    </div>






    </>)
}