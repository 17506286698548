import '../../../css/footer.css'
import banner from '../../../img/main/015- PASİF YANGIN KORUNUM SİSTEMLERİ/Başlık.png'
export default function PasifYangın() {
    const containerStyle = {
       
      
        maxWidth: '800px',
        
        
        fontFamily: 'Arial, sans-serif',
      };
    
      const headingStyle = {
        fontSize: '24px',
        marginTop: '0',
        fontWeight: '800',
      };
      const divStyle={
        height: '200px',
        width: '100%',
        backgroundImage:`url(${banner})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        padding: 0,
        fontSize: "3rem",
        color: " #ffffff",
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom:20,
        textShadow: '4px 4px 4px rgba(0, 0, 0, 0.5)'
    }
    return (<>
  <div style={containerStyle}>
     <div style={divStyle}>
      
     </div>
      <h2 style={headingStyle}>Yangından Korunma </h2>
      <div style={{minHeight:1, backgroundColor:'red',marginBottom:10,marginTop:10}}></div>
      
  <p>
    Yangından korunmak için sadece aktif yangın korunma sistemleri yeterli değildir. Bir tesisin veya binanın korunması için aktif ve pasif yangından korunum sistemlerinin birlikte kullanılması gereklidir.
    Pasif yangın durdurucu malzemeler, tüm dünyada olduğu gibi ülkemizdeki yangından korunma yönetmeliğinde de belirtildiği üzere yangının etkilerinden korunma, yangın sonrası oluşacak risklerin en aza indirilmesi, can güvenliğinin sağlanması için yapılarda özellikle kullanılması gerekliliği belirtilen bir malzeme türüdür.
    Acil çıkış yollarında ve koridorlarda kullanılması ile insanlara yangın anında dışarı çıkışlar için ilave zaman kazandırır. Yapıyı yangının etkilerine karşı koruyarak, yangın esnasında yapının hizmet vermesi için gerekli bütünlüğü ve sürekliliği sağlar. Yapının ciddi anlamda deforme olmasını geciktireceği veya engelleyeceği için tamir ve yeniden inşa maliyetlerini azaltır. Yangının kontrolsüz yayılmasını, alev ve dumanın hareketini kısıtlayarak mal ve can kaybını azaltır. Böylece yatırımınızı ve sevdiklerinizi korur.
  </p>

  <p style={{fontWeight:600}}>Yangın Durdurucular Nasıl Çalışır?</p>
  <p>
    Yangın Durdurma ilkesi doğru bölümlendirmeye / kompartmanlamaya dayanmaktadır. Doğru bölmelendirme ile yangın bölmelerin dışına çıkmadan kalacak ve bir süre binaya yayılmayacaktır. Zeminler, duvarlar ve tavan montajları gibi ana bölme yapıları, belirli bir zaman dilimine kadar yangına dayanacaktır.
    Binalar elektrik, ağ bağlantıları, su, hava ve gaz ile donatıldığından bu yapılardan çeşitli hizmet şaftları geçmektedir. Bölümlendirmenin işlevselliği için bu ek yerlerin ve şaftlarında yalıtımına ihtiyacı vardır.
    Yangın durdurucu uygulanmadığı takdirde alev, ısı ve duman bölmedeki tüm açıklıklardan yayılacaktır. Doğru uygulanmış Yangın Durdurma çözümleri yangını başladığı yerde hedeflenen süre kadar tutacaktır.
  </p>

  <p style={{fontWeight:600}}>Yangın Durdurucu Uygulaması Neden Önemlidir?</p>
  <p>
    Bir binada yangın çıkması durumunda ve özellikle Yangın Durdurmanın yetersiz olması durumunda, yangın ve duman bina içinde çok hızlı yayılır. Trajik bir şekilde, yangının kurbanların büyük çoğunluğunun yangının başladığı odada dahi olmadığı belirlenmiştir. Ve yangında ölenlerin %80'i duman solunmasıyla ilgilidir.
    Yangın Durdurucu ürünler alevlerin, ısının ve dumanın yayılmasını engeller. Binanın geri kalanı güvenli olacak ve güvenli bir şekilde tahliye ve kurtarmaya olanak sağlayacaktır.
  </p>

  <p style={{fontWeight:600}}>Yangın Kompartımanı Nedir?</p>
  <p>
    Yangın kompartımanı, tahliye için gereken süre zarfında binanın içindeki alevlerin sadece bir bölümde kalmasını sağlamak için bir binanın içindeki tavan ve taban dahil olmak üzere her yanının en az 60 dakika boyunca yangına karşı ısı, alev ve duman geçirmez bölgelere ayrılması demektir.
  </p>

  <p style={{fontWeight:600}}>Kullanım Alanları</p>
  <ul>
    <li>Yatay ve Düşey Boru Geçişleri</li>
    <li>Yatay veya Düşey Kablo Geçişleri</li>
    <li>Yatay ve Düşey Şaftlar</li>
    <li>Duvar, Zemin veya Tavan Açıklıkları</li>
    <li>Cephe İzolasyonları</li>
    <li>Yangın Zonu Planlaması</li>
    <li>Bölümler Arası Yalıtılmış Duvarlar Oluşturulması</li>
    <li>Acil Durum Tahliye ve Kaçış Koridorları</li>
    <li>Çelik veya Betonarme Taşıyıcı Sistemlerin Kaplanması</li>
    <li>Havalandırma Kanal Geçişleri ve Kanallarının Kaplanması</li>
  </ul>

<p>
AKSAY Yangın pasif yangından korunum sistemleri konusunda eğitimli personeli ile hizmet vermektedir.
Binanızın veya tesisinizin güvende kalması için sahanın risklerini dikkate alarak çalışmaktadır.
</p>
</div>





    </>)
}