import { useParams } from "react-router-dom";
import { info } from "../info/Info";
import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import '../App.css'

function System() {

    const divStyle = {
        height: '25px',
        width: '100%',
        
        padding: 0,
        fontSize: "3rem",
        color: " #ffffff",
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        
        
    };
    const { name } = useParams();
    const [currentData, setCurrentData] = useState();
    const [dataId, SetDataId] = useState(0)
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {

        let foundData = info.find(item => item.name == name);
        setCurrentData(foundData);
        console.log(foundData)
        setIsLoading(false); // İşlem tamamlandığında isLoading'i false yap


    });

    const setData = (id) => {
        SetDataId(id)
    };
    if (isLoading) {
        return <div>Loading...</div>;
    }
    return (<>
        <div style={divStyle} className="gradient">

        </div>
        <Container style={{ paddingTop: 10, paddingLeft:20 }}>
            <Row>
               
                <Col lg={3} style={{ backgroundColor: 'white' }}>

                    {currentData && currentData.subtiltles.map((subtitle, index) => (
                        <div key={index} className="subtitle-item" style={{ padding: 10, color: dataId === currentData.subtiltles.indexOf(subtitle) ? 'white' : 'black', backgroundColor: dataId === currentData.subtiltles.indexOf(subtitle) ? 'red' : 'white' }}>
                            <div onClick={() => setData(currentData.subtiltles.indexOf(subtitle))}>{subtitle}</div>
                        </div>
                    ))}
                    {currentData.photos[dataId] && currentData.photos[dataId].photo && currentData.photos[dataId].photo.length > 0 ? (
                        <div>
                            {currentData.photos[dataId].photo.map((item) => (
                                <img
                                    key={item}
                                    src={item}
                                    alt={`Photo ${item.id}`}
                                    style={{
                                        width: '350px',  // İstediğiniz genişlik değerini buraya ekleyin
                                        height: '250px', // İstediğiniz yükseklik değerini buraya ekleyin
                                        objectFit: 'contain', // veya 'contain' kullanabilirsiniz
                                        marginTop: 30,
                                        marginBottom: 30,
                                       
                                    }}
                                />
                            ))}
                        </div>
                    ) : <></>}

                </Col>
                <Col lg={9} style={{ backgroundColor: 'white', paddingRight: 100  }}>
                    {currentData.data[dataId] ? currentData.data[dataId].component : currentData.data[0].component}
                </Col>
            </Row>
        </Container>
    </>
    );
}

export default System;