import divStyle from '../../../css/bannerCss';

export default function Oda() {
    const containerStyle = {
       
        maxWidth: '800px',
       
        fontFamily: 'Arial, sans-serif',
      };
    
      const headingStyle = {
        fontSize: '24px',
        marginTop: '0',
      
        fontWeight: '800',
      };
    
      const subHeadingStyle = {
        fontWeight: '800',
        marginTop: '20px',
      };
    
      const listStyle = {
        paddingLeft: '20px',
      };
  
    return (<>
  <div style={containerStyle}>
  <div style={divStyle}>
    
    </div>
      <h2 style={headingStyle}>Door Fan Test /  Oda Sızdırmazlık Testi</h2>
      <div style={{minHeight:1, backgroundColor:'red',marginBottom:10,marginTop:10}}></div>
      <p>Door Fan Test   FM-200, Novec 1230, IG-01, IG-55, IG100 ve IG541 gazlı söndürme sistemi uygulanan mahallerde söndürmenin sağlıklı gerçekleşmesi için zorunlu olarak uygulanması ve belgelenmesi gereken bir testtir.
</p>
<p>Gazlı söndürme sistemlerinin başarısı uygulandıkları mahal içinde gerekli söndürme konsantrasyonuna erişilmesine bağlıdır. Bunun anlamı gazlı söndürme sistemi ile korunan hacmin sızdırmazlığı en az gerekli gaz konsantrasyonunu hacim içinde belirlenen zaman için tutacak nitelikte olmalıdır. Bu kriter sağlanamadığı zaman sistemin başarılı olabilme ihtimali yoktur. Bu amaçla Dor-Fan Test yapılarak korunan mahalin söndürme konsantrasyonunu standardın ön gördüğü süre için sağladığını yani sızdırmaz olduğunu teyit etmemiz gereklidir. Eğer Dor-Fan Test yapmadan yukarıda belirtilen kimyasal veya İnert gazlarla koruduğunuz alanda gerekli söndürme konsantrasyonu yeterli süre için sağlanamıyor ise söndürme gerçekleşmeyecektir. Bu durumda alanda mimari açıdan iyileştirme yapılmalıdır. Testler yinelenir sızdırmazlık belirlenen süre için sağlanana kadar inşai iyileştirmeler ve testler tekrarlanır. Tespit edilen açıklıklar var ise yangın durdurucu malzemeler ile kapatılıp test tekrardan yapılmalıdır.
</p>
<p>
Dor Fan Test sistemin tasarım standardına bağlı olarak söndürücü gazın 10 dk içeride kalmasını test etmek amacıyla yapılması zorunlu olan testir. İçeriye nüfuz eden gaz cinsi ne olursa olsun door fan testlerin her odaya uygulanması zorunludur. İlgili standartlarca belirtilen koşullara göre; Door fan testi için ortama nüfuz eden gazın 10 dk sonrasında ortam içinde en az %85’inin bulunması gerekmektedir. Aksi halde mahal testten geçmeyecektir.

</p>
<p>
Door fan testi için akredite kuruluşlarda yapılan eğitimler neticesinde başarı sertifikası alınıp sadece ilgili personelin yapmakla hükümlü olduğu işlemlerdir. Yangını söndürmek için kullanılan gaz halindeki kimyasal maddelerin çoğunun havadan daha ağır olduğu göz önüne alınmalıdır.
Boşalmaya başlayan söndürücü gaz yerden yükselerek mahali doldurmaya başlayacağı için alçak seviyeli kaçak alanlarından çok hızlı sızmaya başlayacaktır. Test sırasında gaz kaçağına neden olabilecek damper vb. açıklıkların otomasyon ile kapatılması gerekmektedir. 

</p>
<p>
Yapılacak testin sonucu bir rapor haline ilgili yazılımdan alınacak sonuç raporuna göre idareye teslim edilmektedir.

</p>
    </div>






    </>)
}