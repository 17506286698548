import banner from '../img/mainFoto/aksay800x200-banneriçbaşlık.png'
const divStyle={
    height: '200px',
    width: '100%',
    backgroundImage:`url(${banner})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    padding: 0,
    fontSize: "3rem",
    color: " #ffffff",
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom:20,
    textShadow: '4px 4px 4px rgba(0, 0, 0, 0.5)'
}
export default divStyle