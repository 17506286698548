import Kopuklu from "../pages/systems/Kopuklu/Kopuklu";
import Yuksek from '../pages/systems/Kopuklu/Yuksek';
import Urunler from "../pages/systems/Kopuklu/Urunler";
import Konsantre from "../pages/systems/Kopuklu/Konsantre";
import Dusuk from "../pages/systems/Kopuklu/Dusuk";
import WaterMist from "../pages/systems/Sulu/Watermist";
import Islak from "../pages/systems/Sulu/Islak";
import Kuru from "../pages/systems/Sulu/Kuru";
import Deluge from "../pages/systems/Sulu/Deluge";
import Preaction from "../pages/systems/Sulu/Preaction1";
import Basınc from "../pages/systems/Sulu/Basınç";
import Akıs from "../pages/systems/Sulu/Akıs";
import Sprinker from "../pages/systems/Sulu/Sprinker";
import Sprey from "../pages/systems/Sulu/Sprey";
import Yivli from "../pages/systems/Sulu/Yivli";
import Fm200 from "../pages/systems/Gazlı/Fm200";
import Novec from "../pages/systems/Gazlı/Novec";
import CO2 from "../pages/systems/Gazlı/CO2";
import Inert from "../pages/systems/Gazlı/Inert";
import Oda from "../pages/systems/Gazlı/Oda";
import Araç from "../pages/systems/Kurutozlu/Araç ";
import Endüstriyel from "../pages/systems/Kurutozlu/Endüstriyel";
import Flowmetre from "../pages/systems/YangınPompa/Flowmetre";
import Mutfak from "../pages/systems/Mutfak/Mutfak";
import Lineer from "../pages/systems/Lineer/Lineer";
import Hava from "../pages/systems/Hava/Hava";
import Kıvılcım from "../pages/systems/kvılcım/Kıvılcım";
import DemoSayfa from "../pages/systems/DemoSayfa";
import MainYazıGazlı from "../pages/systems/Gazlı/MainYazıGazlı";
import MainYazıKopuk from "../pages/systems/Kopuklu/MainYazıKopuk";
import MainYazıSulu from "../pages/systems/Sulu/MainYazıSulu";
import SuveKopuk from "../pages/systems/SuveKopuk/SuveKopuk";
import MainYazıPompa from "../pages/systems/YangınPompa/MainYazıPompa";
import MainYazıKuruTozlu from "../pages/systems/Kurutozlu/MainYazıKuruTozlu";
import MainYazıDolap from "../pages/systems/YangınDolap/MainYazıDolap";
import Aerosol from "../pages/systems/Aerosol/Aerosol";
import Tip from "../pages/systems/Kopuklu/Tip";
import Relief from "../pages/systems/YangınPompa/Relief";
import Sismik from "../pages/systems/Sulu/Sismik";


import FireDetect from "../pages/systems/FireDetect/FireDetect";

import Cevreci from '../pages/systems/KopukKonsantre/Cevreci'
import Solberg from '../pages/systems/KopukKonsantre/Solberg'
import MainYazıKopukConst from '../pages/systems/KopukKonsantre/MainKopukConst'
import Aclass from '../pages/systems/KopukKonsantre/Aclass'
import Bclass from '../pages/systems/KopukKonsantre/Bclass'
import Egitim from '../pages/systems/KopukKonsantre/Egitim'
import Caf from '../pages/systems/KopukKonsantre/Caf'

import PasifYangın from "../pages/systems/Pasif/PasifYangın";
import Patlayıcı from "../pages/systems/Patlayıcı/Patlayıcı";
import Panel from "../pages/systems/Panel/Panel";
import YangınVana from "../pages/systems/Sulu/YangınVana";
import YangınAlgılama from "../pages/systems/YangınAlgılama/YangınAlgılama";

import Bakım from "../pages/systems/Bakım/Bakım";



//Resimler 
import sulu1 from  '../img/main/01- SULU SÖNDÜRME SİSTEMLERİ-YENİ/001-SULU SÖNDÜRME SİSTEMLERİ/1new.png'
import sulu2 from  '../img/main/01- SULU SÖNDÜRME SİSTEMLERİ-YENİ/001-SULU SÖNDÜRME SİSTEMLERİ/2.png'
import sulu3 from  '../img/main/01- SULU SÖNDÜRME SİSTEMLERİ-YENİ/001-SULU SÖNDÜRME SİSTEMLERİ/3.png'
import sulu4 from  '../img/main/01- SULU SÖNDÜRME SİSTEMLERİ-YENİ/001-SULU SÖNDÜRME SİSTEMLERİ/4.png'

import ıslak1 from '../img/main/01- SULU SÖNDÜRME SİSTEMLERİ-YENİ/002- ISLAK BORULU SPRİNKLER SİSTEMİ/1.png'
import ıslak2 from '../img/main/01- SULU SÖNDÜRME SİSTEMLERİ-YENİ/002- ISLAK BORULU SPRİNKLER SİSTEMİ/2.png'
import ıslak3 from '../img/main/01- SULU SÖNDÜRME SİSTEMLERİ-YENİ/002- ISLAK BORULU SPRİNKLER SİSTEMİ/3.png'

import kuru1 from '../img/main/01- SULU SÖNDÜRME SİSTEMLERİ-YENİ/003- KURU BORULU SPRİNKLER SİSTEMİ/1.png'
import kuru2 from '../img/main/01- SULU SÖNDÜRME SİSTEMLERİ-YENİ/003- KURU BORULU SPRİNKLER SİSTEMİ/2.jpg'



import baskın1 from '../img/main/01- SULU SÖNDÜRME SİSTEMLERİ-YENİ/004-BASKIN SİSTEMLER/1.png'
import baskın2 from '../img/main/01- SULU SÖNDÜRME SİSTEMLERİ-YENİ/004-BASKIN SİSTEMLER/2.png'
import baskın3 from '../img/main/01- SULU SÖNDÜRME SİSTEMLERİ-YENİ/004-BASKIN SİSTEMLER/3.png'
import baskın4 from '../img/main/01- SULU SÖNDÜRME SİSTEMLERİ-YENİ/004-BASKIN SİSTEMLER/4.png'

import ön1 from '../img/main/01- SULU SÖNDÜRME SİSTEMLERİ-YENİ/005- ÖN TEPKİLİ SİSTEMLER/1.png'
import ön2 from '../img/main/01- SULU SÖNDÜRME SİSTEMLERİ-YENİ/005- ÖN TEPKİLİ SİSTEMLER/2.png'
import ön3 from '../img/main/01- SULU SÖNDÜRME SİSTEMLERİ-YENİ/005- ÖN TEPKİLİ SİSTEMLER/3.png'
import ön4 from '../img/main/01- SULU SÖNDÜRME SİSTEMLERİ-YENİ/005- ÖN TEPKİLİ SİSTEMLER/4.png'

import sprinker1 from '../img/main/01- SULU SÖNDÜRME SİSTEMLERİ-YENİ/006-SPRİNKLER BAŞLIKLARI/1.jpg'
import sprinker2 from '../img/main/01- SULU SÖNDÜRME SİSTEMLERİ-YENİ/006-SPRİNKLER BAŞLIKLARI/2.jpg'
import sprinker3 from '../img/main/01- SULU SÖNDÜRME SİSTEMLERİ-YENİ/006-SPRİNKLER BAŞLIKLARI/3.png'
import sprinker4 from '../img/main/01- SULU SÖNDÜRME SİSTEMLERİ-YENİ/006-SPRİNKLER BAŞLIKLARI/4.jpg'
import sprinker5 from '../img/main/01- SULU SÖNDÜRME SİSTEMLERİ-YENİ/006-SPRİNKLER BAŞLIKLARI/5.jpg'
import sprinker6 from '../img/main/01- SULU SÖNDÜRME SİSTEMLERİ-YENİ/006-SPRİNKLER BAŞLIKLARI/6.png'

import sogutma1 from '../img/main/01- SULU SÖNDÜRME SİSTEMLERİ-YENİ/007-SOĞUTMA  NOZULLARI/1.png'
import sogutma2 from '../img/main/01- SULU SÖNDÜRME SİSTEMLERİ-YENİ/007-SOĞUTMA  NOZULLARI/2.png'
import sogutma3 from '../img/main/01- SULU SÖNDÜRME SİSTEMLERİ-YENİ/007-SOĞUTMA  NOZULLARI/3.png'
import sogutma4 from '../img/main/01- SULU SÖNDÜRME SİSTEMLERİ-YENİ/007-SOĞUTMA  NOZULLARI/4.jpg'
import sogutma5 from '../img/main/01- SULU SÖNDÜRME SİSTEMLERİ-YENİ/007-SOĞUTMA  NOZULLARI/5.jpg'
import sogutma6 from '../img/main/01- SULU SÖNDÜRME SİSTEMLERİ-YENİ/007-SOĞUTMA  NOZULLARI/6.jpg'
import sogutma7 from '../img/main/01- SULU SÖNDÜRME SİSTEMLERİ-YENİ/007-SOĞUTMA  NOZULLARI/7.jpg'
import sogutma8 from '../img/main/01- SULU SÖNDÜRME SİSTEMLERİ-YENİ/007-SOĞUTMA  NOZULLARI/8.jpg'
import sogutma9 from '../img/main/01- SULU SÖNDÜRME SİSTEMLERİ-YENİ/007-SOĞUTMA  NOZULLARI/9.jpg'
import sogutma10 from '../img/main/01- SULU SÖNDÜRME SİSTEMLERİ-YENİ/007-SOĞUTMA  NOZULLARI/10.jpg'
import sogutma11 from '../img/main/01- SULU SÖNDÜRME SİSTEMLERİ-YENİ/007-SOĞUTMA  NOZULLARI/11.png'
import sogutma12 from '../img/main/01- SULU SÖNDÜRME SİSTEMLERİ-YENİ/007-SOĞUTMA  NOZULLARI/12.png'

import yangınvana1 from '../img/main/01- SULU SÖNDÜRME SİSTEMLERİ-YENİ/008-YANGIN SİSTEMİ VANALARI/Birleşmiş1.jpg'

import sismik1 from '../img/main/01- SULU SÖNDÜRME SİSTEMLERİ-YENİ/009- SİSMİK ASKILAMA ELEMANLARI ve DİLATASYON KOMPANSATÖRLERİ/1.png'
import sismik2 from '../img/main/01- SULU SÖNDÜRME SİSTEMLERİ-YENİ/009- SİSMİK ASKILAMA ELEMANLARI ve DİLATASYON KOMPANSATÖRLERİ/2.png'
import sismik3 from '../img/main/01- SULU SÖNDÜRME SİSTEMLERİ-YENİ/009- SİSMİK ASKILAMA ELEMANLARI ve DİLATASYON KOMPANSATÖRLERİ/3.png'
import sismik4 from '../img/main/01- SULU SÖNDÜRME SİSTEMLERİ-YENİ/009- SİSMİK ASKILAMA ELEMANLARI ve DİLATASYON KOMPANSATÖRLERİ/4.png'

import yivli1 from '../img/main/01- SULU SÖNDÜRME SİSTEMLERİ-YENİ/010-YİVLİ BORU BAĞLANTI ELEMANLARI/Birleşmiş.png'

import kopuk1 from '../img/main/02- KÖPÜKLÜ SÖNDÜRME SİSTEMİ-YENİ/K-01  KÖPÜKLÜ SÖNDÜRME SİSTEMLERİ - KARŞILAMA YAZISI/1.png'
import kopuk2 from '../img/main/02- KÖPÜKLÜ SÖNDÜRME SİSTEMİ-YENİ/K-01  KÖPÜKLÜ SÖNDÜRME SİSTEMLERİ - KARŞILAMA YAZISI/2.png'

import kopukN1 from '../img/main/02- KÖPÜKLÜ SÖNDÜRME SİSTEMİ-YENİ/K-02  KÖPÜK NEDİR/1.jpg'
import kopukN2 from '../img/main/02- KÖPÜKLÜ SÖNDÜRME SİSTEMİ-YENİ/K-02  KÖPÜK NEDİR/2.jpg'

import dusuk1 from '../img/main/02- KÖPÜKLÜ SÖNDÜRME SİSTEMİ-YENİ/K-03  DÜŞÜK GENLEŞMELİ KÖPÜKLÜ SÖNDÜRME SİSTEMLERİ/1.png'
import dusuk2 from '../img/main/02- KÖPÜKLÜ SÖNDÜRME SİSTEMİ-YENİ/K-03  DÜŞÜK GENLEŞMELİ KÖPÜKLÜ SÖNDÜRME SİSTEMLERİ/2.jpg'
import dusuk3 from '../img/main/02- KÖPÜKLÜ SÖNDÜRME SİSTEMİ-YENİ/K-03  DÜŞÜK GENLEŞMELİ KÖPÜKLÜ SÖNDÜRME SİSTEMLERİ/3.png'

import orta1 from '../img/main/02- KÖPÜKLÜ SÖNDÜRME SİSTEMİ-YENİ/K-04  ORTA GENLEŞMELİ KÖPÜKLÜ SÖNDÜRME SİSTEMLERİ/1.png'
import orta2 from '../img/main/02- KÖPÜKLÜ SÖNDÜRME SİSTEMİ-YENİ/K-04  ORTA GENLEŞMELİ KÖPÜKLÜ SÖNDÜRME SİSTEMLERİ/2.jpg'

import yuksek1 from '../img/main/02- KÖPÜKLÜ SÖNDÜRME SİSTEMİ-YENİ/K-05  YÜKSEK GENLEŞMELİ KÖPÜK SİSTEMLERİ/1.png'
import yuksek2 from '../img/main/02- KÖPÜKLÜ SÖNDÜRME SİSTEMİ-YENİ/K-05  YÜKSEK GENLEŞMELİ KÖPÜK SİSTEMLERİ/2.png'
import yuksek3 from '../img/main/02- KÖPÜKLÜ SÖNDÜRME SİSTEMİ-YENİ/K-05  YÜKSEK GENLEŞMELİ KÖPÜK SİSTEMLERİ/3.png'
import yuksek4 from '../img/main/02- KÖPÜKLÜ SÖNDÜRME SİSTEMİ-YENİ/K-05  YÜKSEK GENLEŞMELİ KÖPÜK SİSTEMLERİ/4.png'

import korunum1 from '../img/main/02- KÖPÜKLÜ SÖNDÜRME SİSTEMİ-YENİ/K-06  KÖPÜKLÜ SİSTEM SİSTEMİ TİPLERİ/1.jpg'
import korunum2 from '../img/main/02- KÖPÜKLÜ SÖNDÜRME SİSTEMİ-YENİ/K-06  KÖPÜKLÜ SİSTEM SİSTEMİ TİPLERİ/2.jpg'
import korunum3 from '../img/main/02- KÖPÜKLÜ SÖNDÜRME SİSTEMİ-YENİ/K-06  KÖPÜKLÜ SİSTEM SİSTEMİ TİPLERİ/3.png'

import monitör1 from '../img/main/02- KÖPÜKLÜ SÖNDÜRME SİSTEMİ-YENİ/K-07  SU VE KÖPÜK MONİTÖRLERİ/1.png'
import monitör2 from '../img/main/02- KÖPÜKLÜ SÖNDÜRME SİSTEMİ-YENİ/K-07  SU VE KÖPÜK MONİTÖRLERİ/monitor2.png'
import monitör3 from '../img/main/02- KÖPÜKLÜ SÖNDÜRME SİSTEMİ-YENİ/K-07  SU VE KÖPÜK MONİTÖRLERİ/monitor3.jpg'

import konsantre1 from '../img/main/03- KÖPÜK KONSANTRELERİ-YENİ/KK-01  KÖPÜK KONSANTRELERİ/1.png'
import konsantre2 from '../img/main/03- KÖPÜK KONSANTRELERİ-YENİ/KK-01  KÖPÜK KONSANTRELERİ/2.jpg'
import konsantre3 from '../img/main/03- KÖPÜK KONSANTRELERİ-YENİ/KK-01  KÖPÜK KONSANTRELERİ/3.png'

import cevreci1 from '../img/main/03- KÖPÜK KONSANTRELERİ-YENİ/KK-02  ÇEVRECİ FLOR İÇERMEYEN YENİ NESİL KÖPÜK KONSANTRELERİ-dks/1.jpg'
import cevreci2 from '../img/main/03- KÖPÜK KONSANTRELERİ-YENİ/KK-02  ÇEVRECİ FLOR İÇERMEYEN YENİ NESİL KÖPÜK KONSANTRELERİ-dks/2.jpg'
import cevreci3 from '../img/main/03- KÖPÜK KONSANTRELERİ-YENİ/KK-02  ÇEVRECİ FLOR İÇERMEYEN YENİ NESİL KÖPÜK KONSANTRELERİ-dks/3.png'
import cevreci4 from '../img/main/03- KÖPÜK KONSANTRELERİ-YENİ/KK-02  ÇEVRECİ FLOR İÇERMEYEN YENİ NESİL KÖPÜK KONSANTRELERİ-dks/4.jpg'

import solberg1 from '../img/main/03- KÖPÜK KONSANTRELERİ-YENİ/KK-03  SOLBERG RE-HEALING KÖPÜK KONSANTRALERİNIN GENEL ÖZELLİKLERİ/1.png'

import asınıf from '../img/main/03- KÖPÜK KONSANTRELERİ-YENİ/KK-04  A SINIFI KÖPÜK KONSANTRELERİ/1.jpg'

import bsınıf from '../img/main/03- KÖPÜK KONSANTRELERİ-YENİ/KK-05  B BINIFI KÖPÜK KONSANTRELERİ/1.png'

import cafs1 from '../img/main/03- KÖPÜK KONSANTRELERİ-YENİ/KK-06  CAFS/1.png'
import cafs2 from '../img/main/03- KÖPÜK KONSANTRELERİ-YENİ/KK-06  CAFS/2.png'

import egitim1 from '../img/main/03- KÖPÜK KONSANTRELERİ-YENİ/KK-07  EĞİTİM KÖPÜĞÜ/1.png'

import gazlı1 from  '../img/main/04- GAZLI SÖNDÜRME SİSTEMLERİ-YENİ/G-01  GAZLI SÖNDÜRME SİSTEMLERİ/birleşik.png'


import fm2001 from '../img/main/04- GAZLI SÖNDÜRME SİSTEMLERİ-YENİ/G-02 FM - 200 SİSTEMLERİ/1.png'
import fm2002 from '../img/main/04- GAZLI SÖNDÜRME SİSTEMLERİ-YENİ/G-02 FM - 200 SİSTEMLERİ/2.jpg'

import novec1 from '../img/main/04- GAZLI SÖNDÜRME SİSTEMLERİ-YENİ/G-03  NOVEC 1230  SİSTEMLERİ/1.png'
import novec2 from '../img/main/04- GAZLI SÖNDÜRME SİSTEMLERİ-YENİ/G-03  NOVEC 1230  SİSTEMLERİ/2.jpg'

import inert1 from '../img/main/04- GAZLI SÖNDÜRME SİSTEMLERİ-YENİ/G-04  INERT SİSTEMLER/1.png'
import inert2 from '../img/main/04- GAZLI SÖNDÜRME SİSTEMLERİ-YENİ/G-04  INERT SİSTEMLER/2.png'

import co21 from '../img/main/04- GAZLI SÖNDÜRME SİSTEMLERİ-YENİ/G-05  CO2 GAZLI SÖNDÜRME SİSTEMLERİ/1.png'
import co22 from '../img/main/04- GAZLI SÖNDÜRME SİSTEMLERİ-YENİ/G-05  CO2 GAZLI SÖNDÜRME SİSTEMLERİ/2.png'
import co23 from '../img/main/04- GAZLI SÖNDÜRME SİSTEMLERİ-YENİ/G-05  CO2 GAZLI SÖNDÜRME SİSTEMLERİ/3.png'

import door1 from '../img/main/04- GAZLI SÖNDÜRME SİSTEMLERİ-YENİ/G-06  DOOR FAN TEST/1.jpg'

import davlumbaz1 from '../img/main/05- DAVLUMBAZ SÖNDÜRME SİSTEMİ -YENİ/DAVLUMBAZ SÖNDÜRME SİSTEMİ - amerex/1.png'
import davlumbaz2 from '../img/main/05- DAVLUMBAZ SÖNDÜRME SİSTEMİ -YENİ/DAVLUMBAZ SÖNDÜRME SİSTEMİ - amerex/2.jpg'
import davlumbaz3 from '../img/main/05- DAVLUMBAZ SÖNDÜRME SİSTEMİ -YENİ/DAVLUMBAZ SÖNDÜRME SİSTEMİ - amerex/3.png'
import davlumbaz4 from '../img/main/05- DAVLUMBAZ SÖNDÜRME SİSTEMİ -YENİ/DAVLUMBAZ SÖNDÜRME SİSTEMİ - amerex/4.jpg'
import davlumbaz5 from '../img/main/05- DAVLUMBAZ SÖNDÜRME SİSTEMİ -YENİ/DAVLUMBAZ SÖNDÜRME SİSTEMİ - amerex/5.jpg'
import davlumbaz6 from '../img/main/05- DAVLUMBAZ SÖNDÜRME SİSTEMİ -YENİ/DAVLUMBAZ SÖNDÜRME SİSTEMİ - amerex/6.jpg'

import endust1 from '../img/main/06-  ENDÜSTRİYEL KURU TOZLU SÖNDÜRME SİSTEMİ-YENİ/1.png'
import endust2 from '../img/main/06-  ENDÜSTRİYEL KURU TOZLU SÖNDÜRME SİSTEMİ-YENİ/2.png'
import endust3 from '../img/main/06-  ENDÜSTRİYEL KURU TOZLU SÖNDÜRME SİSTEMİ-YENİ/3.jpg'

import subaskın from '../img/main/subaskın/1.png'
import is1 from '../img/main/07-  İŞ MAKİNELERİ SÖNDÜRME SİSTEMLERİ-YENİ/1.jpg'
import is2 from '../img/main/07-  İŞ MAKİNELERİ SÖNDÜRME SİSTEMLERİ-YENİ/2.png'

import aerosol1 from '../img/main/08-  AEROSOL SİSTEMLERİ/1.png'
import aerosol2 from '../img/main/08-  AEROSOL SİSTEMLERİ/2.png'

import alevDetec1 from '../img/main/AlevDetektör/1.jpg'
import alevDetec2 from '../img/main/AlevDetektör/2.jpg'
import alevDetec3 from '../img/main/AlevDetektör/3.jpg'

import dolap1 from '../img/main/09-  YANGIN DOLAPLARI-YENİ/1.png'
import dolap2 from '../img/main/09-  YANGIN DOLAPLARI-YENİ/2.png'
import dolap3 from '../img/main/09-  YANGIN DOLAPLARI-YENİ/3.png'
import dolap4 from '../img/main/09-  YANGIN DOLAPLARI-YENİ/4.jpg'
import dolap5 from '../img/main/09-  YANGIN DOLAPLARI-YENİ/5.png'

import asırıbasınc1 from '../img/main/010-  BASINÇ TAHLİYE VANALARI-YENİ/001- AŞIRI BASINÇ EMNİYET VANALARI/1.png'
import basıcdusurme1 from '../img/main/010-  BASINÇ TAHLİYE VANALARI-YENİ/002- BASINÇ DÜŞÜRME VE AŞIRI BASINÇ TAHLİYE VANALARI/1.png'

import debimetre from '../img/main/011-  DEBİMETRE/1.png'
import waste1 from '../img/main/012-  YANGIN POMPALARI/waste.jpeg'
import yangınPompa1 from '../img/main/012-  YANGIN POMPALARI/1.png'
import yangınPompa2 from '../img/main/012-  YANGIN POMPALARI/2.png'
import yangınPompa3 from '../img/main/012-  YANGIN POMPALARI/3.png'
import yangınPompa4 from '../img/main/012-  YANGIN POMPALARI/4.png'
import yangınPompa5 from '../img/main/012-  YANGIN POMPALARI/5.png'
import yangınPompa6 from '../img/main/012-  YANGIN POMPALARI/6.png'
import yangınPompa7 from '../img/main/012-  YANGIN POMPALARI/7.png'
import yangınPompa8 from '../img/main/012-  YANGIN POMPALARI/8.jpg'

import algılama1 from '../img/main/013-ALGILAMA SİSTEMLERİ/001- YANGIN ALGILAMA SİSTEMLERİ/1.png'
import algılama2 from '../img/main/013-ALGILAMA SİSTEMLERİ/001- YANGIN ALGILAMA SİSTEMLERİ/2.png'
import algılama3 from '../img/main/013-ALGILAMA SİSTEMLERİ/001- YANGIN ALGILAMA SİSTEMLERİ/3.png'
import algılama4 from '../img/main/013-ALGILAMA SİSTEMLERİ/001- YANGIN ALGILAMA SİSTEMLERİ/4.png'
import algılama5 from '../img/main/013-ALGILAMA SİSTEMLERİ/001- YANGIN ALGILAMA SİSTEMLERİ/5.png'
import algılama6 from '../img/main/013-ALGILAMA SİSTEMLERİ/001- YANGIN ALGILAMA SİSTEMLERİ/6.jpg'
import algılama7 from '../img/main/013-ALGILAMA SİSTEMLERİ/001- YANGIN ALGILAMA SİSTEMLERİ/7.png'
import algılama8 from '../img/main/013-ALGILAMA SİSTEMLERİ/001- YANGIN ALGILAMA SİSTEMLERİ/8.png'
import algılama9 from '../img/main/013-ALGILAMA SİSTEMLERİ/001- YANGIN ALGILAMA SİSTEMLERİ/9.png'

import havaornek1 from '../img/main/013-ALGILAMA SİSTEMLERİ/002- HAVA ÖRNEKLEMELİ SİSTEMLER/1.png'
import havaornek2 from '../img/main/013-ALGILAMA SİSTEMLERİ/002- HAVA ÖRNEKLEMELİ SİSTEMLER/2.png'

import kablo1 from '../img/main/013-ALGILAMA SİSTEMLERİ/003- KABLO TİPİ LİNEER ALGILAMA SİSTEMLERİ/1.png'
import kablo2 from '../img/main/013-ALGILAMA SİSTEMLERİ/003- KABLO TİPİ LİNEER ALGILAMA SİSTEMLERİ/2.png'
import kablo3 from '../img/main/013-ALGILAMA SİSTEMLERİ/003- KABLO TİPİ LİNEER ALGILAMA SİSTEMLERİ/3.jpg'
import kablo4 from '../img/main/013-ALGILAMA SİSTEMLERİ/003- KABLO TİPİ LİNEER ALGILAMA SİSTEMLERİ/4.jpg'
import kablo5 from '../img/main/013-ALGILAMA SİSTEMLERİ/003- KABLO TİPİ LİNEER ALGILAMA SİSTEMLERİ/5.png'

import patlayıcı1 from '../img/main/013-ALGILAMA SİSTEMLERİ/004- PATLAYICI GAZ ALGILAMA SİSTEMLERİ/1.png'
import patlayıcı2 from '../img/main/013-ALGILAMA SİSTEMLERİ/004- PATLAYICI GAZ ALGILAMA SİSTEMLERİ/2.png'
import patlayıcı3 from '../img/main/013-ALGILAMA SİSTEMLERİ/004- PATLAYICI GAZ ALGILAMA SİSTEMLERİ/3.jpg'
import patlayıcı4 from '../img/main/013-ALGILAMA SİSTEMLERİ/004- PATLAYICI GAZ ALGILAMA SİSTEMLERİ/4.jpg'
import patlayıcı5 from '../img/main/013-ALGILAMA SİSTEMLERİ/004- PATLAYICI GAZ ALGILAMA SİSTEMLERİ/5.jpg'

import sondurme1 from '../img/main/013-ALGILAMA SİSTEMLERİ/005-SÖNDÜRME KONTROL PANELİ ve AKSESUARLARI/1.png'
import sondurme2 from '../img/main/013-ALGILAMA SİSTEMLERİ/005-SÖNDÜRME KONTROL PANELİ ve AKSESUARLARI/2.png'
import sondurme3 from '../img/main/013-ALGILAMA SİSTEMLERİ/005-SÖNDÜRME KONTROL PANELİ ve AKSESUARLARI/4.jpg'

import kıvılcım1 from '../img/main/014- KIVILCIM SÖNDÜRME SİSTEMLERİ/1.jpg'
import kıvılcım2 from '../img/main/014- KIVILCIM SÖNDÜRME SİSTEMLERİ/2.png'
import Kıvılcım3 from '../img/main/014- KIVILCIM SÖNDÜRME SİSTEMLERİ/2.jpg'

import pasif1 from '../img/main/015- PASİF YANGIN KORUNUM SİSTEMLERİ/1.png' 
import pasif2 from '../img/main/015- PASİF YANGIN KORUNUM SİSTEMLERİ/2.png' 
import pasif3 from '../img/main/015- PASİF YANGIN KORUNUM SİSTEMLERİ/3.png' 
import pasif4 from '../img/main/015- PASİF YANGIN KORUNUM SİSTEMLERİ/4.png' 
import pasif5 from '../img/main/015- PASİF YANGIN KORUNUM SİSTEMLERİ/5.png' 
import pasif6 from '../img/main/015- PASİF YANGIN KORUNUM SİSTEMLERİ/6.png' 

import susisi1 from '../img/main/016- SU SİSİ SİSTEMLERİ/1.png'
import susisi2 from '../img/main/016- SU SİSİ SİSTEMLERİ/2.png'
import susisi3 from '../img/main/016- SU SİSİ SİSTEMLERİ/3.jpg'
import emniyet from '../img/main/012-  YANGIN POMPALARI/emniyet.png'
import firedetect1 from '../img/main/017-FIRE DETECT SİSTEM/1.png'
import firedetect2 from '../img/main/017-FIRE DETECT SİSTEM/2.png'

import tahliye1 from '../img/main/019- DUMAN TAHLİYE SİSTEMİ/1.png'
import tahliye2 from '../img/main/019- DUMAN TAHLİYE SİSTEMİ/2.jpg'
import tahliye3 from '../img/main/019- DUMAN TAHLİYE SİSTEMİ/3.jpg'
import Tahliye from "../pages/systems/Sulu/Tahliye";
import Puf from "../pages/systems/Gazlı/Puf";
import Dilatasyon from "../pages/systems/Sulu/Dilatasyon";
import TahliyeS from "../pages/systems/Duman/Tahliye";
import Emniyet from "../pages/systems/YangınPompa/Emniyet";
import AlevDetec from "../pages/systems/AlevDet/AlevDetec";
import SuBaskın from "../pages/systems/SuBaskın/SuBaskın";
export const info = [
    {
    photos: [{id:0,photo:[kopuk1,kopuk2]},{id:1,photo:[kopukN1,kopukN2]},{id:2,photo:[dusuk1,dusuk2,dusuk3]},{id:3,photo:[orta1,orta2]},{id:4,photo:[yuksek1,yuksek2,yuksek3,yuksek4]},{id:5,photo:[korunum1,korunum2,korunum3]}],
      id: 1,
      name: 'KöpüklüSöndürmeSistemleri',
      names:'Köpüklü Söndürme Sistemleri',
      subtiltles: ["Köpüklü Söndürme Sistemleri","Köpük Nedir ?","Düşük Genleşmeli Sistemler",'Orta Genleşmeli Sistemler',"Yüksek Genleşmeli Sistemler",'Köpüklü Yangından Korunum Sistemi Tipleri'],
      data:[{id:0,component:<MainYazıKopuk/>},{id:1,component:<Kopuklu/>},{id:2,component:<Dusuk/>},{id:3,component:<Konsantre/>},{id:4,component:<Yuksek/>},{id:5,component:<Tip/>}],
    },
    {
      photos: [{id:0,photo:[konsantre1,konsantre2,konsantre3]},{id:1,photo:[cevreci1,cevreci2,cevreci3,cevreci4]},{id:2,photo:[solberg1]},{id:3,photo:[asınıf]},{id:4,photo:[bsınıf]},{id:5,photo:[cafs1,cafs2]},{id:6,photo:[egitim1]}],
        id:22,
        name: 'KöpükKonsantreleri',
        names:'Köpük Konsantreleri',
        subtiltles: ["Köpük Konsantreleri","Çevreci Flor İçermeyen Yeni Nesil Köpük Konsantreleri","Solberg RE-HEALING™ köpük Konsantrelerinin Genel Özellikleri","A Sınıfı Köpük Konsantreleri",'B Sınıfı Köpük Konsantreleri','CAFS Köpük Konsantreleri','Eğitim Köpük Konsantreleri'],
        data:[{id:0,component:<MainYazıKopukConst/>},{id:1,component:<Cevreci/>},{id:2,component:<Solberg/>},{id:3,component:<Aclass/>},{id:4,component:<Bclass/>},{id:5,component:<Caf/>},{id:6,component:<Egitim/>}],
      },
    {
      photos: [{id:0,photo:[sulu1,sulu2,sulu3,sulu4]},{id:1,photo:[ıslak1,ıslak2,ıslak3]},{id:2,photo:[kuru1,kuru2]},{id:3,photo:[baskın4,baskın1,baskın2,baskın3]},{id:4,photo:[ön1,ön2,ön3,ön4]},{id:5,photo:[sprinker1]},{id:6,photo:[sogutma1,sogutma2,sogutma3,sogutma11]},{id:7,photo:[]},{id:8,photo:[basıcdusurme1]},{id:9,photo:[]},{id:10,photo:[sismik2,sismik3]},{id:11,photo:[sismik4]}],
      id: 2,
      name: 'SuluSöndürmeSistemleri',
      names:'Sulu Söndürme Sistemleri',
      subtiltles: ['Sulu Söndürme Sistemleri','Islak Borulu (Wet Pipe) Sprinkler Sistemleri','Kuru Borulu (Dry Pipe)  Sprinkler Sistemleri','Baskın (Deluge) Sistemleri','Ön Tepkili (Pre-Action) Sprinkler Sistemleri',"Sprinkler Başlıkları",'Soğutma Nozulları',"Yangın Sistemi Vanaları",'Basınç Düşürücü Vana ','Yivli Bağlantı Elemanları ','Sismik Askılama Sistemleri ',"Dilatasyon Kompansatörü"],
      data: [{id:0,component:<MainYazıSulu/>},{id:1,component:<Islak/>},{id:2,component:<Kuru/>},{id:3,component:<Deluge/>},{id:4,component:<Preaction/>},{id:5,component:<Sprinker/>},{id:6,component:<Sprey/>},{id:7,component:<YangınVana/>},{id:8,component:<Basınc/>},{id:9,component:<Yivli/>},{id:10,component:<Sismik/>},{id:11,component:<Dilatasyon/>}],
    },
    {
    photos: [{id:0,photo:[]},{id:1,photo:[fm2001,fm2002]},{id:2,photo:[novec1,novec2]},{id:3,photo:[co21,co22,co23]},{id:4,photo:[inert1]},{id:5,photo:[door1]},{id:6,photo:[]}],
      id: 3,
      name: 'GazlıSöndürmeSistemleri',
      names: 'Gazlı Söndürme Sistemleri',
      subtiltles: ["Gazlı Söndürme Sistemleri",'HFC – 227ea (FM – 200) Sistemleri ',"FK 5-1-12  (Novec - 1230) Sistemleri ","CO2 Söndürme Sistemi","Inert (IG-01/IG-55/IG-100/IG-541) Sistemler ","Oda Sızdırmazlık (Door-Fan) Testi ","Puf Test ve Boru Sızdırmazlık   (Leak) Testi "],
      data: [{id:0,component:<MainYazıGazlı/>},{id:1,component:<Fm200/>},{id:2,component:<Novec/>},{id:3,component:<CO2/>},{id:4,component:<Inert/>},{id:5,component:<Oda/>},{id:6,component:<Puf/>}],
    },
    {
    photos: [{id:0,photo:[davlumbaz1,davlumbaz2,davlumbaz3,davlumbaz4,davlumbaz5,davlumbaz6]}],
      id: 4,
      name: 'MutfakDavlumbazSöndürmeSistemleri',
      names: 'Mutfak Davlumbaz Söndürme Sistemleri',
      subtiltles: ['Mutfak Davlumbaz Söndürme Sistemleri'],
      data: [{id:0,component:<Mutfak/>}],
    },
    {
      photos: [{id:0,photo:[dolap1,dolap2,dolap3,dolap4,dolap5]}],
      id: 5,
      name: 'YangınDolapları',
      names: 'Yangın Dolapları',
      subtiltles: ['Yangın Dolapları'],
      data: [{id:0,component:<MainYazıDolap/>}],
    },
    {
     photos: [{id:0, photo:[yangınPompa1,yangınPompa8,yangınPompa5,yangınPompa6,yangınPompa7]},{id:1, photo:[debimetre]},{id:2, photo:[emniyet,waste1]},{id:2, photo:[basıcdusurme1]}],
      id: 6,
      name: 'YangınPompaları',
      names: 'Yangın Pompaları',
      subtiltles: ['Yangın Pompaları','Flowmetre (Debimetre)','Aşırı Basınç (Relief) Tahliye Vanası ve Serbest Akış Konu ','Basıç Düşürme Vanaları'],
      data: [{id:0,component:<MainYazıPompa/>},{id:1,component:<Flowmetre/>},{id:2,component:<Relief/>},{id:3,component:<Emniyet/>}],
    },
    {
     photos: [{id:0,photo:[]},{id:1,photo:[endust1,endust2,endust3]},{id:2,photo:[is1,is2]}],
      id: 7,
      name: 'KurutozluSöndürmeSistemleri',
      names:  'Kurutozlu Söndürme Sistemleri',
      subtiltles: ['Endüstriyel Söndürme Sistemleri','Kurutozlu Söndürme Sistemleri','Araç Söndürme Sistemleri'],
      data: [{id:0,component:<Endüstriyel/>},{id:1,component:<MainYazıKuruTozlu/>},{id:2,component:<Araç/>}],
    },
    {
      photos: [{id:0,photo:[monitör1,monitör2,monitör3]}],
      id: 8,
      name: 'SuveKöpükMonitörleri',
      names:  'Su ve Köpük Monitörleri',
      subtiltles: ['Su ve Köpük Monitörleri'],
      data: [{id:0,component:<SuveKopuk/>}],
    },
    {
    photos: [{id:0,photo:[]}],
      id: 9,
      name: 'AdresliSistemler',
      names:  'Adresli Sistemler',
      subtiltles: ['Adresli Sistemler'],
      data: [{id:0,component:<DemoSayfa/>}],
    },
    {
    photos: [{id:0,photo:[kablo1,kablo2,kablo3,kablo4,kablo5]}],
      id: 11,
      name: 'LineerSıcaklıkAlgılama',
      names: 'Lineer Sıcaklık Algılama',
      subtiltles: ['Lineer Sıcaklık Algılama'],
      data: [{id:0,component:<Lineer/>}],
    },
 
    {
    photos: [{id:0,photo:[]}],
      id: 13,
      name: 'AlevDedektörleri',
      names: 'Alev Detektörleri',
      subtiltles: ['Alev Detektörleri'],
      data: [{id:0,component:<AlevDetec/>}],
    },
    {
      photos: [{id:0,photo:[firedetect1,firedetect2]}],
        id: 13,
        name: 'FireDetecSöndürmeSistemleri',
        names: 'Fire Detec™ Söndürme Sistemleri',
        subtiltles: ['Fire Detec™ Söndürme Sistemleri'],
        data: [{id:0,component:<FireDetect/>}],
      },
    {
    photos: [{id:0,photo:[patlayıcı1,patlayıcı2,patlayıcı3,patlayıcı4,patlayıcı5]}],
      id: 14,
      name: 'Yanıcı-ParlayıcıGazAlgılamaSistemi',
      names: 'Yanıcı-Patlayıcı Gaz Algılama Sistemi',
      subtiltles: [ 'Yanıcı-Patlayıcı Gaz Algılama Sistemi'],
      data: [{id:0,component:<Patlayıcı/>}],
    },
    {
    photos: [{id:0,photo:[havaornek1,havaornek2]}],
      id: 15,
      name: 'HavaÖrneklemeliAlgılama',
      names: 'Hava Örneklemeli Algılama',
      subtiltles: [ 'Hava Örneklemeli Sistemler'],
      data: [{id:0,component:<Hava/>}],
    },
    {
    photos: [{id:0,photo:[sondurme2,sondurme1,]}],
      id: 16,
      name: 'SöndürmeKontrolElemanları',
      names: 'Söndürme Kontrol Paneli ve Aksesuarları',
      subtiltles: ['Söndürme Kontrol Paneli ve Aksesuarları'],
      data: [{id:0,component:<Panel/>}],
    },
    {
    photos: [{id:0,photo:[]}],
      id: 17,
      name: 'PanoiçiSöndürme(Direkveİndirekt)',
      names: 'Pano içi Söndürme(Direk ve İndirect)',
      subtiltles: ['Pano içi Söndürme(Direk ve İndirect)'],
      data: [{id:0,component:<DemoSayfa/>}],
    },
    {
     photos: [{id:0,photo:[aerosol1]}],
      id: 18,
      name: 'AerosolSöndürme',
      names: 'Aerosol Söndürme',
      subtiltles: ['Aerosol Söndürme'],
      data: [{id:0,component:<Aerosol/>}],
    },
    {
      photos:[{id:0,photo:[kıvılcım1,Kıvılcım3,kıvılcım2]}],
      id: 19,
      name: 'KıvılcımSöndürme',
      names: 'Kıvılcım Söndürme',
      subtiltles: ['Kıvılcım Söndürme'],
      data: [{id:0,component:<Kıvılcım/>}],
    },
    {
      photos:[],
      id: 20,
      name: 'DoğalDumanveIsıTahliye',
      names: 'Doğal Duman ve Isı Tahliye',
      subtiltles: ['Doğal Duman ve Isı Tahliye'],
      data: [{id:0,component:<DemoSayfa/>}],
    },
    {
      photos:[{id:0,photo:[pasif1,pasif2,pasif3,pasif4,pasif5,pasif6]}],
      id: 21,
      name: 'PasifYangınDurdurucular',
      names: 'Pasif Yangın Durdurucular',
      subtiltles: ['Pasif Yangın Durdurucular'],
      data: [{id:0,component:<PasifYangın/>}],
    },
    {
      photos: [{id:0,photo:[susisi1,susisi2,susisi3]}],
      id: 23,
      name: 'SuSisi(WaterMist)Sistemleri',
      names:'Su Sisi (Water Mist) Sistemleri ',
      subtiltles: ['Su Sisi (Water Mist) Sistemleri '],
      data: [{id:0,component:<WaterMist/>}],
    },
    {
      photos: [{id:0,photo:[]}],
      id: 24,
      name: 'SistemBakımveOnarımHizmetleri',
      names:'Sistem Bakımve Onarım Hizmetleri ',
      subtiltles: ['Sistem Bakım ve Onarım Hizmetleri'],
      data: [{id:0,component:<Bakım/>}],
    },
    {
      photos: [{id:0,photo:[algılama1,algılama2,algılama3,algılama6,algılama7,algılama8,algılama9]}],
      id: 24,
      name: 'YangınAlgılamaVeİhbarSistemleri',
      names:'Yangın Algılama Ve İhbar Sistemleri ',
      subtiltles: ['Yangın Algılama Ve İhbar Sistemleri'],
      data: [{id:0,component:<YangınAlgılama/>}],
    },
    {
      photos: [{id:0,photo:[tahliye1,tahliye2,tahliye3]}],
      id: 24,
      name: 'DoğalDumanVeIsıTahliyeSistemleri',
      names:'Doğal Duman Ve Isı Tahliye Sistemleri',
      subtiltles: ['Doğal Duman Ve Isı Tahliye Sistemleri'],
      data: [{id:0,component:<TahliyeS/>}],
    },
    {
      photos: [{id:0,photo:[subaskın]}],
      id: 24,
      name: 'SuBaskınDetektörü',
      names:'Su Baskın Detektörü',
      subtiltles: ['Su Baskın Detektörü'],
      data: [{id:0,component:<SuBaskın/>}],
    },

  ];