import React from 'react';
import '../css/footer.css'
import { useNavigate } from 'react-router-dom';
import face from '../img/sociel/fc.png'
import instagram from '../img/sociel/instagram.png'
import twitter from '../img/sociel/twitter.png'
function Footer() {
  const navigate = useNavigate();
  // Define a function to check if a given path matches the current URL;
  const handleNavigation = (path) => {
    navigate(path);
  };
  return (
<footer id="footer" class="footer-1">
<div class="main-footer widgets-dark typo-light">
<div class="container">
<div class="row">
  
<div class="col-xs-12 col-sm-6 col-md-3">
<div class="widget subscribe no-box">
<h5 class="widget-title">AKSAY YANGIN<span></span></h5>
<p>AKSAY Yangın ve Güvenlik Sistemleri <br /> Sanayi  ve Ticaret Limited Şirketi <br/> Aktif ve pasif yangın korunum sistemlerinin

tasarım, tedarik, kurulum, bakım ve eğitim

hizmetlerini sağlayan bir organizyona sahiptir. </p>
</div>
</div>

<div class="col-xs-12 col-sm-6 col-md-2" style={{marginLeft:120}}>
<div class="widget no-box">
<h5 class="widget-title">Kurumsal<span></span></h5>
<ul class="thumbnail-widget">
<li>
<div class="thumb-content"><a  onClick={() => handleNavigation('/sertifikavebelgeler')}>Sertifika ve Belgelerimiz</a></div>	
</li>
<li>
<div class="thumb-content"><a onClick={() => handleNavigation('/distribütörlüklerimiz')}>Distribütörlüklerimiz</a></div>	
</li>

</ul>
</div>
</div>

<div class="col-xs-12 col-sm-6 col-md-3"  style={{marginRight:100}}>
<div class="widget no-box" style={{maxWidth:350}}>
<h5 class="widget-title">Adres Bilgileri<span></span></h5>
<address style={{marginTop:20}}>
                    <p>Adres: <span>Perpa Ticaret Merkezi <br />Kat 13 1979. A Blok <br />Şişli - İstanbul</span></p>
                   
                  </address>
                  <hr />
                  <address >
            <p>Adres: İsmetpaşa Mah ÇOSB Ticaret Blokları <br></br>No 6G7, 59510 <br/> Çerkezköy / Tekirdağ </p>
           
          </address>

</div>
</div>

<div class="col-xs-12 col-sm-6 col-md-2">

<div class="widget no-box">
<h5 class="widget-title">Bize Ulaşın<span></span></h5>
<p>+90 (212) 210 02 43</p>
<p><a href="mailto:info@aksayyangin.com" >info@aksayyangin.com</a></p>

  <a href='https://www.instagram.com/aksayyangin1'><img style={{width:40}} src= {instagram}></img> </a>

  <a href='https://www.facebook.com/aksayyangin1'><img style={{width:40}} src= {face}></img> </a>


  <a href='https://twitter.com/AksayVe'><img style={{width:40}} src= {twitter}></img></a>


</div>
</div>

</div>
</div>
</div>
  
<div class="footer-copyright">
<div class="container">
<div class="row">
<div class="col-md-12 text-center">
<p>Copyright AKSAY YANGIN © 2024. All rights reserved.</p>
</div>
</div>
</div>
</div>
</footer>
  );
}

export default Footer;