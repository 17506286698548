import divStyle from '../../../css/bannerCss';
import banner from '../../../img/main/02- KÖPÜKLÜ SÖNDÜRME SİSTEMİ-YENİ/K-05  YÜKSEK GENLEŞMELİ KÖPÜK SİSTEMLERİ/Başlık.png'
export default function Yuksek (){
    const containerStyle = {
        
      
        maxWidth: '800px',
       
        fontFamily: 'Arial, sans-serif',
      };
    
      const headingStyle = {
        fontSize: '24px',
        marginTop: '0',
        fontWeight: '800',
      };
      const divStyle={
        height: '200px',
        width: '100%',
        backgroundImage:`url(${banner})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        padding: 0,
        fontSize: "3rem",
        color: " #ffffff",
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom:20,
        textShadow: '4px 4px 4px rgba(0, 0, 0, 0.5)'
    }
    return(
   <>
  <div style={containerStyle}>
     <div style={divStyle} >
      
     </div>
      <h2 style={headingStyle}>Yüksek Genleşmeli Sistemler</h2>
      <div style={{minHeight:1, backgroundColor:'red',marginBottom:10,marginTop:10}}></div>
      <p>
      Hi-Ex Yüksek Genleşmeli  Köpüklü Söndürme Sistemleri özel olarak geliştirilen donanımı nedeni ile  kurulum ve bakım açısından son derece ekonomik çözümler sunar. Hi-Ex  (High Expansion Foam System) Konvansiyonel Köpüklü Söndürme Sistemlerine alternatif olarak kullanılabilen sistemdir. Özellikle hacim doldurmanın gerektiği, Raflı veya serbest depolamanı yapıldı, geniş alanlarda zemin kapatma ve yanıcı parlayıcı buharları bastırmak amacı ile kullanılmaktadır. Yakıt ile havanın temasını kesip, yapı içindeki suyu direk yangına iletmesi, yanabilen katı veya sıvı malzemelerin köpük içine gömülmesi nedeni ile hızlı ve etkili söndürme yapmak için tasarlanır.
      </p>
      <p style={{fontWeight:600}}>
      HI- EX Yüksek Genleşmeli Köpüklü Sistem Nedir?
      </p>
      <p>
      Özel köpük konsantresi ve köpük jeneratörleri kullanılarak, su ve köpük karışımının jeneratörlerin tiplerine bağlı olarak ortalama 1/200 – 1/1000  oranında genleştirilmesi  ile elde edilen büyük köpük Hi-Ex Sisteminde  kullanılan jeneratörler sıklıkla dışardan ek enerji kaynağına ihtiyaç duymaz. Mevcut pompa basıncını kullanarak su  ve köpük karışımını özel nozulları vasıtası ile jeneratörün çarptırma yüzeyine gönderir,  hava ile buluşturur ve solüsyonun genleşmesini sağlar.
      </p>
      <p>
      Hi-Ex  Köpük Jeneratörleri sıklıkla paslanmaz çelik malzemeden üretildikleri için son derece hafiftir. Tavandaki konumuna göre spesifik konstrüksiyon gerektirmeden montaj yapılmasına olanak tanır. Ancak bazı üreticilerin Hi-Ex Köpüklü Söndürme Sistemi Jeneratörleri üzerinde elektrik veya hidrolik motorlar bulunduğundan montaj işleminin son derece dikkatli ve ek sabitleme işlemi yapılarak monte Hi-Ex  Köpüklü söndürme sistemi jeneratörleri köpük yapmak için  kullanacakları havayı dışardan veya bulundukları ortamdan temin etmektedir. İç ortam havasını kullanmak son derece önemlidir. Ortam havası ile birlikte yanma ile ortaya çıkan ısı, duman vb. zararlı yangının taşıyıp yayabilecek etkenler köpük içine hapsedilir. Ortam havasını kullanma prensibi söndürme açısından son derece önemlidir. Isınan ortam havasını kullanması nedeni ile yanmanın devamı için gerekli ısıyı ve ortamda mevcut olan Oksijeni köpük içine hapseder. Ortamdaki oksijen seviyesini ve ortam ısısını düşürür. Bu anlamda ek havalandırma veya duman tahliyesi göreceli olarak gerekmeyebilir. Yapınızın yapısal bütünlüğünün korunması için katkı sağlar. Hi-Ex  Sistemi kullanıldığında, ortamdan ısı ve oksijen emilerek yanma için gerekli iki önemli etken ortadan kaldırılır. Bu sebeple hızla  yangını kontrol eder. Yapısı içinde bulunan su nedeni ile  soğutma etkisi gösterir. Malzemenin üzerini örtmesi nedeni ile malzemenin ortam ile ilişkisini keser ve yangını güvenle söndürmenizi sağlar. Yapısındaki minimum su miktarından dolayı konvansiyonel köpük sistemlerine nazaran su baskını nedeniyle oluşacak zarar ihmal edilebilir boyutta küçüktür.

      </p>
      <p style={{fontWeight:600}}>
      Hi-Ex Yüksek Genleşmeli Köpüklü Söndürme Sistemleri neden tercih edilmektedir. 

      </p>
      <ul>
    <li>Genleşen köpük ortamdaki ısıyı içine hapseder</li>
    <li>Genleşen köpük ortamdaki oksijeni içine hapseder</li>
    <li>Yapısındaki su nedeni ile soğutma sağlar</li>
    <li>Riskin üzerini örter ve yanmanın devamını engeller</li>
    <li>Alevi ve ısıyı hızlı kontrol eder</li>
    <li>Ortamın tansiyonunu düşürür</li>
    <li>Jeneratörlerin hacim içine esnek konumlandırılır</li>
    <li>Geniş sahalarda esnek kurulum olanaklı hale gelir</li>
    <li>Nispeten düşük su tüketimi gerektirir</li>
    <li>Nispeten düşük pompa kapasitesi gerektirir</li>
    <li>Düşük yangın suyu rezervi gerektirir</li>
    <li>Düşük borulama maliyeti ve yerinde kurulum sağlar</li>
    <li>Minimum bakım gerektirir</li>
    <li>Ek duman ve ısı tahliyesi gerektirmeyebilir</li>
    <li>Hava borusu ve duvar açıklıkları gerektirmez</li>
    <li>Kurulduğu alanın yapısal bütünlüğünü korur</li>
    <li>Basitleştirilmiş konumlandırma ve montaj kolaylığı</li>
    <li>Çevre dostudur</li>
</ul>
<p>Yukarıda da belirtildiği gibi yangının kontrolü, hızla kapatılması, buharın bastırılması, ısı ve oksijen seviyesinin düşürülmesi vb. söndürme işlemi için elzem olan temel gereklilikleri tek başına sağladığı için Hi-Ex Sistemler sıklıkla tercih edilmektedir. </p>
    <p style={{fontWeight:600}}>Hi-Ex Köpüklü Söndürme Sistemi Kullanım Alanları:</p>
    <ul>
    <li>Kimya Fabrikaları</li>
    <li>Solvent ve Hidrokarbon Depolama Alanları</li>
    <li>Boya Üretim ve Depolama Alanları</li>
    <li>Uçak Hangarları</li>
    <li>Helikopter Hangarları</li>
    <li>Gemi Makine Daireleri ve Kargo Alanları</li>
    <li>Kazan Daireleri</li>
    <li>Yanıcı, Parlayıcı Ortamlarda Buhar Bastırma</li>
    <li>Geniş Tank Çiftliği Havuzlarında Dike Koruma</li>
    <li>Katı Malzeme Depoları</li>
    <li>Raflı Depolama Alanları</li>
    <li>Jeneratör ve Motor Test Odaları</li>
    <li>Kablo ve Tesisat Galerileri</li>
    <li>Enerji Üretim Alanları</li>
    <li>Jeneratör Odaları</li>
    <li>Endüstriyel Uygulamalar</li>
</ul>
<p>Kullanılacak malzemenin kaliteli ve sertifikalı olması tek başına yeterli değildir.  Yüksek Genleşmeli Köpüklü Sistemi ile korunacak alandaki uygulamanın doğru mühendislik hizmeti ile birleştirilerek Hi-Ex Söndürme Sistemleri B sınıfı yanıcı sıvı yangınlarında kullanıldığı gibi A sınıfı yangınlarda da son derece etkili alternatif çözümlerin üretilebileceği söndürme sistemidir. Tesisinizde doğru mühendislik ve uygulama bilgisi ile tesis edilen Yüksek Genleşmeli Söndürme Sistemi maksimum koruma sağlar. Söndürme amaçlı tasarlanır.
</p>
<p>Hi-Ex  Köpüklü Söndürme sistemleri için %2, %2.5, %2.75 veya %3 Kullanım oranlı standart tip veya alkol ve solvent yangınlarında etkili köyüp konsantreleri kullanılmaktadır. Kullanılan köpük üreteci solüsyon genleştirme oranı ile köpük konsantresinin genleşme oranı kontrol edilmelidir. Duruma göre ilave köpük jeneratörü sisteme eklenmesi veya köpük konsantresinin arttırılması gerekebilir. Diyaframlı (Bladder) Tanklı oranlama düzenekleri ile birlikte sıklıkla kullanılmaktadır.
</p>
<p>AKSAY Yangın SOLBERG ve FPI işbirliği ile Yüksek Genleşmeli Köpüklü Söndürme Sistemlerinin Tasarımı, Mühendislik Hesapları, Tedarik Edilmesi, Montaj -Test ve Devreye Alma ve Kontratlı Bakım hizmetlerini yetişmiş Mühendis ve Teknisyen kadrosu ile gerçekleştirmektedir.  </p>
    </div>






    </>
    )
}