
import '../../../css/footer.css'
import banner from '../../../img/main/01- SULU SÖNDÜRME SİSTEMLERİ-YENİ/002- ISLAK BORULU SPRİNKLER SİSTEMİ/Başlık.png'
export default function Islak() {
    const containerStyle = {
      
        
        maxWidth: '800px',
     
        
        fontFamily: 'Arial, sans-serif',
      };
    
      const headingStyle = {
        fontSize: '24px',
        marginTop: '0',
        fontWeight: '800',
      };

      const divStyle={
        height: '200px',
        width: '100%',
        backgroundImage:`url(${banner})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        padding: 0,
        fontSize: "3rem",
        color: " #ffffff",
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom:20,
        textShadow: '4px 4px 4px rgba(0, 0, 0, 0.5)'
    }
    
    return (<>
  <div style={containerStyle}>
     <div style={divStyle}>
      
     </div>
      <h2 style={headingStyle}>Islak Borulu (Wet Pipe) Sprinkler Sistemler</h2>
      <div style={{minHeight:1, backgroundColor:'red',marginBottom:10,marginTop:10}}></div>
      <p>
        Islak borulu sprinkler sisteminde borular, sürekli olarak basınçlı su ile dolu tutulur. Sadece donma riski olmayan ve çevre sıcaklığının 95 °C’yi geçmediği mahallere uygulanır. En yaygın kullanılan sprinkler sistemidir.
    </p>

    <p>
        Konutlar dahil tüm yüksek yapılar, alışveriş merkezleri, otoparklar, konaklama, eğitim, sağlık hizmeti, ticaret, depolama, üretim vb. amaçlarla kullanılan yapılarda mekanların özelliklerine bağlı olmak üzere, uygun tipte sprinkler başlıklarını içeren Islak Borulu sistemler kullanılmaktadır.
    </p>

    <p>
        Özel tehlikelerin söz konusu olduğu alanlarda köpük konsantresi katkısı ile “Köpüklü Islak Borulu (Wet Pipe) Sprinkler Sistemi” dizayn edilerek kullanılmaktadır. Donma riski olan mahallerde bulunan ıslak borulu sprinkler sistemi bölümleri; antifriz katkısı veya elektrikli izlemeli ısıtıcı kablo sistemi ile korunmalıdır.
    </p>

    <p>
        Islak borulu sprinkler sistemleri ortak bir kolondan beslenen zonlara ayrılabilir. Islak Borulu Sprinkler Sistemi Zon büyüklüğü; düşük ve orta tehlike sınıfı için en fazla 4831 m² ve yüksek tehlike sınıfı için hidrolik hesap yapılması durumunda en fazla 3716 m² olmalıdır. Yüksek tehlike sınıfı mahaller için boru tablosu yönteminin kullanılması durumunda, zon alanı en fazla 2323 m²’ seçilmelidir.
    </p>

    <p>
        Tesis ve yapının yangın tehlike sınıfına bağlı olarak sprinkler sisteminin tek bir sistem kolonu veya birleşik sistem kolonuna bağlanan sprinklerin koruduğu herhangi bir kattaki maksimum kat alanı aşağıdaki tabloda belirtilen değerlerin üzerinde olmamalıdır.
    </p>

    <table border="1">
        <tr>
            <th>Tehlike Sınıfı</th>
            <th>Maksimum Zon Alanı (m²)</th>
        </tr>
        <tr>
            <td>Düşük Tehlike (DT)</td>
            <td>4831</td>
        </tr>
        <tr>
            <td>Orta Tehlike (OT)</td>
            <td>4831</td>
        </tr>
        <tr>
            <td>Yüksek Tehlike (YT)</td>
            <td>3716 (Hidrolik Hesap Yöntemi)</td>
        </tr>
        <tr>
            <td></td>
            <td>2323 (Boru Tablosu Yöntemi)</td>
        </tr>
    </table>

    <p>
        Yüksek depolama alanlarında, herhangi bir besleme kolonuna bağlanan her bir kattaki veya koruma zonundaki maksimum kat alanı 3716 m²’nin üzerinde olmamalıdır. Bir sprinkler zonu birden fazla kata veya ayrı kullanım bölgesine hitap ediyorsa, katların veya bölgelerin girişine izlenebilir kesme vanası, akış anahtarı ve test ve drenaj vanası konulmalıdır.
    </p>

    <p>
        Yüksek binalarda, maksimum statik basıncın sprinkler sistemi ekipmanının limit değerleri içinde kalması sağlanmalıdır. Zonlara ayrılmış sprinkler tesisatlarında vanaların açık veya kapalı durumu ile zon su akış durumunu izlemek için gerekli cihazlar teçhiz edilmeli ve tesiste ulaşılabilir bir yerde bulunan bir kontrol ve gösterge paneline bağlantılı olmalıdır. Yangın ve arıza sinyalleri sürekli olarak izlenmelidir. Loop ve grid borulama sistemleri sadece ıslak borulu sprinkler sistemlerinde kullanılabilir.
    </p>

    <p>
        Antifriz sıvısı ile korunan Islak Borulu Sprinkler Sistemi bölümleri aşağıda belirtilen kurallara uygun tasarlanmalıdır:
    </p>

    <ul>
        <li>Antifriz sıvısı ile korunan her bir ıslak sprinkler boru bölümündeki sprinkler sayısı 20 adedi geçemez.</li>
        <li>Bir adet kontrol vana seti ile iki antifriz bölümünden daha fazla sayıda bölüm kontrol ediliyorsa, antifriz bölmelerindeki toplam sprinkler sayısı 100 adedi geçemez.</li>
        <li>Antifriz sıvısının donma noktası bulunduğu lokasyonda beklenen minimum sıcaklığın altında olmalıdır. Hazırlanan çözeltinin özgül ağırlığı uygun bir hidrometre ile kontrol edilmelidir.</li>
        <li>Antifriz sıvısı kullanılan sistemlerde suda kirliliği önleyici, geri akış önleyici cihazlar kullanılmalıdır.</li>
        <li>ESFR sprinkler antifirizli sistemlerde kullanılamaz. ESFR Sprinkler kullanılan sistemlerde eğer sprinkler başlığı Antifiriz (Propilen Glikol) ile listeli ise kullanımına izin verilir.</li>
    </ul>

    </div>






    </>)
}