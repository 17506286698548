import React from 'react';
import { Container } from 'react-bootstrap';
export default function Sartnameler() {
    const divStyle = {
      height: '100px',
      width: '100%',
      background: ' rgb(0,55,105)',
      background: '   linear-gradient(90deg, rgba(0,55,105,1) 0%, rgba(0,130,226,1) 100%)',
      paddingTop:10,
      fontSize: "3rem",
      color:" #ffffff",
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      textShadow: '4px 4px 4px rgba(0, 0, 0, 0.5)'
    };
  
    const downloadDoc1 = (pdfFileName) => {
        const downloadUrl = `${process.env.PUBLIC_URL}/sartnameler/${pdfFileName}.pdf`; 
        const anchor = document.createElement('a');
        anchor.href = downloadUrl;
        anchor.download = pdfFileName + '.pdf';
        anchor.click();

      };

  
    return (
      <>
        <div style={divStyle}>
          <p>Teknik Şartnameler</p>
        </div>
        <Container style={{ minHeight: 600, padding: 40 }}>
    
          <li  style={{ padding: 20, margin: 20 }} className="pdf-download-button" onClick={()=>downloadDoc1("01-ARGON_IG01_FMUL_VDS_CONSTANT FLOW_ŞARTNAME")}>IG-01 (ARGON) TEMİZ GAZLI SÖNDÜRME SİSTEMLERİ ŞARTNAMESİ</li>
          <li  style={{ padding: 20, margin: 20 }} className="pdf-download-button" onClick={()=>downloadDoc1("02-FK5-1-12_FMUL_ŞARTNAME")}>FK 5-1-12 GAZLI SÖNDÜRME SİSTEMLERİ ŞARTNAMESİ</li>
          <li  style={{ padding: 20, margin: 20 }} className="pdf-download-button" onClick={()=>downloadDoc1("03-FM-200_FM UL_ŞARTNAME-2023")}>HFC227-EA (FM200®) GAZLI SÖNDÜRME SİSTEMLERİ ŞARTNAMESİ</li>
          <li  style={{ padding: 20, margin: 20 }} className="pdf-download-button" onClick={()=>downloadDoc1("04-CO2_SÖNDÜRME_SİSTEMİ_ŞARTNAMESİ_2023")}>CO2 GAZLI  YANGIN SÖNDÜRME SİSTEMLERİ ŞARTNAMESİ</li>
          <li  style={{ padding: 20, margin: 20 }} className="pdf-download-button" onClick={()=>downloadDoc1("05-AMEREX_DAVLUMBAZ_SÖNDÜRME_SİSTEMİ_ ŞARTNAMESİ_2023")}>MUTFAK DAVLUMBAZ SÖNDÜRME SİSTEMİ ŞARTNAMESİ</li>
          <li  style={{ padding: 20, margin: 20 }} className="pdf-download-button" onClick={()=>downloadDoc1("06-YANGIN ALGILAMA VE İHBAR SİSTEMİ SİSTEMI TEKNIK SARTNAMESI")}>ELEKTRONİK ADRESLENEBİLİR İNTERAKTİF YANGIN ALGILAMA VE ALARM SİSTEMİ ŞARTNAMESİ</li>
          <li  style={{ padding: 20, margin: 20 }} className="pdf-download-button" onClick={()=>downloadDoc1("DİYAFRAMLI TANKLI KÖPÜK MERKEZİ ŞARTNAMESİ AFFF VE AFFF AR")}>DİYAFRAMLI - BLADDER TANKLI KÖPÜK MERKEZİ ŞARTNAMESİ</li>
          <li  style={{ padding: 20, margin: 20 }} className="pdf-download-button" onClick={()=>downloadDoc1("YANGIN_POMPA_GRUBU_ŞARTNAME-2023")}>YANGIN POMPA GRUBU ŞARTNAMESİ</li>
          <li  style={{ padding: 20, margin: 20 }} className="pdf-download-button" onClick={()=>downloadDoc1("YANGINDAN KORUNUM SİSTEMİ")}>ÜRETİM VE DEPOLAMA SAHALARI YANGINDAN KORUNUM SİSTEMİ ŞARTNAMESİ </li>
        </Container>
      </>
    );
  }