import banner from '../../../img/main/014- KIVILCIM SÖNDÜRME SİSTEMLERİ/Başlık.png'
export default function Kıvılcım() {
    const containerStyle = {
       
      
        maxWidth: '800px',
      
        
        fontFamily: 'Arial, sans-serif',
      };
    
      const headingStyle = {
        fontSize: '24px',
        marginTop: '0',
        fontWeight: '800',
      };
      const divStyle={
        height: '200px',
        width: '100%',
        backgroundImage:`url(${banner})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        padding: 0,
        fontSize: "3rem",
        color: " #ffffff",
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom:20,
        textShadow: '4px 4px 4px rgba(0, 0, 0, 0.5)'
    }
  
    return (<>
  <div style={containerStyle}>
     <div style={divStyle}>
   
     </div>
      <h2 style={headingStyle}>Kıvılcım Algılama Ve Söndürme Sistemi</h2>
      <div style={{minHeight:1, backgroundColor:'red',marginBottom:10,marginTop:10}}></div>
      <p>
    Yangından korunmak için kıvılcım söndürme sistemi kullanılır. Kıvılcım söndürme sistemi, yangın veya toz patlaması meydana gelmeden önce ateşleme kaynaklarını tespit edip ortadan kaldırabilir. Öğütme, doğrama, kurutma, soğutma ve soğutma sistemleri, Pnömatik veya mekanik taşıma ve ekstraksiyon sistemleri ve ayırma veya depolama amaçlı tesisler de dahil olmak üzere presleme malzemeleri, ayrıca toz toplayıcılar, filtreler, siklonlar, silolar ve toz toplama hazneleri özellikle risk altındadır.
    Endüstriyel üretimde hammaddelerin boyutları büyük ölçüde küçültülür. Bu nedenle pnömatik veya mekanik taşıma yolları üretim tesislerinin temel bileşenidir. Ancak bu bağlantı üzerinden yalnızca "planlanan" tozlar ve dökme mallar taşınmıyor. İşleme makinelerinde veya kurutucularda da kıvılcımlar veya iç içe yanan parçacıklar üretilebilir ve bu nedenle, filtreler ve silolar da dahil olmak üzere sistem parçaları için önemli bir yangın ve patlama tehlikesi oluşturur.
  </p>

  <p style={{fontWeight:600}}>Yangın ve Patlama Koşulları</p>
  <p>
    Üç koşulun karşılanması durumunda bir yangın veya toz patlaması meydana gelir. Yanıcı tozlar, hava (oksijen) ve bir tutuşma kaynağı bir arada bulunabilir. Bu koşulların meydana gelmesi durumunda yangın meydana gelebilir. Bu koşullardan birinin ortadan kalkması, yangın veya patlama riskini büyük ölçüde azaltacaktır. Yanıcı toz giderilemediği için inert gazların kullanılması veya tutuşma kaynaklarının ortadan kaldırılması mümkündür.
  </p>

  <p style={{fontWeight:600}}>Kıvılcım Söndürme Sistemleri</p>
  <p>
    Kıvılcım söndürme sistemleri sanayi ve zanaatta önleyici yangın ve patlamadan korunma sistemleri olarak kurulmaktadır. Günümüzde pek çok endüstri kıvılcım söndürme sistemleri olmadan ekonomik olarak işletilememektedir. Teknik olarak kullanım alanları dikkate alındığında yangına karşı son derece etkili ve önleyici koruma sistemidir.
  </p>

  <p style={{fontWeight:600}}>Kıvılcım Söndürme Sistemleri Yapısı ve Çalışma Modu</p>
  <p>
    Kıvılcım söndürme sistemlerinin yapısı ve çalışma modu dikkate alındığında yangın söndürme sisteminin üç ana unsuru vardır:
  </p>

  <ul>
    <li>Kontrol Paneli</li>
    <li>Kıvılcım (Spark) Dedektörleri</li>
    <li>Otomatik Söndürme Sistemi</li>
  </ul>

  <p style={{fontWeight:600}} >Kontrol Paneli</p>
  <p>
    Kıvılcım (Spark) söndürme sisteminin merkezidir. Dedektörlerden sinyaller alır ve önceden programlanmış karşı önlemleri etkinleştirir. Tesisin farklı yerlerinden gelen bireysel kıvılcım dedektörlerinin tüm sinyalleri kontrol konsolunda bir araya gelir. Bağlı söndürme ekipmanı veya ilgili algılama bölgesinin alarm rölesi çıkışı gecikmeden etkinleştirilir.
  </p>

  <p style={{fontWeight:600}} >Kıvılcım (Spark) Algılama Detektörleri</p>
  <p>
    Ateşleme kaynaklarını (kıvılcım-spark) tespit etmek için kullanılır. Kıvılcımlar veya iç içe yanan parçacıklar tarafından yayılan kızılötesi radyasyonu, hatta bazen toz tabakasından veya taşınan malzemeden bile algılarlar. Sistemde kıvılcım (Spark) tespit edilmesi durumunda kıvılcım dedektörleri, bu sinyalleri değerlendiren ve otomatik olarak belirli karşı önlemleri başlatan kontrol panosuna alarm sinyalleri gönderir.
  </p>

  <p style={{fontWeight:600}} >Otomatik Söndürme Sistemi</p>
  <p>
    Çoğu durumda söndürme su ile yapılır. Otomatik söndürme ünitesi; Kontrol Paneli, Kıvılcım Detektörleri, haricinde "Pompa Grubu, Su deposu, Manual Kesme Vanası, Solenoid Vana, Flow Switch, Su Püskürtme Nozulları ve Boru Ağından" oluşur.  Tespit edilen ateşleme kaynağının veya sparkın güvenilir bir şekilde söndürülmesini sağlamak için kıvılcım dedektörleri ve söndürme ünitesi belirli bir mesafeye yerleştirilmelidir. Bu mesafeye kıvılcım söndürme aralığı olarak adlandırabiliriz. Bu aralık taşınan malzeme ve boru içindeki akış hızına bağlı olarak üretici firma tarafından belirlenmiş tablolar kullanılarak belirlenir.
  </p>

  <p>
    Otomatik söndürme sistemi, kıvılcımların yakalanıp söndürülmesi için kanal içinde geçici olarak su sisi oluşturur. Başka kıvılcım tespit edilmezse su uygulaması otomatik olarak durdurulur; böylece üretim sorunsuz bir şekilde devam edebilir. Çok yüksek ısı azaltma kapasitesi sayesinde mükemmel bir söndürme etkisine ulaştığı için ağırlıklı olarak su söndürücü madde olarak kullanılmaktadır.
  </p>

  <p style={{fontWeight:600}}>
    Kıvılcım Söndürme Sistemi Kullanım Alanları:
  </p>

  <ul>
    <li>Ağaç Endüstrisi</li>
    <li>Geri Dönüşüm Endüstrisi</li>
    <li>Pelet Yakıt Üretimi</li>
    <li>Gıda Endüstrisi</li>
    <li>Tekstil Endüstrisi</li>
    <li>Metal Endüstrisi</li>
    <li>Mobilya Endüstrisi</li>
    <li>Kağıt Endüstrisi</li>
    <li>Hayvan Yemi</li>
    <li>Tütün Endüstrisi</li>
    <li>Hijyenik Ped Üretim Endüstrisi</li>
    <li>Un Üretimi</li>
    <li>Şeker Endüstrisi</li>
    <li>Kahve Endüstrisi</li>
    <li>Kakao Endüstrisi</li>
  </ul>

  <p>
    Kıvılcım söndürme sistemlerinin uygulama alanları Yanıcı maddelerin işlendiği, taşındığı, filtrelendiği veya kurutulduğu her yerde kıvılcım veya sıcak parçacıkların neden olduğu yangın ve patlama tehlikesi mevcuttur. Kıvılcım söndürme sistemi yangınları ve toz patlamalarını önleyebilir. Başlangıçta MDF üretimi, sunta üretimi, OSB üretimi, kontrplak üretimi başta olmak üzere ahşap endüstrisi için geliştirilen güvenlik konsepti günümüzde tüm üretici endüstrilere aktarılabilmektedir.
  </p>

      
  
    </div>






    </>)
}