import Tablo from '../../../img/main/TabloYangın.png'
import divStyle from '../../../css/bannerCss';
export default function MainYazıDolap() {
    const containerStyle = {
      
      
      maxWidth: '800px',
     
      
      fontFamily: 'Arial, sans-serif',
    };
  
    const headingStyle = {
      fontSize: '24px',
      marginTop: '0',
      fontWeight: '800',
    };

    return (<>
      <div style={containerStyle}>
        <div style={divStyle}>
          
        </div>
        <h2 style={headingStyle}>TS EN 671-1 ve EN 671-2'ye Uygun Yangın Dolapları</h2>
        <div style={{ minHeight: 1, backgroundColor: 'red', marginBottom: 10, marginTop: 10 }}></div>
        <p>Binaların Yangından Korunması Hakkında Yönetmeliği’nin 94. maddesinde belirtildiği üzere tesisatın amacı, bina içinde yangın ile mücadelede güvenilir ve yeterli suyun sağlanmasıdır. Bunun için bina içinde kurulumu yapılacak olan sistemlerden biride yangın dolaplarıdır. Yangın dolapları saha personelinizin oluşan küçük çaplı yangınlara müdahale etmesi için planlanmaktadır. Boru ağı vasıtası ile yangın sistemi üzerinden beslenecek şekilde planlanır.
</p>
<p>Yangın dolap sistemi planlanırken göz önünde bulundurulması gereken kritik şartlar ve zorunluluklar; 
</p>
<ul>
  <li>Yüksek binalarda boyutu 60 m’yi geçen katlarda ve toplam kapalı kullanım alanı 1000 m2den büyük imalathane, atölye, depo, konaklama, sağlık, toplanma amaçlı ve eğitim binalarında, alanlarının toplamı 600 m2den büyük olan kapalı otoparklarda, Isıl kapasitesi 350 kW’ın üzerindeki kazan daireleri yangın dolabı yapılması zorunludur.</li>
  <li>Yangın dolapları mümkün olduğu kadar koridor çıkışı ve merdiven sahanlığı yakınına kolaylıkla görülebilecek şekilde yerleştirilir.</li>
  <li>Eğer tesis veya bina sprinkler (yağmurlama) sistemi ile korunmuyor ise  yangın dolapları; her katta ve yangın duvarları ile ayrılmış her bölümde aralarındaki uzaklık 30 m’den fazla olmayacak şekilde planlanır.</li>
  <li>Binanın yağmurlama sistemi ile korunması ve katlarda itfaiye su alma ağzı bulunması durumunda, yangın dolapları, ıslak borulu sprinkler sistemi üzerinden beslenebildiği gibi ve aralarındaki uzaklık 45 m’ye kadar arttırılabilir.</li>
  <li>Özellikle yangın dolabı kabinleri içindeki vanaları, makara ve hortumun muhafazası ve ergonomik kullanımı için doğru boyutlandırılmış olmalıdır.</li>
  <li>Yangın dolaplarının sadece yangına müdahale amacı ile kullanılması gerekir.</li>
  <li>Hortumları serme ve bağlama gibi becerilere sahip eğitilmiş personeli veya itfaiye görevlisi olmayan yapılarda, TS EN 671-1’e uygun  yuvarlak yarı-sert hortumlu yangın dolaplarının kurulumu şarttır.</li>
  <li>Hortumun, yuvarlak yarı - sert  TS EN 694 normuna uygun, çapının 25 mm olması gereklidir.</li>
  <li>Dolap içindeki yuvarlak yanı-set hortum uzunluğunun 30 m’yi aşmaması istenir.</li>
  <li>Yangın dolabi içendeki nozulun (lansın) fog-jet ve açma/kapama işlevlerini yerine getirebilecek özellikte minimum 9.4mm orifise sahip olması gereklidir.</li>
  <li>İçinde itfaiye su alma ağzı olmayan yuvarlak yarı-sert hortumlu yangın dolaplarında tasarım debisinin 100 l/dak ve tasarım basıncının 400 kPa olması şarttır.</li>
  <li>Yangın dolabının Nozul (lans) girişindeki basıncın 900 kPa’ı geçmesi hâlinde, basınç düşürücü kullanılması gerekir.</li>
  <li>Yassı hortumları sıklıkla çevre hidrant sistemi ile birlikte sıklıkla dış sahada kullanmamıza rağmen, yetişmiş yangın söndürme görevlisi bulundurulmak mecburiyetinde olan yapılarda kullanılabilir.</li>
  <li>Yassı hortumlu yangın dolaplarının TS EN 671-2 standardına uygun olması şarttır.</li>
  <li>Yassı hortumun, anma çapının 50 mm’yi, uzunluğunun 20 m’yi geçmemesi ve kullanılan lansın Fog/Jet ve Açma/Kapama yapabilmesi gerekir.</li>
  <li>Yangın dolabı tasarım debisinin 400 l/dak. ve tasarım basıncının en az 400 kPa olması şarttır. Yangın Lansı girişindeki basıncın 900 kPa’ı geçmesi hâlinde, basınç düşürücü kullanılması gerekir.</li>
  <li>Binalarda bulunan yangın dolaplarının ve hortum makara sistemlerinin TS EN 671-3 standardında belirtilen periyodik bakımlarının, tesis sahibi, bina sahibi, yönetici veya sorumlu yetkili tarafından yaptırılması zorunludur.</li>
</ul>
<p>
Bu zorunlulukların yanında, yapının barındırdığı risklere bağlı olarak duruma ve ihtiyaca yönelik özel dolaplar tanımlanabilir ve kullanılabilir.

</p>
<p style={{fontWeight:600}}>
Yangın Dolabı Türleri
</p>
<p p style={{fontWeight:600}}>
Standart Tek Bölmeli Yangın Dolapları 
</p>
<p>
Standart yangın dolaplarında DKP sacdan kasa içerisinde makara, 180° açıyla dönebilen bir desteğe bağlıdır. 1” küresel veya 2” köşe vanalı 20 m veya 30 m 1” kauçuk hortum makaraya sarılı olarak tutulur. EN 671/1 standardına uygun bir nozul bulunmaktadır. EN694’e göre imal edilmiş yarı sert kırılmaz kauçuk hortum Kabin dış kapağına 92/58/EEC’ye uygun işaretlemeler bulunmaktadır.

</p>
<p style={{fontWeight:600}}>
Teçhizat veya Tüp Bölmeli Yangın Dolapları
</p>
<p>
Bu yangın dolapları standart yangın dolapları ile aynı özelliklerde olup, ilave olarak içerisinde portatif söndürücü konulabilecek bir bölme bulunmaktadır. İsteğe bağlı olarak çift bölmeli ve üç bölmeli modelleri de uygulanmakta olan bu yangın dolaplarında, özellikle yer darlığı olan alanlarda kullanılan, portatif söndürücü ve hortum makarasının aynı hacimde bulunduğu tek bölmeli dikey modelleri de  mevcuttur.
</p>
<p style={{fontWeight:600}}>
Özel Tasarım Yangın Dolapları
</p>
<p>
Konut, konaklama ve endüstriyel projelerde tercih edilen standart yangın dolaplarının dışında, Endüstriyel tesislerin misafir ve müşteri karşılama alanlarında, lüks konut ve otel gibi mimari açıdan görselliğin ön planda olduğu projelerde tercih edilmektedir. Bu yangın dolaplarında genel olarak işlevsel kısımlar standart olmasına karşın, kasa malzemesi, boya veya kaplaması ile kapak malzemesi gibi değişiklikler müşteri talepleri noktasında şekillendirilmektedir.

</p>
<p style={{fontWeight:600}}>
Köpük Karışımı Yapabilen Yangın Dolapları</p>
<p>
Endüstriyel projelerde yanıcı ve parlayıcı sıvıların yani hidrokarbon, solvent ve alkol vb. kimyasalların bulunduğu yangınlara müdahale için özellikle köpük karışımı gerekmesi durumunda geliştirilmiş yangın dolaplarıdır.Dolap içinde standart ürünlere ek olarak; köpük karışım yapıcı, köpük haznesi ve yanıcı sıvıya bağlı olarak uygun köpük konsantresi ile donatılan yangın dolapları tercih edilmektedir. Bu dolaplarda bulunan köpük tankında, standart %1 veya %3 kullanım oranlı AFFF köpük konsantresi, eğer solvent ve alkoller söz konusu ile AFFF-AR 3x3 bulunmakta ve ayarlanabilir bir köpük karıştırıcı ile hortum vasıtasıyla köpük lansından köpük elde edilebilmektedir. Film yapıcı köpük konsantrelerinin kulalnılması durumunda aspirasyon yapmayan lanslarla da kullanılabilir. Tesis ihtiyaçlarına göre çeşitli kapasitelerde üretilebilmektedir.
</p>
<p style={{fontWeight:600}}>
Standart Tek Bölmeli veya Techizat Bölmeli Bez Hortumlu Yangın Dolapları 
</p>
<p>
Bez hortum ile kullanılması durumunda; standart yangın dolaplarında DKP sacdan kasa içerisinde makara, daha basite indirgenmiş ve 180° açıyla dönebilen bir desteğe bağlıdır. 2” köşe vanalı olup, 2” bez hortum makaraya sarılı ve TS 9222’ye uygun olmalıdır. Kullanılacak yangın lansı jet/spray-açma-kapama yapabilir özellikte seçilmelidir. Dahili alanlarda itfaiyeci veya eğitimli personel bulundurulan işletmelerde kullanımına müsaade edilir.
</p>
<p style={{fontWeight:600}}>
Dış Saha Yangın Ekipman (Hidrant) Dolapları  
</p>
<p>
Dış sahada bulunan çevre hidrant sistemini kullanarak yangına müdahale amacı üretilen dolaplardır. Hidrant ağızlarına ihtiyaç halinde bağlanmak üzere uygun rakorlara sahip yangın hortumu, jet/spray-açma-kapama lansı ile donatılan yangına müdahale için gerekli ekipmanın bulunduğu dolaptır. Dış sahada kullanılmak için üretilen dolapların içinde tek ya da çift makara TS.EN671-2’ye uygun olarak imal edilen Alman Tip bağlantı rakorlu  2” ve 2 ½” çaplarında bez hortum, ve yangına müdahale lansı ile hidrant anahtarı sıklıkla standart eleman olarak bulunmaktadır. Dış saha hortum dolapları; görülmesi ve erişilmesi kolay bir yere beton kaide üzerine kalacak şekilde sabitlemelidir. Dış saha yangın dolabının üreticisi tarafından üretilen ve sabitlemeye olanak sağlayacak ayak ve montaj elemanlarının dolaplarla birlikte tedarik edilmesini tavsiye ederiz.
</p>
<p>
AKSAY Yangın Yangın Dolap Sistemlerinin Tasarımı, Mühendislik Hesapları, Tedarik Edilmesi, Montaj-Test ve Devreye Alma ve Kontratlı Bakım hizmetlerini yetişmiş Mühendis ve Teknisyen kadrosu ile gerçekleştirmektedir.  

</p>
      </div>
  
  
  
  
  
  
    </>)
  }