import { useState } from 'react';
import '../../../css/footer.css';

import banner from '../../../img/main/01- SULU SÖNDÜRME SİSTEMLERİ-YENİ/005- ÖN TEPKİLİ SİSTEMLER/Başlık.png';
export default function Deluge() {
  const [showMore, setShowMore] = useState(true);

  const containerStyle = {
  
    
    maxWidth: '800px',
  
    
    fontFamily: 'Arial, sans-serif',
  };

  const headingStyle = {
    fontSize: '24px',
    marginTop: '0',
    fontWeight: '800',
  };

  const divStyle={
    height: '200px',
    width: '100%',
    backgroundImage:`url(${banner})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    padding: 0,
    fontSize: "3rem",
    color: " #ffffff",
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom:20,
    textShadow: '4px 4px 4px rgba(0, 0, 0, 0.5)'
}
  const toggleShowMore = () => {
    setShowMore(!showMore);
  };

  return (
    <>
      <div style={containerStyle}>
        <div style={divStyle}></div>
        <h2 style={headingStyle}>Preaction Sistemler</h2>
        <div style={{ minHeight: 1, backgroundColor: 'red', marginBottom: 10, marginTop: 10 }}></div>
        <p>
        Özellikle suyun baskın etkisinin vereceği zararlardan korunmak için ön tepkili sistemler kullanılır. 

        </p>
        <p>
        Bu hali ile sistemin korunan mahalin temel risklerini dikkate alarak aktivasyon sisteminin yani devreye girebilmesi için arzu edilen öncelikli şartın belirlenmesi gereklidir. Öncelikli şartın belirlenmesi ile aşağıda verilen Ön tepkili (Pre-Action) sistem tiplerinden biri seçilerek kullanılır.

        </p>

        {showMore && (
          <>
            <p>
            Inbal Ön Tepkili (Pre-Action)Sistemi, su beslemesinin Inbal Vana tarafından ayarlı pozisyonda tutulduğu, hava veya gazla basınçlandırılan kapalı sprinkler boru sistemine sahip yangından korunma sistemlerinde kullanılmak üzere özel olarak tasarlanmış ve kontrol edilebilen ek bir algılama sistemi ile donatılmıştır. Bu algılama sistemleri; Pnömatik veya Elektrikle veya her ikisinin de kullanıldığı sistemlerdir. Ön tepkili (Pre-Action) sistemler kendi içinde aşağıda listelenen tiplere ayrılır.
            </p>
            <h5 style={{fontWeight:600}}>1 - Kilitlemesiz Ön Tepkili (Non-Interlock Pre-Action) Sistemler</h5>
            <p>
            Pnömatik Aktivasyonlu ve Elektrik Aktivasyonlu Non-Interlock Pre-Action Vana olmak üzere kendi içinde ikiye ayrılmaktadır.
            </p>
            <p>
            Bu sistem normal kuru borulu sprinkler sisteminin diğer çeşidi olup, ön tepkili alarm vanasından boru sistemine su geçişi, sprinklerin açılması ile veya otomatik bir algılama sisteminin devreye girmesi ile başlar. Algılama sisteminden  bağımsız olarak, boru içindeki basıncın düşmesi ön tepkili alarm vanasının açılmasına sebep olur.
            </p>
            <ul>
              <li>
              Kilitlemesiz ön tepkili sprinkler sistemi kuru borulu sistem talep edilen, yangın yayılımının hızlı olmasının beklendiği yerlerde uygulanabilir. Aynı zamanda, hızlandırıcılı veya hızlandırıcısız kuru borulu sistemlerin yerine kullanılabilir.

              </li>
              <li>
              Aktivasyon yöntemi mahalde kullanılması uygun görülen algılama sistemi seçeneğine göre belirlenir. Aktivasyon pneumatik veya pneumatik/elektriksel olabilir.

              </li>
            </ul>
            <p>
            Kilitlemesiz ön tepkili  (Non-Interlock Pre-Action) sistemler için lütfen bizimle irtibata geçiniz.
            </p>
            <h5 style={{fontWeight:600}}>2 - Tek Kilitlemeli Ön Tepkili (Single Interlock Pre-Action) Sistemler</h5>
            <p>
            Bu sistem normal kuru borulu sprinkler sisteminin diğer çeşidi olup, ön tepkili alarm vanasından boru sistemine su geçişi sadece otomatik bir algılama sisteminin devreye girmesi ile başlar. Sprinkler başlığı kapalı tiptir.  Sprinklerin açılması ile boru sistemine su geçişi olmaz.

            </p>
            <ul>
              <li>
              Tek kilitlemeli ön tepkili sprinkler sisteminin, kaza sonucu suyun boşalmasının ciddi maddi zararlara neden olabileceği durumlarda kullanılır.

              </li>
              <li>
              Aktivasyon yöntemi mahalde kullanılması uygun görülen algılama sistemi seçeneğine göre belirlenir.

              </li>
              <li>
              Aktivasyon pneumatik veya elektriksel olabilir.

              </li>
            </ul>
            <p>
            Tek kilitlemeli ön tepkili  (Single Interlock Pre-Action) sistemler için lütfen bizimle irtibata geçiniz.
            </p>
            <h5 style={{fontWeight:600}}>3 - Çift Kilitlemeli Ön Tepkili (Double Interlock Pre-Action) Sistemler</h5>
            <p>
            Bu sistem normal kuru borulu sprinkler sisteminin diğer çeşidi olup, ön tepkili alarm vanasından boru sistemine su geçişi sprinklerin açılması ile ve aynı zamanda otomatik bir algılama sisteminin devreye girmesi ile başlar.

            </p>
            <ul>
              <li>
              Çift kilitlemeli ön tepkili sprinkler sisteminin, kaza sonucu suyun boşalmasının ciddi hasarlara sebep olacağı yerlerde kullanılması uygundur.

              </li>
              <li>
              Suyun boru sistemine geçişi iki bağımsız olayın gerçekleşmesi durumunda olduğundan kaza sonucu boşalma olasılığının minimize edildiği sistem tipidir. 

              </li>
              <li>
              Aktivasyon yöntemi mahalde kullanılması uygun görülen algılama sistemi seçeneğine göre belirlenir.

              </li>
              <li>
              Aktivasyon yöntemi pnömatik/pnömatik, pnömatik/elektrikli, elektrikli/ elektrikli olabilir.

              </li>
            </ul>
            <p>
            Çift kilitlemeli ön tepkili  (Double Interlock Pre-Action) sistemler için lütfen bizimle irtibata geçiniz.

            </p>
            <h5 style={{fontWeight:600}}>Pre-Action (Ön Tepkili) Sistemler için temel gereklilikler:</h5>
            <p>
            Kilitlemesiz (Non-Interlock) ve Tek Kilitlemeli (Single-Interlock) ön tepkili sistemlerde sistem büyüklüğü, en fazla 1000 sprinkler aynı ön tepkili alarm vanasından kontrol edilecek şekilde belirlenmelidir. Çift kilitlemeli ön tepkili sistemlerde; tek bir ön tepkili alarm vanası ile kontrol edilen sistem büyüklüğü; aşağıdaki maddelerden herhangi birine uygun olarak belirlenmelidir.

            </p>
            <ul>
              <li>En uzak noktada bulunan test vanası tamamen açıldığında ve eş zamanlı olarak algılama sistemi devreye girdiğinde, suyun boşalması için geçen süre en fazla 60 saniye olmalıdır.</li>
              <li>
              Sistem büyüklüğü; suyun boşalması için geçen süreyi hesaplayan bilgisayar programı kullanılarak belirlenmelidir.

              </li>
              <li>
              Hesap programı ve metodu ulusal olarak tanınmış bir laboratuar tarafından onaylanmış olmalıdır.

              </li>
              <li>
              Sistem büyüklüğü; akış testleri yapıldığında suyun boşalması için geçen süre ile ilgili olarak verilen değerleri sağlayacak ölçüde olmalıdır. Akış testleri, açılacak sprinkler sayısı simüle edilerek yapılır.

              </li>
              <li>
              Yukarıda (1), (2) ve (3) maddelerinde verilen şartların sağlanmasına yardımcı olmak için hızlandırıcı kullanılabilir. 

              </li>
            </ul>
            <h5 style={{fontWeight:600}}>Ön tepkili sprinkler sistemlerinde hangi sprinkler tiplerinin kullanılmasına izin verilir.</h5>
            <ul>
              <li>
              Dik tip sprinkler

              </li>
              <li>
              Kuru tip sprinkler

              </li>
              <li>
              Yatay duvar tipi sprinkler (Boru içinde su kalmayacak şekilde imal edilen tesisatlarda)

              </li>
              <li>
              Sarkık tip ve duvar tipi sprinkler (Sprinkler, boru kolu ve branşmanların, sıcaklığın 4ºC üzerinde olduğu mahallerde bulunması durumunda)

              </li>
            </ul>
            <h5 style={{fontWeight:600}}>Ön tepkili sprinkler sistemlerinde aşağıdaki kriterlere uyulmalıdır.</h5>
            <ul>
              <li>
              Borulamadaki basıncı sağlamak için kullanılan hava veya nitrojen beslemesi sürekli izlenmelidir.

              </li>
              <li>
              Acil durumlarda ön tepkili alarm vanasını devreye sokmak için en az bir adet elle boşaltma istasyonu teçhiz edilmelidir.

              </li>
              <li>
              Tüm ön tepkili sistem tiplerinde, grid borulama tesisatı kullanılamaz. Kilitlemesiz ve çift kilitlemeli sistemlerde, hava basıncı en az 0.5 bar olmalıdır.

              </li>
              <li>
              Ön tepkili sistemlerde hava beslemesi yapılan kaynak sürekli olmalıdır.

              </li>
              <li>
              Hava beslemesi, sistemin dolumunu 30 dakika içinde sağlayacak kapasitede olmalıdır.

              </li>
              <li>
              Tek kompresör ile birden fazla kuru borulu sistem besleniyorsa, 30 dakika dolum süresi için en büyük sistem baz alınmalıdır.

              </li>
              <li>
              -15ºC’nin altında soğutulmuş mahallerde, dolum süresi 60 dakika alınabilir.
Ön tepkili sprinkler sistemi ile korunan tüm oda ve bölmelere otomatik algılama sistemi yapılmalıdır.

              </li>
              <li>
               Algılama sistemleri TS EN54 Standardına uygun olmalıdır.

              </li>
              <li>
              Birden fazla ön tepkili sprinkler sistemi kullanılan yerlerde, birden fazla sistemin aynı anda çalışma durumu göz önüne alınarak risk değerlendirmesi yapılmalıdır.

              </li>
              <li>
              Birden fazla sistemin aynı anda çalışma durumunda aşağıdakiler uygulanmalıdır

              </li>
              <li>
              Depolanan su hacmi toplam ön tepkili sistem hacmine göre arttırılmalıdır.

              </li>
            </ul>
            <p>
            Çoklu sistemlerde suyun en uzak noktadaki test vanasından boşalma süresi 60 saniyeyi geçmemelidir.

            </p>
          </>
        )}

       
      </div>
    </>
  );
}