import { Col, Container, Row } from "react-bootstrap"
import '../App.css'
import { useEffect, useState } from "react";
import ReferansSlider from "../component/ReferansSlider";
import img1 from '../img/mainFoto/açılışsayfa2.png'
import img3 from '../img/mainFoto/açılışsayfa3.png'
import img2 from '../img/mainFoto/açılışsayfa4.png'
import img4 from '../img/mainFoto/açılışsayfa5.png'
function Anasayfa (){
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const images = [
        img1,
        img3,
        img2,
        img4
        // Diğer resim URL'lerini buraya ekleyin
      ];
      
    const changeInterval = 5000;
    
    useEffect(() => {
      // Otomatik resim değişimi için bir zamanlayıcı ayarlayın
      const interval = setInterval(() => {
        setCurrentImageIndex((prevIndex) =>
          prevIndex === images.length - 1 ? 0 : prevIndex + 1
        );
      }, changeInterval);
  
      // Bileşen temizlendiğinde zamanlayıcıyı temizleyin
      return () => clearInterval(interval);
    }, []);
    const containerStyle = {
      backgroundImage: `url(${images[currentImageIndex]})`,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      width:'100'
      // Diğer stil özelliklerini buraya ekleyebilirsiniz
  };
    return(
        <>
        <Container fluid className="welcome-container" style={containerStyle}>
        <Row className="justify-content-center align-items-center full-height">
          <Col xs={12} className="text-center">
            <h1 className="welcome-title">Hoş Geldiniz</h1>
            <p className="welcome-description">Yangın, hem maddi hem de insan hayatı açısından büyük zararlar verebilecek potansiyele sahip ciddi bir tehlikedir.</p>
            <p  className="welcome-description">Toplumumuzun ve iş dünyamızın güvenliği için bu tehlikeyi en aza indirme konusundaki kararlılığımızı paylaşıyoruz.</p>          
          </Col>
        </Row>
      </Container>
      <Container>
      <ReferansSlider/>
      </Container>
      <Container  className="info-container">
        
        
            <h5 className="text-center">Hakkımızda</h5>
            <br />
            <p style={{textAlign:"center",justifyContent:"center"}}>Aksay Yangın, 1997 yılından bu yana yangın söndürme ve algılama sistemleri alanında kapsamlı hizmetler sunarak, sektördeki öncü konumunu pekiştirmiştir. 1995 yılından beri süregelen birikim ve deneyimle, danışmanlık, tasarım, projelendirme, ürün temini, montaj, servis ve bakım gibi geniş bir yelpazede müşteri ihtiyaçlarına profesyonel çözümler üretmektedir.</p>
            <p style={{textAlign:'center',justifyContent:'center'}}>Aksay Yangın, yangın korunum sistemlerinde mühendislik odaklı bir yaklaşım benimseyerek, müşteri taleplerini en uygun çözümlerle karşılamayı hedeflemektedir. Dünya çapında lider firmalar ile işbirliği yaparak, kaliteli ve sertifikalı ürünler tedarik edip müşterilerinin kullanımına sunmaktadır. Müşteri memnuniyetini odak noktasına alarak, kaliteli hizmet anlayışını her geçen gün bir adım daha ileriye taşımak, Aksay Yangının temel hedeflerinden biridir</p>
            <p style={{textAlign:'center',justifyContent:'center'}}>Aksay Yangın tek kapıdan tüm yangından korunum sistemlerinin çözümü ilkesiyle hareket eden, satış öncesi ve sonrası sunduğu güvenilir hizmetlerle sektörde fark yaratmaktadır. Satış öncesi danışmanlık, tasarım ve projelendirme hizmetlerinin yanı sıra, satış sonrasında da teknik servis, devreye alma ve periyodik bakım hizmetleriyle müşterilerinin yanında yer alır.</p>
            <p style={{textAlign:'center',justifyContent:'center'}}>Sertifikalı mühendis ve teknisyen kadrosuyla profesyonel hizmet veren Aksay Yangın iç ve dış pazarda müşteri potansiyelini artırarak hizmet yelpazesini genişletme yolunda emin adımlarla ilerlemektedir.</p>
         
        
      </Container>
      </>
    )
}
export default Anasayfa