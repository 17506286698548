import '../../../css/footer.css'
import banner from '../../../img/main/03- KÖPÜK KONSANTRELERİ-YENİ/KK-01  KÖPÜK KONSANTRELERİ/Başlık.png'
import divStyle from '../../../css/bannerCss';
import React, { useState } from 'react';
export default function MainKopukConst() {
    const [devam, setDevam] = useState(false);
    const containerStyle = {
       
        maxWidth: '800px',
       
        fontFamily: 'Arial, sans-serif',
    };

    const headingStyle = {
        fontSize: '24px',
        marginTop: '0',
        fontWeight: '800',
    };
    const divStyle={
        height: '200px',
        width: '100%',
        backgroundImage:`url(${banner})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        padding: 0,
        fontSize: "3rem",
        color: " #ffffff",
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom:20,
        textShadow: '4px 4px 4px rgba(0, 0, 0, 0.5)'
    }
    const handleToggleContent = () => {
        setDevam(!devam);
      };
    return (<>
        <div style={containerStyle}>
            <div style={divStyle}>
            
            </div>
            <h2 style={headingStyle}>Köpük Konsantreleri </h2>
            <div style={{ minHeight: 1, backgroundColor: 'red', marginBottom: 10, marginTop: 10 }}></div>
            <div>
                <p>
                    Özellikle kimya sektöründe nerede ise her gün yeni bir bileşik beraberindeki riskleri ile hayatımızın bir parçası olmaya devam ediyor.
                    Bu durum beraberinde su ile zor söndürülebilen veya su ile söndürülemeyen yanıcı-parlayıcı sıvılar için özel çözümler üretmenin gerekliliğini ortaya koymaktadır.
                    Su ile söndürülmesi mümkün olmayan bu tip kolay alevlenme özelliğine sahip yanıcı-parlayıcı sıvılar, büyük yangın riski oluşturmaktadır.
                    Bu tip sıvıların bulundurulduğu, üretimde kullanıldığı veya depolandığı noktalarda uygun tasarlanmış köpüklü söndürme sistemleri ile mevcut riskleri ortadan kaldırabilir veya etkilerini en aza indirebiliriz.
                </p>
                <p>
                    Özellikle yanıcı sıvı yangınlarının söz konusu olduğu alanlarda, suyun daha etkili müdahale aracı olarak kullanılmasını sağlamak için köpük konsantreleri özel düzeneklerle söndürme sistemlerine ilave edilmektedir.
                    Günümüzde Klasik Köpük Konsantrelerinin yanı sıra yeni geliştirilen ve Yeni Nesil Çevre Dostu olan <strong>“Re-Healing”</strong> veya <strong>“Florine Free”</strong> köpük konsantreleri üretim ve depolama tesislerinde yatırımcılar tarafından da öncelikli olarak tercih edilmekte ve yoğun olarak kullanılmaktadır.
                    Çevre bilincinin artması ile “Yeni Nesil Çevreci Köpük Konsantreleri” kullanımı hızla yayılmaktadır.
                    Özellikle su havzaları üzerinde kurulu kimya tesislerinde “Yeni Nesil Çevreci Köpük Konsantreleri” kullanımı artmaktadır.
                </p>
                <p style={{fontWeight: '600'} }>Günümüzde Klasik Köpük Konsantreleri ve Yeni Nesil Çevre Dostu Köpük Konsantreleri</p>
                        <p>
                            Özellikle kimya sektöründe nerede ise her gün yeni bir bileşik beraberindeki riskleri ile hayatımızın bir parçası olmaya devam ediyor.
                            Bu durum beraberinde su ile zor söndürülebilen veya su ile söndürülemeyen yanıcı-parlayıcı sıvılar için özel çözümler üretmenin gerekliliğini ortaya koymaktadır.
                            Su ile söndürülmesi mümkün olmayan bu tip kolay alevlenme özelliğine sahip yanıcı-parlayıcı sıvılar, büyük yangın riski oluşturmaktadır.
                            Bu tip sıvıların bulundurulduğu, üretimde kullanıldığı veya depolandığı noktalarda uygun tasarlanmış köpüklü söndürme sistemleri ile mevcut riskleri ortadan kaldırabilir veya etkilerini en aza indirebiliriz.
                        </p>
                        <p>
                            Özellikle yanıcı sıvı yangınlarının söz konusu olduğu alanlarda, suyun daha etkili müdahale aracı olarak kullanılmasını sağlamak için köpük konsantreleri özel düzeneklerle söndürme sistemlerine ilave edilmektedir.
                            Günümüzde Klasik Köpük Konsantrelerinin yanı sıra yeni geliştirilen ve Yeni Nesil Çevre Dostu olan<strong>“Re-Healing”</strong> veya <strong>“Florine Free”</strong> köpük konsantreleri üretim ve depolama tesislerinde yatırımcılar tarafından da öncelikli olarak tercih edilmekte ve yoğun olarak kullanılmaktadır.
                            Çevre bilincinin artması ile “Yeni Nesil Çevreci Köpük Konsantreleri” kullanımı hızla yayılmaktadır. Özellikle su havzaları üzerinde kurulu kimya tesislerinde “Yeni Nesil Çevreci Köpük Konsantreleri” kullanımı artmaktadır.
                        </p>
                {devam ? (
                    <div>
                  

                        <p style={{fontWeight: '600'} }>Köpük Konsantre Tipleri</p>
                        <ul>
                            <li>
                                <p style={{fontWeight: '600'}} >Sulu ile Film Oluşturan Köpük Konsantresi (AFFF)</p>
                                <p>İnce bir köpük çözeltisi filminin bazı sıvı hidrokarbon yakıtların yüzeyinde yüzebilmesi ve yayılabilmesi için suyun fiziksel özelliklerini kontrol eden deterjanlar ve florokarbon yüzey aktif maddeler içeren sentetik bir köpük konsantresi.</p>
                            </li>
                            <li>
                                <p style={{fontWeight: '600'} }>Alkole Dayanıklı Sulu ile Film Oluşturan Köpük Konsantresi (AFFF-AR)</p>
                                <p>Alkoller ve polar solventler vb. gibi suyla karışabilen risklerde kullanılmak üzere özel olarak formüle edilmiş bir köpük konsantresi. Bu tür köpükler standart hidrokarbon yakıtlarda da etkili bir şekilde kullanılmaktadır.</p>
                            </li>
                            <li>
                                <p style={{fontWeight: '600'} }>Protein Köpük Konsantresi (P)</p>
                                <p>Doğal bitkisel veya hayvansal kaynaklardan elde edilen organik konsantreler içeren madde olarak tanımlanabilir. Temel olarak hidrolize protein malzemelerinden elde edilen sıvılardır. Hidrolize protein ürünleri, köpüklere olağanüstü kararlı ve ısıya dayanıklı özellikler sağlar.</p>
                            </li>
                            <li>
                                <p style={{fontWeight: '600'}} >Floroprotein Köpük Konsantresi (FP)</p>
                                <p>Florokarbon yüzey aktif maddeler eklenmiş hidrolize protein bazlı bir köpük konsantresidir. Yüksek ısılara mukavim ve dik yüzeylerde kararlı tutma özelliklerine sahip konsantredir.</p>
                            </li>
                            <li>
                                <p style={{fontWeight: '600'} }>Film Oluşturan Floroprotein Köpük (FFFP)</p>
                                <p>Film oluşturma özelliklerine sahip, protein bazlı bir köpük konsantresi. Protein veya Floroprotein konsantralarin film yapabilen ve daha etkili kullanılabilen halidir.</p>
                            </li>
                            <li>
                                <p style={{fontWeight: '600'} }>Alkole Dayanıklı Film Oluşturan Floroprotein Köpük (FFFP-AR)</p>
                                <p>Film oluşturma özelliklerine sahip, protein bazlı bir köpük konsantresi olup ek olarak alkol ve solvent yangınlarına müdahale özelliği kazandırılmış Floroprotein esaslı köpük konsantresidir.</p>
                            </li>
                            <li>
                                <p style={{fontWeight: '600'}} >Sentetik Köpük Konsantresi (S)</p>
                                <p>Deterjan bazlı bir köpük yüksek genleşmeye kadar kullanılabilir ancak sınırlı geri yanma ve ısı direnci sunar. A sınıfı ve Hi-Ex Köpük konsantreleri bu kapsamda üretilirler.</p>
                            </li>
                        </ul>

                        <p style={{fontWeight: '600'} }>SOLBERG Klasik Köpük Konsantreleri</p>
                        <ul>
                            <li>
                                <p style={{fontWeight: '600'}} >SOLBERG A sınıfı köpük Konsantreleri</p>
                                <p>Sıklıkla düşük, orta ve yüksek genleşebilen konsantrelerden oluşur ve % 0.1 – 1 oranında su ile buluşturularak kullanılmaktadır.</p>
                            </li>
                            <li>
                                <p style={{fontWeight: '600'} }>SOLBERG AFFF Köpük Konsantreleri</p>
                                <p>Düşük ve orta genleşebilen konsantrelerden oluşur ve %1, %3 ve %6 kullanım oranlı olarak su ile buluşturularak kullanılır. A sınıfı katı malzeme yangınları ve yanıcı-parlayıcı hidrokarbon yangınlarında kullanılır.</p>
                            </li>
                            <li>
                                <p style={{fontWeight: '600'} }>SOLBERG AFFF-AR Köpük Konsantreleri</p>
                                <p>Düşük ve orta genleşebilen konsantrelerden oluşur %1x3 , %3x3 ve %3x6 kullanım oranlı olarak su ile buluşturularak kullanılır. A sınıfı katı malzeme yangınları ve yanıcı-parlayıcı alkol, solvent ve hidrokarbon yangınlarında kullanılır. Bazı üreticilerde takribi 1/600 oranında yüksek genleşebilen FFFF-AR köpük konsantreleri mevcuttur.</p>
                            </li>
                            <li>
                                <p style={{fontWeight: '600'}} >SOLBERG P (Protein) Köpük Konsantreleri</p>
                                <p>Yüksek ısıya mukavemetin gerektiği Hidrokarbon yangınlarında yoğun bir şekilde kullanılabilir. %3 ve %6 kullanım oranlı ve düşük genleşebilir özellikte üretilmektedir.</p>
                            </li>
                            <li>
                                <p style={{fontWeight: '600'} }>SOLBERG FP (Floroprotein) Köpük Konsantreleri</p>
                                <p>Özellikle yüksek tehlike içeren hidrokarbon yangınlarında film yapıcı özelliği, yüksek ısıya mukavemeti ve dik yüzeylerde tutunma özelliği nedeni ile tercih edilir. %3 ve %6 kullanım oranlı üretilmektedir.</p>
                            </li>
                            <li>
                                <p style={{fontWeight: '600'} }>SOLBERG FFFP Köpük Konsantresi</p>
                                <p>Mükemmel film yapma özelliği, dikey yüzeylerde tutunma ve yüksek ısıya mukavemeti nedeni ile özellikle hidrokarbon yangınlarının söz konusu olduğu alanlarda kullanılır. %3 ve %6 kullanım oranlı ve düşük genleşebilir özellikte üretilmektedir.</p>
                            </li>
                            <li>
                                <p style={{fontWeight: '600'} }>SOLBERG FFFP – AR Köpük Konsantresi</p>
                                <p>Mükemmel film yapma özelliği, dikey yüzeylerde tutunma ve yüksek ısıya mukavemeti nedeni ile özellikle alkol, solvent ve hidrokarbon yangınlarının söz konusu olduğu alanlarda kullanılır. FFFP – AR 3x3, FFFP-AR 3x6 ve FFFP-AR 6x6 kullanım oranlı üretilmektedir.</p>
                            </li>
                            <li>
                                <p style={{fontWeight: '600'}} >SOLBERG Sentetik Hi-Ex Köpük Konsantreleri</p>
                                <p>Hızlı kapatma ve ortam ile teması kesme yetenekleri nedeni ile hidrokarbon, alkol veya solvent yangınlarına müdahale amaçlı kullanılmaktadır. %1, %2, %3 veya ara oranlama değerleri için üretilmektedir.</p>
                            </li>
                        </ul>

                        <p>
                            Kullanımınıza sunduğumuz köpük konsantrelerimiz UL Listeli ve/veya FM onaylı ve/veya EN 1568 sertifikalarına sahiptir. Tedarik ederek kullanacağınız köpük konsantreniz ile köpük oranlama sisteminiz Örneğin; Köpük Sisteminin ana elemanları olan “Diyaframlı(Bladder) Tank”, “Bladder Tank Tipi Oranlayıcı”, “Hidrolik Konsantrasyon Vanası” ve “Köpük Konsantresi” aynı üreticiye ait ve birlikte test edilerek sertifikalandırılmış olması gerekir. Aksi taktirde sistemin sertifikalı olmasından bahsedemeyeceğimiz gibi ihtiyaç anında sistemin kendisine atanan söndürme performansından emin olamayız.
                        </p>

                    </div>
                ) : (
                    null
                )}
                 <p onClick={handleToggleContent} style={{ color: '#ff0000', cursor: 'pointer' }}>
          {devam ? 'Gizle' : 'Devamını Görüntüle'}
        </p>
            </div>

        </div>






    </>)
}