import '../../../css/footer.css'
import banner from '../../../img/main/017-FIRE DETECT SİSTEM/Başlık.png';

export default function FireDetect() {
    const containerStyle = {
        
      
        maxWidth: '800px',
      
        
        fontFamily: 'Arial, sans-serif',
      };
    
      const headingStyle = {
        fontSize: '24px',
        marginTop: '0',
        fontWeight: '800',
      };
      const divStyle={
        height: '200px',
        width: '100%',
        backgroundImage:`url(${banner})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        padding: 0,
        fontSize: "3rem",
        color: " #ffffff",
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom:20,
        textShadow: '4px 4px 4px rgba(0, 0, 0, 0.5)'
    }
  
    return (<>
  <div style={containerStyle}>
     <div style={divStyle}>
      
     </div>
      <h2 style={headingStyle}>Fire Detec™ Söndürme Sistemleri </h2>
      <div style={{minHeight:1, backgroundColor:'red',marginBottom:10,marginTop:10}}></div>
      <p style={{fontWeight:600}} >Endüstriyel Sahalarda İndirek ve Direk Söndürme Sistemleri</p>

<p>
  Nispeten daha küçük sistemlerin gerektiği ünitelerin korunması için kullanılmaktadır.
  Söndürücü olarak kullanılan gaz değişkenlik gösterebilir. Sıklıkla HFC – 227ea (FM-200),
  FK 5-1-12 (Novec 1230) ve CO2 vb. gazlar kullanılır. Kuru Tozlu seçenekleri de mevcuttur.
  Direk ve İn-direk sistemler olmak üzere ikiye ayrılır. Yukarıda belirtilen söndürücü
  maddeler için farklı kapasitelere sahip tipleri üretilmektedir.
</p>

<p>
  Hızlı ve kolay kurulum: Esnek sensör tüb doğrudan duvarlara veya korunan hacmin içine kolayca monte edilir.
  Tüb kuru nitrojen ile 16 bara kadar basınçlandırılır. Basınçlandırmanın dinamikleri boruyu ısıya karşı daha
  hassas hale getirir.
</p>

<p>
  Yangının erken tespiti: Bir alevlenme meydana gelirse, yangının ısısı, basınçlı sensör tüpünün en sıcak noktada
  (yaklaşık 110°C) patlamasına neden olur.
</p>

<p>
  Anında bastırma: Ani boru basıncının düşmesi sistem aktif hale geldi anlamına gelir ve söndürücü madde
  direk yanma noktasına boşaltılır. Yangın, başladıktan sonra hızla bastırılarak hasar ve aksama süresi en aza
  indirilir.
</p>

<p style={{fontWeight:600}} >Sistemin Avantajları</p>

<ul>
  <li>Kaynağa yakın kurulum</li>
  <li>Kolay / Esnek kurulum</li>
  <li>Yangının erken tespiti</li>
  <li>Hızlı ve etkili bastırma</li>
  <li>Son derece güvenilir</li>
  <li>Son derece ekonomik</li>
  <li>Tak çalıştır basitliği</li>
  <li>Tam otomatik</li>
  <li>7/24 Koruma</li>
  <li>Kanıtlanmış Fire Detec™ teknolojisi</li>
</ul>

<p style={{fontWeight:600}} >Direk Sistem</p>
<p>
  Söndürme maddesi, sensör tübün ısı etkisi ile oluşan patlama deliğinden boşaltılır.
</p>
<p style={{fontWeight:600} }>İndirek Sistem</p>
<p>
  Sensör tüb dedektör gibi kullanılır ve sistemi kapatan valfin dengesini bozarak açılmasını sağlar ancak
  söndürme maddesi ayrı bir boru sistemi ile irtibatlandırılmış dağıtım nozulundan boşaltılır.
</p>

<p style={{fontWeight:600}} >İndirek Sistem Nerede Kullanılır?</p>
<ul>
  <li>Çok küçük kapalı alan ve ulaşılması zor alanlar  1m³ Avantajları</li>
  <li>Daha ekonomik</li>
  <li>Kurulumu en kolay</li>
  <li>Birden fazla ayrılmış ünite için ideal</li>
  <li>Manual Çalıştırılamaz</li>
</ul>


<p style={{fontWeight:600} }>İndirek Sistemin Avantajları:</p>
<ul>
  <li>En etkili yangın söndürme</li>
  <li>Geniş bir alanı kapsıyor</li>
  <li>Söndürme maddesinin daha hızlı boşaltılmasını sağlar</li>
  <li>Tüm baskılama ajanlarıyla çalışır</li>
  <li>Manuel çalıştırma mümkün</li>
</ul>

<p style={{fontWeight:600}} >Kullanım Alanları</p>
<ul>
  <li>Enerji Dağıtım Panoları</li>
  <li>Ağır Makineler ve Özel Araçlar</li>
  <li>Rüzgar Türbinleri</li>
  <li>CNC Makineleri</li>
  <li>Toplu Taşıma Araçları</li>
  <li>Baz İstasyonları</li>
</ul>
    </div>






    </>)
}