import '../../../css/footer.css'
import divStyle from '../../../css/bannerCss';
import banner from '../../../img/main/03- KÖPÜK KONSANTRELERİ-YENİ/KK-06  CAFS/Başlık.png'
export default function Caf() {
    const containerStyle = {
  
        maxWidth: '800px',
       
        fontFamily: 'Arial, sans-serif',
      };
    
      const headingStyle = {
        fontSize: '24px',
        marginTop: '0',
        fontWeight: '800',
      };
      const divStyle={
        height: '200px',
        width: '100%',
        backgroundImage:`url(${banner})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        padding: 0,
        fontSize: "3rem",
        color: " #ffffff",
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom:20,
        textShadow: '4px 4px 4px rgba(0, 0, 0, 0.5)'
    }
    return (<>
  <div style={containerStyle}>
     <div style={divStyle}>
      
     </div>
      <h2 style={headingStyle}>CAFS (Compressed Air Systems) Köpük Konsantreleri</h2>
      <div style={{minHeight:1, backgroundColor:'red',marginBottom:10,marginTop:10}}></div>
      <p>Basınçlı Havalı Köpük Sistemleri (CAFS) ile birlikte kullanılmak üzere özel olarak tasarlanmış, A sınıfı yangınlarla mücadeleye yönelik bir köpük konsantresidir. Standart olarak % 0,1 - 1 oranında su ile karıştırılarak kullanılacak özellikte üretilmiştir. Suya düşük konsantrasyonlarda katıldığında yüzey ve yüzeyler arası gerilimleri azaltır, nüfuz etme ve yayılma kabiliyetini arttırır. CAFS veya A sınıfı köpükler su tüketimini azaltır, daha hızlı söndürme sağlar ve yeniden tutuşmayı zorlaştırır. Bazı üreticilere ait A sınıfı konsantreyi su ile % 0.3 oranında kullanılarak CAFS ekipmanıyla köpük üreterek, mükemmel yapışma, yavaş drenaj, uzun ömür, mükemmel ıslatma yeteneği vb. gibi bazı önemli özellikleri bir araya getirir. Bu hali ile etkili ve güçlü söndürme sağlanır. Köpük konsantresi kokusuzdur ve biyolojik olarak kolayca parçalanabilir.</p>

<p>CAFS ana kullanım alanı A Sınıfı yangınlar olmasına rağmen, dikkatli ve bilerek uygulanması durumunda küçük hidrokarbon yangınlarını (B Sınıfı) %0,5 (üreticiye göre değişiklik gösterir) oranında kontrol etmek için kullanılabilir. A sınıfı köpük konsantreleri tatlı veya deniz suyu ile kullanılabilir.</p>

    </div>






    </>)
}