
import banner from '../../../img/main/016- SU SİSİ SİSTEMLERİ/Başlık.jpg'
export default function Watermist() {
    const containerStyle = {
       
      
        maxWidth: '800px',
   
        
        fontFamily: 'Arial, sans-serif',
      };
    
      const headingStyle = {
        fontSize: '24px',
        marginTop: '0',
        fontWeight: '800',
      };
      const divStyle={
        height: '200px',
        width: '100%',
        backgroundImage:`url(${banner})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        padding: 0,
        fontSize: "3rem",
        color: " #ffffff",
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom:20,
        textShadow: '4px 4px 4px rgba(0, 0, 0, 0.5)'
    }
    return (<>
  <div style={containerStyle}>
     <div style={divStyle}>
      
     </div>
      <h2 style={headingStyle}>Water Mist (Su Sisi)</h2>
      <div style={{minHeight:1, backgroundColor:'red',marginBottom:10,marginTop:10}}></div>
      <p>
      Su Sisli (Water Mist)  yangın söndürme sistemleri, tüketilen ve dağıtılan su hacmini en aza indirirken optimum yangın söndürme gerektiren uygulamalar için sağlam, etkili, kaliteli mühendislik çözümleri sunar. Yüksek basınçlı pompalar, suyu paslanmaz çelik su sisi nozullarından geçirerek suyu milyonlarca küçük damlacık (tipik olarak 30 – 200 µm çapında) şeklinde boşaltır. 

      </p>
      <p>
      Etkinleştirilmiş bir sistem, yangından ısı emilimini en üst düzeye çıkaran ve ardından su buhara dönüşürken buharlaşma ve genleşme sonucu oksijenin yerini alan bir su sisi damlacıkları örtüsü oluşturacaktır. 

      </p>
      <p>
      Su Sisi (Water Mist) sistemleri, sisin hızlı ısı emilimini mümkün kılan çok daha büyük bir su yüzey alanı sunma yeteneği ve sisin havada çok daha uzun bir süre asılı kalma yeteneğinin bir araya gelmesi ve böylece daha fazla yangınla ve yanan malzeme ile temasta kalma süresi yaratması ve ısı emiliminin maksimum seviyede etkili olması nedeniyle yağmurlama sistemlerinden ayrılır. Bu, su sisi (water mist) sistemlerinin yağmurlama sistemlerine göre çok daha az su kullanarak yanan yüzeylerin hızla soğutulmasına ve bastırılmasına olanak tanır. Water Mist yangın koruma sistemleri her türlü kapalı alanda veya lokal uygulama için mutlak koruma sağlar. Bunun nedeni, bulutsu hale getirilmiş damlacıkların ortamdaki  mevcut oksijenle yer değiştirmesi yangının hızlı kontrol edilmesinin temelini oluşturur. Su sisi buhara dönüştüğünde hacim olarak genişler, yanmanın devamını sağlamak için gerekli oksijen sis ile yer değiştirir ve oksijenin ortamdan uzaklaşmasını sağlar. Toplam koruma etkisi nedeni ile yanan yüzeyin hızla ıslanmasını ve soğumasını sağlar.

      </p>
      <p>
      Konvansiyonel sulu söndürme sistemlerindeki hasar oranı, Water Mist Sistemlerinde suyun hacmi azaltıldığından ciddi oranda azalır. Suyun kullanılmasından maksimum verim alınır.

      </p>
      <p style={{fontWeight:600}}>Yüksek Basınçlı Water Mist Sistemlerinin Temel Karakteristikleri </p>
      <ul>
    <li>Çevreye ve Ozon Tabakasına Zarar Vermez</li>
    <li>Ortam Isısını Emerek ve Oksijen Seviyesini Düşürerek Söndürme Yapar</li>
    <li>Lokal Söndürme Amaçlı Kullanılabilir</li>
    <li>Toksik Gazların ve Dumanın Yıkanarak Uzaklaştırılmasını Sağlar</li>
    <li>Korunan Ekipman için Son Derece Güvenli</li>
    <li>Yaşam Mahallerinde Güvenli</li>
    <li>Konvansiyonel Sulu Sistemlere Nazaran, Su Kaynaklı Minimum Hasar</li>
    <li>Yeniden Parlamayı Önler</li>
    <li>Seçici Vanalarla  Çoklu  Boşaltma Olanağı Sağlar</li>
    <li>Küçük Mahallerde Silindir Bataryalı Tipleri Kullanılabilir</li>
    <li>Büyük Mahallerde Pompalı Modelleri Kullanılabilir</li>
    <li>Minimum Boru Çapı Gerektirir</li>
  </ul>
  <p style={{fontWeight:600}}>Kullanım alanları </p>
  <ul>
    <li>Yanıcı  ve Parlayıcı Sıvı Yangınları</li>
    <li>Endüstriyel Pişirme Sahaları</li>
    <li>Yaşam Mahalleri</li>
    <li>Mutfak Davlumbaz Sistemleri</li>
    <li>Türbin ve Jeneratör Odaları</li>
    <li>Yürüyen Merdiven ve Eskalatörler</li>
    <li>Kablo ve Tesisat Galerileri</li>
    <li>Bilgi İşlem Merkezleri</li>
    <li>Arşiv ve Tarihi Eserler</li>
    <li>Değerli Malzeme Depoları</li>
    <li>Metrolar ve Toplu Taşıma Araçları</li>
    <li>Deniz Toplu Taşıma Araçları</li>
    <li>Gemi Makine Daireleri ve Yaşam Mahalleri</li>
  </ul>
  <p>
  Su sisi sistemi, zorlu çalışma ortamından bağımsız olarak her zaman çalışması gereken çok sayıda bileşenden oluşur. AKSAY Yangın servis ve satış sonrası hizmetleri doğrudan ve özenle eğitilmiş yetkin personel ile sağlamaktadır. Sistemin mükemmel durumda kalmasını sağlamak için sisteminizin bakım işlemlerini kalifiye personelimiz nezaretinde gerçekleştiriyoruz.

  </p>
    </div>
  
  
  
  
  
  
    </>)
  }