import '../../../css/footer.css'
import Tablo from '../../../img/main/tablo.png'
import divStyle from '../../../css/bannerCss';
export default function Flowmetre() {
    const containerStyle = {
  
      
        maxWidth: '800px',
    
        
        fontFamily: 'Arial, sans-serif',
      };
    
      const headingStyle = {
        fontSize: '24px',
        marginTop: '0',
        fontWeight: '800',
      };
  
    
    return (<>
  <div style={containerStyle}>
     <div style={divStyle}>
     
     </div>
      <h2 style={headingStyle}>Debimetre (Flowmetre)</h2>
      <div style={{minHeight:1, backgroundColor:'red',marginBottom:10,marginTop:10}}></div>
      <p>
      Debimetre veya Debi Ölçer üzerine monte edildiği suyun debisini ölmek için kullanılan cihazdır. Yangından korunum sistemleri kapsamında yangın pompa odasında yangın pompalarının performans testlerinde kullanılan cihazdır.

      </p>
      <p>
      Yangın Pompa grupları yangın söndürme sistemlerinin en önemli parçasıdır. Her pompa setinin durumu her yıl performans testi ile kontrol edilmelidir. Performans testlerinde;  kapalı vana, %50 debi, %100 debi %125 ve %150 (değerler farklılaştırılabilir) debi değerinin okunması için debi ölçer kullanılır; Debi ölçer üzerinden okunan debi değerleri ve pompa basınç göstergelerinden okunan basınç değerleri saha test verisi olarak kaydedilir, Eğri haline getirilir ve pompa karakteristik eğrisi veya bir önceki saha test eğrisi ile karşılaştırılır. Eğri kıyaslamasında görülen pompa performansındaki düşüşler, pompa çarkı veya emiş hattındaki aşınmalar veya tıkanıklıklar gibi pompada meydana gelen problemleri görmemizi sağlar.  

      </p>
      <p>
      Debimetre tedariki ve yerleşimi NFPA 20 şartlarında belirtilen kritik zorunlulukları kapsayacak özelikte olmalıdır. Debimetreler yangın hatlarında olmazsa olmaz ürünlerdendir. Debimetreler yivli bağlantılı veya kaynaklı bağlantı yapılacak şekilde tedarik edilebilmektedir. 

      </p>
      <p style={{fontWeight:600}}>Debimetre Seçim Tablosu: </p>
      <p>Pompa kapasitesine bağlı olarak aşağıda verilen tablo kullanılır. Nominal debi yani pompa kapasitesi dikkate alınarak minimum ve maksimum ölçüm aralığı kontrol edilerek debimetre seçimi yapılmalıdır. Aşağıda verilen tablo GVI debimetre üreticisinin katalog verileri kullanılarak oluşturulmuştur. 
</p>
<img  style={{marginBottom:20 , width:800 }} src={Tablo} alt="" />
    </div>






    </>)
}