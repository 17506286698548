
import banner from '../../../img/main/04- GAZLI SÖNDÜRME SİSTEMLERİ-YENİ/G-02 FM - 200 SİSTEMLERİ/Başlık.png'
export default function Fm200() {
  const containerStyle = {
     
      maxWidth: '800px',
      
      fontFamily: 'Arial, sans-serif',
    };
  
    const headingStyle = {
      fontSize: '24px',
      marginTop: '0',
      fontWeight: '800',
    };
    const divStyle={
      height: '200px',
      width: '100%',
      backgroundImage:`url(${banner})`,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      padding: 0,
      fontSize: "3rem",
      color: " #ffffff",
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginBottom:20,
      textShadow: '4px 4px 4px rgba(0, 0, 0, 0.5)'
  }
  return (<>
<div style={containerStyle}>
   <div  style={divStyle}>
  
   </div>
    <h2 style={headingStyle}>HFC-227EA Söndürme Sistemi</h2>
    <div style={{minHeight:1, backgroundColor:'red',marginBottom:10,marginTop:10}}></div>
    <p>
    FM-200 Sistemleri yaygın biçimde kabul edilen <strong>"Temiz Gazlı - Clean Agent"</strong> sistemlerden biridir. Çevreye karşı duyarlı yangın söndürme sistemidir. FM – 200 Gazlı Söndürme Sistemi,  söndürme işlemini ısıyı absorbe  eder ve fiziksel söndürme yapar. FM-200 Sistemlerini dizayn edilirken;
    </p>
    <ul>
    <li>Dizayn Standardı</li>
    <li>Hacim Ölçüleri</li>
    <li>Tehlike Tipi</li>
    <li>Yangın Tipi</li>
    <li>Maksimum ve Minimum Sıcaklık</li>
    <li>Deniz Seviyesine Göre Konum</li>
    <li>Havalandırma Detayı</li>
    <li>Dizayn Konsantrasyonu</li>
    <li>Ön Hesaplama</li>
    <li>Kesin Hesaplama</li>
    <li>Net Sonuç</li>
</ul>
<p>yukarıdaki temel maddeler esas alınarak ilerlenir.</p>
<p  style={{fontWeight:600}}>FM-200 Sisteminin Temel Özellikleri</p>
<ul>
    <li>İletken Değildir</li>
    <li>Renksiz ve Kokusuzdur</li>
    <li>Korozif Değildir</li>
    <li>Termal Şok Yaratmaz</li>
    <li>Söndürme İşlemini Fiziksel Olarak Gerçekleştirir</li>
    <li>Boşaltım Sonrası Atık Bırakmaz, Temizlik Gerektirmez</li>
    <li>İnsan Bulunan Mekanlarda Güvenle Kullanılabilir</li>
    <li>Göreceli Ekonomiktir</li>
    <li>Az Yer Kaplar</li>
    <li>Ozona Zarar Vermez</li>
    <li>Göreceli Düşük Depolama Basıncı</li>
    <li>Boşaltım Zamanı 10 Saniyedir</li>
    <li>Basit ve Yerinde Kurulum</li>
</ul>
<p style={{fontWeight:600}}>Kullanım Alanları</p>
<ul>
    <li>Bilgi İşlem Odaları</li>
    <li>UPS Odaları</li>
    <li>Elektrik Dağıtım Merkezleri</li>
    <li>Değerli Malzeme Depoları</li>
    <li>Telekomünikasyon Merkezleri</li>
    <li>Türbin Kabinleri</li>
    <li>Arşivler ve Müzeler</li>
    <li>Yanıcı ve Parlayıcı Malzeme Depoları</li>
    <li>Off-Shore Uygulamalar</li>
    <li>Sivil, Askeri ve Marin Uygulamalar</li>
</ul>
<p style={{fontWeight:600}}>FM-200 Sistemleri Nerede Kullanılmaz</p>
<ul>
    <li>Nitroseluloz yangınları (Oksijen)</li>
    <li>Sodyum Klorat veya Sodyum nitrat (Oksitleyici)</li>
    <li>Oksijenperoksitler</li>
    <li>Sodyum, potasyum ve magnezyum v.b. reaktif metaller</li>
    <li>Uranyum ve plutonyum gibi radyoaktif metaller</li>
</ul>
<p>
FM-200 Sistemleri üreticilere göre çalışma basınçları anlamında farklılıklar göstermektedir. Klasik Sistemlerde 25bar ve 42bar seçenekleri ile UL, FM ve VdS onaylı sistem seçenekleri ile piyasa talepleri doğrultusunda üretilmektedir. Yeni Nesil S-Flow FM-200 sistemlerinde ise 32bar, 34bar, 50bar ve 55bar çalışma basıncına kadar UL ve FM sertifikalı olarak üretilmekte ve kullanıma sunulmaktadır.     S-Flow Sistemlerde 32bar, 34bar, 50bar, 55bar ve 60bar çalışma basıncına kadar VdS onaylı olarak üretilmekte ve kullanıma sunulmaktadır.   Normal şartlar altında göreceli olarak kurulumun yapılacağı alana yakın lokasyonda silindirleri konumlandırmak gerekli iken 25bar üzerine çıktığınızda silindirleri daha uzak noktaya koyabilmenize olanak tanır.   
 
</p>
<p>
FM-200 Sistemleri uygulanmadan önce ilgili koruma alanlarının mutlaka <strong>“Dor-Fan Test” </strong>, <strong>“Puf Test”</strong> ve <strong>“Boru Sızdırmazlık Testi (Leak Test)”</strong>  uygulanmalıdır.

</p>
<p>
Sistem tasarımı NFPA 2001 veya EN 15004 standardı gereği yapılmaktadır. Sahada sağlıklı bir mühendislik çalışması yapılarak korunacak mahalin tehlike sınıflaması yapılır. 

</p>
<p>
Örneğin Class A için %7.9, Class A Higher için %8.5  ve  Class B ve C için ise minimum %9  söndürme konsantrasyonlarını kullanılarak  tehlike sınıfına göre uygulama yoğunluğu yani Flooding Factor belirlenir (Veriler EN 15004 standardı gereği verilmiştir).  Belirlenen Flooding Factor kullanılarak gerekli söndürücü gaz miktarı hesaplanır.

</p>
<p>
Tek sistem üzerinden çoklu bölgelerin korunması selektör vanalar kullanılarak sağlanabilir. Bunun için asıl sistemin %100 yedekli seçilmesini tavsiye ederiz.

</p>
<p>
Söndürme Kontrol paneli ve aksesuarları ile birlikte kullanılır. Söndürmenin algılama kanadı korunacak mahalin gereklerine göre farklı dedektörler kullanılarak çözülebilir.

</p>
<p>
AKSAY Yangın SIEX işbirliği ile FM-200 Sistemlerinin Tasarımı, Mühendislik Hesapları, Tedarik Edilmesi, <br />Montaj-Test ve Devreye Alma ve Kontratlı Bakım hizmetlerini yetişmiş Mühendis ve Teknisyen kadrosu ile gerçekleştirmektedir.  
</p>
  </div>






  </>)
}