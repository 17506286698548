import { useState } from 'react';
import '../../../css/footer.css'
import banner  from '../../../img/main/01- SULU SÖNDÜRME SİSTEMLERİ-YENİ/003- KURU BORULU SPRİNKLER SİSTEMİ/Başlık.png'
export default function Kuru() {
  const [currentPage, setCurrentPage] = useState(1);

  const containerStyle = {
   
    
    maxWidth: '800px',
  
    
    fontFamily: 'Arial, sans-serif',
  };

  const headingStyle = {
    fontSize: '24px',
    marginTop: '0',
    fontWeight: '800',
  };
  const divStyle={
    height: '200px',
    width: '100%',
    backgroundImage:`url(${banner})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    padding: 0,
    fontSize: "3rem",
    color: " #ffffff",
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom:20,
    textShadow: '4px 4px 4px rgba(0, 0, 0, 0.5)'
}

  const pages = [
    // İlk sayfa
    (
      <>
         <div style={divStyle}>
      
      </div>
        <h2 style={headingStyle}>Kuru Borulu</h2>
        <div style={{ minHeight: 1, backgroundColor: 'red', marginBottom: 10, marginTop: 10 }}></div>
        <p>
          Kuru borulu sprinkler sistemleri, kuru alarm vanasının üst kısmının basınçlı hava veya inert gaz ile sürekli olarak basınç altında tutulduğu ve kuru alarm vanasının alt kısmının su ile basınç altında tutulduğu sistemlerdir. Kuru borulu sistemler sadece donma hasarı olasılığı bulunan mahallerde ve sıcaklığın 70 °C'nin üzerinde olduğu kurutma fırını vb. mahallerde uygulanmalıdır.
        </p>
        <p>
          Kuru borulu sprinkler sistemlerinde aşağıdaki sprinkler tiplerinin kullanılmasına izin verilir
        </p>
        <ul style={{ marginTop: 10, marginBottom: 20 }}>
          <li>Dik tip sprinkler</li>
          <li>Kuru tip sprinkler</li>
          <li>Yatay duvar tipi sprinkler (Boru içinde su kalmayacak şekilde yapılan tesisatlarda)</li>
          <li>Sarkık tip ve duvar tipi sprinkler (Sprinkler, boru kolu ve branşmanların, sıcaklığın 4ºC ve üzerinde olduğu mahallerde bulunması durumunda)</li>
        </ul>
        <p>
        Borulamadaki basıncı sağlamak için sürekli hava/inert gaz beslemesi teçhiz edilmelidir. Kuru Alarm vana üreticisi tarafından tavsiye edilen basınç değer aralığına göre basınçlandırılmalıdır. Kuru alarm vanasına hava beslemesi yapılan kaynak sürekli olmalıdır. Hava beslemesi, sistemin dolumunu 30 dakika içinde sağlayacak kapasitede olmalıdır. Tek kompresör ile birden fazla kuru borulu sistem besleniyorsa, 30 dakika dolum süresi için en büyük sistem baz alınmalıdır. -15ºC’nin altında soğutulmuş mahallerde, dolum süresi 60 dakika alınabilir. Klapeyi kapalı tutacak gücü sağlayan hava basıncı, sistem su basıncına göre üretici kataloglarında belirtilir. Yaklaşık olarak hava basıncının su basıncına oranı 1/3 alınabilir. Kesin değerler için üretici kataloglarına bakılmalıdır.
        </p>
        <p  style={{ color: '#ff0000', cursor: 'pointer' }} onClick={() => setCurrentPage(2)}>İkinci Sayfa</p>
      </>
    ),

    // İkinci sayfa
    (
      <>
        <div>
        <img src="https://picsum.photos/800/200" alt="" style={{ marginBottom: 10 }} />
        </div>
        <h2 style={headingStyle}>Kuru Borulu</h2>
        <p>
        Tek bir kuru alarm vanası ile kontrol edilen sistem büyüklüğü; aşağıdaki maddelerden herhangi birine uygun olarak belirlenmelidir. İstisna olarak, (3) ve (4) maddeleri konut birimlerinde uygulanamaz.
        </p>
        <ul style={{ marginTop: 10, marginBottom: 20 }}>
          <li>En uzak noktada bulunan test vanası tamamen açıldığında, suyun boşalması için geçen süre en fazla 60 saniye olmalıdır.</li>
          <li>Kuru alarm vanasının üstündeki net boru iç hacmi aşağıdaki tabloda verilen değerlerin üzerinde olmamalıdır.</li>
        </ul>
        <table style={{marginTop:20,marginBottom:20}} width="400" border="1" align="center" cellpadding="0" cellspacing="0">
                            <tbody><tr>
                              <td className='bas' width="28%" height="35" rowspan="2" align="center"><strong>Tehlike Sınıfı </strong></td>
                              <td className='bas' height="35" colspan="2" align="center"><strong>Maksimum Boru Hacmi (m³) </strong></td>
                            </tr>
                            <tr>
                              <td width="33%" height="35" align="center"><strong>Hızlandırıcılı </strong></td>
                              <td width="39%" height="35" align="center"><strong>Hızlandırıcısız </strong></td>
                            </tr>
                            <tr>
                              <td height="35" align="left"><strong>Düşük Tehlike </strong></td>
                              <td height="35" align="center">4</td>
                              <td height="35" align="center">1,5</td>
                            </tr>
                            <tr>
                              <td height="35" align="left"><strong>Orta Tehlike </strong></td>
                              <td height="35" align="center">4</td>
                              <td height="35" align="center">1,5</td>
                            </tr>
                            <tr>
                              <td height="35" align="left"><strong>Yüksek Tehlike </strong></td>
                              <td height="35" align="center">3</td>
                              <td height="35" align="center">-</td>
                            </tr>
                          </tbody></table>
        <ul>
        <li>
        Net boru iç hacminin 1,5 m³’ten az olduğu sistemlerde hızlandırıcı kullanılması gerekli değildir ve suyun boşalması için geçen süre limitine uyulması gerekmez.
        </li>  
        <li>
        Net boru iç hacminin 4 m³’ten az olduğu sistemlerde hızlandırıcı kullanılması durumunda, suyun boşalması için geçen süre limitine uyulması gerekmez.  
        </li>
        <li>
        Sistem büyüklüğü; suyun boşalması için geçen süreye bağlı olarak hesap programı ile yapılmalıdır. Hesap programı ve metodu ulusal olarak tanınmış bir laboratuar tarafından onaylanmış olmalıdır. Hesaplama, aşağıdaki tabloda verilen değerlere uygun olmalıdır.  
        </li>
        </ul> 
        <table style={{marginTop:20,marginBottom:20}} width="90%" border="1" align="center" cellpadding="0" cellspacing="0">
                 <tbody><tr>
                            <td width="33%" height="20" align="center"><strong>Tehlike Sınıfı </strong></td>
                            <td width="39%" height="20" align="center"><strong>En Uzak Noktada Açılacak Sprinkler Sayısı </strong></td>
                            <td width="28%" height="20" align="center"><strong>Maksimum Su Boşalma </strong><strong><br/>
                            </strong><strong>Süresi (sn) </strong></td>
                      </tr>
                          <tr>
                            <td height="20" align="left"><strong>Konut Birimi </strong></td>
                            <td height="20" align="center">1</td>
                            <td height="20" align="center">15</td>
                          </tr>
                          <tr>
                            <td height="20" align="left"><strong>Düşük Tehlike Sınıfı </strong></td>
                            <td height="20" align="center">1</td>
                            <td height="20" align="center">60</td>
                          </tr>
                          <tr>
                            <td height="20" align="left"><strong>Orta Tehlike Sınıfı </strong></td>
                            <td height="20" align="center">2</td>
                            <td height="20" align="center">50</td>
                          </tr>
                          <tr>
                            <td height="20" align="left"><strong>Yüksek Tehlike Sınıfı </strong></td>
                            <td height="20" align="center">4</td>
                            <td height="20" align="center">45</td>
                          </tr>
                          <tr>
                            <td height="20" align="left"><strong>Yüksek Depolama </strong></td>
                            <td height="20" align="center">4</td>
                            <td height="20" align="center">40</td>
                          </tr>
                </tbody> 
                </table>        
                <ul>
                <li>
                Sistem büyüklüğü; akış testleri yapıldığında, yukarıdaki tabloda suyun boşalması için geçen süre ile ilgili olarak verilen değerleri sağlayacak ölçüde olmalıdır. Akış testleri, açılacak sprinkler sayısı simüle edilerek yapılmalıdır. Tek sprinkler açılması istenen mahaller için, en uzak noktadaki test vanası açılır. Daha fazla sayıda sprinkler açılma durumunu simüle etmek için, test manifoldu düzenlemesi gereklidir. 4 sprinkler açılması durumunu test etmek için; her iki branşman kolundan iki adet sprinkler akışına uygun manifold düzenlemesi yapılır. Yapılan testlerle gerekli su boşalma süreleri karşılanıyorsa, (1) ve (5) maddesindeki şartlar aranmaz. 
                </li>
                </ul>   
                <p>
                Kuru borulu sistemlerde, grid borulama tesisatı kullanılmamalıdır.

Yüksek tehlike sınıfı uygulamalarında kuru borulu sistemlerin kullanılmaması tavsiye edilir. İlk açılan sprinklere suyun ulaşmasında olacak gecikme, sistemin verimini ciddi şekilde azaltabilmektedir. 
                </p>    
        <p onClick={() => setCurrentPage(1)} style={{ color: '#ff0000', cursor: 'pointer' }}>İlk Sayfa</p>
      </>
    )
  ];

  return (
    <div style={containerStyle}>
      {pages[currentPage - 1]}
    </div>
  );
}








