import { Container } from "react-bootstrap";

export default function Sirket() {

    
    const divStyle = {
        height: '100px',
        width: '100%',
        background: ' rgb(0,55,105)',
        background: '   linear-gradient(90deg, rgba(0,55,105,1) 0%, rgba(0,130,226,1) 100%)',
        paddingTop:10,
        fontSize: "3rem",
        color:" #ffffff",
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textShadow: '4px 4px 4px rgba(0, 0, 0, 0.5)'
        
      };
return(<>
<div style={divStyle}>
              <p>Şirket Profili</p>
        </div>
<Container style={{minHeight:600 ,paddingLeft:20,paddingTop:20}}>
    <p >
    Yangın, günlük hayatımızda gerek verdiği maddi zararları açısından, gerekse toplum ve insan üzerinde bıraktığı etkileri açısından, üzerinde ısrarla durulması ve mutlaka etkilerinin en aza indirilmesi gereken bir sorundur.
    </p>
    <p>
    AKSAY Yangın ve Güvenlik Sistemleri San. Ve Tic. Ltd. Şti. 1997 yılında bu düşünce ile kuruldu. Kuruluş amacımız yangın sektöründe özellikle endüstriyel tesislerin korunması konusunda çalışmaktır.
    </p>
    <p>
    Temel amacımız, endüstriyel yangın tehlikelerine doğru ve akılcı çözümler üretmektir. Endüstriyel yangın tehlikelerine uygun çözümler üretirken, ulusal ve uluslararası kuralları uygulayarak bu kuralların gerektirdiği kalitede ve onaylara sahip ürünleri kullanarak çözüm üretmektedir.
    </p>
    <p>
    Temel hedefimiz sorun değil çözüm üretmektir. Bu yönü ile AKSAY Yangın, yetişmiş kadrosu, yurt dışı çözüm ortakları ve özel çözüm yöntemleri ile sektördeki yerini almıştır. Deneyimli kadrosu ile kendi içinde iş bölümü yapmış olup, malzeme satışı, sistem dizaynı ve projelendirme, teknik servis hizmetlerini farklı departmanları ile vermektedir.
    </p>
</Container>

</>)
    }