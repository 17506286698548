
import banner from '../../../img/main/04- GAZLI SÖNDÜRME SİSTEMLERİ-YENİ/G-03  NOVEC 1230  SİSTEMLERİ/Başlık.png'
export default function Novec() {
  const containerStyle = {
   
      maxWidth: '800px',
     
      fontFamily: 'Arial, sans-serif',
    };
  
    const headingStyle = {
      fontSize: '24px',
      marginTop: '0',
      fontWeight: '800',
    };
    const divStyle={
      height: '200px',
      width: '100%',
      backgroundImage:`url(${banner})`,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      padding: 0,
      fontSize: "3rem",
      color: " #ffffff",
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginBottom:20,
      textShadow: '4px 4px 4px rgba(0, 0, 0, 0.5)'
  }
  return (<>
<div style={containerStyle}>
   <div style={divStyle}>
    
   </div>
    <h2 style={headingStyle}>FK-5-1-12 Söndürme Sistemi</h2>
    <div style={{minHeight:1, backgroundColor:'red',marginBottom:10,marginTop:10}}></div>
    <p>
    Novec 1230  Sistemleri yaygın biçimde kabul edilen <strong>"Temiz Gazlı - Clean Agent"</strong> sistemlerden biridir. Çevreye karşı duyarlı yangın söndürme sistemidir. Ozona zarar vermez. Küresel ısınma etkisi 1 olup, insan bulunan ortamlarda güvenle kullanılır. Novec 1230 Gazlı Söndürme Sistemi,  söndürme işlemini ısıyı absorbe  ederek fiziksel söndürme yapar. 

    </p>
    <p style={{fontWeight:600}}>Novec 1230 Sistemleri Dizayn Edilirken; </p>
    <ul>
    <li>Dizayn Standardı</li>
    <li>Hacim Ölçüleri</li>
    <li>Tehlike Tipi</li>
    <li>Yangın Tipi</li>
    <li>Maksimum ve Minimum Sıcaklık</li>
    <li>Deniz Seviyesine göre konum</li>
    <li>Havalandırma Detayı</li>
    <li>Dizayn Konsantrasyonu</li>
    <li>Ön Hesaplama</li>
    <li>Kesin Hesaplama</li>
    <li>Net Sonuç</li>
</ul>
<p>yukarıdaki temel maddeler esas alınarak ilerlenir.</p>
<p style={{fontWeight:600}}>Novec 1230 Sisteminin Temel Özellikleri</p>
<ul>
    <li>İletken Değildir</li>
    <li>Renksiz ve Kokusuzdur</li>
    <li>Korozif Değildir</li>
    <li>Termal Şok Yaratmaz</li>
    <li>Söndürme İşlemini Fiziksel Olarak Gerçekleştirir</li>
    <li>Boşaltım Sonrası Atık Bırakmaz, Temizlik Gerektirmez</li>
    <li>İnsan Bulunan Mekanlarda Güvenle Kullanılabilir</li>
    <li>Basit Dolum</li>
    <li>Az Yer Kaplar</li>
    <li>Ozona Zarar Vermez</li>
    <li>Küresel Isınma Katsayısı 1</li>
    <li>Göreceli Düşük Depolama Basıncı</li>
    <li>Boşaltım Zamanı 10 saniyedir</li>
    <li>Basit ve Yerinde Kurulum</li>
</ul>
<p style={{fontWeight:600}}>Novec 1230  Kullanım Alanları</p>
<ul>
    <li>Bilgi İşlem ve UPS Odaları</li>
    <li>Elektrik Dağıtım Merkezleri</li>
    <li>Değerli Malzeme Depoları</li>
    <li>Telekomünikasyon Merkezleri</li>
    <li>Türbin Kabinleri</li>
    <li>Arşivler ve Müzeler</li>
    <li>Yanıcı ve Parlayıcı Malzeme Depoları</li>
    <li>Off-Shore Uygulamalar</li>
    <li>Sivil, Askeri ve Marin Uygulamalar</li>
    <li>Raylı Sistemler ve Toplu Taşıma Araçları</li>
</ul>
<p  style={{fontWeight:600}}>Novec 1230 Sistemleri Nerede Kullanılmaz</p>
<ul>
    <li>Nitroseluloz yangınları (Oksijen)</li>
    <li>Sodyum Klorat veya Sodyum nitrat (Oksitleyici)</li>
    <li>Oksijenperoksitler</li>
    <li>Sodyum, potasyum ve magnezyum v.b. reaktif metaller</li>
    <li>Uranyum ve plutonyum gibi radyoaktif metaller</li>
</ul>
<p>
Novec 1230 Sistemleri üreticilere göre çalışma basınçları anlamında farklılıklar göstermektedir. Klasik Sistemlerde 25bar ve 42bar seçenekleri ile UL, FM ve VdS onaylı sistem seçenekleri ile piyasa talepleri doğrultusunda üretilmektedir. 
</p>
<p>
Yeni Nesil S-Flow Novec 1230 sistemlerinde ise 25bar, 32bar, 34bar, 42bar 50bar ve 55bar ve 67bar çalışma basıncına kadar UL ve FM sertifikalı olarak üretilmekte ve kullanıma sunulmaktadır. S-Flow Sistemlerde 25bar, 32bar, 34bar, 42bar 50bar ve 55bar ve 60bar çalışma basıncına kadar VdS onaylı olarak üretilmekte ve kullanıma sunulmaktadır. Geçmişte klasik sistemlerde göreceli olarak kurulumun yapılacağı alana yakın lokasyonda silindirleri konumlandırmak gerekli iken 25bar üzerine çıktığınızda silindirleri daha uzak noktaya koyabilmenize olanak tanır.   

</p>
<p>
Novec 1230 Sistemleri uygulanmadan önce ilgili koruma alanlarının mutlaka <strong>“Dor-Fan Test”</strong> ,<strong>“Puf Test”</strong> ve <strong>“Boru Sızdırmazlık Testi (Leak Test)”</strong>  uygulanmalıdır.

</p>
<p>
Sistem tasarımı NFPA 2001 veya EN 15004 standardı gereği yapılmaktadır. Sahada sağlıklı bir mühendislik çalışması yapılarak korunacak mahalin tehlike sınıflaması yapılır. 

</p>
<p>
Örneğin Class A için %5.3, Class A Higher için %5.6  ve  Class B için ise %5.9  söndürme konsantrasyonlarını kullanılarak  tehlike sınıfına göre uygulama yoğunluğu yani Flooding Factor belirlenir (Veriler EN 15004 standardı gereği verilmiştir).  Belirlenen Flooding Factor kullanılarak gerekli söndürücü gaz miktarı hesaplanır.

</p>
<p>
Tek sistem üzerinden çoklu bölgelerin korunması selektör vanalar kullanılarak sağlanabilir. Bunun için asıl sistemin %100 yedekli seçilmesini tavsiye ederiz.

</p>
<p>
Söndürme Kontrol paneli ve aksesuarları ile birlikte kullanılır. Söndürmenin algılama kanadı korunacak mahalin gereklerine göre farklı dedektörler kullanılarak çözülebilir.

</p>
<p>
AKSAY Yangın SIEX işbirliği ile Novec 1230 Sistemlerinin Tasarımı, Mühendislik Hesapları, Tedarik Edilmesi, Montaj-Test ve Devreye Alma ve Kontratlı Bakım hizmetlerini yetişmiş Mühendis  ve Teknisyen kadrosu ile gerçekleştirmektedir.  

</p>
  </div>






  </>)
}