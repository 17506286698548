import '../../../css/footer.css'
import img from '../../../img/main/02- KÖPÜKLÜ SÖNDÜRME SİSTEMİ-YENİ/K-07  SU VE KÖPÜK MONİTÖRLERİ/Su ve Köpük Monitörleri iç.png'
import banner from '../../../img/main/02- KÖPÜKLÜ SÖNDÜRME SİSTEMİ-YENİ/K-07  SU VE KÖPÜK MONİTÖRLERİ/Başlık.png'
export default function SuveKopuk() {
    const containerStyle = {
      
      
        maxWidth: '800px',
       
        
        fontFamily: 'Arial, sans-serif',
      };
    
      const headingStyle = {
        fontSize: '24px',
        marginTop: '0',
        fontWeight: '800',
      };
      const divStyle={
        height: '200px',
        width: '100%',
        backgroundImage:`url(${banner})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        padding: 0,
        fontSize: "3rem",
        color: " #ffffff",
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom:20,
        textShadow: '4px 4px 4px rgba(0, 0, 0, 0.5)'
    }
    return (<>
  <div style={containerStyle}>
     <div style={divStyle}>
      
     </div>
      <h2 style={headingStyle}>Su ve Köpük Monitörleri </h2>
      <div style={{minHeight:1, backgroundColor:'red',marginBottom:10,marginTop:10}}></div>
      <p>
      Su ve Köpük monitörleri (Su ve Köpük Topları) riskli veya riskten etkilenen bölgeye en kısa zamanda, güvenli mesafeden gerekli ve doğru miktarda su veya köpük vermek için kullanılan saha elemanlarıdır.
      </p>
      <p>
      Sahanın gerektirdiği debiyi sağlamak için basınç ve akış ilişkisini doğru kurmak ve bu durumu korunacak alana uyarlamak gerekir.  Sahada kullanılacak nozul ve monitör doğru seçilmelidir. Sabit monitörler en çok tercih edilen ve nispeten ekonomik rakamlarla tedarik edilebilmektedir. Kendinden Salınımlı veya Uzaktan kumandalı monitörler (su veya köpük topları)  sahanın barındırdığı risk gereklerine göre tercih edilebilmektedir.

      </p>
      <p>
      Monitör nozulları direk solid-jet, namluda solüsyona hava ekleyen aspirasyon özelikli ve aspirasyonsuz fog/jet olmak üzere üç tip olarak tanımlanabilir. Direk solid-jet nozullar monitörlerden su kullanmak için dizayn edilmişlerdir. Günümüzde çok nadir tercih edilmektedir. 

      </p>
      <p>
      Fog/Jet özellikli ve aspirasyonsuz nozulların atış mesafeleri eş değer debi ve basınç için Aspirasyonlu nozullara göre daha uzundur. Özellikle kombine su ve köpük kullanımının söz konusu olduğu alanlarda tercih edilir. Aspirasyonlu nozullar ise direk köpük kullanımının gerektiği durumlarda tercih edilir. Aspirasyonlu nozullarda solüsyon ile hava monitör namlusunda buluşur ve namludan bitmiş köpük (genleşmiş köpük) atılır. Nispeten atış mesafeleri daha kısadır. Endüstriyel sahalarda aspirasyonsuz fog/jet özellikli nozullar tercih edilmesinin en önemli nedeni atış mesafesinin Aspirasyonlu nozullara göre %10 civarında daha uzun olmasıdır.Aspirasyon yapmayan Fog/Jet nozullarla birlikte  film yapıcı köpüklerin kullanılması önerilir.

      </p>
      <p>
      Kullanılacak monitörün performansı kapasite ve etkili atış mesafesine göre ölçülebilir. Genellikle su ve köpük kullanabilen monitörleri pratikte 30 - 120m atış aralığında 1.000 - 50.000 l/dak. debi değerleri için kullanım alanınıza göre seçilir. Bu değerler üreticilere göre farklılık göstermekle birlikte sıklıkla endüstriyel sahalarda 1.000 - 12.500 l/dak. aralığında çalışan su ve köpük monitörleri kullanılır. Yüksek kapasitelere sahip su ve köpük topları özel durumlar için seçilir.

      </p>
      <p>
      Kullanım amacına ve tehlike grubuna bağlı olarak monitörün nozul tipi belirlenir. Monitörlerde kullanılan nozullar direk jet nozul veya fog/jet özellikli olarak tedarik edilebilir. Günümüzde sıklıkla monitörler daha iyi atış menzili sağladıkları için fog/jet nozul içerecek şekilde seçilir. Seçilen Fog/Jet nozullar nozulun namlusunda debiyi kalibre edecek özelliğe sahip olursa kurulum son derece esnek ve etkili olacaktır.

      </p>
      <p>
      Monitörler; su kullanabilir veya su ve köpük kullanabilir olarak gruplandırabileceğimiz gibi, taşınabilir veya sabit tip olarak ta gruplandırılabilir. 

      </p>
      <p style={{fontWeight: '600'}} >Taşınabilir Monitörler</p>
      <ul>
        <li>Manual Kumandalı Monitörler</li>
        <li>Kendinden Salınımlı Monitörler</li>
      </ul>
      <p style={{fontWeight: '600'}} >Sabit Monitörler</p>
      <ul>
  <li>Manuel Kumandalı Monitörler</li>
  <li>Kendinden Salınımlı Monitörler</li>
  <li>Hidrolik Kumandalı Monitörler</li>
  <li>Elektriksel Kumandalı Monitörler</li>
</ul>
<p>Monitörün kullanım şekli ve yeri monitöre nasıl kumanda edilmesi gerektiğini belirleyen en önemli kriterdir. Çok yönlü kullanılması planlanan su ve köpük monitörleri gereksinimlerinize bağlı olarak çok çeşitli ve yüksek riskli sahalarda oluşabilecek yangınları bastırmak için kullanılır. 
</p>
<p style={{fontWeight: '600'}}>
Su ve Köpük Monitörleri Kullanım Alanları :

</p>
<ul>
  <li>Rafineriler Kimya Tesisleri</li>
  <li>Akaryakıt Terminalleri</li>
  <li>Solvent ve Hidrokarbon Depolama Alanları</li>
  <li>Boya Üretimi Ve Depolama Alanları</li>
  <li>LNG / LPG ve Diğer Yanıcı Gaz Depolama Alanları</li>
  <li>Gemiler / Tankerler / Mavnalar</li>
  <li>Yükleme Rıhtımları/Limanlar</li>
  <li>Uçak Bakım Hangarları</li>
  <li>Heliport Sahaları</li>
  <li>İtfaiye Araçları</li>
  <li>Söndüren Gemileri/Söndüren Botları</li>
  <li>Katı Madde Depolama Alanları</li>
  <li>Atık Bertaraf Tesisleri</li>
  <li>Ağaç Endüstrisi Odun ve Yonga Stok Alanları</li>
</ul>
<p>AKSAY güçlü mühendislik birikimi ve alternatifli ürün seçenekleri ile hızlı ve güvenilir hizmet vermek için size bir telefon kadar yakındır. AKSAY yatırımlarınızı güvenle korur.
</p>
<p>
AKSAY Yangın HD işbirliği ile Su ve Köpük Monitörler (Su ve Köpük Topu) Sistemlerinin Tasarımı, Mühendislik Hesapları, Tedarik Edilmesi, Montaj-Test ve Devreye Alma ve Kontratlı Bakım hizmetlerini yetişmiş Mühendis ve Teknisyen kadrosu ile gerçekleştirmektedir.  

</p>
<img style={{width:600,height:600,marginBottom:20}} src={img} alt="" />
    </div>






    </>)
}