import { Col, Container, Row } from "react-bootstrap"
import test from '../img/Referanslar/1.png'

function Referans() {
    const images = [
        { id: 1, src: require('../img/Referanslar/1.png') },
        { id: 2, src: require('../img/Referanslar/new.jpg') },
       
        { id: 3, src: require('../img/Referanslar/3.png') },
        { id: 4, src: require('../img/Referanslar/4.jpg') },
        { id: 5, src: require('../img/Referanslar/5.png') },
        { id: 6, src: require('../img/Referanslar/6.jpg') },
        { id: 7, src: require('../img/Referanslar/7.jpg') },
        { id: 8, src: require('../img/Referanslar/8.png') },
        { id: 9, src: require('../img/Referanslar/9.jpg') },
        { id: 10, src: require('../img/Referanslar/10.jpg') },
        { id: 11, src: require('../img/Referanslar/11.jpg') },
        { id: 12, src: require('../img/Referanslar/12.jpg') },
        { id: 13, src: require('../img/Referanslar/13.jpg') },
        
        { id: 15, src: require('../img/Referanslar/15.jpg') },
        { id: 16, src: require('../img/Referanslar/16.png') },
        { id: 17, src: require('../img/Referanslar/17.jpg') },
        { id: 18, src: require('../img/Referanslar/18.png') },
        { id: 19, src: require('../img/Referanslar/19.jpg') },
        { id: 20, src: require('../img/Referanslar/20.jpg') },
        { id: 21, src: require('../img/Referanslar/21.jpg') },
        { id: 22, src: require('../img/Referanslar/22.jpg') },
        { id: 23, src: require('../img/Referanslar/23.jpg') },
        { id: 24, src: require('../img/Referanslar/24.jpg') },
        { id: 25, src: require('../img/Referanslar/hk.jpg') },
      ];

    const divStyle = {
      height: '100px',
      width: '100%',
      background: ' rgb(0,55,105)',
      background: '   linear-gradient(90deg, rgba(0,55,105,1) 0%, rgba(0,130,226,1) 100%)',
      paddingTop:10,
      fontSize: "3rem",
      color:" #ffffff",
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      textShadow: '4px 4px 4px rgba(0, 0, 0, 0.5)'
      
      };
    return (<>
        <div style={divStyle}>
        <p>Referanslarımız</p>
      </div>
        <Container style={{marginTop:30,marginBottom:30}}>
            <Row>
            {images.map(image => (
        <Col key={image.id} xs={6} md={4} lg={3}>
          <div style={{width:200,height:200}} className="d-flex justify-content-center">
            <div><img style={{maxWidth:200}} src={image.src} alt="" /></div>
          </div>
        </Col>
      ))}
               

            </Row>
        </Container>
        </>)
}
export default Referans