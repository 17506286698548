export default function DemoSayfa() {
    const containerStyle = {
        padding: '20px',
        maxWidth: '800px',
        margin: '0 auto',
        fontFamily: 'Arial, sans-serif',
      };
    
      const headingStyle = {
        fontSize: '24px',
        marginTop: '0',
        fontWeight: '800',
      };
    
    return (
    <>
    <div style={containerStyle}>
     <div >
      <img src="https://picsum.photos/800/200" alt="" style={{marginBottom:10}} />
     </div>
      <h2 style={headingStyle}>Bilgiler Gelecek</h2>
      <div style={{minHeight:1, backgroundColor:'red',marginBottom:10,marginTop:10}}></div>
      
      
  
    </div>






    </>
    )
}