import '../../../css/footer.css'
import banner from '../../../img/main/013-ALGILAMA SİSTEMLERİ/005-SÖNDÜRME KONTROL PANELİ ve AKSESUARLARI/3.png'
export default function Panel() {
    const containerStyle = {
      
      
        maxWidth: '800px',
      
        
        fontFamily: 'Arial, sans-serif',
      };
    
      const headingStyle = {
        fontSize: '24px',
        marginTop: '0',
        fontWeight: '800',
      };
      const divStyle={
        height: '200px',
        width: '100%',
        backgroundImage:`url(${banner})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        padding: 0,
        fontSize: "3rem",
        color: " #ffffff",
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom:20,
        textShadow: '4px 4px 4px rgba(0, 0, 0, 0.5)'
    }
  
    return (<>
  <div style={containerStyle}>
     <div style={divStyle}>
      
     </div>
      <h2 style={headingStyle}>Söndürme Kontrol Paneli Ve Aksesuarları</h2>
      <div style={{minHeight:1, backgroundColor:'red',marginBottom:10,marginTop:10}}></div>
      <p>3 algılama zonu ve 1 söndürme bölgesinden oluşan EP203 Söndürme Control Paneli, bu zonların çapraz doğrulama prensibi ile alarm durumunu aktive etmek veya söndürmeyi başlatmak için kullanılabilir.</p>

<p>Panel olay hafızalıdır ve 500 olaya kadar hafızasında tutar. Olay hafızasına sadece yetkilendirilmiş personel erişebilir. Söndürme işleminin 0 - 5 dakika aralığında geciktirilmesi tanımlanabilir. LCD ekranlıdır. Panele tuş takımı ile verilen komutlar ekrandan takip edilebilir. Panel söndürme konumunda iken LCD ekran renk değiştirir ve tanımlı ise geri sayma işlemi görüntülenir. EP203 Söndürme Kontrol Paneli EN 12094-1 belgeli ve LPCB onaylıdır.</p>

<p style={{fontWeight: '600'}} >EP203 Söndürme Kontrol Panelinin Genel Özellikleri</p>

<ul>
  <li>Sistem durumunu ve programlamasını gösteren LCD ekran.</li>
  <li>Zamanıyla birlikte listeleyen olay hafızası.</li>
  <li>Ön panele monte edilmiş boşaltmayı başlatan iki adet "RELEASE BUTTON."</li>
  <li>Panele entegre "ABORT" bağlantısı.</li>
  <li>Panele entegre "HOLD" bağlantısı.</li>
  <li>Ön panele entegre kullanıcı seviyesi ayarı.</li>
  <li>Ön panele monte entegre otomatik/manuel geçiş anahtarı.</li>
  <li>Kısa devre ve açık devre arızalarına karşın panel tarafından sürekli denetlenen 3 adet konvansiyonel algılama zonu.</li>
  <li>Kısa devre ve açık devre arızalarına karşı denetlenen 3 adet konvansiyonel siren zonu.</li>
  <li>Manuel söndürme, Flow switch, Düşük basınç, Mod, Bekletme ve Durdurma uçları için 6 adet izleme girişi.</li>
  <li>Genel alarm, bölgesel alarm, Hata, 1. seviye ve 2. seviye alarm yardımcı çıkışları.</li>
  <li>2 adet selenoidi besleyen söndürme çıkışlarını.</li>
  <li>Ayarlanabilir söndürme gecikmesi.</li>
  <li>Alarm sayacı.</li>
  <li>Reset modu, bekletme ve durdurma çıkışları için role kartı.</li>
  <li>8 adete kadar çıkabilen sistem durumuyla ilgili ve boşaltma işlemini başlatabilen uzaktan kontrol üniteleri için bağlantı.</li>
  <li>8 adete kadar çıkabilen ekonomi kontrol üniteleri için bağlantı imkanı.</li>
  <li>Kullanıcının yetki düzeyini belirlemek için gösterge bölümünde buton ve anahtarlar.</li>
</ul>
 
</div>





    </>)
}