import '../../../css/footer.css'
import banner from '../../../img/main/06-  ENDÜSTRİYEL KURU TOZLU SÖNDÜRME SİSTEMİ-YENİ/4.png' 
export default function Araç() {
    const containerStyle = {
        
      
        maxWidth: '800px',
        
        
        fontFamily: 'Arial, sans-serif',
      };
    
      const headingStyle = {
        fontSize: '24px',
        marginTop: '0',
        fontWeight: '800',
      };
      const divStyle={
        height: '200px',
        width: '100%',
        backgroundImage:`url(${banner})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        padding: 0,
        fontSize: "3rem",
        color: " #ffffff",
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom:20,
        textShadow: '4px 4px 4px rgba(0, 0, 0, 0.5)'
    }
    return (<>
  <div style={containerStyle}>
     <div style={divStyle}>
      
     </div>
      <h2 style={headingStyle}>Araç Söndürme Sistemleri</h2>
      <div style={{minHeight:1, backgroundColor:'red',marginBottom:10,marginTop:10}}></div>
      <p>
      Yangın her yıl milyarlarca dolar değerindeki yükleme, taşıma ve ulaşım araçlarını yok ediyor. İnsan gücü kayıplarına, sosyal binaların, üretim tesislerinin ya tamamen yok almasına yada değerinin azalmasına ve çoğu durumda insan hayatının kaybına neden olmaktadır.
      </p>
     <p>
     İster bir yükleyicinin üzerinde yüksekte oturan bir operatör, ister okul otobüsüne binen çocuklarınız olsun, Amerex Araç Söndürme Sistemleri her şeyden önce insan hayatını korumak için tasarlanmıştır. Sistemlerimiz, yangının operatör veya yolcu alanlarına ulaşmadan hızlı ve etkili bir şekilde söndürülmesini sağlayacak teknik donanım ile tasarlanmıştır.
     </p>
     <p style={{fontWeight:600}}>İş makinelerinin kullanıldığı alanlarda veya araç üzerinde bazı tehlikeler her zaman mevcuttur. Bunlar;
 </p>
 <ul>
  <li>Yüksek basınçlı hidrolik hattındaki aşınma, delinmeye neden olur ve hidrolik sıvısı sıcak manifold üzerine sızmasına neden olabilir</li>
  <li>Bir çöp kamyonunun motor bölmesindeki sıcak egzoz manifoldunun arkasında karton, kağıt ve diğer yanıcı katılar toplanabilir</li>
  <li>Makinenin elektrik kablolarından birinde kısa devre meydana gelir ve kablo yalıtkanı alev alabilir</li>
  <li>Yüksek derecede yanıcı, görünmez yangın tehlikeleri olan Doğal Gaz veya Hidrojen, bir sigara izmaritinin kazara atılmasıyla tutuşabilir</li>
  <li>Maden sahalarında kömür tozu metal bir kıvılcımla tutuşabilir</li>
  <li>Akünün yakınındaki bir kablo demeti aracın şasisine kısa devre yapar, erir ve ardından tutuşabilir</li>
</ul>
<p style={{fontWeight:600}}>Sigorta Maliyetini Azaltın</p>
<p>
Amerex Araç Söndürme Sistemleri sigorta poliçesi yaptırabilmenizin önünü açar ve aynı zamanda daha düşük prim ödemenizi sağlar. Ağır vasıtaların maliyetleri arttıkça sigorta oranları da buna bağlı pirim ödemeler de artar. Birçok sigorta şirketi, sabit araç yangın söndürme sisteminin varlığı nedeniyle ödenen hasarlarda ciddi bir azalma olduğunu gördüğünden beri özellikle iş makineleri, toplu taşıma araçları vb. araçları korunması için “Araç Söndürme Sistemleri” talep etmektedir. Amerex Araç Söndürme Sistemi aracınızda oluşan yangını algılar, söndürür ve hasarınızı minimize eder. Bu durumda da sigorta primlerinizi düşecektir. 

</p>
<p style={{fontWeight:600}}>Amerex Araç Söndürme Sistemi Bileşenleri</p>
<p>Silindirler:  Amerex Araç Söndürme Sistemi silindirleri en kaliteli malzemelerden üretilmektedir. Silindirler çelikten yapılmış olup, korozyona karşı mükemmel direnç gösteren epoksi astar ve poliüretan son kat ile kaplanmıştır. Valf pirinçtir ve zorlu ortamlarda korunacak şekilde planlanmış bir basınç göstergesine sahiptir. Silindirler sürekli basınçlı ABC veya Purple K kuru kimyevi madde  ile doldurulmuş olarak sevk edilir.</p>
 <p>Amerex Araç Söndürme Sistemi Silindir Boyutları ;</p>
 <p>Dikey Montaj yapılabilir tipler; 13 lb. , 25lb.  ve 50lb.</p>
 <p>Yatay Montaj yapılabilir tipler; 25lb.  ve 50lb.</p>
  <p>Kontrol Paneli : Sistem durumu Kontrol Paneli tarafından sürekli olarak izlenmekte ve görsel LED göstergeler ve sesli alarm ile araç operatörüne iletilmektedir. Kontrol Paneli, modüler renk kodlu, geçmeli kilitli konnektörlerle sistem kablolarına bağlanır.
</p>
<p>Algılama Ağı: Yangın algılaması sabit sıcaklık termostatları, lineer kablo tipi sıcaklık detektörü, Lineer tubing veya Safe-IR dedektörler ile sağlanabilmektedir. Gerektiğinde belirtilen algılama elemanlarının herhangi bir kombinasyonu da kullanılabilir.
</p> 
<p>
Nozullar ve Dağıtım Hattı : Yangın söndürme maddesi, sabit, yüksek kapasiteli, hızlı akışlı, pirinç nozullar aracılığıyla tehlike bölgesine iletilir. Söndürücü maddeyi nozullara kadar taşımak için silindir ile boru ağı arasına bakır, paslanmaz çelik boru veya flexible çelik takviyeli üreticinin önerdiği hortumlar ve ek parçaları kullanılabilir.

</p>
<p style={{fontWeight:600}}>
Sistemin Temel Özellikleri

</p>
<ul>
  <li>Digital Panel Üzerinden Sürekli Kontrol İmkanı</li>
  <li>Algılamada Alternatif Ürün Seçeneği</li>
  <li>Hızlı Alev Kapatma</li>
  <li>Etkili Söndürme</li>
  <li>Yeniden Tutuşmayı Engelleme</li>
  <li>Düşük Maliyetli Yerinde Kurulum</li>
  <li>Çevreye Saygılı</li>
  <li>Dolum Maliyeti Düşük</li>
</ul>
<p>
AMEREX İş Makinasi ve Araç Söndürme Sistemleri FM sertifikalı olarak üretilmektedir. 
AKSAY Yangın AMEREX işbirliği ile İş Makinesi ve Araç Söndürme Sistemlerinin Tasarımı, Ön Mühendislik Hesapları, Tedarik Edilmesi, Montaj-Test ve Devreye Alma ve Kontratlı Bakım hizmetlerini yetişmiş Mühendis ve Teknisyen kadrosu ile gerçekleştirmektedir.  
</p>
    </div>






    </>)
}