import divStyle from '../../../css/bannerCss';

export default function SuBaskın (){
    const containerStyle = {
        
      
        maxWidth: '800px',
      
        
        fontFamily: 'Arial, sans-serif',
      };
    
      const headingStyle = {
        fontSize: '24px',
        marginTop: '0',
        fontWeight: '800',
      };
  
    return(
   <>
  <div style={containerStyle}>
     <div style={divStyle}>
     
     </div>
      <h2 style={headingStyle}>Su Baskın ( Water Leak ) Dedektörü </h2>
      <div style={{minHeight:1, backgroundColor:'red',marginBottom:10,marginTop:10}}></div>
      <p>
      Su baskın dedektörü; ortamda istenmeyen su baskınlarında ya da nem algılaması sonucunda alarm durumu oluşturan dedektör tipidir.

      </p>
      <p>
      Su baskınları, gerek yaşam alanlarımız olan evler için gerekse çalışma alanları olan iş yerlerimiz  ve endüstriyel üretim yapan fabrikalarımız açısından son derece büyük bir tehdittir. Su baskın dedektörü, sisteminizi yaşanabilecek bu tür su baskını durumlarına karşı erkenden uyararak hızlı bir biçimde gerekli önlemler almanızı ve müdahalelerde bulunmanızı kolaylaştırmaktadır.

      </p>
      <p>
      Su baskın dedektörü, önlem alınmadığı takdirde son derece büyük maddi kayıplara sebep olabilen su baskınlarının etkisini, erken müdahale sayesinde minimize edebilmesi mantığı ile geliştirilmiştir. 

      </p>
      <p>
      Günümüzde;  farklı çalışma prensiplerine sahip su baskını dedektörleri ile karşılaşılmaktadır,  Ancak en etkili olanlar, su baskınını algılayarak alarm sistemini etkinleştiren dedektörlerdir. Dedektörün çalışma prensibi oldukça basittir. Kullanım alanı içerisinde belirlenen yere yerleştirilen dedektörün ucunda açık bir kablo (algılama elemanı)  bulunur. Bu kablo suyla temas ettiğinde kısa devreye neden olur ve alarm sistemini aktive eder. Bu şekilde meydana gelen su baskını kolay bir şekilde fark edilebilir. Su baskın dedektörü 1.5m algılama probu ile birlikte tedarik edilmektedir. 

      </p>
      <p>
      Özellikle yangından korunum sistemleri konu başlığı altında ilerlediğimizde Bilgi işlem Merkezleri, Enerji Dağıtım Odaları ve özellikle Yangın Pompa İstasyonlarında kullanılmaktadır.

      </p>
      <p style={{fontWeight:600}}>
      Su Baskın Detektörü Avantajları:
      </p>
      <ul>
        <li>
        Besleme gerektirmez 

        </li>
        <li>
        9V DC Entegre pil 

        </li>
        <li>
        Entegre 85dB alarm çıkışı 

        </li>
        <li>
        Alarm Panellerine bağlanabilir

        </li>
        <li>
        Hızlı ve Kolay kurulum

        </li>
        <li>
        Programlama gerektirmez

        </li>
        <li>
        Su kaynaklı hasarlar minimize edilir
 
        </li>
        <li>
        Minimum bakım ihtiyacı

        </li>
      </ul>
      <p style={{fontWeight:600}}>
      Su Baskın Detektörü Avantajları:
      </p>
      <ul>
        <li>
        Yangın Pompa İstasyonları

        </li>
        <li>
        Bilgi İşlem alanları

        </li>
        <li>
        Enerji Dağıtım Odaları

        </li>
        <li>
        Operasyon ve Bilgisayar Odaları

        </li>
        <li>
        Jeneratör ve Kompresör Alanları

        </li>
        <li>
        Data Center vb alanlar

        </li>
        <li>
        Depolama Alanları

        </li>
      </ul>
      <p>
      Su baskın dedektörü 1.5m algılama probu ile birlikte tedarik edilmektedir. 

      </p>
      <p>
      Su baskın dedektörünün tedarik edilmesi, kurulumu, test-devreye alma ve bakım hizmeti için ; AKSAY Yangın ile iletişime geçmeniz yeterlidir. 
      </p>
      


    </div>






    </>
    )
}