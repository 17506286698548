import '../../../css/footer.css'
import divStyle from '../../../css/bannerCss';
import banner from '../../../img/main/03- KÖPÜK KONSANTRELERİ-YENİ/KK-03  SOLBERG RE-HEALING KÖPÜK KONSANTRALERİNIN GENEL ÖZELLİKLERİ/Başlık.png'
export default function Solberg() {
    const containerStyle = {
     
        maxWidth: '800px',
        
        fontFamily: 'Arial, sans-serif',
      };
    
      const headingStyle = {
        fontSize: '24px',
        marginTop: '0',
        fontWeight: '800',
      };
      const divStyle={
        height: '200px',
        width: '100%',
        backgroundImage:`url(${banner})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        padding: 0,
        fontSize: "3rem",
        color: " #ffffff",
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom:20,
        textShadow: '4px 4px 4px rgba(0, 0, 0, 0.5)'
    }
  
    return (<>
  <div style={containerStyle}>
     <div style={divStyle}>
      
     </div>
      <h2 style={headingStyle}>Solberg RE-HEALING™ Köpük Konsantrelerinin Genel Özellikleri </h2>
      <div style={{minHeight:1, backgroundColor:'red',marginBottom:10,marginTop:10}}></div>
      <p>RE-HEALING™ köpük konsantreleri, konvansiyonel yangın koruma ekipmanı ve sistemleri ile geniş bir uygulama yelpazesi için tasarlanmış sentetik yangın söndürücü köpük konsantreleridir. Bu konsantreler, çevre dostu ve sürdürülebilir özelliklere sahiptir.</p>

<ul>
    <li>Solberg <strong>RE-HEALING™</strong> köpük konsantreleri doğada iz bırakmaz, biyolojik birikim yapmaz ve toksik bozulma açısından çevre dostudur.</li>
    <li><strong>RE-HEALING™  </strong> köpük konsantreleri , sürdürülebilir uzun ömürlü yanıcı, toksik veya zararlı buharı bastırmayı sağlarken, art yanma için doğal dirençliliği temsil eder.</li>
    <li><strong>RE-HEALING™  </strong> Köpük Konsantreleri; su ile film yapıcı AFFF köpükler gibi yüksek performanslı alternatif köpüklerdir.</li>
    <li><strong>RE-HEALING™  </strong> köpük konsantreleri, hidrokarbon ve polar solvent kaynaklı yangınların hızla söndürülmesini sağlar.</li>
    <li><strong>RE-HEALING™  </strong> köpük konsantreleri, geleneksel AFFF, AR-AFFF, FP ve FFFP gibi köpük konsantrelerine göre yeni, yenilikçi ve yeni alternatif ürün grubunu temsil etmektedir.</li>
    <li><strong>RE-HEALING™  </strong> Köpük konsantresi düşük ve yüksek genleşebilir konsantre seçeneklerine sahiptir.</li>
    <li><strong>RE-HEALING™  </strong> köpük konsantreleri yakıtı etkin bir şekilde ve iyi soğutur, yüksek buhar bastırma özelliğine sahiptir. Uygulandığı yüzeyi iyi kapatmaları nedeni ile yakıt buharlarını bastırıp tehlikeli yanıcı buhar bulutlarını azaltır.</li>
    <li><strong>RE-HEALING™  </strong> köpük konsantreleri, tatlı ve tuzlu su ile kullanabildiği gibi kuru kimyasal maddeler ile kullanıma uygun ve uyumludur.</li>
    <li><strong>RE-HEALING™  </strong> köpük konsantrelerinin hızlı akan köpük battaniyesi, protein bazlı konsantrelere göre daha iyi kontrol ve söndürme sağlar. Bu durumda art yanma ciddi oranda kontrol altına alınır.</li>
    <li><strong>RE-HEALING™  </strong> köpük konsantreleri mevcut köpük sistemleriniz ile uyumludur. Mevcut sisteminizde kullanmak için SOLBERG distribütörü veya teknik servisi ile görüşünüz.</li>
    <li><strong>RE-HEALING™  </strong> köpük konsantreleri diğer markaların köpük konsantreleri ile karıştırılmamalıdır. Köpük solüsyonu olarak yangına uygulandığında, <strong>RE-HEALING™  </strong> köpük konsantreleri Solberg üretimi benzer köpük konsantreleri ile karıştırılabilir.</li>
    <li><strong>RE-HEALING™  </strong> köpük konsantresinin kullanımı “B” sınıfı yangınlarla sınırlı değildir. “A” sınıfı yangınlarda da kullanılması uygundur. A ve B sınıfı yanıcıların bir arada olabildiği yangınların söndürülmesinde de son derece etkili ve başarılıdır.</li>
    <li><strong>RE-HEALING™  </strong> köpük konsantre hammaddelerinin tamamı kararlı ve suda çözünebilir, çökelti meydana getirmez.</li>
</ul>

<p>Orijinal ambalajında veya üreticinin tavsiye ettiği ekipmanlarda, belirtilen sıcaklık aralığında muhafaza edildiğinde minimum raf ömrü 10 senedir. Donma meydana gelirse, çözülme ve karıştırma, köpük konsantrelerini tekrar kullanılabilir hale getirir. Donma ve sonrasında çözünme ve karıştırma, üründe kalite kaybına neden olmaz.</p>

<p>RE-HEALING™ Köpük konsantreleri FM, UL 162 ve EN 1568 standartlarına göre test edilmiş ve sertifikalandırılmıştır.</p>

<p>Yanıcı ve yanıcı sıvılar (B Sınıfı Yakıtlar), hızlı söndürme için tasarlanmış köpüklere ve yeniden tutuşmayı önlemek için güvenli bir köpük örtüsüne ihtiyaç duyar. Yangının battaniyenin zarar gördüğü alanlardan yayılmasını en aza indirmek için köpük battaniyenin iyi bir geri yanma direncine sahip olması önemlidir. Köpük battaniyenin bütünlüğü genellikle düşen döküntüler veya itfaiyecinin hareketi ve ardından yangın hortumunun ve/veya ekipmanının köpük battaniyenin içinden sürüklenmesi nedeniyle tehlikeye girer.</p>

<p>Geçtiğimiz on beş yılda dünya genelindeki hükümetler, köpük (AFFF) içeren Per veya Polifloroalkil maddelerin (PFAS) kullanımını yasaklayan veya kısıtlayan yasalar çıkarmaya devam etti. Perimeter Solutions, son yirmi yıldır flor içermeyen alternatifler geliştiriyor, pazarlıyor ve satıyor ve dünyanın en geniş ve teknolojik olarak en gelişmiş B Sınıfı yangın söndürme köpük konsantreleri portföylerinden birini (Florsuz ve AFFF tipi köpükler) sunuyoruz. Flor içermeyen ürün grubumuz, hidrokarbon yakıtlar için köpük konsantreleri, polar solvent yakıt yangınları için alkole dirençli konsantreler, eğitim köpüğü, özel köpükler ve A Sınıfı köpük konsantrelerinin eksiksiz bir serisini içerir. Flor içermeyen köpüklerimiz, PFOS veya PFOA siloksanları da dahil olmak üzere PFAS maddeleri gibi kasıtlı olarak eklenen kalıcı kimyasalları içermeyen ham maddeler içerir.</p>

<p>Flor içermeyen köpük, yakıt yüzeyini kapatarak buhar salınımını bastırır ve yangını söndürmek için yakıt ile havayı ayırır. Alkol Tipi Konsantreler (ATC), polar solventin köpük kabarcıklarından su çekerek battaniyeye zarar vermesini önleyen katkı maddeleri ile özel olarak tasarlanmıştır.</p>

<p>SOLBERG flor içermeyen köpükler çeşitli karışım oranları (%1, %3 veya %6), viskoziteler ve donma noktalarına sahiptir. SOLBERG ayrıca sulu film oluşturucu köpükler (AFFF) ve alkole dayanıklı AFFF (AR-AFFF) de üretmektedir. Çevre Çözümleri AFFF ve AR-AFFF, değişen karışım oranlarına (%1, %3 veya %6), viskozitelere ve donma noktalarına sahip çeşitli formülasyonlarla sunulur.</p>

<p>Uygulamanız için doğru ürünü seçmek önemlidir. B Sınıfı yangın söndürme köpükleri genellikle kimya ve petrokimya tesislerinde, rafinerilerde, petrol ve gaz depolama tesislerinde, yanıcı sıvı depolama tesislerinde, havaalanlarında ve yanıcı sıvıların depolandığı veya kullanıldığı her yerde kullanılır.</p>

<p>AKSAY Yangın ve SOLBERG yatırımlarınızı güvenle koruyacağınız çevreci köpük konsantrelerinin seçimi ve kullanımı için desteğe hazırız.</p>

    </div>






    </>)
}