
import banner from '../../../img/main/06-  ENDÜSTRİYEL KURU TOZLU SÖNDÜRME SİSTEMİ-YENİ/4.png' 
export default function MainYazıKuruTozlu() {
    const containerStyle = {
    
      
      maxWidth: '800px',
     
      
      fontFamily: 'Arial, sans-serif',
    };
  
    const headingStyle = {
      fontSize: '24px',
      marginTop: '0',
      fontWeight: '800',
    };
    const divStyle={
      height: '200px',
      width: '100%',
      backgroundImage:`url(${banner})`,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      padding: 0,
      fontSize: "3rem",
      color: " #ffffff",
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginBottom:20,
      textShadow: '4px 4px 4px rgba(0, 0, 0, 0.5)'
  }
    return (<>
      <div style={containerStyle}>
        <div style={divStyle}>
          
        </div>
        <h2 style={headingStyle}>Amerex Kuru Kimyevi Tozlu Söndürme Sistemi</h2>
        <div style={{ minHeight: 1, backgroundColor: 'red', marginBottom: 10, marginTop: 10 }}></div>
        <p>
        Amerex Endüstriyel Kuru Kimyevi Tozlu Söndürme Sistemi IS18, IS35 ve IS45 silindirlerden oluşmaktadır. Amerex Endüstriyel Kuru Tozlu Söndürme Sistemi kendine has tetikleme sistemi,  algılama sistemi,  sistemin borulaması ve boşaltma nozulları ile UL 1254 standardı kapsamında ön mühendisliği yapılmış UL ve ULC listeli olarak tedarik edilmekte ve kurulumu yapılmaktadır.

        </p>
        <p>
        Endüstriyel sahalarda hacim söndürme ve lokal uygulama amaçlı kullanılabilen etkin bir söndürme sistemidir. ABC ve BC sınıfındaki yangınlar için son derece etkilidir. Sıvı yanıcıların söz konusu olduğu alanlarda da etkin kullanılmaktadır.  Sistem paket olarak söndürücü silindir veya silindirler, tetikleme düzeneği, boşaltma boru ağı ve boşaltma nozullarından oluşmaktadır. Diğer sistemlere nazaran daha etkindir. 

        </p>
        <p>
        Sistemde söndürücü madde olarak özel üretilmiş Kuru Kimyevi Toz kullanılmaktadır. Hızlı alev kapatma ve yeniden tutuşmayı engellemesi nedeni ile Amerex Endüstriyel Kuru Tozlu Sistemler tercih edilir. Standart Sistem silindirleri 18lb, 35lb ve 45lb söndürücü madde içerecek boyutlarda üretilmektedir

        </p>
        <p>
        Amerex Endüstriyel Kuru Tozlu Sistemler ön mühendisliği yapılmış paket sistemler olup sağlıklı saha keşfi yapılmadan sistem seçimi ve montajı yapılamaz. Detaylı Keşfi yapılan mahalin “Endüstriyel Kuru Kimyevi Tozlu Söndürme Sistemi” tasarımı “NFPA 17 Standard for Dry Chemical Extinguishing Systems” esas alınarak yapılmaktadır. Tasarımda temel gereklilikler;

        </p>
        <ul>
  <li>Sistem, sahada standardın ve üreticinin ön gördüğü ergir metal dedektörleri vasıtasıyla otomatik olarak veya kaçış yoluna yerleştirilecek elle çekme istasyonu ile devreye girebilmeli</li>
  <li>Sistem, mekanik veya elektriksel olarak bir vana veya kritik ekipmana kumanda edebilir olmalı</li>
  <li>Sistem, merkezi yangın algılama ve ihbar sistemine sinyal gönderebilmeli</li>
  <li>Kurulacak sistem uygulandığı mahallerdeki yangını bastıracak nitelikte olmalı</li>
  <li>Üreticinin yetkilendirdiği mühendis tarafından tasarlanarak kurulumu sağlanmalı</li>
  <li>Riskin söz konusu olduğu her bağımsız bölüm veya alan için bağımsız bir sistem uygulanmalı</li>
  <li>Sistem çoklu silindir kullanımına olanak verebilir yetenekte olmalı</li>
  <li>Her sistem kendi içinde bir bütün olup özellikli tüm elemanlar aynı üreticinin ürünü olmalı. Sistem bütün bileşenleri ile bir bütündür ve yerine başka bir komponent veya ikame edebilecek bir eleman eklenemez.</li>
  <li>Lokal ve Hacim Söndürme için kullanılabilir</li>
  <li>Baskın sistemlerde toplam kapatılamayan açıklık %5’i aşamaz</li>
</ul>
<p><span style={{fontWeight:600}}>Amerex IS Serisi Endüstriyel Kuru Kimyevi Tozlu Söndürme Sistemi </span>aşağıda belirtilen ana elemanlardan oluşmaktadır.
</p>
<ul>
  <li>Depolama Silindiri</li>
  <li>Boşaltma Vanası</li>
  <li>Söndürücü Madde (KKT ABC veya BC)</li>
  <li>Silindir Montaj Braketi</li>
  <li>Mekanik Boşaltma Düzeneği, Kontaklı</li>
  <li>İtici Azot Kartuşu</li>
  <li>Boşaltma Nozulları</li>
  <li>Ergir Metal Detektörler</li>
  <li>Algılama Hattı</li>
  <li>Makaralı Dirsekler</li>
  <li>Manual Tetikleme İstasyonu</li>
  <li>Mekanik veya Elektriksel Gaz Kesme Vanası</li>
</ul>
<p>
Çoğu endüstriyel tehlike, çok fazla ilave tehlikeyi ve çeşitli yakıt kaynaklarını içerdiğinden, A Sınıfına (katı malzeme yangınları), B Sınıfına (yanıcı sıvılar) ve C Sınıfına (elektrik kaynaklı) karşı koruma sağlayan ABC kuru kimyevi toz en uygun söndürme maddelerinden biri olarak karşımıza çıkmaktadır.

</p>
<p>
Alanın büyüklüğü çoklu silindir grupları ile koruma yapılarak aşılmakta ve bir boşaltma ünitesi üzerinden toplamda 20 silindire kadar oluşturulan silindir gruplarına kumanda edilebilmektedir. 

</p>
<p style={{fontWeight:600}}>
Sistemin Temel Özellikleri 

</p>
<ul>
  <li>Hacimsel Uygulamalarda Etkin</li>
  <li>Lokal Uygulamalarda Etkin</li>
  <li>ABC veya BC Sınıfındaki Yangınlara Uygun</li>
  <li>Hızlı Alev Kapatma</li>
  <li>Yeniden Tutuşmayı Engelleme</li>
  <li>Düşük Maliyetli Yerinde Kurulum</li>
  <li>Çevreye Saygılı</li>
  <li>Dolum Maliyeti Düşük</li>
</ul>
<p style={{fontWeight:600}}>
Uygulama Alanları 

</p>
<ul>
  <li>Yanıcı ve Parlayıcı Depoları</li>
  <li>Araç Boya Kabinleri</li>
  <li>Kazan Daireleri</li>
  <li>Jeneratör Odaları</li>
  <li>Akaryakıt İstasyonları</li>
</ul>
<p>
<span style={{fontWeight:600}}>Amerex IS Kuru Kimyevi Tozlu Söndürme Sistemleri </span>aşağıda belirtilen özel alanlarda özellikle risklerinizi etkin korur. Bunlar;
</p>
<p>
<span style={{fontWeight:600}}>Lokal Uygulama (Local Application): </span>Amerex IS Kuru Kimyevi Tozlu Yangın Söndürme Sistemini kullanılarak Lokal Uygulama yöntemi ile; Daldırma Tankları, Jeneratörler, Trafolar, Yağ Tankları veya Solvent Tankları gibi çok çeşitli risklerin korunmasına olanak verir.  

</p>
<p>
<span style={{fontWeight:600}}>Hacim Söndürme (Total Application):  </span>Amerex IS Kuru Kimyevi Tozlu Yangın Söndürme Sistemi hacimdeki herhangi bir tehlikeyi korumak için hacim söndürme seçeneği ile de endüstriyel sahalar için etkin koruma sağlamaktadır. Hacim söndürme işleminden bahsedebilmek için kapalı bir kap veya kapalı bir yapı olması gereklidir. Hacim Söndürme yapılacak alanda %5’in altında kapatılamayan açıklığa sahip olan alanlarda etkin olarak kullanılacak şekilde tasarlanmıştır.

</p>
<p>
<span style={{fontWeight:600}}>Önü Açık Boya Uygulama Kabini (Open Front Paint Spray Booth):</span> Önü Açık Boya Püskürtme Kabini bir filtre bölümü ve bir egzoz kanalıyla ayrılmış bir çalışma alanından oluşur. Amerex IS Yangın Söndürme Sistemi kapsamı, 7m yüksekliğe kadar “Önü Açık Boya Püskürtme Kabini” için koruma sunan sektörde bir ilk olup, üretilen sistem diğer üreticilerin sistemlerinin yeteneklerinin çok üzerindedir.
   
</p>
<p>
<span style={{fontWeight:600}}>Araç Boyama Kabini (Vehicle Paint Spray Booth):</span> Amerex IS Yangın Söndürme Sistemleri piyasadaki en etkili Araç Boyama Kabini söndürme sistemine sahiptir. Sistem, sprey boyama yapılan araçların bulunduğu boyama kabinlerinde kapalı alanın tamamını korur Amerex IS Yangın Söndürme Sistemi ile çok sayıda ve tipte boya püskürtme kabini tasarımı paket olarak sunulmaktadır. Amerex IS Sistemi, Boyama Kabinleri için mevcut en verimli ve ekonomik yangından korunum sistemini kullanımınıza sunmaktadır. Tek bir total flooding nozul ile 2.720 ft3'e (7070.2m3) kadar koruma sağlar. 

</p>
<p>
AMEREX IS Kuru Kimyevi Tozlu Söndürme Sistemleri UL ve ULC sertifikalı olarak üretilmektedir. 

</p>
<p>
AKSAY Yangın AMEREX işbirliği ile IS Kuru Tozlu Söndürme Sistemlerinin Tasarımı, Ön Mühendislik Hesapları, Tedarik Edilmesi, Montaj-Test ve Devreye Alma ve Kontratlı Bakım hizmetlerini yetişmiş Mühendis ve Teknisyen kadrosu ile gerçekleştirmektedir.  
</p>
      </div>
  
  
  
  
  
  
    </>)
  }