import '../../../css/footer.css'
import divStyle from '../../../css/bannerCss';
export default function Relief() {
    const containerStyle = {
        
      
        maxWidth: '800px',
        
        
        fontFamily: 'Arial, sans-serif',
      };
    
      const headingStyle = {
        fontSize: '24px',
        marginTop: '0',
        fontWeight: '800',
      };
  
    
    return (<>
  <div style={containerStyle}>
     <div style={divStyle}>

     </div>
      <h2 style={headingStyle}>Aşırı Basıç Tahliye Vanası</h2>
      <div style={{minHeight:1, backgroundColor:'red',marginBottom:10,marginTop:10}}></div>
      <p>
      Yangın pompa gruplarında dizel pompa çıkışından sonra, çek vanadan önce alınacak hat üzerine konularak sistemi aşırı basınç artışlarına karşı korumak amacı ile kullanılır. Vana seçimi dizel pompa kapasitesi dikkate alınarak NFPA 20 gereklerine göre belirlenir. Çıkışında serbest akış konu montajı yapılarak hattan geriye doğru basınç yansıması olmaması için aşırı basınç tahliye hattı çapı yükseltilerek yangın suyu besleme deposuna üstten serbest düşüm sağlayacak şekilde montajı yapılır. 
      </p>
<p>
Aşırı Basınç Tahliye vanaları (Pressure Relief Valve) ani basınç artışı olan sistemlerde basınca duyarlı bir şekilde yüksek basınç dalgasını tahliye ederek sistemin güvenli bir şekilde çalışmasını sağlar. Herhangi bir sebepten dolayı basınç ayar basıncının üzerine çıktığı anda vana açılarak fazla basıncı tahliye eder. Bu durum sistemin daha uzun ömürlü ve sağlıklı çalışmasına olanak tanır. Sistemlerin kritik noktalarında da kullanılabilir.

</p>
<p style={{fontWeight:600}}>Aşırı Basınç Tahliye Vanaları Teknik Özellikler:</p>
<ul>
  <li>
  	Şebeke basıncı ile çalışıp ekstra enerjiye ihtiyaç duymama

  </li>
  <li>
  Şebekeyi aşırı basınçtan koruma

  </li>
  <li>
  İstenilen basınca kolay ve hassas ayarlama

  </li>
  <li>
  	Kolay ve ekonomik bakım

  </li>
</ul>
<p style={{fontWeight:600}}>Aşırı Basınç Tahliye Vanasının Ayarlanması:</p>
<p>
Sisteme su verildikten sonra manometrede görülen değer istenilen basıncın altında ise ayar cıvatası saat yönünde döndürülerek giriş basıncı arttırılır. Manometrenin gösterdiği değer istenilen basınç değerinin üzerinde ise ayar cıvatası saat yönü tersine döndürülerek istenilen basınca ayarlanmış olur. Bu işlemden sonra ayar cıvatası altında bulunan kontra somunu sıkılarak ayar cıvatası sabitlenir.
</p>
    </div>






    </>)
}