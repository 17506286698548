import banner  from '../../../img/main/02- KÖPÜKLÜ SÖNDÜRME SİSTEMİ-YENİ/K-03  DÜŞÜK GENLEŞMELİ KÖPÜKLÜ SÖNDÜRME SİSTEMLERİ/Başlık.png';
export default function Dusuk (){
    const containerStyle = {
        
      
        maxWidth: '800px',
      
        
        fontFamily: 'Arial, sans-serif',
      };
    
      const headingStyle = {
        fontSize: '24px',
        marginTop: '0',
        fontWeight: '800',
      };
      const divStyle={
        height: '200px',
        width: '100%',
        backgroundImage:`url(${banner})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        padding: 0,
        fontSize: "3rem",
        color: " #ffffff",
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom:20,
        textShadow: '4px 4px 4px rgba(0, 0, 0, 0.5)'
    }
    return(
   <>
  <div style={containerStyle}>
     <div style={divStyle}>
     
     </div>
      <h2 style={headingStyle}>Düşük Genleşmeli Sistemler</h2>
      <div style={{minHeight:1, backgroundColor:'red',marginBottom:10,marginTop:10}}></div>
      <p>Gelişen endüstri ile birlikte özellikle üretim proseslerimize sayılamayacak tipte, çok ve çeşitli kimyasal maddeler girmekte ve yaşanan yangın riskinin boyutu buna bağlı olarak artmaktadır. Su ile söndürmenin mümkün olmadığı durumlarda suya köpük konsantreleri eklenerek yangına müdahale edilmektedir.</p>
    <p style={{fontWeight:600}}>Düşük Genleşmeli Köpüklü Söndürme Sistemleri kullanım alanları:</p>
    <ul>
        <li>Kimya Fabrikaları</li>
        <li>Ham Petrol Depolama Alanları</li>
        <li>Akaryakıt Depolama Alanları</li>
        <li>Tank Çiftlikleri</li>
        <li>Spesifik Solvent, Alkol ve Hidrokarbon Depolama Alanları</li>
        <li>Boya Üretim ve Depolama Alanları</li>
        <li>Uçak Hangarları</li>
        <li>Helikopter Hangarları</li>
        <li>Raflı Depolama Alanları</li>
        <li>Katı Malzeme Depoları</li>
        <li>Jeneratör ve Motor Test Odaları</li>
        <li>Kablo ve Tesisat Galerileri</li>
        <li>Enerji Üretim Alanları</li>
        <li>Jeneratör Odaları</li>
        <li>Gemi Makine Daireleri ve Kargo Alanları</li>
        <li>Kazan Daireleri</li>
        <li>Yanıcı, Parlayıcı Ortamlarda Buhar Bastırma</li>
    </ul>
    <p>Bu tip sahalar Köpüklü Söndürme Sistemleri ile korunurken detaylı standart bilgisi, detaylı ürün bilgisi ve uygulama tecrübesi sistemin başarısı için kaçınılmaz gereklilikler olarak karşımıza çıkmaktadır. AKSAY Yangın SOLBERG iş birliği ile endüstriyel risklerin ortadan kaldırılması veya hasarlarının minimuma indirilmesi için çalışmaktadır.</p>
    <p>Köpüklü söndürme sistemleri otomatik veya manual dizayn edilebilir. Endüstriyel sahalarda risklerin boyutu, hızla yayılması, risk ortaya çıktıktan sonra kontrol sağlamanın zorluğu ve geç müdahale edilmesi nedeni ile yangının yayılma riski nedeniyle sistemler otomatik olarak dizayn edilmektedir. Bu amaçla Islak Borulu Köpüklü Sprinkler Sistemleri, Ön Tepkili (Pre-Action); Kilitlemesiz - Non Interlock, Tek Kilitlemeli - Single Interlock, Çift Kilitlemeli - Double Interlock Sistemler ve Baskın - Deluge Köpüklü Söndürme Sistemleri tercih edilmektedir.</p>
    <p>AKSAY kurulduğu günden bu yana Köpüklü Söndürme Sistemleri ile özdeşleşerek özellikle hızlı yanıcıların kullanıldığı petrokimya sektörünün köpüklü söndürme sistemleri ile korunumu konusunda çalışan sektörün lider firmalarından biridir. AKSAY kurulduğu günden bu yana yüzlerce Köpüklü Söndürme Sitemi tasarlamış ve sistemlerin bakım hizmetini de yürütmeye devam etmektedir.</p>
    <p>SOLBERG ürünleri ağır ortam şartlarında çalışabilir özellikte paslanmaz çelik ve bronzdan imal edilmişlerdir.</p>
    <p>Köpüklü Söndürme Sistemlerinin en önemli elemanlarından biri köpük merkezleridir. Köpük merkezleri uygulama yöntemine yani su ile konsantrenin karıştırılması esasına göre kendi içinde adlandırılmaktadır.</p>
    <ul>
        <li>Yatay veya Dikey Diyaframlı (Bladder) Tanklı Oranlama Sistemleri</li>
        <li>Hidrolik Motorlu Türbin Tip Atmosferik Tanklı Oranlama Sistemleri</li>
        <li>Dizel veya Elektrik Motorlu Pompalı ve Atmosferik Tanklı Oranlama Sistemleri</li>
        <li>Hat Tipi Oranlayıcı İçeren Atmosferik Tanklı Oranlama Sistemler</li>
    </ul>
    <p>Özellikle Diyaframlı (Bladder) Tank Tipi oranlama sistemleri dışardan enerji istemediği ve oranlama işlemi için hat basıncını kullanması nedeniyle sıklıkla tercih edilmektedir. Günümüzde özellikle üretim kalitesinin artması ile birlikte Hidrolik motorlu oranlama düzenekleri de ön plana çıkmaktadır. Hidrolik motorlu ve farklı enerji kaynakları ile beslenen atmosferik tanklı köpük oranlama düzenekleri konsantre kapasitesinin Diyaframlı Tank üretim aralığını geçtiği durumlarda tercih edilmektedir. Hat tipi oranlayıcılar belirlenen sabit basınç ve akış değerine üreticinin tesisinde kalibre edilerek tedarik edilmekte ancak basınç ve akış dengesi bozulduğunda sağlıklı oranlama yapamamakta, daha önemlisi UL Listeli veya FM sertifikalı üretimleri mevcut olmadığı için tercih edilmemektedir.</p>
    <p>Günümüzde sıklıkla kullanılan AFFF, AFFF AR, P, FP, FP AR, FFFP, FFFP AR gibi klasik köpük konsantrelerinin yanı sıra yeni nesil flor içermeyen "Re-Healing" veya "Flourine Free" "Çevreci Köpük Konsantreleri" karşımıza çıkmaktadır. Kullanılan ekipmana bağlı olarak genleşme oranları farklılık göstermektedir. Sıklıkla %1, %3, %6 veya Alkol türevi köpükler için 1x3, 3x3 veya 3x6 karışım oranları söz konusudur.</p>
    <p>Köpüklü Yangından korunum Sistemleri sıklıkla NFPA 11, NFPA 16, NFPA 30 veya EN 13565 standartları gereği tasarlanır.</p>
    <p>AKSAY Yangın SOLBERG işbirliği ile Köpüklü Söndürme Sistemlerinin Tasarımı, Mühendislik Hesapları, Tedarik Edilmesi, Montaj - Test ve Devreye Alma ve Kontratlı Bakım hizmetlerini yetişmiş Mühendis ve Teknisyen kadrosu ile gerçekleştirmektedir.</p>
    </div>






    </>
    )
}