import '../../../css/footer.css'
import banner from '../../../img/main/06-  ENDÜSTRİYEL KURU TOZLU SÖNDÜRME SİSTEMİ-YENİ/4.png' 
export default function Endüstriyel() {
    const containerStyle = {
        
      
        maxWidth: '800px',
       
        
        fontFamily: 'Arial, sans-serif',
      };
    
      const headingStyle = {
        fontSize: '24px',
        marginTop: '0',
        fontWeight: '800',
      };
      const divStyle={
        height: '200px',
        width: '100%',
        backgroundImage:`url(${banner})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        padding: 0,
        fontSize: "3rem",
        color: " #ffffff",
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom:20,
        textShadow: '4px 4px 4px rgba(0, 0, 0, 0.5)'
    }
    return (<>
  <div style={containerStyle}>
     <div  style={divStyle}>
      
     </div>
      <h2 style={headingStyle}>Endüstriyel Yangın Söndürme Sistemleri</h2>
      <div style={{minHeight:1, backgroundColor:'red',marginBottom:10,marginTop:10}}></div>
      <p>
      Endüstriyel sahalarda hacim söndürme ve lokal uygulama amaçlı kullanılabilen etkin bir söndürme sistemidir. ABC ve BC sınıfındaki yangınlar için son derece etkilidir. Sistem paket olarak söndürücü silindir/silindirler, tetikleme düzeneği ve boşaltma nozullarından oluşmaktadır. Diğer sistemlere nazaran daha etkindir.
      </p>
     <p>
     Sistemde söndürücü madde olarak özel üretilmiş Kuru Kimyevi Toz kullanılmaktadır.
     </p>
     <p>
     Hızlı alev kapatma ve yeniden tutuşmayı engellemesi nedeni ile tercih edilir.
     </p>
     <p style={{fontWeight:600}}>Sistemin Temel Özellikleri </p>
     <ul>
  <li>Hacimsel Uygulamalarda Etkin</li>
  <li>Lokal Uygulamalarda Etkin</li>
  <li>ABC veya BC Sınıfındaki Yangınlara Uygun</li>
  <li>Hızlı Alev Kapatma</li>
  <li>Yeniden Tutuşmayı Engelleme</li>
  <li>Düşük Maliyetli Yerinde Kurulum</li>
  <li>Çevreye Saygılı</li>
  <li>Dolum Maliyeti Düşük</li>
</ul>
<p style={{fontWeight:600}}>Uygulama Alanları </p>
<ul>
  <li>Yanıcı ve Parlayıcı Depoları</li>
  <li>Araç Boya Kabinleri</li>
  <li>Kazan Daireleri</li>
  <li>Jeneratör Odaları</li>
  <li>Akaryakıt İstasyonları</li>
</ul>
    </div>






    </>)
}