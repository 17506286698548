import divStyle from '../../../css/bannerCss';
import img from '../../../img/main/01- SULU SÖNDÜRME SİSTEMLERİ-YENİ/008-YANGIN SİSTEMİ VANALARI/Birleşmiş1.jpg'
export default function YangınVana() {
  const containerStyle = {

    maxWidth: '800px',

    fontFamily: 'Arial, sans-serif',
  };

  const headingStyle = {
    fontSize: '24px',
    marginTop: '0',
    fontWeight: '800',
  };


  return (<>
    <div style={containerStyle} >
      <div style={divStyle} >

      </div>
      <h2 style={headingStyle}>Yangın Sistemi Vanaları </h2>
      <div style={{ minHeight: 1, backgroundColor: 'red', marginBottom: 10, marginTop: 10 }}></div>
      <p>Yangın Korunum Sistemlerinin temel elemanları arasında yangın sistemi vanaları yer almaktadır. Aşağıda ihtiyaç duyabileceğiniz yangın sistemi vanalarının bir bölümü gösterilmektedir. Yangın sistemi vanalarının kullanım alanları hakkında sizi bilgilenmek ve tedarik edilmesi için lütfen bizimle irtibata geçiniz.</p>
      <ul>
        <li>İzlenebilir Kelebek Vanalar</li>
        <li>	Yükselen Milli  (OS&Y) vanalar</li>
        <li> 	Post Indikatörlü (NR&S) Vana ve Post Indikatör</li>


        <li>	Çek Vanalar</li>




        <li>	İzleme Anahtarlı Dişli Kelebek Vana</li>



        <li>	Basınç Düşürücü Vanalar </li>

        <li>	Basınç Tahliye Vanası</li>



        <li>	İtfaiye Bağlantı Vanası</li>



        <li>	İtfaiye Su Alma Vanası</li>

        <li>	Debimetre</li>



        <li>	Test & Drenaj Vanası</li>



        <li>	Akış Anahtarı (Flow Switc)</li>


        <li>	Basınç Anahtarı (Pressure Switch)</li>


        <li>	OS&Y Konum İzleme Anahtarı (Tamper Switch)</li>

      </ul>
      <img style={{width:800,height:800}} src={img}></img>
    </div>






  </>)
}