import '../../../css/footer.css'
import divStyle from '../../../css/bannerCss';
import banner from '../../../img/main/03- KÖPÜK KONSANTRELERİ-YENİ/KK-04  A SINIFI KÖPÜK KONSANTRELERİ/Başlık.jpg'
export default function Aclass() {
    const containerStyle = {
        
        maxWidth: '800px',
       
        fontFamily: 'Arial, sans-serif',
      };
    
      const headingStyle = {
        fontSize: '24px',
        marginTop: '0',
        fontWeight: '800',
      };
      const divStyle={
        height: '200px',
        width: '100%',
        backgroundImage:`url(${banner})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        padding: 0,
        fontSize: "3rem",
        color: " #ffffff",
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom:20,
        textShadow: '4px 4px 4px rgba(0, 0, 0, 0.5)'
    }
    return (<>
  <div style={containerStyle}>
     <div style={divStyle}>
      
     </div>
      <h2 style={headingStyle}> A Sınıfı Köpük Konsantreleri </h2>
      <div style={{minHeight:1, backgroundColor:'red',marginBottom:10,marginTop:10}}></div>
      <p>SOLBERG A Sınıfı Köpük Konsantreleri, %0.1 – 1 oranında su ile karıştırılmakta ve suyun yangınla mücadelede daha etkili olmasını sağlamak üzere özel olarak formüle edilmiştir. A Sınıfı köpükteki yüzey aktif maddeler suyun yüzey gerilimini önemli ölçüde azaltır ve havayla karıştırıldığında yakıtları kalın bir su tabakasıyla saran üstün bir köpük battaniyesi oluşturur. Bu, yakıt ile yangın arasında bir bariyer oluşturarak yangını tek başına sudan daha hızlı söndürür ve itfaiyecilerin uygulama alanlarını görmesine olanak tanır. Suyu daha etkili hale getirmek, yangını söndürmek için gereken su miktarını azaltır, su hasarını azaltır ve daha hızlı sökme ve daha az temizleme/bakım gereksinimleri sayesinde itfaiyeci güvenliğini artırır.</p>

<p style={{fontWeight:600}}>Özellikle yanabilen katı malzemelerin, orman vb. doğal alan yangınlarında, sıklıkla katı malzeme yangınlarının gerçekleştiği, ağaç, kağıt ve lastik endüstrilerinde kullanılır. A Sınıfı Köpük Faydaları:</p>

<ul>
    <li>Radyant ısıyı yansıtır ve oldukça görünürdür</li>
    <li>Yakıtları yalıtır ve oksijeni dışarıda bırakır</li>
    <li>Geniş yüzey alanı nedeniyle maksimum enerji emer</li>
    <li>Dikey yüzeylere tutunur ve su deposu görevi görür</li>
    <li>Düşük yüzey gerilimi nedeniyle suyun yakıtlara nüfuz etmesini sağlar</li>
    <li>CAFS –Basınçlı Hava Köpük Sistemlerinde kullanılır</li>
</ul>

    </div>






    </>)
}