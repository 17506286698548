import divStyle from '../../../css/bannerCss';

export default function MainYazıKopuk() {
    const containerStyle = {
      
      maxWidth: '800px',
      fontFamily: 'Arial, sans-serif',
    };
  
    const headingStyle = {
      fontSize: '24px',
      marginTop: '0',
      fontWeight: '800',
    };

    return (<>
      <div style={containerStyle}>
        <div style={divStyle}>
          
        </div>
        <h2 style={headingStyle}>Köpüklü Söndürme Sistemleri</h2>
        <div style={{ minHeight: 1, backgroundColor: 'red', marginBottom: 10, marginTop: 10 }}></div>
<p>Bilim ve teknoloji alanındaki gelişimin hızını üretim ve imalat alanlarında da yaşıyoruz. Özellikle kimya sektöründe nerede ise her gün yeni bir bileşik beraberindeki riskleri ile hayatımızın bir parçası olmaya devam ediyor. Bu durum beraberinde su ile zor söndürülebilen veya su ile söndürülemeyen yanıcı-parlayıcı kimyasallar için özel çözümler üretmenin gerekliliğini ortaya koymaktadır.
</p>
<p>
Su ile söndürülmesi mümkün olmayan bu tip kolay alevlenme özelliğine sahip yanıcı-parlayıcı sıvılar, büyük yangın riski oluşturmaktadır. Bu tip sıvıların bulundurulduğu, üretimde kullanıldığı veya depolandığı noktalarda uygun tasarlanmış köpüklü söndürme sistemleri ile mevcut riskleri ortadan kaldırabilir veya etkilerini en aza indirebiliriz. Tehlikenin doğru analizi ve çözümü için öncelikle yol haritasının doğru belirlenmesi gereklidir. Bu amaçla;

</p>
    <ul>
        <li>Tasarım Standardının Belirlenmesi</li>
        <li>Depolanan veya Kullanılan Yanıcı Parlayıcı Sıvıların Sınıfının Belirlenmesi</li>
        <li>Uygun Söndürme Sisteminin Seçimi</li>
        <li>Tasarım Yoğunluğunun Belirlenmesi</li>
        <li>Operasyon Alanının Belirlenmesi</li>
        <li>Uygulama Başlıklarının Belirlenmesi</li>
        <li>Uygulama Başlıkları için Minimum Çalışma Basıncının Belirlenmesi</li>
        <li>Uygulama Süresinin Belirlenmesi</li>
        <li>Köpük Konsantresinin Belirlenmesi</li>
        <li>Köpük Konsantresi Karışım Oranının Belirlenmesi</li>
        <li>Oranlama Sisteminin Belirlenmesi</li>
        <li>Köpük Konsantresi Depolanma Yönteminin Belirlenmesi</li>
    </ul>
    <p>
    benzeri temel ve zorunlu parametreleri doğru kullanmak sistemin efektif ve gerekli performansı gösterebilmesi için ön şart olarak karşımıza çıkar. Köpüklü Söndürme Sistemleri B sınıfı yanıcı ve parlayıcı sıvı yangınlarının yanı sıra A sınıfı katı malzeme yangınlarında da efektif kullanılabilmektedir. Bazen yangın veya yanma söz konusu değilken köpüklü söndürme sistemlerini yanıcı parlayıcı buharları bastırmak için etkili bir şekilde kullanırız.

    </p>
    <p>Köpüklü Söndürme Sistemleri kendi içinde genleşme oranlarına göre üçe ayrılmaktadır.</p>
    <ul>
        <li style={{marginBottom:10}}><strong>Düşük Genleşmeli Köpüklü Söndürme Sistemleri;</strong> depolama tankları, tanker dolum sahaları, köpüklü sprinkler sistemleri gibi yanıcı ve parlayıcı sıvı içeren üretim alanları, depolama alanları ve özel risklere uygulanabilir.</li>
        <li  style={{marginBottom:10}}><strong>Orta Genleşmeli Köpüklü Söndürme Sistemleri;</strong> Dike Alanlarının Korunması, Tanker Dolum Alanlarında Tanker Altı veya çeşitli endüstriyel alanlarda zemin kapatmak amaçlı kullanılmaktadır.</li>
        <li  style={{marginBottom:10}}><strong>Yüksek Genleşmeli Köpük Sistemleri; </strong>alanın köpük ile doldurulmasının istendiği, hava ile yangın temasının engellenmeye çalışıldığı ve özellikle buhar bastırma işlemine ihtiyaç duyulan yerlerde kullanılabilir.</li>
    </ul>
    <p>Endüstriyel sahalarınızın barındırdığı riskleri dikkate alarak uygun sistemi veya sistemleri seçip hayata geçirmek için yetkilendirilmiş Tasarım Mühendislerimiz çalışmaktadır. Bazen aynı tesiste farklı alanlarda her üç sistem tipi ile korunması gereken alanlarınız söz konusu olabilmektedir. AKSAY kurulduğu günden bu yana Köpüklü Söndürme Sistemleri ile özdeşleşerek özellikle hızlı yanıcıların kullanıldığı petrokimya sektörünün köpüklü söndürme sistemleri ile korunumu konusunda çalışan sektörün lider firmalarından biridir. AKSAY kurulduğu günden bu yana yüzlerce Köpüklü Söndürme Sitemi tasarlamış ve sistemlerin bakım hizmetini de yürütmeye devam etmektedir.</p>
    <p>AKSAY güçlü mühendislik birikimi ve alternatifli sistem seçenekleri ile hızlı ve güvenilir hizmet vermek için size bir telefon kadar yakındır. AKSAY yatırımlarınızı güvenle korur.</p>
    <p style={{fontWeight:600}}> Köpüklü Söndürme Sistemleri Kullanım Alanları</p>
    <ul>
        <li>Kimya Fabrikaları</li>
        <li>Ham Petrol Depolama Alanları</li>
        <li>Akaryakıt Depolama Alanları</li>
        <li>Tank Çiftlikleri</li>
        <li>Solvent, Alkol ve Hidrokarbon Depolama Alanları</li>
        <li>Boya üretim ve Depolama Alanları</li>
        <li>Katı Malzeme Depoları</li>
        <li>Raflı Depolama Alanları</li>
        <li>Jeneratör ve Motor Test Odaları</li>
        <li>Kablo ve Tesisat galerileri</li>
        <li>Enerji Üretim Alanları</li>
        <li>Jeneratör Odaları</li>
        <li>Uçak Hangarları</li>
        <li>Helikopter Hangarları</li>
        <li>Gemi Makine Daireleri ve Kargo Alanları</li>
        <li>Kazan Daireleri</li>
        <li>Yanıcı, Parlayıcı Ortamlarda Buhar Bastırma</li>
        <li>Endüstriyel Uygulamalar</li>
    </ul>
    <p>Günümüzde sıklıkla kullanılan <strong>AFFF</strong>, <strong>AFFF</strong>-<strong>AR</strong>, <strong>P</strong>, <strong>FP</strong>-<strong>AR</strong> , <strong>FFFP</strong>, <strong>FFFP</strong>- <strong>AR</strong>  gibi klasik köpük konsantrelerinin yanı sıra yeni nesil flor içermeyen <strong>“Re-Healing”</strong> veya <strong>“Flourine Free”</strong> ,<strong>“Çevre Dostu”</strong> konsantreler karşımıza çıkmaktadır. Kullanılan ekipmana bağlı olarak genleşme oranları farklılık göstermektedir. Sıklıkla %1, %3, %6 veya Alkol türevi köpükler için 1x3, 3x3 veya 3x6 karışım oranları söz konusudur.</p>
    <p>Günümüzde Klasik Köpük Konsantrelerinin yanı sıra yeni geliştirilen ve Çevre Dostu olan köpük konsantreleri üretim ve depolama tesislerinde yatırımcılar tarafından da öncelikli olarak tercih edilmekte ve yoğun olarak kullanılmaktadır. Çevre bilincinin artması ile “Yeni Nesil Çevreci Köpük Konsantreleri” kullanımı hızla yayılmaktadır. Özellikle su havzaları üzerinde kurulu kimya tesislerinde “Yeni Nesil Çevreci Köpük Konsantreleri” kullanımı artacaktır.</p>
    <p>Köpüklü Yangından korunum Sistemleri Sıklıkla NFPA 11, NFPA 16, NFPA 30 veya EN 13565 standartları gereği tasarlanır.</p>
    <p>AKSAY Yangın SOLBERG işbirliği ile Köpüklü Söndürme Sistemlerinin Tasarımı, Mühendislik Hesapları, Tedarik Edilmesi, Montaj - Test ve Devreye Alma ve Kontratlı Bakım hizmetlerini yetişmiş Mühendis ve Teknisyen kadrosu ile gerçekleştirmektedir.</p>
        
  
      </div>
  
  
  
  
  
  
    </>)
  }