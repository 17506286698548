import banner from '../../../img/main/02- KÖPÜKLÜ SÖNDÜRME SİSTEMİ-YENİ/K-04  ORTA GENLEŞMELİ KÖPÜKLÜ SÖNDÜRME SİSTEMLERİ/Başlık.png';
export default function Konsantre (){
    const containerStyle = {
       
      
        maxWidth: '800px',
      
        
        fontFamily: 'Arial, sans-serif',
      };
    
      const headingStyle = {
        fontSize: '24px',
        marginTop: '0',
        fontWeight: '800',
      };
      const divStyle={
        height: '200px',
        width: '100%',
        backgroundImage:`url(${banner})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        padding: 0,
        fontSize: "3rem",
        color: " #ffffff",
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom:20,
        textShadow: '4px 4px 4px rgba(0, 0, 0, 0.5)'
    }
  
    return(
   <>
  <div style={containerStyle}>
     <div style={divStyle} >
     
     </div>
      <h2 style={headingStyle}>Orta Genleşmeli Sistemler</h2>
      <div style={{minHeight:1, backgroundColor:'red',marginBottom:10,marginTop:10}}></div>
     <p>Endüstriyel sahalarda özellikle hızla zemin kapatmanın gerektiği, yatay ve düşey engellerin bulunduğu üretim ve depolama alanları ile hızla buhar bastırmanın gerekli olduğu alanlarda tercih edilerek kullanılmaktadır. Çoğu zaman üretim alanlarında Düşük Genleşmeli Köpüklü Söndürme Sistemleri ile birlikte kullanılmaktadır.</p>
     <p>Orta Genleşmeli Köpüklü Söndürme Sistemleri 20:1 - 200:1 arası genleşebilmektedir.</p>
     <p style={{fontWeight:600}}>Orta Genleşmeli Köpüklü Söndürme Sistemleri Kullanım Alanları </p>
     <ul>
        <li>Dike Alanlarının (Tank Havuzlarının) Korunması </li>
        <li>Tanker Dolum Alanlarında Tanker Altı Koruma</li>
        <li>Çeşitli Endüstriyel Üretim Alanlarında Zemin Kapatma </li>
        <li>Buhar Bastırma Amaçlı</li>
     </ul>

     <p>Günümüzde sıklıkla kullanılan AFFF, AFFF AR, P, FP, FP AR, FFFP, FFFP AR gibi  klasik köpük konsantrelerinin yanı sıra  yeni nesil flor içermeyen Re-Healing veya Flourine Free konsantreler karşımıza çıkmaktadır. Kullanılan ekipmana bağlı olarak genleşme oranları farklılık göstermektedir. Sıklıkla %1, %3, %6 veya Alkol türevi köpükler için 1x3, 3x3 veya 3x6 karışım oranları söz konusudur.
</p>
<p>Köpüklü Yangından korunum Sistemleri Sıklıkla NFPA 11, NFPA 16, NFPA 30 veya EN 13565 standartları gereği tasarlanır.
</p>
<p>AKSAY Yangın SOLBERG işbirliği ile Köpüklü Söndürme Sistemlerinin Tasarımı, Mühendislik Hesapları, Tedarik Edilmesi, Montaj -Test ve Devreye Alma ve Kontratlı Bakım hizmetlerini yetişmiş Mühendis  ve Teknisyen kadrosu ile gerçekleştirmektedir.  
</p>
    </div>






    </>
    )
}