import banner from '../../../img/main/01- SULU SÖNDÜRME SİSTEMLERİ-YENİ/004-BASKIN SİSTEMLER/Başlık.png'
import '../../../css/footer.css'
export default function Deluge() {
    const containerStyle = {
        
        
        maxWidth: '800px',
       
        
        fontFamily: 'Arial, sans-serif',
      };
    
      const headingStyle = {
        fontSize: '24px',
        marginTop: '0',
        fontWeight: '800',
      };

      const divStyle={
        height: '200px',
        width: '100%',
        backgroundImage:`url(${banner})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        padding: 0,
        fontSize: "3rem",
        color: " #ffffff",
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom:20,
        textShadow: '4px 4px 4px rgba(0, 0, 0, 0.5)'
    }
    
    return (<>
  <div style={containerStyle}>
  <div style={divStyle}>
      
      </div>
      <h2 style={headingStyle}>Baskın (Deluge) Sistemler   </h2>
      <div style={{minHeight:1, backgroundColor:'red',marginBottom:10,marginTop:10}}></div>
      <p>
        Baskın (Deluge) Sistem, yangın yayılımının yüksek ve hızlı olmasının beklendiği, suyun yangının meydana gelip yayılacağı tüm alana gecikmesiz uygulanmasının istendiği durumlarda kullanılır. Boru tesisatına açık tip sprinkler veya nozullar bağlanır. Borular su veya hava ile basınçlandırılmaz, Baskın (Deluge) Sistem vanasından sonra sistem atmosfere açıktır.
    </p>

    <p>
        Baskın alarm vanası uygun otomatik algılama sistemi ile devreye girer. Bunlar hidrolik, pneumatik, elektriksel veya belirtilen yöntemlerden oluşan karma yöntemler kullanılabilir. Acil durumlarda baskın alarm vanasını devreye sokmak için en az bir adet elle boşaltma istasyonu teçhiz edilmelidir.
    </p>

    <p>
        Baskın sprinkler sistemi ile korunan tüm oda ve bölmelere otomatik algılama sistemi yapılmalıdır. Baskın (Deluge) sistemlerde algılama elektriksel planlanabileceği gibi, hidrolik aktivasyon veya pnematik aktivasyonlu seçilebilir. Bu durumda Deluge (Baskın) vananın pneumatik veya hidrolik aktivasyona uygun seçilmiş olması gereklidir.
    </p>

    <p>
        Inbal baskın (Deluge) Vanaları aktivasyon şekline göre kendi içinde kategorilere ayrılır:
    </p>

    <ul>
        <li>Pnömatik Aktivasyonlu Baskın (Deluge) Vana</li>
        <li>Hidrolik Aktivasyonlu Baskın (Deluge) Vana</li>
        <li>Elektrik Aktivasyonlu Baskın (Deluge) Vana</li>
        <li>Pnö-Elektrik Aktivasyonlu Baskın (Deluge) Vana</li>
    </ul>

    <p>
        Deluge vanalar lokal resetlenebildikleri gibi uzaktan resetlenme opsiyonları da mevcuttur. Korunan riskinizin durumuna, Deluge (Baskın) vananın bulunduğu konuma erişim, deluge vananın bulunduğu alanın sahip olduğu riskler nedeni ile uzaktan resetleyip tekrar kurma opsiyonu önem kazanmaktadır.
    </p>

    <p>
        <strong>Uygulama Alanları:</strong>
    </p>

    <ul>
        <li>Ham Petrol Depolama Alanları</li>
        <li>Akaryakıt Depolama Alanları</li>
        <li>Solvent, Alkol ve Hidrokarbon Depolama Alanları</li>
        <li>Dike (Tank Havuzu) Alanları</li>
        <li>Tanker Dolum Alanları</li>
        <li>Kimya Tesisleri</li>
        <li>Trafo, Jeneratör ve Motor Test Odaları</li>
        <li>Kablo ve Tesisat galerileri</li>
        <li>Enerji Üretim Alanları</li>
        <li>Endüstriyel Uygulamalar</li>
    </ul>

    <p>
        Hızlı müdahale gerektiren ve ani ısı yükselmesi nedeni ile oluşabilecek termal hasarların önlenmesinin gerektiği diğer alanlarda da kullanılır.
    </p>

    <p>
        Baskın sistemlerde; özel mühendislik yaklaşımları ile sprinkler sistemlerinden ayrı tasarım yapılması sebebiyle verilen kurallar baskın sistem tasarımlarında uygulanmaz. AKSAY Yangın Inbal işbirliği ile Baskın (Deluge) Sistemlerinin Tasarımı, Mühendislik Hesapları, Tedarik Edilmesi, Montaj -Test -Devreye Alma ve Kontratlı Bakım hizmetlerini yetişmiş Mühendis ve Teknisyen kadrosu ile gerçekleştirmektedir.
    </p>
      
    </div>






    </>)
}