import React from 'react';
import '../css/footer.css';

const PdfLinkSertifika = ({ pdfFileName, name }) => {
  const pdfUrl = `${process.env.PUBLIC_URL}/pdf/sertifika/${pdfFileName}.pdf`;

  const handleDownload = async () => {
    try {
      console.log(pdfUrl)
      const response = await fetch(pdfUrl);
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `${pdfFileName}.pdf`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    } catch (error) {
      console.error('PDF indirme hatası:', error);
    }
  };

  return (
    <ul>
    <li style={{ }} className="pdf-download-button" onClick={handleDownload}>
      {name} <span style={{color:'blue'}}> ⬇️</span>
    </li>
    </ul>
  );
};

export default PdfLinkSertifika;