import '../../../css/footer.css'

import banner from '../../../img/main/01- SULU SÖNDÜRME SİSTEMLERİ-YENİ/006-SPRİNKLER BAŞLIKLARI/Başlık.png'
import img from '../../../img/main/01- SULU SÖNDÜRME SİSTEMLERİ-YENİ/006-SPRİNKLER BAŞLIKLARI/birlesmis.png'
export default function Deluge() {
    const containerStyle = {
       
      
        maxWidth: '800px',
       
        
        fontFamily: 'Arial, sans-serif',
      };
    
      const headingStyle = {
        fontSize: '24px',
        marginTop: '0',
        fontWeight: '800',
      };
  
      const divStyle={
        height: '200px',
        width: '100%',
        backgroundImage:`url(${banner})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        padding: 0,
        fontSize: "3rem",
        color: " #ffffff",
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom:20,
        textShadow: '4px 4px 4px rgba(0, 0, 0, 0.5)'
    }
    
    return (<>
  <div style={containerStyle}>
  <div style={divStyle}>
      
      </div>
      <h2 style={headingStyle}>Sprinkler Başlıkları </h2>
      <div style={{minHeight:1, backgroundColor:'red',marginBottom:10,marginTop:10}}></div>
      <p><strong>Sprinkler başlıkları</strong></p>
  <ul>
    <li>Dik Tip - Upright Sprinkler</li>
    <li>Sarkık Tip - Pendent Sprinkler</li>
    <li>Konvansiyonel Sprinkler</li>
    <li>Sarkık Gömme Tip - Recessed Pendent Sprinkler</li>
    <li>Duvar Tipi Yatay - Horizontal Sidewall Sprinkler</li>
    <li>Duvar Tipi Dikey - Vertical Sidewall Sprinkler</li>
    <li>Gizli Tip - Concealed Sprinkler</li>
    <li>ELO Tip Sprinkler</li>
    <li>ESFR Tip Sprinkler</li>
    <li>Kuru Tip - Dry Sprinkler</li>
    <li>Sprinkler Aksesuarları</li>
  </ul>

  <p>Binanın kullanım amacı ve içerdiği risk dikkate alınarak işletmelerde yukarıda belirtilen sprinkler başlıklarından biri veya bir kaçı tercih edilerek kullanılmaktadır. Sprinkler başlıklarının ayrıca “Standart Tepkili, Hızlı Tepkili ve Geniş Koruma Etkili” seçenekleri mevcuttur.</p>

  <p>Bir sprinkler başlığının koruma alanı belirlenirken sıklıkla hata yapılmaktadır. Sprinkler koruma alanı, korunan mahalin sahip olduğu tehlike yani risk sınıfı ve kullanılan tasarım standardının gereklerine göre belirlenir.</p>

  <p>Kullanımınıza sunduğumuz ürünlerimiz <strong>UL/ULC Listeli ve FM onaylıdır.</strong> Onay bilgileri, teknik özellikler ve sipariş bilgileri için lütfen bizimle <a href="mailto:iletisim@example.com">irtibata geçiniz</a>.</p>

  <p>AKSAY Yangın RELIABLE işbirliği ile Sprinkler Sistemlerinin Tasarımı, Mühendislik Hesapları, Tedarik Edilmesi, Montaj-Test ve Devreye Alma, ve Kontratlı Bakım hizmetlerini yetişmiş Mühendis ve Teknisyen kadrosu ile gerçekleştirmektedir.</p>
   <img style= {{ width:800,height:800}}src= {img}></img>
  


</div>
    </>)
}