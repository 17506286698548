import divStyle from '../../../css/bannerCss';
import banner from '../../../img/main/02- KÖPÜKLÜ SÖNDÜRME SİSTEMİ-YENİ/K-02  KÖPÜK NEDİR/Başlık.jpg'
export default function Kopuklu() {
    const containerStyle = {
        
      
        maxWidth: '800px',
    
        
        fontFamily: 'Arial, sans-serif',
      };
    
      const headingStyle = {
        fontSize: '24px',
        marginTop: '0',
        fontWeight: '800',
      };
    
      const subHeadingStyle = {
        fontWeight: '800',
        marginTop: '20px',
      };
  
      const listStyle = {
        paddingLeft: '20px',
      };
      const divStyle={
        height: '200px',
        width: '100%',
        backgroundImage:`url(${banner})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        padding: 0,
        fontSize: "3rem",
        color: " #ffffff",
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom:20,
        textShadow: '4px 4px 4px rgba(0, 0, 0, 0.5)'
    }
    return (<>
  <div style={containerStyle}>
     <div style={divStyle} >
      
     </div>
      <h2 style={headingStyle}>Köpük Nedir?</h2>
      <div style={{minHeight:1, backgroundColor:'red',marginBottom:10,marginTop:10}}></div>
      <p>Yangın söndürme köpüğü; köpük konsantresinin su ve hava ile basınç altında oluşturduğu kabarcıklar topluluğudur. Yangın köpükleri genleşme oranlarına göre üç sınıfa ayrılır.</p>
    <ul>
        <li><strong>Düşük Genleşmeli Köpüklü Sistemler: 20:1 'e kadar </strong></li>
        <li><strong>Orta Genleşmeli Köpüklü Sistemler: 20:1 - 200:1 arası </strong></li>
        <li><strong>Yüksek Genleşmeli Köpüklü Sistemler: 200:1 'den yüksek </strong></li>
    </ul>
    <p>Köpüklü Söndürme Sistemleri köpük kabarcıklarını sıvı yüzeyine boşaltır. Böylece yapı içine hapsedilmiş su etkisi ile soğutma ve yanıcı sıvı yüzeyi üzerinde yoğunlaşmış battaniye (tabaka) oluşturarak hava ile teması keser ve yangını söndürür. </p>
    <p>  Genleşme oranlarına göre sınıflandırdığımız Köpüklü Söndürme Sistemleri kullanım alanları;</p>
    <ul>
        <li> <strong>Düşük Genleşmeli Köpüklü Söndürme Sistemleri;</strong> depolama tankları, tanker dolum sahaları, köpüklü sprinkler sistemleri gibi yanıcı ve parlayıcı sıvı içeren üretim alanları, depolama alanları ve özel risklere uygulanabilir.</li>
        <li> <strong>Orta Genleşmeli Köpüklü Söndürme Sistemleri;</strong> Dike Alanlarının Korunması, Tanker Dolum Alanlarında Tanker Altı veya çeşitli endüstriyel alanlarda zemin kapatmak amaçlı kullanılmaktadır.</li>
        <li> <strong>Yüksek Genleşmeli Köpük Sistemleri;</strong> alanın köpük ile doldurulmasının istendiği, hava ile yangın temasının engellenmeye çalışıldığı ve özellikle buhar bastırma işlemine ihtiyaç duyulan yerlerde kullanılabilir.</li>
    </ul>
    <p>Sağlıklı uygulama yapabilmek için su - köpük karışımın son derece sağlıklı ve minimum - maksimum su-konsantre karışım sınırlarına sadık kalınarak solüsyonu oluşturmak ve doğru oranda hava ile buluşturmak gereklidir. Bu temel kuralları dikkate alarak son derece iyi kalitede yapılmış köpükler;</p>
    <ul>
        <li>Sağlam</li>
        <li>Yapışkan</li>
        <li>Yangını Hızlı Kilitleme</li>
        <li>Isıya Dayanıklı</li>
        <li>Buhar Tutma</li>
        <li>Yapıdaki Suyu Drenaj Zamanı Uzun</li>
        <li>Yeniden Parlamanın Engellenmesi</li>
        <li>Yangını Etkili Biçimde Söndürme</li>
    </ul>
    <p>gibi özellikleri taşımalıdır. Sistemin istenen performansı sağlaması için yukarıda sıralanan gerekliliklerin çok büyük bir bölümünün veya tamamının sağlanması gereklidir.</p>
    <p>Günümüzde sıklıkla kullanılan AFFF, AFFF AR, P, FP, FP AR, FFFP, FFFP AR gibi klasik köpük konsantrelerinin yanı sıra yeni nesil flor içermeyen “Re-Healing” veya “Flourine Free” “Çevre Dostu” konsantreler karşımıza çıkmaktadır. Kullanılan ekipmana bağlı olarak genleşme oranları farklılık göstermektedir. Sıklıkla %1, %3, %6 veya Alkol türevi köpükler için 1x3, 3x3 veya 3x6 karışım oranları söz konusudur.</p>
    <p>Günümüzde Klasik Köpük Konsantrelerinin yanı sıra yeni geliştirilen ve Çevre Dostu olan köpük konsantreleri üretim ve depolama tesislerinde yatırımcılar tarafından da öncelikli olarak tercih edilmekte ve yoğun olarak kullanılmaktadır. Çevre bilincinin artması ile “Yeni Nesil Çevreci Köpük Konsantreleri” kullanımı hızla yayılmaktadır. Özellikle su havzaları üzerinde kurulu kimya tesislerinde “Yeni Nesil Çevreci Köpük Konsantreleri” kullanımı artacaktır.</p>
    <p>Köpüklü Yangından korunum Sistemleri Sıklıkla NFPA 11, NFPA 16, NFPA 30 veya EN 13565 standartları gereği tasarlanır.</p>
    </div>






    </>)
}