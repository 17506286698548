import { Col, Container, Row } from "react-bootstrap";

export default function Dispiritör() {
  const images = [
    { id: 1, src: require('../../img/Distribütörlükler/1.jpg') },
    { id: 2, src: require('../../img/Distribütörlükler/2.jpg') },
    { id: 3, src: require('../../img/Distribütörlükler/3.jpg') },
    { id: 4, src: require('../../img/Distribütörlükler/4.jpg') },
    { id: 5, src: require('../../img/Distribütörlükler/5.jpg') },
    { id: 6, src: require('../../img/Distribütörlükler/6.jpg') },
    { id: 7, src: require('../../img/Distribütörlükler/7.png') },
    { id: 8, src: require('../../img/Distribütörlükler/8.png') },
    { id: 9, src: require('../../img/Distribütörlükler/9.jpg') },
    { id: 10, src: require('../../img/Distribütörlükler/10.jpg') },
    { id: 11, src: require('../../img/Distribütörlükler/11.jpg') },
    { id: 12, src: require('../../img/Distribütörlükler/12.jpg') },
    { id: 13, src: require('../../img/Distribütörlükler/13.jpg') },
    { id: 14, src: require('../../img/Distribütörlükler/14.jpg') },
    { id: 15, src: require('../../img/Distribütörlükler/15.jpg') },
    { id: 16, src: require('../../img/Distribütörlükler/16.png') },

  ];

    const divStyle = {
      height: '100px',
      width: '100%',
      background: ' rgb(0,55,105)',
      background: '   linear-gradient(90deg, rgba(0,55,105,1) 0%, rgba(0,130,226,1) 100%)',
      paddingTop:10,
      fontSize: "3rem",
      color:" #ffffff",
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      textShadow: '4px 4px 4px rgba(0, 0, 0, 0.5)'
        
      };
return(<>
<div style={divStyle}>
    <p>Distribütorlüklerimiz</p>
</div>
<Container style={{marginBottom:20}}>
<Row>
            {images.map(image => (
        <Col key={image.id} xs={6} md={4} lg={3}>
          <div className="d-flex justify-content-center">
            <div style={{height:200}}><img style={{maxWidth:200}}  src={image.src} alt="" /></div>
          </div>
        </Col>
      ))}
               

            </Row>
</Container>

</>)
    
    }