import '../../../css/footer.css'
import divStyle from '../../../css/bannerCss';
import banner from '../../../img/main/03- KÖPÜK KONSANTRELERİ-YENİ/KK-05  B BINIFI KÖPÜK KONSANTRELERİ/Başlık.jpg'
export default function Bclass() {
    const containerStyle = {
      
        maxWidth: '800px',
       
        fontFamily: 'Arial, sans-serif',
    };

    const headingStyle = {
        fontSize: '24px',
        marginTop: '0',
        fontWeight: '800',
    };
    const divStyle={
        height: '200px',
        width: '100%',
        backgroundImage:`url(${banner})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        padding: 0,
        fontSize: "3rem",
        color: " #ffffff",
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom:20,
        textShadow: '4px 4px 4px rgba(0, 0, 0, 0.5)'
    }
    return (<>
        <div style={containerStyle}>
            <div style={divStyle}>

            </div>
            <h2 style={headingStyle}>B Sınıfı Köpük Konsantreleri </h2>
            <div style={{ minHeight: 1, backgroundColor: 'red', marginBottom: 10, marginTop: 10 }}></div>
            <p>B Sınıfı Yakıtlar yani yanıcı ve parlayıcı sıvılar, hızlı söndürme için tasarlanmış köpüklere ve yeniden tutuşmayı önlemek için güvenli bir köpük örtüsüne ihtiyaç duyar. Yangının köpük battaniyesinin zarar gördüğü alanlardan yayılmasını en aza indirmek için köpük battaniyesinin kendini hızla tamamlaması ve iyi bir geri yanma direncine sahip olması önemlidir. Köpük battaniyenin bütünlüğü (film veya polimerik tabaka) genellikle düşen döküntüler veya itfaiyecinin müdahale esnasındaki hareketi ve ardından yangın hortumunun ve/veya ekipmanın köpük battaniyesinin içinden sürüklenmesi nedeniyle tehlikeye girer. Film yapıcı köpükler bu anlamda yakıt yüzeyinde oluşturdukları film tabakası ile (örtü) kendini tamamlamaya ve kırılan film tabakasının onarılmasına çalışır. Özellikle AR Alkole dirençli köpükler kullandığınızda bu tip kırılmalarda köpük konsantresinin oluşturduğu polimerik tabaka daha etkin ve kırılmaya müteakip çok daha hızlı yeniden oluşturup yakıt yüzeyini kapatma eğilimi gösterir.</p>

            <p>Uygulamanız için doğru ürünü seçmek önemlidir. B Sınıfı yangın söndürme köpükleri genellikle kimya ve petrokimya tesislerinde, rafinerilerde, petrol ve gaz depolama tesislerinde, yanıcı sıvı depolama tesislerinde, havaalanlarında ve yanıcı sıvıların ambalaj içinde depolandığı veya kullanıldığı her yerde kullanılır.</p>

            <p>SOLBERG ailesi olarak dünyanın en geniş ve teknolojik olarak en gelişmiş B Sınıfı yangın söndürme köpük konsantreleri portföylerinden <strong>“Florsuz, Çevreci"</strong> <strong>“Re-Healing™” </strong>ve AFFF ile aynı özelliklere sahip köpük konsantrelerinin yanı sıra “Florsuz AFFF – AR ile aynı özelliklere sahip köpük konsantrelerini sunuyoruz. Flor içermeyen ürün grubumuz, hidrokarbon yakıtlar için köpük konsantreleri, polar solvent yakıt yangınları için alkole dirençli konsantreler, eğitim köpüğü, özel köpükler ve A Sınıfı köpük konsantrelerinin eksiksiz bir serisini içerir. Flor içermeyen köpüklerimiz, Per veya Polifloroalkil maddeleri gibi kalıcı kimyasalları yapısında bulundurmayan ham maddelerden imal edilimektedir.</p>

            <p>Flor içermeyen çevreci köpük konsantreleri, yakıt yüzeyini kapatarak buhar salınımını bastırır ve yangını söndürmek için yakıt ile havayı ayırır. Alkol Tipi Konsantreler, polar solventin köpük kabarcıklarından su çekerek battaniyeye zarar vermesini önleyen katkı maddeleri ile özel olarak tasarlanmıştır.</p>

        </div>






    </>)
}