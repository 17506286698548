import React from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import '../App.css'
import NavDropdown from 'react-bootstrap/NavDropdown';
import logo from '../img/logo/logo.png'
import { Col, Row } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
function Navbars() {
  const location = useLocation(); // Get the current location
  const navigate = useNavigate();
  // Define a function to check if a given path matches the current URL
  const isCurrentPage = (path) => {
    return location.pathname === path;
  };
  const handleNavigation = (path) => {
    navigate(path);
  };
  return (
    <div sticky="top" className="container-fluid" style={{ backgroundColor: 'white', position: 'sticky', top: 0, zIndex: 100 }}>
      <div style={{ backgroundColor: 'white', paddingTop: 20 }} className='container-fluid'>
        <div className='container'>
          <img src={logo} alt="logo" style={{ minWidth: 100, maxWidth: 250, maxHeight: 150, margin: 0, paddingLeft: '0.5rem' }} />
          <Navbar bg="transparent" expand="md" variant="dark" sticky="top">
            <Navbar.Toggle style={{ backgroundColor: 'gray' }} color='white' aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="mr-auto">
                <Nav.Link className='hoverNav' style={{ color: isCurrentPage('/') ? 'red' : 'black' }} href="/">Anasayfa</Nav.Link>
                <NavDropdown className='hoverNav' renderMenuOnMount={true} title={<span style={{ color: 'black', marginLeft: 15 }}>Kurumsal</span>}  >
                  <div style={{ width: 550 }}>
                    <NavDropdown.Item className='hoverNav' style={{ color: 'black' }} onClick={() => handleNavigation('/sirketprofili')}>Şirket Profili</NavDropdown.Item>
                    <NavDropdown.Item className='hoverNav' style={{ color: 'black' }} onClick={() => handleNavigation('/misyonvevizyon')}>Misyon & Vizyon</NavDropdown.Item>
                    <NavDropdown.Item className='hoverNav' style={{ color: 'black' }} onClick={() => handleNavigation('/distribütörlüklerimiz')}>Distribütörlüklerimiz</NavDropdown.Item>
                    <NavDropdown.Item className='hoverNav' style={{ color: 'black' }} onClick={() => handleNavigation('/sertifikavebelgeler')}>Sertifika & Belgeler</NavDropdown.Item>
                  </div>
                </NavDropdown>

                <NavDropdown className='hoverNav' renderMenuOnMount={true} title={<span style={{ color: 'black', marginLeft: 15 }}>Sistemler</span>}  >
                  <div className='navbarDropdown'  >
                    <Row>
                      <Col xs={12} md={4} style={{ padding: 20 }}>
                      <a  style={{ color: 'black', padding: 5 }} >Söndürme Sistemleri</a>
                        <NavDropdown.Item className='hoverNav' style={{ color: '#626569', padding: 5, fontSize: 13 }} onClick={() => handleNavigation('/system/SuluSöndürmeSistemleri')}>Sulu Söndürme Sistemleri</NavDropdown.Item>
                        <NavDropdown.Item className='hoverNav' style={{ color: '#626569', padding: 5, fontSize: 13 }} onClick={() => handleNavigation('/system/SuSisi(WaterMist)Sistemleri')}>Su Sisi (Water Mist) Sistemleri </NavDropdown.Item>
                        <NavDropdown.Item className='hoverNav' style={{ color: '#626569', padding: 5, fontSize: 13 }} onClick={() => handleNavigation('/system/KöpüklüSöndürmeSistemleri')}>Köpüklü Söndürme Sistemleri</NavDropdown.Item>
                        <NavDropdown.Item className='hoverNav' style={{ color: '#626569', padding: 5, fontSize: 13 }} onClick={() => handleNavigation('/system/KöpükKonsantreleri')}>Köpük Konsantreleri</NavDropdown.Item>
                        <NavDropdown.Item className='hoverNav' style={{ color: '#626569', padding: 5, fontSize: 13 }} onClick={() => handleNavigation('/system/GazlıSöndürmeSistemleri')}>Gazlı Söndürme Sistemleri</NavDropdown.Item>
                        <NavDropdown.Item className='hoverNav' style={{ color: '#626569', padding: 5, fontSize: 13 }} onClick={() => handleNavigation('/system/KurutozluSöndürmeSistemleri')}>Kurutozlu Söndürme Sistemleri</NavDropdown.Item>
                        <NavDropdown.Item className='hoverNav' style={{ color: '#626569', padding: 5, fontSize: 13 }} onClick={() => handleNavigation('/system/MutfakDavlumbazSöndürmeSistemleri')}>Mutfak Davlumbaz Söndürme Sistemi</NavDropdown.Item>
                        <NavDropdown.Item className='hoverNav' style={{ color: '#626569', padding: 5, fontSize: 13 }} onClick={() => handleNavigation('/system/YangınDolapları')}>Yangın Dolapları</NavDropdown.Item>
                        <NavDropdown.Item className='hoverNav' style={{ color: '#626569', padding: 5, fontSize: 13 }} onClick={() => handleNavigation('/system/YangınPompaları')}>Yangın Pompaları</NavDropdown.Item>
                        <NavDropdown.Item className='hoverNav' style={{ color: '#626569', padding: 5, fontSize: 13 }} onClick={() => handleNavigation('/system/SuveKöpükMonitörleri')}>Su ve Köpük Monitörleri</NavDropdown.Item>
                      </Col>
                      <Col xs={12} md={4} style={{ padding: 20 }}>
                      <a  style={{ color: 'black', padding: 5 }} >Yangın  Algılama ve İhbar Sistemleri</a>
                        <NavDropdown.Item className='hoverNav' style={{ color: '#626569', padding: 5, fontSize: 13 }} onClick={() => handleNavigation('/system/YangınAlgılamaVeİhbarSistemleri')}>Yangın Algılama ve İhbar Sistemleri</NavDropdown.Item>
                        <NavDropdown.Item className='hoverNav' style={{ color: '#626569', padding: 5, fontSize: 13 }} onClick={() => handleNavigation('/system/HavaÖrneklemeliAlgılama')}>Hava Örneklemeli Algılama</NavDropdown.Item>
                        <NavDropdown.Item className='hoverNav' style={{ color: '#626569', padding: 5, fontSize: 13 }} onClick={() => handleNavigation('/system/LineerSıcaklıkAlgılama')}>Lineer Sıcaklık Algılama</NavDropdown.Item>
                        <NavDropdown.Item className='hoverNav' style={{ color: '#626569', padding: 5, fontSize: 13 }} onClick={() => handleNavigation('/system/Yanıcı-ParlayıcıGazAlgılamaSistemi')}>Yanıcı-Parlayıcı Gaz Algılama</NavDropdown.Item>
                        <NavDropdown.Item className='hoverNav' style={{ color: '#626569', padding: 5, fontSize: 13 }} onClick={() => handleNavigation('/system/AlevDedektörleri')}>Alev Dedektörleri</NavDropdown.Item>
                        <NavDropdown.Item className='hoverNav' style={{ color: '#626569', padding: 5, fontSize: 13 }} onClick={() => handleNavigation('/system/SöndürmeKontrolElemanları')}>Söndürme Kontrol Elemanları</NavDropdown.Item>
                     
                      </Col>
                      <Col xs={12} md={4} style={{ padding: 20 }}>
                        <a  style={{ color: 'black', padding: 5 }} >Spesifik Yangından Korunum Sistemleri </a>
                        <NavDropdown.Item className='hoverNav' style={{ color: '#626569', padding: 5, fontSize: 13 }} onClick={() => handleNavigation('/system/FireDetecSöndürmeSistemleri')}>Fire Detec™ Söndürme Sistemleri</NavDropdown.Item>
                        <NavDropdown.Item className='hoverNav' style={{ color: '#626569', padding: 5, fontSize: 13 }} onClick={() => handleNavigation('/system/AerosolSöndürme')}>Aerosol Söndürme Sistemleri</NavDropdown.Item>
                        <NavDropdown.Item className='hoverNav' style={{ color: '#626569', padding: 5, fontSize: 13 }} onClick={() => handleNavigation('/system/KıvılcımSöndürme')}>Kıvılcım Söndürme Sistemleri</NavDropdown.Item>

                        <NavDropdown.Item className='hoverNav' style={{ color: '#626569', padding: 5, fontSize: 13 }} onClick={() => handleNavigation('/system/PasifYangınDurdurucular')}>Pasif Yangın Durdurucular</NavDropdown.Item>
                        <NavDropdown.Item className='hoverNav' style={{ color: '#626569', padding: 5, fontSize: 13 }} onClick={() => handleNavigation('/system/DoğalDumanVeIsıTahliyeSistemleri')}>Doğal Duman Ve Isı Tahliye Sistemleri</NavDropdown.Item>
                        <NavDropdown.Item className='hoverNav' style={{ color: '#626569', padding: 5, fontSize: 13 }} onClick={() => handleNavigation('/system/SuBaskınDetektörü')}>Su Baskın Detektörleri</NavDropdown.Item>
                      </Col>
                      <Col xs={12} md={4} style={{ padding: 20 }}>
                        <a  style={{ color: 'black', padding: 5 }} >Sistem Bakım ve Onarım Hizmetleri</a>
                        <NavDropdown.Item className='hoverNav' style={{ color: '#626569', padding: 5, fontSize: 13 }} onClick={() => handleNavigation('/system/SistemBakımveOnarımHizmetleri')}>Periyodik Kontrol-Bakım-Onarım ve  Uygunluk Denetimi</NavDropdown.Item>


                      </Col>
                    </Row>
                  </div>
                </NavDropdown>
                <Nav.Link className='hoverNav' style={{ color: isCurrentPage('/brosürler') ? 'red' : 'black', marginLeft: 15 }} onClick={() => handleNavigation('/hizmetlerimiz')}>Hizmetlerimiz</Nav.Link>
                <Nav.Link className='hoverNav' style={{ color: isCurrentPage('/brosürler') ? 'red' : 'black', marginLeft: 15 }} onClick={() => handleNavigation('/sartnameler')}>Şartnameler</Nav.Link>
                <Nav.Link className='hoverNav' style={{ color: isCurrentPage('/brosürler') ? 'red' : 'black', marginLeft: 15 }} onClick={() => handleNavigation('/uygulamalarımız')}>Uygulamalarımız</Nav.Link>
                <Nav.Link className='hoverNav' style={{ color: isCurrentPage('/brosürler') ? 'red' : 'black', marginLeft: 15 }} onClick={() => handleNavigation('/referans')}>Referanslarımız</Nav.Link>
                <Nav.Link className='hoverNav' style={{ color: isCurrentPage('/brosürler') ? 'red' : 'black', marginLeft: 15 }} onClick={() => handleNavigation('/brosür')}>Broşürlerimiz</Nav.Link>
             
                
                <Nav.Link
                  style={{ color: isCurrentPage('/contact') ? 'red' : 'black' }}
                  onClick={() => navigate('/contact')}
                >
                  İletişim
                </Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Navbar>
        </div>
        <div className="container" style={{ backgroundColor: 'white' }}>


        </div>
      </div>
    </div>
  )
}
export default Navbars