import banner from '../../../img/main/013-ALGILAMA SİSTEMLERİ/002- HAVA ÖRNEKLEMELİ SİSTEMLER/Başlık.png'
export default function Hava() {
    const containerStyle = {
  
        maxWidth: '800px',
      
        fontFamily: 'Arial, sans-serif',
      };
    
      const headingStyle = {
        fontSize: '24px',
        marginTop: '0',
        fontWeight: '800',
      };
      const divStyle={
        height: '200px',
        width: '100%',
        backgroundImage:`url(${banner})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        padding: 0,
        fontSize: "3rem",
        color: " #ffffff",
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom:20,
        textShadow: '4px 4px 4px rgba(0, 0, 0, 0.5)'
    }

    return (<>
  <div style={containerStyle} >
  <div style={divStyle}>
   
    </div>
      <h2 style={headingStyle}>Hava Örneklemeli Sistemler</h2>
      <div style={{minHeight:1, backgroundColor:'red',marginBottom:10,marginTop:10}}></div>

<p>Hava örneklemeli yangın alarm sistemleri, korumak istenen bölgedeki havayı bir boru ağı yardımıyla sürekli olarak çekerek, yüksek hassasiyetli dedektörü sayesinde yangını algılayan sistemlerdir. Aktif hava örneklemeli sistem, ana hatlarıyla aşağıda belirtilen bileşenlerden oluşmaktadır:</p>

<ul>
    <li>Algılama Hücresi</li>
    <li>Entegre Fan</li>
    <li>Entegre Filtre</li>
    <li>Hava Emiş Boruları ve Ek Parçaları</li>
    <li>Sabitleme veya Askılama Elemanları</li>
</ul>

<p>Sistemin kurulumunun gerçekleştirileceği saha detaylı olarak incelenmelidir. Hava örnekleme borusunun yerleşimi planlanmalı, kurulumu kararlaştırılan markaya ait yazılım kullanılarak planlanan boru ağı üzerine açılacak hava emiş orifislerinin yeri ve kesidinin belirlenmesi gerekir.</p>

<p>Aktif hava örneklemeli sistemler marka ve modele bağlı olarak 1, 2 veya 4 kanallı olabilirler. Her kanala 20-25 mm lik ABS borular monte edilir. Maksimum boru uzunluğu ve delik sayıları marka ve modellere göre değişiklik gösterir. Örneğin 4 kanallı bir sistem için max. boru uzunluğu 200 metre olup 4x50m veya 2x100m veya farklı kombinasyonlar kullanılabilir.</p>

<p style={{fontWeight: '600'}} >Aktif Hava Emmeli veya Hava Örneklemeli Yangın Algılama Sistemi Neden Kullanılır?</p>

<ul>
    <li>İşletme devamlılığı için en erken algılama</li>
    <li>Gereksiz gaz deşarjlarının önlenmesi</li>
    <li>Güvenli bir tahliye için ekstra zaman ihtiyacı</li>
    <li>Dumanı algılamanın zorluğu</li>
    <li>Toz ve rutubet gibi zorlayıcı etkenler</li>
    <li>Estetik ve göze çarpmayan algılama ihtiyacı</li>
    <li>Bakım için dedektörlere erişim zorluğu olan alanlarda</li>
</ul>

<p style={{fontWeight: '600'} }>Kullanım Alanları</p>

<ul>
    <li>Telekomünikasyon tesisleri</li>
    <li>Bilgisayar odaları</li>
    <li>Üretim tesisleri</li>
    <li>Metro istasyonları</li>
    <li>Tiyatro ve Sinemalar</li>
    <li>Alış-Veriş Merkezleri</li>
    <li>Halka Açık Yapılar</li>
    <li>Temiz odalar</li>
    <li>Endüstriyel Depolar</li>
    <li>Uçak hangarları</li>
    <li>Atriumlar</li>
    <li>Soğuk depolar</li>
    <li>Yeraltı tünelleri</li>
</ul>

      
  
    </div>






    </>)
}