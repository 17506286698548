import banner from '../../../img/main/013-ALGILAMA SİSTEMLERİ/001- YANGIN ALGILAMA SİSTEMLERİ/Başlık.png'
export default function YangınAlgılama() {
    const containerStyle = {
  
        maxWidth: '800px',
      
        fontFamily: 'Arial, sans-serif',
      };
    
      const headingStyle = {
        fontSize: '24px',
        marginTop: '0',
        fontWeight: '800',
      };
  
      const divStyle={
        height: '200px',
        width: '100%',
        backgroundImage:`url(${banner})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        padding: 0,
        fontSize: "3rem",
        color: " #ffffff",
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom:20,
        textShadow: '4px 4px 4px rgba(0, 0, 0, 0.5)'
    }
    return (<>
  <div style={containerStyle} >
  <div style={divStyle} >
    
    </div>
      <h2 style={headingStyle}>Yangın Algılama Ve İhbar Sistemleri</h2>
      <div style={{minHeight:1, backgroundColor:'red',marginBottom:10,marginTop:10}}></div>
      <p>Yangın algılama ve alarm sistemleri prensip olarak kendi içinde Konvansiyonel ve Adresli olmak üzere ikiye ayrılmaktadır. Konvansiyonel sistemler göreceli olarak küçük mekanların kontrol edilmesi için tasarlanır. Mekanların daha büyüdüğü, riskin farklılaştığı, nokta tespitinin hayati önem taşıdığı noktalarda adresli algılama ve alarm sistemleri kullanılmaktadır.
</p>
<p>
Yangın Algılama Sistemi, bir ortamdaki yangın tehlikesine karşı konumlandırılmış olan duman, ısı, kombine dedektörler, Alev Detektörleri vb. sayesinde algılama yaparak ortamdaki insanları olabildiğince hızlı bir şekilde uyarmayı amaçlar. Bu sayede can ve mal kaybının büyük ölçüde önüne geçilmiş olur.

</p>
<p>
Yangına karşı sigortalanan bir tesiste mal kayıpları sigorta firması tarafından karşılanabilir. Ancak Yangın algılama ve İhbar sistemleri, söndürme sistemleri tesis edilmemiş ise ! 

</p>
<ul>
  <li>Tesisin faaliyete geçmesi için gerekli olan süre, </li>
  <li>Duran üretim - Karşılanamayan siparişler</li>
  <li>Sigortanın ödeme yapması için gerekli inceleme ve araştırma süresi</li>
</ul>
<p>işletmenin zarar hanesine yazılacaktır.</p>
<p>Yangın algılama ve İhbar sistemleri, söndürme sistemleri tesis edildiğinde, yangın sigortası primlerinde ciddi miktarda azalma olacaktır. Böylece bu sistemlere yapılan yatırım takribi 3-5 yıl içerisinde işletme kasasına geri dönecektir.</p>
  <p style={{fontWeight: '600'}} >Yangın Algılama ve İhbar Sistemlerinin Kuruluş Amacı Nedir?</p>
  <ul>
    <li>Erken Algılama; duman, Isı, alev, yanıcı-parlayıcı gaz, vb.</li>
    <li>Yangının başlangıç yerini açık ve net olarak belirleme ve gösterme</li>  
    <li>Sesli ve ışıklı alarm verme</li>
    <li>Kontrol/kumanda ve haberleşme olanağı</li>
    <li>Söndürmenin aktive edilmesi</li>
  </ul>

<p style={{fontWeight: '600'}} >Yangın Algılama Sistemi Tasarım Esasları;</p>
<p>Bir Yangın Algılama ve Alarm Sistemi, belirtilen amaçlar doğrultusunda sistemin kurulacağı yapının veya tesisin mimari yapısı, kullanım şekli ve yangın riskleri dikkate alınarak tasarlanır. Temel olarak ; </p>
<ul>
  <li>Binaların Yangından Korunması Yönetmeliği / Türkiye (2021)</li>
  <li>EN 54/14 Yangın Algılama ve İhbar Sistemleri Tasarım ve Uygulama - 2018</li>
  <li>BS5839 Part 1 İngiliz Yangın Alarm Sistemi Tasarım ve Uygulama standardı</li>
  <li>NFPA 72 ABD Yangın Alarm Sistemi Tasarım ve Uygulama standardı</li>
</ul>
<p>Yukarda listelenen standartlardan birine uygun olarak tasarlanır. Ülkemizde geçerli olan EN54/14 standardıdır.</p>

<p style={{fontWeight: '600'}} >Yangın Alarm ve Algılama Sistemi Tipleri</p>
<ul>
  <li><strong>Konvansiyonel Sistemler – Bölgesel bildirim yapar</strong></li>
  <li><strong>Adresli Sistemler – Noktasal ve istenirse bölgesel bildirim yapar</strong></li>
</ul>

<p style={{fontWeight: '600'}}>Konvansiyonel Sistemler (Bölgesel Bilgilendirme)</p>
<ul>
  <li>Her zona ayrı bir devre bağımsız kablolama</li>
  <li>Her devrede 20 –30 dedektör ve buton</li>
  <li>Yangın veya arıza uyarısı zon bazında alınır</li>
  <li>Detay gerekiyorsa zonları arttırmak gerekli</li>
  <li>Sınırlı kullanım</li>
  <li>Küçük alanlar için uygulanabilir</li>
</ul>

<p style={{fontWeight: '600'}}>Adresli Sistemler (Noktasal Bildirim)</p>
<ul>
  <li>Bir çevrimde 100-126 detektör ve buton (Mevcut Klasik Sistemler)</li>
  <li>Bir çevrim için 250 detektör ve buton (Yeni Nesil sistemler)</li>
  <li>Zonlar çevrimlere bağlı değil</li>
  <li>Zonlar panelde yazılım ile tanımlanıyor</li>
  <li>Uyarı veren ya da arızalanan cihaz bireysel olarak fark ediliyor</li>
  <li>Yangının adresi net olarak belirleniyor.</li>
  <li>Loop uzunluğu üreticiye göre farklılık göstermekte (1000 - 1500 - 2000m)</li>
  <li>Algılama kablo kesiti 1x2x0.8mm - 1x2x1mm - 1x2x1.5mm</li>
  <li>Yaygın kurulum ve kullanım</li>
  <li>Kolay arıza tespiti</li>
  <li>Sabit veya genişleyebilir loop seçenekli panel üretimi</li>
  <li>Dedektörler ön alarm (Pre-Alarm) verebilmekte</li>
  <li>Dedektörlerin ön alarm ve alarm eşik seviyeleri ayarlanabilir</li>
  <li>Dedektörler kirlilik ve bakım uyarısı verebilir</li>
  <li>Dedektörler hassasiyetlerini otomatik kalibre edebilir</li>
  <li>Çevrim hattında olabilecek kısa devre durumlarında algılama elemanlarına entegre veya bağımsız izolatör modüller sayesinde hattın tümünün çökmesini engelleme</li>
  <li>Kontrol Panelleri arasında network imkanı</li>
  <li>Ayrıntılı program yapabilme imkanı</li>
  <li>Grafik yazılım olanağı</li>
  <li>Software üzerinden uzaktan erişim imkanı</li>
  <li>Cep telefonları ile eşleştirme ve kontrol olanağı</li>
</ul>
<p style={{fontWeight: '600'}} >
Yangın Algılama Sistemlerinin Kullanımı İle İlgili Zorluklar ?
</p>
<p>
Günümüzde yangın algılama sistemlerinin kullanımıyla ilgili olarak sıkça karşılaştığımız bir sorun bu sistemlerin yanlış algılama yapmasıdır. Yani sistemin normal bir durumu yangın olarak algılayıp değerlendirmesidir. Hatalı dedektör seçimi ve hatalı dedektör yerleşim yapılmadığı takdirde, yanlış algılamaya daha çok kötü ortam koşulları ve çevresel faktörler neden olmaktadır. Aşağıda bu faktörlerden bazıları verilmiştir.
</p>
<ul>
    <li>Kirli ve tozlu ortamlar</li>
    <li>Rutubetli veya su buharının bulunduğu ortamlar</li>
    <li>Diğer sıvı buharlarının bulunduğu alanlar</li>
    <li>Elektromanyetik girişimler ve yüksek frekanslar</li>
    <li>Sigara dumanı</li>
    <li>Taşıtlardan çıkan egzoz gazları</li>
    <li>Ortamda mevcut bulunan duman, ısı, alev gibi üretimin doğal evreleri ancak aldatıcı yangın belirtileri</li>
    <li>Tekstil sektöründe havada uçuşan pamuk ve iplik partikülleri</li>
  </ul>
  <p style={{fontWeight: '600'}} >Adresli Yangın Alarm Sistemi Bileşenleri</p>
  <ul>
    <li>Algılama ve İhbar Panelleri</li>
    <li>Algılama Detektörleri</li>
    <li>Özel Detektörler</li>
    <li>Zener Bariyer/Galvanik Bariyer</li>
    <li>Kontrol/kumanda/izleme modülleri</li>
    <li>Butonlar</li>
    <li>Siren/Flaşölü Siren/Çan</li>
    <li> Harici  İzolatör  veya Ürüne Entegre</li>
    <li>Enerji Kaynağı</li>
    <li>Acil Durum Aydınlatma</li>
    <li>Acil Durum Yönlendirme</li>
    <li>Yanıcı-Parlayıcı-Patlayıcı Gaz Algılama</li>
    <li>Anons Sistemleri</li>
    <li>Algılama ve İhbar Kablolaması</li>
    <li>Data kablolaması</li>
    <li>Besleme Kablolaması</li>
  </ul>
  <p>
  Yangına karşı can ve mal güvenliğinizi sağlamak amacı ile tesis ettireceğiniz C TEC Yangın Algılama ve Alarm Sisteminin en önemli ve ayırt edici özelliği doğru ve erken uyarı verebilmesidir.

  </p>
  <p>
  AKSAY Yangın C TEC işbirliği ile Yangın Algılama ve İhbar Sistemlerinin Tasarımı, Tedarik Edilmesi, Montaj -Test ve Devreye Alma ve Kontratlı Bakım hizmetlerini yetişmiş Mühendis  ve Teknisyen kadrosu ile gerçekleştirmektedir.  

  </p>
    </div>






    </>)
}