import Tablo from '../../../img/main/TabloYangın.png'
import React, { useState } from 'react';
import divStyle from '../../../css/bannerCss';
export default function MainYazıPompa() {
  const [devam, setDevam] = useState(true);
    const containerStyle = {
    
      maxWidth: '800px',
     
      fontFamily: 'Arial, sans-serif',
    };
  
    const headingStyle = {
      fontSize: '24px',
      marginTop: '0',
      fontWeight: '800',
    };

  const handleToggleContent = () => {
    setDevam(!devam);
  };
    return (<>
      <div style={containerStyle}>
        <div style={divStyle}>

        </div>
        <h2 style={headingStyle}>Yangın Pompaları</h2>
        <div style={{ minHeight: 1, backgroundColor: 'red', marginBottom: 10, marginTop: 10 }}></div>
        <p style={{fontWeight:700}}>Yangın Pompası Nedir? Ne İşe Yarar?</p>
        <p>
        Yangın pompaları, depoda hazır bekleyen yangın suyunu basınclandırarak yangından korunum sistemi boru ağına ileten  ve yangından korunum sistemlerini besleyen bir ekipmandır. Yangın pompaları, Endüstriyel Tesislerde, sosyal binalarda sağlık tesislerinde ve yüksek yapılarda yangından korunma sisteminin temel bileşenidir.

        </p>
        {devam ? (<>
        <p style={{fontWeight:700}}>Yangın Pompa Grubu Neyi İfade Eder</p>
        <p>
        Ülkemizde yangın pompa grubu  Elektrik Motorlu Ana Yangın pompası, Dizel Motorlu Yedek Yangın Pompası ve Jokey pompadan oluşacak şekilde tanımlanmıştır. Her pompanın kontrol panosu bağımsız olmalıdır. Her kontrol panosu basınç transdüseri (basınç algılama elemanı) içermelidir. Her pompanın bağımsız su deposu ile irtibatlandırılması gerekir. Zorunlu hallerde emme kollektörü kullanılabilir ancak NFPA 20 standardının gerektirdiği şartları sağlamak gerekmektedir.

        </p>
        <p>
        Pompaları tarif ederken; kapalı vanada (sıfır debi) basma yüksekliği anma basıncının en fazla % 140’ı kadar olması ve % 150 debideki basma yüksekliği anma basıncının minimum %65’i değerine kadar düşebileceğini kabul ederiz.  Bu tür pompalar, istenen basınç değerini karşılamak şartıyla, anma debi değerlerinin % 130’u kapasitedeki sistem talepleri için kullanılabilir.

        </p>
        <img style={{width:500,height:300}} src={Tablo} alt="" srcset="" />
        <p>
        Yangın pompaları normal şartlar altında%100 yedekli olacak şekilde planlanır ve kurulumu gerçekleştirilir. Yangından korunum sistemlerinde yüksek debiye sahip pompalar kullanılması gerektiğinden hesaplanan nominal debinin minimum %50 oranında yedeklenmesi şartı ile yeterli sayıda pompa seçilerek kurulumuna müsaade edilir. 
 
        </p>
        <p>
        Pompa kontrolü basınç kumandalı; tam otomatik veya yarı otomatik olabilir. Özel durumlarda yangın pompaların manuel kumanda edilerek kullanıldığını da görmekteyiz.
        </p>
        <p style={{fontWeight:700}}>Yangın Pompa Tipleri</p>
        <ul>
            <li>Dikey Hat Tipi (Vertical In-Line)Yangın Pompaları</li>
            <li>Sondan Emişli (End Suction) Yangın Pompaları</li>
            <li>Yatay Bölünebilir Gövdeli (Horizontal Splitcase) Yangın Pompaları</li>
            <li>Dik Türbin (Vertical Turbine) Tip Yangın Pompaları</li>
        </ul>
        <p style={{fontWeight:700}}> Yangın Pompaları İçin Alternatifli Motor Seçenekleri;</p>
        <p>
        Yangın pompaları alıştığımız hali ile Elektrik Motorlu, Dizel Motorlu, Türbinli vb. motor seçenekleri ile üretilebilir. Yangın pompaları Elektrik Motorlu Ana Yangın pompası, Dizel Motorlu Yedek Yangın Pompası ve Jokey pompadan oluştuğunu belirtmiştik. 

        </p>
        <p>
        Eğer elektrik enerjisi ile ana pompanın beslenmesi mümkün değil veya alt yapı maliyeti çok yüksek ise asıl pompada Dizel Motorlu seçilebilir. Eğer asıl ve yedek pompanın elektrik motorlu seçilmesi zorunlu ise yedek pompa olarak kullanılacak ikinci pompa için kontrol panosuna ek olarak Güç Transfer Panosu kullanılması ve ayrıca bağımsız bir Jeneratör üzerinden (güvenilir enerji kaynağı üzerinden) beslenmesi istenir.  

        </p>
        <p>
        Dizel pompa yakıt tankı ile birlikte tedarik edilmelidir. Sistemde biirden fazla dizel motor kullanılacak ise her dizel pompanın yakıt deposu, besleme hattı ve geri dönüş hattı bağımsız olmak zorundadır.

        </p>
        <p>
        Dizel yangın pompalarını radyatör yani hava soğutmali yerine eşanjörlü seçmek pompa odası havalandırma sisteminin sadece yanma havasını karşılayacak boyutta küçülmesini sağlar. Böylece sadece pompanın çalışması için gereken yanma havasını standardın ön gördüğü oraenda arttırarak sağlamak yeterli olacaktır. 

        </p>
        <p style={{fontWeight:700}}>  Yangın Pompası Nasıl Seçilir?</p>
        <p>
        Yangın pompaları, nominal akış ve basınç değerlerine göre belirlenmektedir. Bina veya tesisin detaylı risk analizi yapılır. Bina veya tesis için uygun söndürme sistemi veya sistemleri seçimi yapılarak tesis veya bina projelendirilir. En kritik saha belirlenir. En kritik saha riskin en yüksek olduğu pompa debisi ve anma basıncının en yüksek olacağı alan olduğundan pompa kapasitesi belirlenmesinde kullanılır.  

        </p>
        <p>
        Pompa kapasitesini en kritik alanı kapsayacak şekilde bilgisayar destekli hidrolik hesap programı kullanarak ve tesisin tehlike durumunu dikkate alarak ilave edilmesi gereken hidrant debisi de dahil edilerek hesaplanır. Eğer hidrolik hesap yapılmadan basit hesap yöntemi ile pompa kapasitesi belirlenmiş ise belirlenen değerin %20 arttırılarak kullanılması tavsiye edilir.

        </p>
        <p style={{fontWeight:700}}>  Yangın Pompası Nasıl Çalışır?</p>
        <p>
        Yangın pompa grupları belirlenen ve birbirini takip eden tanımlanmış basın düşümünü dikkate alarak çalışır. Bu nedenle her yangın pompasının kontol paneli bağımsız ve tanımlı basınç algılayan  transdüseri içerir.  Basınç hissetme hattı pompa basma hattı üzerinde çekvana ile kesme vanası arasından alır ve NFPA 20 gereklerini sağlayacak şekilde akışın tersi yönünde konulmuş orifisli çekvana, pislik tutucu ve kesme vanaları ile paslanmaz çelik boru kullanılarak basınç düşümünü algılayacak eleman ile irtibatlandırılır. 

        </p>
        <p>
        Yangın pompalarında öncelikli olarak jokey pompa devreye girer, Jokey pompa kullanılan debiyi karşılayamıyor ise basınç düşmeye devam eder. Elektrikli ana yangın pompası için belirlenen devreye girme noktasına kadar düşen basıncı pompa basınç algılama elemanı algılar ve ana pompa devreye girer. Eğer sistemde enerji yok ise ana elektrik pompa çalışamıyor ve basınç düşmeye devam eder. Basınç düşümü dizel pompa için tanımlı basınç düşümü değerine geldiğinde dizel pompa basınç algılama elemanı dizel pompayı çalıştırır. 

        </p>
        <p>
        Jokey pompa ve Elektrikli Ana yangın pompası yangından korunum sisteminde su kullanımı sona erdiğinde sistem basıncı belirlenen pompanın devreden çıkma basıncına ulaştığında bir süre daha çalışarak sistemi basınç altında sıkıştırır ve devreden çıkar. Ancak sistemde dizel pompa çalışmaya başladığında su tüketimi durduğunda eğer özel bir tanımlama söz konusu değil ise durmaz. Pompa odasına kadar gidip yangın pompasının manuel deveden çıkarılması gerekir. Personeliniz pompa odasına gidene kadar dizel pompa sistemi sıkıştırmaya devam eder. Sistemi dizel pompanın oluşturacağı aşırı basınçtan korumak için aşırı basınç tahliye vanası ve çıkışında da serbest akış konu kullanılır. Basınç aşırı basınç tahliye vanasında tanımlanan sınıra geldiğinde hattı açarak basıncı tahliye eder. Böylece yangından korunum sistemi aşırı basınçtan korunmuş olur.

        </p>
        <p>
        Basınç algılama eleman; kumanda panosunun içine yerleştirilmiş, su basıncını boru bağlantısıyla hisseden, su darbelerine karşı korumalı, alt ve üst değerler ayrı ayrı ve bağımsız olarak ayarlanabilir ve ayarlandıktan sonra kilitlenebilir olması şarttır
  
        </p>
        <p>
        Pompaların devreye girip çıkma basınçları arasında minimum 1.5 – 2 bar basınç farkı tanımlanır.

        </p>
        <p  style={{fontWeight:700}}>Yangın Pompalarının Bakımı?</p>
        <p>Yangın pompa grubunun rutin yıllık periyotlarla bakımının yapılması zorunludur. Yangın pompalarının bakımı ve saha performans testi her yıl belirlenen periyotlarla yinelenmeli ve kayıtları tutulmalıdır. 
</p>
<p>
Yangından korunum sistemlerinin en önemli bileşeni olan yangın pompa gruplarının UL Listeli ve FM onaylı tedarik edilerek montajının yapılması ve kullanımınıza sunulması yatırımınızın korunmasında son derece etkili olacaktır. AKSAY Yangın UL Listeli ve FM onaylı pompaları 100 – 7000GPM  (378 – 26.474 l./dak.) aralığında tedarik edip kendi personeli ile kurulumunu yapmaktadır.

</p>
<p>
Üretimi aralığı 100 - 7000 GPM (378 - 26495 lt./ dak.) olup, dizel ve elektrikli yangın pompaları mevcuttur. Belirtilen aralıktaki pompa grupları UL
listeli ve FM onaylıdır.
</p>
</>) : (
                    null
                )}
    
      </div>
  
  
  
  
  
  
    </>)
  }