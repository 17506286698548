import divStyle from '../../../css/bannerCss';
import '../../../css/footer.css'
export default function Basınç() {
    const containerStyle = {
       
      
        maxWidth: '800px',
       
        
        fontFamily: 'Arial, sans-serif',
      };
    
      const headingStyle = {
        fontSize: '24px',
        marginTop: '0',
        fontWeight: '800',
      };

    return (<>
  <div style={containerStyle}>
     <div style={divStyle}>
      
     </div>
      <h2 style={headingStyle}>Basınç Düşürme Vanaları</h2>
      <div style={{minHeight:1, backgroundColor:'red',marginBottom:10,marginTop:10}}></div>
      <p>
        Basınç düşürücü vanalar yangından korunum sistemlerindeki uygulamalarda özellikle yüksek basıncı azaltmak için kullanılmaktadır. Basınç düşürücü vana, sistemde istenen basınç değerini sağlamak için otomatik olarak yüksek olan giriş basıncını azaltır ve yüksek basıncın sisteme veya ekipmanlara verebileceği zararı etkisiz hale getirmeyi sağlar.
    </p>

    <p>
        Basınç düşürücü vanalar, basınçla çalışan, kesintisiz diyafram, bağlantı trimleri, axial valflerden ve basınç kontrol pilotlarından oluşmaktadır.
    </p>

    <p>
        Basınç düşürücü vana kullanımı; basınç merkezine daha yakın ve alçakta kalan lokasyonlardaki yüksek basıncı önlemek için mevcut su akımını dengelemek için tercih edilir. Tüm sistem, talep edilen normal su basıncını dengelemek ve alçak lokasyonlarda oluşan yüksek basıncı ekarte etmek için belirli bir basınç oranına sabitlenir. Suyun valfe giriş basıncının ayarlanması suyun kullanıldığı noktada kolaylıkla modifiye edilebilir.
    </p>

    <h5 style={{fontWeight:600}}>Basınç Düşürücü Vana için Temel Gereklilikler</h5>
    <ul>
        <li>Basınç düşürücü çapı ana hat ile aynı ölçüde kullanılmalıdır.</li>
        <li>Ürün siparişi esnasında maksimum debi, işletme basıncı, hat çapı, bağlantı tipi, maksimum ve minimum giriş basıncı ve istenilen çıkış basınç değeri ile ilgili bilgiler iletilmelidir.</li>
        <li>Giriş ve çıkış basınç değerlerinin birbirine yakın olması durumunda 3 yollu pilot kullanılır.</li>
        <li>Yatay ve düşey montaj yapılabilir.</li>
        <li>Çıkış basıncı istenilen değerde sürekli sabit tutulur.</li>
        <li>Şebeke ya da hattaki basınç ile çalışır ve ekstra enerjiye ihtiyaç duymaz.</li>
        <li>Sistemdeki basınç ve debi değişimlerinden etkilenmez.</li>
        <li>Vanadaki hareketli parça diyaframdır. Diyafram dizaynı sayesinde düşük basınç kaybı ve düzgün akış sağlar.</li>
    </ul>

    <h5 style={{fontWeight:600}}>Pilot Vana ile Kontrol</h5>
    <p><strong>Kapalı Pozisyon:</strong> Akışkan basıncı ile diyaframa baskı uygulanarak valf kapatılır.</p>
    <p><strong>Açık Pozisyon:</strong> İç hat basıncı diyaframı yukarı doğru hareket ettirerek akış sağlanır.</p>
    <p><strong>Modülasyon Pozisyonu:</strong> İç hat ve dış hat basıncı dengelenerek diyafram sabit konumda kalır.</p>

    </div>






    </>)
}