import { Col, Container, Row } from "react-bootstrap";
import PdfLinkSertifika from "../../component/PdfLinkSertifika";


export default function Sertifika() {
  const pdfFileNames = [
    {path:'HİZMET YETERLİLİK BELGESİ',name:' TSE Hizmet Yeterlilik Belgesi'},
    {path:'ISO 9001%20 2015',name:'ISO 9001 '},
    {path:'ISO 14001 2015',name:'ISO 14001 '},
    {path:'Müşavirlik Tescil Belgesi',name:'Serbest Mühendislik Müşavirlik Büro Tescil Belgesi'},
    {path:'Yangın Tesisatı Mühendislik',name:'Yangın Tesisatı Mühendis Yetki Belgesi'},
    {path:'ISO 10002 2018',name:'ISO 10002'},

  ];

    const divStyle = {
      height: '100px',
      width: '100%',
      background: ' rgb(0,55,105)',
      background: '   linear-gradient(90deg, rgba(0,55,105,1) 0%, rgba(0,130,226,1) 100%)',
      paddingTop:10,
      fontSize: "3rem",
      color:" #ffffff",
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      textShadow: '4px 4px 4px rgba(0, 0, 0, 0.5)'
        
      };
return(<>
        <div style={divStyle}>
              <p>Sertifika Ve Belgeler</p>
        </div>
        <Container style={{minHeight:600,paddingLeft:20,paddingTop:20}}>
<div>
      {pdfFileNames.map((pdfFileName, index) => (
        <PdfLinkSertifika key={index} pdfFileName={pdfFileName.path} name={pdfFileName.name} />
      ))}
    </div>
</Container>


</>)
    
    }