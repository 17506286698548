import banner from '../../../img/main/02- KÖPÜKLÜ SÖNDÜRME SİSTEMİ-YENİ/K-06  KÖPÜKLÜ SİSTEM SİSTEMİ TİPLERİ/Başlık.png';
export default function Tip (){
    const containerStyle = {
        
      
        maxWidth: '800px',
       
        
        fontFamily: 'Arial, sans-serif',
      };
    
      const headingStyle = {
        fontSize: '24px',
        marginTop: '0',
        fontWeight: '800',
      };
      const divStyle={
        height: '200px',
        width: '100%',
        backgroundImage:`url(${banner})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        padding: 0,
        fontSize: "3rem",
        color: " #ffffff",
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom:20,
        textShadow: '4px 4px 4px rgba(0, 0, 0, 0.5)'
    }
    return(
   <>
  <div style={containerStyle}>
     <div style={divStyle} >
     
     </div>
      <h2 style={headingStyle}>Köpüklü Yangından Korunum Sistemi Tipleri</h2>
      <div style={{minHeight:1, backgroundColor:'red',marginBottom:10,marginTop:10}}></div>
      <p style={{fontWeight:600}}>
      Genel Endüstriyel Uygulamalar
      </p>
      <p>
      Her sektörde, iyi tasarlanmış köpük sistemiyle etkili şekilde korunabilecek bir veya birden fazla risk vardır. Tehlikelerin kapsamı oldukça geniştir; küçük dizel motorlu jeneratör odaları, boya depoları, genel amaçlı depolar,üretim tesisleri, dolum alanları,  depolama tankları ve kablo tünelleri tipik örneklerdir. Bu Olası tehlike konfigürasyonları ve saha koşulları çok çeşitli olduğundan, bu tür riskler için kesin yönergeler sağlamak imkansızdır. En etkili koruma konfigürasyonuna karar vermek için her durumun kapsamlı bir incelemeye ihtiyacı vardır. 

      </p>
      <p>
      AKSAY Yangın bu analize yardımcı olabilir. Çoğunlukla nihai karar, düşük genleşmeli köpüklü söndürme sistemi, düşük/orta genleşmeli köpüklü söndürme sistemi veya orta/yüksek genleşmeli köpüklü söndürme sistemi arasında verilecektir. Bu tür sistemlere yönelik tasarım örnekleri, koruyabilecekleri tehlike türlerine ilişkin daha genel yönergelerle birlikte verilmektedir.


      </p>
      <p style={{fontWeight:600}}>
      Köpüklü Yağmurlama (Köpüklü Sprinker) Sistemleri
      </p>
      <p>
      Sprinkler sistemi, boşaltma başlıklarının kırılabilir bir ampul veya önceden belirlenmiş bir sıcaklıkta kırılan eriyebilir bir bağlantı ile kapatıldığı sistemdir. Boşaltma başlığı aynı zamanda dedektör görevi de görür ve yalnızca harekete geçen başlıklar suyu boşaltır, böylece köpük yalnızca doğrudan yangın alanına uygulanır ve su hasarı en aza indirilir.

      </p>
      <p>
      Bu tür sistemler yanıcı sıvı veya genel amaçlı depolarda, proses tesislerinde ve malzeme taşıma –depolama-sevk etme alanlarında giderek daha popüler hale gelmektedir. Tam olarak ihtiyaç duyulan yerde çalışan tek bir sistemle korunabilen geniş alanlarda özellikle kullanışlıdır. Ayrıca makine daireleri ve jeneratör muhafazaları gibi küçük alanlarda birleşik algılama/koruma sistemi olarak da kullanılabilir. 

      </p>
      <p>
      Sprinkler başlıkları aspirasyon yapmadığından film oluşturucu köpük konsantrelerinden biri kullanılmalıdır.  Polar solvent, alkol vb. kimyasallarının depolanabileceği alanlarda Alkol ve solventlere dayanıklı (AR) türevi köpüğüne ihtiyaç vardır.

      </p>
      <p>
      Geçmişte bu tür sistemlerdeki temel sorun sağlıklı oranlamanın zorluğuydu. Kaç adet boşaltma başlığının başlangıçta çalışacağını bilmek mümkün olmadığından herhangi bir olaydaki gerçek akış değerini doğru bir şekilde tahmin edilemezdi. Bu nedenle, son derece geniş bir olası akış aralığıyla başa çıkabilen bir oranlama sistemine sahip olmak hayati önem taşımaktadır. Sınırlı sayıda üretici geniş kullanım aralıklı son derece kaliteli ve güvenilir ürünler geliştirmiştir. Geniş kullanım oranlı oranlayıcı köpüklü sprinkler sistemi kullanıcılarının, sistemin olası tüm çalışma koşullarında doğru şekilde çalışacağından emin olmalarını sağlar. 

      </p>
      <p>
      Özellikle yanıcı sıvı depolarında ve yüksek raflı depolama alanlarının raf içi korunmasına yönelik pek çok üretici ve sigorta şirketleri tarafından basit-hızlı ve etkili koruma yapmak için önemli miktarda çalışma yürütülüyor.  Devam eden araştırma çalışmaları nedeniyle tasarım standartları sürekli değişim halindedir. 

      </p>
      <p>
      AKSAY Yangın ilgili standartları sürekli takip ederek, üretici firmaların yeni üretimlerini ve uygulama tecrübelerini izleyerek güncel bilgileri edinerek yangından korunum sistemleri tasarlamakta ve tasarladığı sistemlerin kurulumunu gerçekleştirip fonksiyon testlerini yaparak (çoğu zaman bire bir boşaltma testi)  etkili çalışmasını taahhüt edecek konumdadır. 

      </p>
      <p>
      Bir su kaynağına film oluşturucu köpüklerin eklenmesinin, suyun yüzey gerilimini azaltarak ve katı yanıcı maddelere daha fazla ve daha hızlı nüfuz etmesini sağlayarak ıslatma etkisini arttırdığı da unutulmamalıdır. Testler, bu mekanizma kullanılarak yangınların daha hızlı söndürülebildiğini ve böylece su hasarının minimumda tutulmasının sağlandığını göstermiştir.

      </p>
      <p style={{fontWeight:600}}>
      Baskın (Deluge) Düşük ve Orta Genleşmeli Köpüklü Sistemler
      </p>
      <p>
      Baskın – Deluge Köpük Sistemi, tüm boşaltma başlıklarının aynı anda çalışması prensibiyle çalışır. Doğru tasarlanmış köpüklü sprinkler (yağmurlama) sistemleri, yanıcı sıvıların dökülebileceği iç ve dış saha risklerine karşı etkili koruma sağlar. Korunabilecek tipik riskler karayolu ve demiryolu yükleme alanları,  Tanker Dolum, uçak hangarları, yatay ve dikey yakıt depolama tankları, pompa odaları ve kimyasal proses tesisleridir. 

      </p>
      <p>
      Baskın Köpük Sistemleri ile hızlı yangın söndürme işleminin yanı sıra yangın kontrolünün güvenli sağlıklı şekilde gerçekleşmesi için eşit yani homojen bir köpük dağılımı sağlayabilir. Ayrıca, yangın alanı içerisindeki yapılara değerli bir soğutma etkisi sağlayabilir veya bitişikteki yangın alanlarından gelen ısı radyasyonuna karşı koruma sağlar. Algılama sistemleri ile birlikte kurulur. Algılama dedektörlerinin çeşitliliği ve hızlı tepki zamanları kullanılarak sistemlerin çok hızlı devreye girmesi sağlanır. Bu özellik nedeni ile sistemler etkili söndürme sağlar. Aynı zamanda Deluge Vana İstasyonu üzerinden sistemin manuel tetiklenme olanağı olduğu gibi, manuel tetikleme işlemi riskli alana taşınabilir. Böylece algılama sisteminden impuls beklenmeden gerektiğinde saha personeli insiyatif kullanarak sistemi tetikleyebilir.

      </p>
      <p>
      Kullanılan nozullar aspirasyonsuz veya aspirasyonlu olabilir. Aspirasyonlu tip nozullar genellikle daha iyi köpük kalitesi sağlar ancak daha pahalı olacaktır. Ancak kurulu bir sistemin toplam maliyetinde nozulların fiyatı genellikle çok önemli bir faktör değildir. Aspirasyon yapmayan nozullar kullanıldığında mutlaka film oluşturucu köpük tipi seçilmelidir.

      </p>
      <p>
      Özellikle yatay veya düşey ekipmanların bulunduğu alanlarda sadece tavan kotunda koruma sağlamak mümkün olamamaktadır. Bu tür riskler için yükseltilmiş veya zemin seviyesinde montaja yönelik farklı deşarj modellerine sahip çeşitli nozullar mevcuttur. Özellikle tavan kotundaki nozulların zemindeki engellerin altlarını hızla kapatmasının mümkün olamayacağı noktalarda Orta Genleşmeli Köpük Dökücülerle desteklenmesi gereklidir.

      </p>
      <p style={{fontWeight:600}}>
      Baskın Yüksek Genleşmeli (HI-EX) Köpük Sistemleri
      </p>
      <p>
      Yüksek genleşmeli konsantreler, suyun erişilemeyen yerlere taşınmasını sağlayan maddeler içerir. Yangını batırmak ve yanmayı sürdürmek için gereken havayı dışarıda bırakmak için kullanıldıkları kapalı alanlarda çok etkilidirler. Nispeten düşük su içerikleri katı yüzey üzerinde büyük bir soğutma etkisine sahiptir ve yangının söndürülmesini sağlar. Yangın nedeniyle köpüğün bir miktar tahribatı meydana gelecektir, bu da ek bir uygulama oranıyla telafi edilir. 

      </p>
      <p>
      Yüksek genleşmeli köpük, kablo tünelleri, yer altı depolama tesisleri, bodrumlar, gemi ambarı, depolar ve uçak hangarları gibi alanların belirlenen süre içinde belirlenen yüksekliğe kadar doldurulması ile etkin söndürme sağlar. Sıvı yangınlarında kullanılmasının yanı sıra kağıt, ahşap gibi yanıcı katı maddelerin yangınlarında da oldukça etkilidir. Derinlemesine yangınlar Yüksek Genleşmeli (Hi-EX)   köpük sistemleri ile uzun süre bastırılabilir ve köpüğün ıslatma etkisi sıradan sudan daha derinlere nüfuz eder.

      </p>
      <p style={{fontWeight:600}}>
      Yüksek genleşmeli köpükler başka amaçlar için de kullanılır: 
 
      </p>
      <p >
      a) Olaya doğrudan karışmayan, açıkta kalan malzeme veya yapıların korunması için yalıtım bariyeri sağlamak 

      </p>
      <p>
      b) zehirli veya yanıcı dökülmelerden kaynaklanan buharın bastırılmasını sağlamak 

      </p>
      <p>
      Dolayısıyla yüksek genleşmeli köpük çok yönlü bir maddedir. Bir yangını söndürmek için gereken su miktarı, özellikle yüksek genleşmeli köpük durumunda en aza indirilir. Bu nedenle, su temininin sınırlı olduğu veya yangın suyunun drenajının sorun olduğu alanlarda özellikle kullanışlıdır. Dünya çevre açısından giderek daha bilinçli hale geldikçe, bazı ülkeler artık sistemlerde kullanılan tüm yangın suyunun bir depoda toplanmasına yönelik özel çalışmalar yapmakta ve doğru imha veya geri kazanım teknikleriyle bertaraf edilmesini sağlamak için çalışmaktalar. Bütün bu çalışmalar yangın suyunun yangın bölgesinden taşıdığı söndürücü madde veya kimyasallarla su yollarının kirlenmesini önlemek içindir.

      </p>
      <p>
      Yüksek genleşmeli köpüklü söndürme sistemi baskın (Deluge) vanalar kullanılarak söndürme amaçlı dizayn edilir.

      </p>
      <p>
      AKSAY güçlü mühendislik birikimi ve alternatifli ürün seçenekleri ile hızlı ve güvenilir hizmet vermek için size bir telefon kadar yakındır. AKSAY yatırımlarınızı güvenle korur.

      </p>
      <p>
      AKSAY Yangın Köpüklü Yangından korunum Sistemlerinin Tasarımı, Mühendislik Hesapları, Tedarik Edilmesi, Montaj-Test ve Devreye Alma ve Kontratlı Bakım Hizmetlerini yetişmiş Mühendis ve Teknisyen kadrosu ile gerçekleştirmektedir.  

      </p>
    </div>






    </>
    )
}