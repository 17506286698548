import HavaBanner from '../../../img/main/HavaBanner.png'
import divStyle from '../../../css/bannerCss';
export default function Bakım() {
    const containerStyle = {
  
        maxWidth: '800px',
      
        fontFamily: 'Arial, sans-serif',
      };
    
      const headingStyle = {
        fontSize: '24px',
        marginTop: '0',
        fontWeight: '800',
      };


    return (<>
  <div style={containerStyle} >
  <div style={divStyle} >
    
    </div>
      <h2 style={headingStyle}>Sistem Bakım ve Onarım Hizmetleri</h2>
      <div style={{minHeight:1, backgroundColor:'red',marginBottom:10,marginTop:10}}></div>
      <p>
      Yangın korunum sistemlerinin ya da ekipmanlarının yangın oluşması durumunda beklenen performansı göstermeye devam edeceğinden emin olmak için periyodu ve bakım-yapım yöntemi önceden belirlenmiş olan “denetleme ve fonksiyon testleri” yapılmalıdır.

      </p>
      <p>
      Denetleme sistemin ilk kurulduğu şartlarda bir değişiklik olmadığı, ilgili sistemin veya ekipmanın hali hazırda görsel olarak kullanılabilir konumda olduğunu belirlemek için yapılan görsel gözlem veya muayene işlemlerini kapsamaktadır. 

      </p>
      <p>
      Fonksiyon testleri ise yalnızca sistemler devreye alındığında değil, işletme aşamasında da belirlenen aralıklarla ilgili uluslararası standartlarda veya üretici tarafından hazırlanan teknik kılavuzlarda tarif edilen şekilde ve belirlenen aralıklarda yapılır. Fonksiyon testlerine benzer şekilde, ekipmanlar için bakım işlemleri de yine ilgili uluslararası standartlarda ve üretici teknik kılavuzlarında tarif edildiği şekilde yapılmalıdır. Periyodik bakım işlemlerinin, 

      </p>
   <ul>
  <li>Sistem veya ekipmanın çalışır durumda olduğunu güvence altına almak ve belirlemek,</li>
  <li>Sistem veya ekipmanın saha şartlarında kullanım ömrünü uzatmak,</li>
  <li>Sistem veya ekipmanın performans kayıplarını engellemek veya ekipman performansını arttırmak,</li>
  <li style={{marginBottom:10}}>Ekipmanın durumunun izlenmesi ve düzenli kayıt tutularak raporlanması adına göz önünde bulundurulması ve düzenli şekilde takibinin yapılması gerekir.</li>
  </ul> 
  <p>
  Binaların Yangından Korunması Hakkında Yönetmelik’te (BYKHY)  sistemlerin periyodik kontrol ve bakım işlemlerinin de yapılması ile ilgili zorunluluklar ve bu işlemlerin yapılmasında sorumlu olan kurum ve kişilerden de bahsedilir. BYKHY’ye göre, yangın korunum sistemlerinin periyodik kontrol ve bakımlarından bina veya işyeri sahibi ve yöneticileri veya bu kişilerin görevlendirdiği tesis yönetim firmaları sorumlu tutulmakta olup, sistemlerin işler halde tutulması ve ihtiyaç halinde çalışır durumda olacaklarından emin olunması gerekli ön şarttır. 
  </p>
  <p>
  Yangın korunum sistemlerinin periyodik kontrol ve bakımlarının nasıl yapılacağı ile ilgili olarak ise BYKHY’de ayrıntılı bilgi bulunmamaktadır. Bu noktada sıklıkla ülkemizde geçerli standartlar kapsamında veya Avrupa birliği yasaları ve stadartları kapsamında ilerlenmekte veya Uluslararası kabul görmüş standartlar kapsamında bakım hizmeti gerçekleştirilir. Bu noktada kurulan sistemin tasarım standardı ve malzemelerin sahip olduğu sertifikaları ile birlikte sistem üreticisinin kurulum-kullanım-bakım kılavuzunda belirttiği bakım şartları dikkate alınarak alınarak bakım planlaması ve bakım aşamaların belirlenmesi gereklidir. 

  </p>
  <p>
  Bakım yönetiminin diğer önemli ayağı ise bakım yapılacak ekipman veya sistemin ihtiyaçlarının doğru şekilde belirlenmesidir. Yangın korunum sistemleri bakım yönetiminde göz önündü bulundurulması gereken temel gerekliliklerin başında bakım yapılacak ekipmanlar veya sistemler için kullanılan kavramlar son derece önemlidir Temel altı başlık altında belirlenen kavramlardan;

  </p>
  
  <ul>
    <li><strong>Güvenilirlik:</strong> Sistem veya ekipmanın belirlenen zaman aralığında, belirlenen şartlarda çalışabilmesini</li>
    <li><strong>Bakım Yapılabilirlik:</strong> Sisteme veya ekipmana daha önceden belirlendiği şekilde bakım yapılmasını müteakip ekipmanın istenen performansı verebilmesini</li>
    <li><strong>Kullanılabilirlik:</strong> Sistem veya ekipmanın ihtiyaç duyulduğu zaman istenen performansı verebilmesini</li>
    <li><strong>Kullanım Alanına Uygunluk:</strong> Ekipmanın teknik özelliklerinin ekipmandan beklenen ihtiyaçları karşılamasını</li>
    <li><strong>Süreklilik veya Bir Sefer Kullanılabilirlik:</strong> Ekipmanın tekrar tekrar kullanılabilmesini veya kullanım sonrası değişimini</li>
    <li><strong>Ekonomik Kullanım Ömrü:</strong> Ekipmanın veya sistemin üzerine yüklenen görevleri ekonomik bakım şartlarında yapabileceği ve arıza çıkarmaya meyilli olmadığı zaman dilimini ifade etmektedir.</li>
  </ul>
<p>Yangın korunum sistemlerinin bakımlarında takip edilebilecek anahtar göstergeleri aşağıda sunulmuştur. Bu göstergeler tesise ve yangın korunum sistemlerinin kullanımına bağlı olarak değişiklik gösterebilir. 
</p>
<ul>
    <li>Ekipman bakımının sistemlerin sürdürülebilirliğine katkısı</li>
    <li>Ekipman performansı, yani yangın durumunda sistemin yükümlülüğünü yerine getirmesi</li>
    <li>Ekipman ömrü</li>
    <li>Bakım raporlarının takibi</li>
    <li>Yetkili servislerden bakım desteği alma oranı</li>
    <li>Ulusal ve Uluslararası yangın standartlarına hakim olunması</li>
    <li>Teknik personel yetkinliği</li>
    <li>Ekipman için uygulanan arıza sonrası düzeltici bakım oranı</li>
    <li>Ekipmana özel kestirimci bakım yöntemlerinin uygulanması</li>
    <li>Bakım maliyetleri</li>
  </ul>
  <p style={{fontWeight: '600'}} >
  Düzeltici bakım: 
  </p>
  <p>
  Plansız bakım türü olup yangından korunum sistemleri veya ekipmanlarında hata  veya arıza oluştuktan sonra en kısa süre içerisinde giderilmesinin hedeflendiği bakım türüdür. Arıza oluştuktan sonra müdahale gerçekleştirilir. Fiziksel ve diagnostik kontroller yapılarak arıza tespit edilir ve giderilir.

  </p>
  <p style={{fontWeight: '600'}} >
  Kestirimci Bakım: 

  </p>
  <p>
  Planlanmamış bakım ihtiyaçlarının önüne geçilmesi ve özellikle endüstriyel tesislerdeki yangından korunum sistemlerinden kaynaklı üretim hattındaki plansız duruşların engellenmesi için veri analizi araçları ve teknikleri kullanılarak; ekipman ve süreçlerdeki olası kusurların ya da anomalilerin tespit edilmesini sağlayan yöntemdir. Kestirimci bakım; çeşitli teknikler ve donanımlar kullanılarak, ekipmanlardan veya sistemlerden toplanan verilere dayanmaktadır. Kestirimci bakımın öncelikli amaçları;

  </p>
  <ul>
    <li>Beklenmeyen arızaların sayısını en aza indirmek ve ekipmanların çalışma süresini en üst düzeye çıkarmak.</li>
    <li>Bakım işlemlerine harcanan zamanı optimize ederek, operasyonel maliyetleri azaltmak.</li>
    <li>Ekipman bakımlarının yalnızca gerektiği zaman yapılmasını sağlamak ve gereğinden fazla bakım yaparak zaman kaybetme olasılığını ortadan kaldırmak.</li>
    <li>Uzun vadeli bakım maliyetlerini azaltmak ve üretim yapılabilen süreyi en üst düzeye çıkararak kâr artışı sağlamak.</li>
  </ul>
  <p style={{fontWeight: '600'}} >
  Rutin olarak yangından korunum sistem veya ekipmanınıza bakımı yapılması durumunda işletmeniz için oluşacak artı değerler nelerdir?

  </p>
  <ul>
    <li>Ekipmanın arızalanma süreleri ile bakım periyotları zaman çizelgesinde incelenerek ekipmanın arızada kalma süresi belirlenebilir.</li>
    <li>Ekipmanın arızada kalma süresinin azaltılması için bakım periyotlarında güncelleme planlanabilir.</li>
    <li>Arızaya düşmeyen ya da performansında değişiklik gözlenmeyen alt ekipman bakımları için bakım periyotlarında değişiklik yapılarak teknik personelin bakıma ayırdığı zaman azaltılarak bakım maliyeti düşürülebilir.</li>
    <li>Ekipmanın performansı bakım periyotlarında takip edilip elde edilen veriler uluslararası standartlarda tarif edilen sınır şartların içerisinde kaldığı gözleniyor ise sıkıntı bulunmadığı anlaşılır. Ancak aksi bir durum söz konusu ise erken müdahale yapılarak ekipman tamir/onarım giderleri düşürülebilir.</li>
    <li>Ekipmandan elde edilen veriler kullanılarak, özellikle üretici firmaların ekipman üretiminde göz önünde bulundurması gereken iyileştirmeler belirlenebilir.</li>
    <li>Ekipmanların arızaya düşme oranları azaltılarak ekipman ömrü uzatılabilir.</li>
    <li>Verilerin uzaktan izlenmesi sayesinde periyodik kontrol, test ve bakım süreçleri çok daha pratik hale getirilebilir.</li>
  </ul>

  <p>
  Sonuç olarak, periyodik kontrol, test ve bakım süreçlerinde verinin analiz edilip kullanılması sosyal binalar veya endüstriyel tesislerde birçok farklı açıdan yarar sağlayacak olup, sosyal bina veya endüstriyel tesis işletmelerinin yanı sıra, üretici firmalara, yangın ve can güvenliği uzmanlarına ve bina teknik personellerine de katkı sağlayacaktır. 

  </p>
  <p style={{fontWeight: '600'} }>
  AKSAY Yangın;

  </p>
  <ul>
    <li>Sprinkler Sistemleri Periyodik Bakımı</li>
    <li>Köpüklü Sprinkler Sistemleri Periyodik Bakımı</li>
    <li>Hi-Ex Yüksek Genleşmeli Köpük Sistemleri Periyodik Bakımı</li>
    <li>Su ve Köpük Monitörleri Periyodik Bakımı</li>
    <li>Diyaframlı Tanklı – Atmosferik Tanklı (In-Line Oranlayıcılı, <br /> Pompalı veya Hidrolik Motorlu) Köpük Merkezleri Bakımı</li>
    <li>Yangın Dolaplarının Periyodik Bakımı</li>
    <li>Çevre Hidrant Sistemleri Periyodik Bakımı</li>
    <li>Yangın Pompa Gruplarının Periyodik Bakımı</li>
    <li>Water Mist – Su Sisi Sistemleri Periyodik Bakımı</li>
    <li>HFC-227ea Sistemleri Periyodik Bakımı</li>
    <li>FK 5-1-12 Sistemleri Periyodik Bakımı</li>
    <li>IG-01 Sistemleri Periyodik Bakımı</li>
    <li>IG-55 Sistemleri Periyodik Bakımı</li>
    <li>IG-100 Sistemleri Periyodik Bakımı</li>
    <li>IG-541 Sistemleri Periyodik Bakımı</li>
    <li>CO2 Karbondioksit Söndürme Sistemleri Periyodik Bakımı</li>
    <li>Mutfak Davlumbaz Sistemi Periyodik Bakımı</li>
    <li>Kuru Tozlu Söndürme Sistemleri Periyodik Bakımı</li>
    <li>İş Makineleri Söndürme Sistemleri Periyodik Bakımı</li>
    <li>Yangın Algılama ve İhbar Sistemleri Periyodik Bakımı</li>
    <li>Yanıcı – Parlayıcı – Patlayıcı Gaz Algılama Sistemleri Periyodik Bakımı</li>
    <li>Aerosol Söndürme Sistemleri Periyodik Bakımı</li>
    <li>Pano İçi Söndürme Sistemleri Periyodik Bakımı</li>
    <li>Kıvılcım (Spark) Söndürme Sistemleri Periyodik Bakımı</li>
    <li>Doğal Isı ve Duman Ventilasyon Sistemlerinin Periyodik Bakımı</li>
  </ul>

  <p>konularında hizmet vermektedir.</p>

  <p>
  AKSAY Yangın Denetleme -Test - Devreye Alma ve Periyodik Bakım hizmetlerini üretici manuelleri ve uluslararası standartları baz alarak eğitim almış ve yetkilendirilmiş Mühendis ve Teknisyen kadrosu ile kendine özgü oluşturduğu  “Denetleme-Kontrol ve Periyodik Bakım” formlarını kullanarak gerçekleştirmektedir.  

  </p>
    </div>






    </>)
}