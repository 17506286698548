import '../../../css/footer.css'
import divStyle from '../../../css/bannerCss';
import banner from '../../../img/main/03- KÖPÜK KONSANTRELERİ-YENİ/KK-02  ÇEVRECİ FLOR İÇERMEYEN YENİ NESİL KÖPÜK KONSANTRELERİ-dks/Başlık.png'
import React, { useState } from 'react';
export default function Cevreci() {
  const [devam, setDevam] = useState(false);
  const containerStyle = {
    
    maxWidth: '800px',
    
    fontFamily: 'Arial, sans-serif',
  };

  const headingStyle = {
    fontSize: '24px',
    marginTop: '0',
    fontWeight: '800',
  };
  const divStyle={
    height: '200px',
    width: '100%',
    backgroundImage:`url(${banner})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    padding: 0,
    fontSize: "3rem",
    color: " #ffffff",
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom:20,
    textShadow: '4px 4px 4px rgba(0, 0, 0, 0.5)'
}

  const handleToggleContent = () => {
    setDevam(!devam);
  };
  return (<>
    <div style={containerStyle}>
      <div style={divStyle}>

      </div>
      <h2 style={headingStyle}>Çevreci Flor İçermeyen Yeni Nesil Köpük Konsantreleri</h2>
      <div style={{ minHeight: 1, backgroundColor: 'red', marginBottom: 10, marginTop: 10 }}></div>
      <p>
        SOLBERG Çevreci Köpük Konsantreleri; AFFF ve AFFF – AR, P, FP, FFFP vb. konsantrelerin yerini alabilecek yetenekte Flor veya türevlerini kullanılmadan üretmektedir. Flor içermeyen Çevreci (RE-HEALING) köpük konsantrelerinin bir bölümü aşağıda listelenmiştir.

      </p>
      <p>
        ⦁	<strong>SOLBERG® VERSAGARD™ AS-100 Multipurpose 3×3 </strong>flor içermeyen  köpük konsantresi , alkole dayanıklı, son teknoloji ürünü bir psödoplastik köpük konsantresidir. Hem Hidrokarbon, hem Alkol ve Solvert yangınlarını hem de derinlemesine yanabilen A sınıfı yangınları söndürmek ve çevreyi emniyete almak için tasarlanmıştır. SOLBERG® VERSAGARD™ AS-100 Multipurpose 3×3 biyolojik olarak parçalanabilen ve kalıcı olmayan, flor içermeyen köpük konsantresi, taze, deniz veya acı su kullanarak hem yüzeysel hem de derinlemesine oluşan yakıt yangınlarına müdahale etmek ve bunları güvence altına almak için mükemmel köpürme, buhar yalıtımı, söndürme ve art yanmayı engelleyen özellikleri sağlayan hidrokarbon yüzey aktif maddeler ve katkı maddelerinin bir kombinasyonudur. SOLBERG VERSAGARD AS-100 siloksan içermez.

      </p>
      {devam ? (<>
      <p>
        SOLBERG VERSAGARD AS-100, yüksek akışkanlığa ve yavaş drenaja sahip stabil köpükler üretmek üzere tasarlanmıştır. Bu yeni teknoloji, SOLBERG VERSAGARD'ın florlu ürünlere benzer performans göstermesine olanak tanır. B Sınıfı hidrokarbon ve polar solvent yakıt yangınlarını içeren yangınlarla mücadele etmek için düşük genleşmeli köpük ekipmanı (nozüller ve monitörler) ve orta ve yüksek genleşmeli köpük boşaltma cihazlarıyla birlikte kullanılabilir. Hat içi indüktörler, kendinden emişli nozullar, itfaiye aracı pompaları, Diyaframlı Köpük Oranlama Sistemleri, Pompalı köpük karışım hazırlama sistemleri ve daha fazlasını içeren standart yangınla mücadele ekipmanlarıyla tamamen uyumludur ve kolayca oranlanır. Mükemmel köpüklenebilirlik ve kabarcık stabilitesine ek olarak SOLBERG VERSAGARD AS-100, mükemmel ıslatma kabiliyetine sahiptir ve bu da onu A Sınıfı yakıt yangınlarının söndürülmesinde de etkili bir araç haline getirir.

      </p>
      <p>
        ⦁	<strong>SOLBERG® VERSAGARD™ AS-100 FP Multipurpose 3x3</strong> flor içermeyen köpük konsantresi , donmaya karşı korumalı, son teknoloji ürünü, alkole dayanıklı, flor içermeyen bir psödoplastik köpük teknolojisidir. Kalıcı olmayan, siloksan içermeyen ve kasıtlı olarak PFAS kimyasalları eklenmeyen SOLBERG VERSAGARD AS-100 FP, hem Sınıf B “hidrokarbon yakıt ve polar solvent”  hem de derinlemesine yanabilen A Sınıfı yangınları söndürmek ve emniyete almak için tasarlanmıştır. SOLBERG VERSAGARD AS-100 FP, tatlı, deniz veya acı su kullanılarak hem yüzelsel hem de derin yakıt yangınları için mükemmel köpük genleşmesi, ürün stabilitesi, buhar yalıtımı, söndürme ve geri yanma özellikleri üreten hidrokarbon yüzey aktif maddeler ve katkı maddelerinin bir kombinasyonudur. Bu flor içermeyen konsantre, stabil ve uzun süre süzülebilen bir köpük battaniyesi üretmek, aynı zamanda su varlığında önemli bir viskozite değişikliği olmayan ve geliştirilmiş depolama, taşıma, oranlama ve uzun vadeli stabiliteye sahip bir konsantre sağlamak üzere tasarlanmıştır.

      </p>
      <p>
        SOLBERG VERSAGARD AS-100 FP, yüksek akışkanlığa ve yavaş drenaja sahip stabil köpükler üretmek üzere tasarlanmıştır. Bu yeni teknoloji, SOLBERG VERSAGARD'ın florlu ürünlere benzer performans göstermesine olanak tanır. B Sınıfı hidrokarbon ve polar solvent yakıt yangınlarını içeren yangınlarla mücadele etmek için düşük genleşmeli köpük ekipmanı (nozüller ve monitörler) ve orta ve yüksek genleşmeli köpük boşaltma cihazlarıyla birlikte kullanılabilir. Hat içi indüktörler, kendinden emişli nozullar, itfaiye aracı pompaları, mesane tankları, pompa kızakları, dengeli basınç sistemleri ve daha fazlasını içeren standart yangınla mücadele ekipmanlarıyla tamamen uyumludur. Düşük sıcaklıklarda bu konsantre özel oranlama ekipmanı gerektirebilir.

      </p>
      <p>
        Mükemmel köpürme ve kabarcık stabilitesine ek olarak, SOLBERG VERSAGARD AS-100 FP mükemmel ıslatma kabiliyetine sahiptir ve bu da onu A Sınıfı yakıt yangınlarının söndürülmesinde etkili bir araç haline getirir.

      </p>
      <p>
        ⦁<strong>	SOLBERG® REHEALING™ RF1</strong>, 1% B Sınıfı hidrokarbon yangınlarını etkili bir şekilde söndürmek için kullanılan yenilikçi, çevresel açıdan sürdürülebilir bir floro yüzey aktif madde ve floropolimer içermeyen köpük konsantresidir. %1'lik solüsyonla oranlanan SOLBERG REHEALING RF1 köpük konsantresi tatlı ve tuzlu suda kullanılabilir. SOLBERG REHEALING RF1 köpük konsantresi, olağanüstü akış ve hızlı yeniden sızdırmazlık özellikleri nedeniyle mükemmel geri yanma direncine sahiptir. SOLBERG REHEALING RF1 köpük konsantresi, geleneksel AFFF, FFFP köpük konsantrelerinin ve eski Protein ve Flororotein köpüklerin yerini alacak yeni bir yüksek performanslı sentetik köpük teknolojisi kullanılarak formüle edilmiştir. SOLBERG RE-HEALING RF1 köpük konsantresi, Sınıf B hidrokarbon yakıt yangınlarında kullanılmak üzere tasarlanmıştır. Köpük, sıvı dökülmesinin yeniden tutuşmasını önlemek ve tehlikeli buharları kontrol etmek için kullanılabilir. A Sınıfı yakıtlarda, SOLBERG RE-HEALING RF1 köpüğü derinlemesine oluşan yangınlarda söndürmeyi iyileştirecektir. Maksimum sonuç elde etmek için aspirasyonlu ve aspirasyonsuz ekipmanlar gibi köpük boşaltma cihazları ile kullanılabilir.

      </p>
      <p>
        ⦁<strong>	SOLBERG® RE-HEALING™ RF1-AG</strong>, %1 köpük konsantresi, B Sınıfı hidrokarbon yakıt yangınlarını etkili bir şekilde söndürmek için kullanılan yenilikçi, çevresel açıdan sürdürülebilir bir floro yüzey aktif madde ve floropolimer içermeyen köpük konsantresidir. %1'lik solüsyonla oranlanan SOLBERG REHEALING RF1-AG köpük konsantresi tatlı ve tuzlu suda kullanılabilir. SOLBERG REHEALING RF1-AG köpük konsantresi, olağanüstü akış ve hızlı yeniden sızdırmazlık özellikleri nedeniyle mükemmel geri yanma direncine sahiptir. SOLBERG RE-HEALING RF1-AG köpük konsantresi, geleneksel AFFF, FFFP köpük konsantrelerinin ve eski protein ve floroprotein köpüklerin yerini alacak yeni bir yüksek performanslı sentetik köpük teknolojisi kullanılarak formüle edilmiştir. SOLBERG RE-HEALING RF1-AG köpük konsantresi, B Sınıfı hidrokarbon yakıt yangınlarında kullanılmak üzere tasarlanmıştır. Aspirasyonlu ve aspirasyonsuz uygulama ekipmanları ile kullanıma uygundur. Mükemmel sonuç verir.

      </p>
      <p>
        ⦁	<strong>SOLBERG® REHEALING™ RF3 </strong> köpük konsantresi , B Sınıfı hidrokarbon yakıt yangınlarını etkili bir şekilde söndürmek için kullanılan yenilikçi, çevresel açıdan sürdürülebilir bir floro yüzey aktif madde ve floropolimer içermeyen köpük konsantresidir. %3'lük çözeltiyle oranlanan SOLBERG REHEALING RF3 köpük konsantresi tatlı, tuzlu veya acı sularla kullanılabilir. SOLBERG REHEALING RF3 köpüğü, dikkat çekici akışı ve hızlı yeniden sızdırmazlık özellikleri nedeniyle mükemmel geri yanma direncine sahiptir. SOLBERG REHEALING köpük konsantreleri, geleneksel AFFF, FFFP köpük konsantrelerinin ve eski protein ve floroprotein köpüklerin yerini alacak yeni bir yüksek performanslı sentetik köpük teknolojisi kullanılarak formüle edilmiştir.

      </p>
      <p>
        SOLBERG RE-HEALING RF3 köpük konsantresi tortu içermez. B Sınıfı hidrokarbon yakıt yangınlarında kullanılmak üzere tasarlanmıştır. Köpük, sıvı dökülmesinin yeniden tutuşmasını önlemek ve tehlikeli buharları kontrol etmek için kullanılabilir. A Sınıfı yakıt yangınlarında güçlü söndürme sağlar. Maksimum sonuç elde etmek için, hava emmeli gibi köpük boşaltma cihazlarının yanı sıra, standart yangın sprinkler başlıkları da dahil olmak üzere, Aspirasyonsuz (hava emmesiz)  ekipmanlar da kullanılabilir.

      </p>
      <p>
        ⦁	<strong>SOLBERG® RE HEALING™ RF3X6  ATC </strong>köpük konsantresi , B Sınıfı hidrokarbon yangınlarını %3 kullanım oranlı,  polar solvent yangınlarını ise %6 kullanım oranı ile etkili bir şekilde söndürmek için kullanılan yenilikçi, çevresel olarak sürdürülebilir bir floro yüzey aktif madde ve floropolimer içermeyen köpük konsantresidir. SOLBERG RE HEALING RF3x6 ATC köpüğü tatlı, tuzlu veya acı sularla kullanılabilir. SOLBERG RE HEALING RF3x6 ATC köpüğü, dikkat çekici akışı ve hızlı yeniden sızdırmazlık özellikleri nedeniyle mükemmel art yanma direncine sahiptir. RE HEALING köpük konsantreleri, geleneksel AFFF, FFFP köpük konsantrelerinin ve eski protein ve floroprotein köpüklerin yerine yeni bir yüksek performanslı sentetik köpük teknolojisi kullanılarak formüle edilmiştir.

      </p>
      <p>
        SOLBERG RE HEALING RF3x6 ATC köpük konsantresi, Sınıf B hidrokarbon veya polar solvent yakıt yangınlarında kullanılmak üzere tasarlanmıştır. Köpük, sıvı dökülmesinin yeniden tutuşmasını önlemek ve tehlikeli buharları kontrol etmek için kullanılabilir. A Sınıfı yakıtlarda, SOLBERG RE HEALING RF3x6 ATC köpüğü, derin yangınlarda söndürmeyi iyileştirecektir. Maksimum sonuç elde etmek için, hava aspirasyonlu köpük boşaltma cihazlarının yanı sıra, standart yağmurlama başlıkları da dahil olmak üzere hava aspirasyonsuz ekipmanlar da kullanılabilir.

      </p>
      <p>
        ⦁	<strong>SOLBERG® REHEALING™ RF3x3 Freeze Protected (FP) ATC™ </strong>köpük konsantresi , %3'lük solüsyonda B Sınıfı hidrokarbon ve polar solvent yakıt yangınlarını etkili bir şekilde söndürmek için kullanılan yenilikçi, çevresel açıdan sürdürülebilir bir floro yüzey aktif madde ve floropolimer içermeyen köpük konsantresidir. RE-HEALING RF3x3 FP ATC köpüğü tatlı, tuzlu veya acı sularla kullanılabilir. RE-HEALING RF3x3 FP ATC köpüğü, olağanüstü akış ve hızlı yeniden sızdırmazlık özellikleri nedeniyle mükemmel art yanma direncine sahiptir.

      </p>
      <p>
        SOLBERG RE-HEALING RF3x3 FP ATC köpük konsantresi, B sınıfı  hidrokarbon veya polar solvent yangınlarında kullanılmak üzere tasarlanmıştır. Köpük, sıvı dökülmesinin yeniden tutuşmasını önlemek ve tehlikeli buharları kontrol etmek için kullanılabilir. A Sınıfı yakıtlarda RE-HEALING RF3x3 FP ATC köpüğü, özellikle derinlemesine seyir izleyen yangınlarda söndürmeyi iyileştirecektir. Maksimum sonuç elde etmek için, hava emmeli gibi köpük boşaltma cihazlarının yanı sıra, standart yağmurlama başlıkları da dahil olmak üzere, aspirasyonsuz (hava emmesiz) ekipmanlar da kullanılabilir. Düşük sıcaklıklarda (-18°C ) kullanıma uygundur.

      </p>
      <p>
        ⦁	<strong>SOLBERG® RE HEALING™ RF3x6 Freeze Protected (FP) ATC™ </strong>köpük konsantresi , B Sınıfı hidrokarbon yangınlarını %3 kullanım oranlı,  polar solvent yangınlarını ise %6 kullanım oranı ile etkili bir şekilde söndürmek için kullanılan yenilikçi, çevresel açıdan sürdürülebilir bir floro yüzey aktif madde ve floropolimer içermeyen köpük konsantresidir. SOLBERG REHEALING RF3x6 FP ATC köpüğü tatlı, tuzlu veya acı sularla kullanılabilir. SOLBERG REHEALING RF3x6 FP ATC köpüğü, olağanüstü akış ve hızlı yeniden sızdırmazlık özellikleri nedeniyle mükemmel geri yanma direncine sahiptir. REHEALING köpük konsantreleri, geleneksel AFFF, FFFP köpük konsantrelerinin ve eski Protein ve Floroprotein köpüklerin yerini alacak yeni bir yüksek performanslı sentetik köpük teknolojisi kullanılarak formüle edilmiştir. SOLBERG RE-HEALING RF3x6 FP ATC köpük konsantresi, B sınıfı hidrokarbon veya polar solvent yakıt yangınlarında kullanılmak üzere tasarlanmıştır. Köpük, sıvı dökülmesinin yeniden tutuşmasını önlemek ve tehlikeli buharları kontrol etmek için kullanılabilir. A Sınıfı yakıtlarda, SOLBERG RE-HEALING RF3x6 FP ATC köpüğü, derinlemesine yangınlarda söndürmeyi iyileştirecektir. Maksimum sonuç elde etmek için, hava aspirasyonlu köpük boşaltma cihazlarının yanı sıra, standart yağmurlama başlıkları da dahil olmak üzere hava aspirasyonsuz ekipmanlar da kullanılabilir. Düşük sıcaklıklarda (-18°C ) kullanıma uygundur.

      </p>
      <p>
        ⦁	<strong>PHOS-CHEK® 1% FLUORINE-FREE </strong> İlk sertifikalı flor içermeyen A ve B sınıfı  yangın söndürme köpüğüdür. PHOS-CHEK® %1 Flor içermeyen köpük konsantresi, su ve hidrokarbon yüzey aktif maddelerin bir karışımıdır. B Sınıfı hidrokarbon yakıt yangınlarında kullanılmak üzere geliştirilen üstün yangın söndürme performansına ve çevresel sorumluluğa olan bağlılığını gösteren, çevreye duyarlı yeni nesil bir üründür. A Sınıfı köpük, yapısal ve konut yangınlarından; vahşi orman yangınları; genel tesislerin korunması; A Sınıfı tehlikeler (ahşap, kumaş, kağıt, kauçuk ve birçok plastik gibi sıradan yanıcı maddeler); Derinlemesine yangının oluşmayacağı B Sınıfı tehlikeler; örneğin araç yangınları vb. uygulamalar için kullanılmaktadır. PHOS-CHEK %1 Flor içermeyen köpük, hidrokarbon yakıt yangınlarında hem hafif hem (bir yüzeye çarptırılarak) de kuvvetli (direk yangının içine) uygulamalarda hızlı kontrol ve söndürme için tasarlanmıştır.

      </p>
      <p>
        Flor içermeyen köpüğün kullanılması, döküntü yangınları için bir seçenektir ve daha düşük tehlikeli yangın senaryolarında yer alır. PHOS-CHEK %1 Flor içermeyen köpük, ahşap, kumaş, kağıt, kauçuk ve birçok plastik gibi sıradan yanıcı malzemeleri içeren A Sınıfı yangınlarda kullanım için son derece etkili bir ıslatma maddesidir. Yeni bir yangın hizmeti aracı olarak PHOS-CHEK® %1 Flor içermeyen köpük konsantresi, düşük genleşmeli hava emişli olmayan ve hava emişli nozullar ve monitörler, orta genleşmeli köpük cihazları ve Basınçlı Havalı Köpük Sistemleri (CAFS) gibi geleneksel yangınla mücadele ekipmanlarıyla uyumludur.

      </p>
      <p>
        ⦁<strong>	PHOS-CHEK® 3×6% FLUORİNE-FREE ALCOHOL RESİSTANT (AR) </strong> köpük konsantresi , su, hidrokarbon yüzey aktif maddeler ve psödoplastik polimerlerin bir karışımıdır. Bu organohalojen içermeyen köpük, tatlı su ile B Sınıfı hidrokarbon ve polar solvent yakıt yangınlarında kullanıma yönelik, çevreye duyarlı yeni nesil bir üründür. PHOS-CHEK 3×6% flor içermeyen alkole dayanıklı, çok yetenekli bir ıslatıcıdır ve A sınıfı yangınlarda kullanılabilir.

      </p>
      <p>
        PHOS-CHEK 3×6% flor içermeyen alkole dayanıklı köpük, hidrokarbon yakıt yangınlarında hem hafif hem de kuvvetli uygulamalarda hızlı kontrol ve söndürme için tasarlanmıştır. Polar solventler söz konusu olduğunda köpük, Tip II uygulama veya yumuşak uygulama ile son derece etkilidir. Köpük konsantresi aynı zamanda LASTFIRE'ın kalite ve performans testi gerekliliklerini de karşılamaktadır.

      </p>
      <p>
        ⦁	<strong>SOLBERG® 3% MIL-SPEC SFFF </strong>, askeri kara operasyonlarının ihtiyaçlarını karşılamak için özel olarak tasarlanmıştır. SOLBERG %3 MIL-SPEC SFFF köpük konsantresi, kalıcı olmayan, silikon/siloksan kimyasalları içermeyen ve kasıtlı olarak eklenmiş per- ve polifloroalkil maddeleri (PFAS) içermeyen en son teknolojiyi içerir. SOLBERG 3% MIL-SPEC SFFF, MIL-PRF-32725 (I1)'de tanımlandığı gibi benzin ve Jet A yakıt sızıntısı yangınlarının hızlı bir şekilde bloke edilmesi ve söndürülmesi için tasarlanmıştır. Bu flor içermeyen köpük konsantresi, mükemmel köpürme, buhar yalıtımı ve stabilite sağlayan özel hidrokarbon yüzey aktif maddeler ve katkı maddelerinin bir kombinasyonudur.

      </p>
      <ul>
        <li>Genişleme oranı ve geri yanma gereksinimlerini aşar</li>
        <li>%25 drenaj süresi performansını aşar</li>
        <li>Tuzlu suda MIL-PRF-32725 (I1) test edilmiş ve onaylanmıştır</li>
        <li>Jet yakıtında pozitif yayılma katsayısı</li>
        <li>Biyolojik olarak parçalanabilir ve kalıcı değildir</li>
        <li>Newtonian tipi viskozite kolay oranlamayı sağlar</li>
        <li>Hızlı kapatma, bloke etme ve söndürme</li>
        <li>Farklı oranlama sistemleri ile uyumlu</li>
        <li>Siloksan içermez</li>
        <li>MIL-PRF-32725 (I1) ile uyumludur ve sertifikalıdır</li>
        <li>ICAO Level B sertifikalıdır</li>
      </ul>

      <p>SOLBERG 3% MIL-SPEC SFFF, benzin ve Jet A yakıt sızıntısı yangınlarının hızlı bir şekilde söndürülmesi ve söndürülmesi için özel olarak tasarlanmıştır. SOLBERG 3% MIL-SPEC SFFF, çeşitli nozullar ve monitörler dahil olmak üzere düşük genleşmeli boşaltma cihazlarıyla da kullanılabilir. Hat içi indüktörler, kendinden emişli nozullar, itfaiye aracı pompaları, mesane tankları, pompa kızakları, dengeli basınç sistemleri ve daha fazlasını içeren standart yangınla mücadele ekipmanlarıyla tamamen uyumludur ve kolayca oranlanır.</p>

      <p style={{fontWeight: '600'}}>SOLBERG AVIGARD™ %3 veya %6 Flor İçermeyen Köpük Konsantreleri</p>
      <ul>
        <li>Havacılık pazarı için çığır açan flor içermeyen formül</li>
        <li>Tatlı ve tuzlu su uyumlu</li>
        <li>PB(toksik bozulma) T profili yok</li>
        <li>Newtonian (düşük viskozite) akış özellikleri</li>
        <li>Hızlı yıkma ve söndürme performansı</li>
        <li>Mükemmel geri yanma ve buhar bastırma performansı</li>
        <li>Çevreye duyarlıdır ve biyolojik olarak kolayca parçalanabilir</li>
        <li>ICAO Level B sertifikalıdır</li>
      </ul>

      <p>SOLBERG AVIGARD serisi, yüksek akışkanlık ve yavaş drenaj ile Jet A/Jet A1 yakıtında mükemmel performansla ICAO düzenlemelerini karşılamak veya aşmak üzere tasarlanmış özel bir formüle sahiptir. B Sınıfı hidrokarbon yakıt yangınlarını içeren yangınlarla mücadele etmek için düşük genleşmeli köpük ekipmanı (nozullar ve monitörler) ve orta genleşmeli köpük cihazlarıyla birlikte kullanılabilirler. Oranlama oranı %3 veya %6'dır. SOLBERG AVIGARD 3B aynı zamanda deniz suyu için de sertifikalıdır. SOLBERG AVIGARD ürünleri, 2006/122/EC sayılı AB Direktifi uyarınca ve 76/769/EEC sayılı Konsey Direktifi ile değiştirilen floro yüzey aktif maddeler, floropolimerler, organohalojenler, PFCA'lar, PFOA ve PFOS içermez. Kalıcılık, biyobirikim veya toksik bozulma (PBT'ler) konusunda herhangi bir endişe yaratmazlar.</p>
      </>) : (
        null
      )}
      <p onClick={handleToggleContent} style={{ color: '#ff0000', cursor: 'pointer' }}>
        {devam ? 'Gizle' : 'Devamını Görüntüle'}
      </p>
    </div>






  </>)
}