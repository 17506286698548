import divStyle from '../../../css/bannerCss';
export default function Dilatasyon() {
    const containerStyle = {
     
      
      maxWidth: '800px',
    
      
      fontFamily: 'Arial, sans-serif',
    };
  
    const headingStyle = {
      fontSize: '24px',
      marginTop: '0',
      fontWeight: '800',
    };

    return (<>
      <div style={containerStyle}>
        <div style={divStyle} >
          
        </div>
        <h2 style={headingStyle}>Dilatasyon  Kompansatörleri </h2>
        <div style={{ minHeight: 1, backgroundColor: 'red', marginBottom: 10, marginTop: 10 }}></div>
        
        <p>
        Dilatasyon kompansatörleri özellikle farklı temellere sahip binaların geçiş noktalarında (dilatasyon), bina çökmeleri veya depremlerden dolayı oluşabilecek boru hatlarındaki gerilme ve kopmaları, yanal sapmaları absorbe ederek tesisatın kesintisiz ve verimli çalışmasını sağlayan ara bağlantı elemanlarıdır.
    </p>
    <p>
    Üç farklı yönde “eksenel, yanal, ve açısal” hareketi karşılayabilecek şekilde dizayn edilen Dilatasyon Kompansatörleri özellikle yangın devrelerinde yaygın olarak kullanılır. Sipariş aşamasında sistemdeki öngörülen hareket miktarı, hattaki akışkanın cinsi, kullanım yeri, bağlantı şekli, sistemin çalışma basıncı ve sıcaklığı bildirilerek ürün seçimi talep edilmelidir.
    </p>

      </div>
  
  
  
  
  
  
    </>)
  }