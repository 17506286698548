import divStyle from '../../../css/bannerCss';
export default function TahliyeS() {
    const containerStyle = {
        
      
        maxWidth: '800px',
        
        
        fontFamily: 'Arial, sans-serif',
      };
    
      const headingStyle = {
        fontSize: '24px',
        marginTop: '0',
        fontWeight: '800',
      };
  
    return (<>
  <div style={containerStyle}>
     <div style={divStyle}>
      
     </div>
      <h2 style={headingStyle}>Doğal Duman Ve Isı Tahliye Sistemleri</h2>
      <div style={{minHeight:1, backgroundColor:'red',marginBottom:10,marginTop:10}}></div>
      <p>
      Doğal Duman Ventilasyon sistemi yangınla birlikte oluşan ısı ve dumanın atmosfere kontrollü bir şekilde atılması amacı ile kullanılan sistemlerdir. Amaç can ve mal güvenliğini sağlıklı bir şekilde korumaktır. 

      </p>
      <p>
      Doğal Duman ve Isı Ventilasyon Sistemi için temel tasarım parametreler;

      </p>
      <ul>
        <li>
        Maksimum Doğal Duman ve Isı Tahliye : 1600m² 

        </li>
        <li>
        Duman ve Isı Tahliye ünitesi koruma alanı: 200m² 
        </li>
        <li>
        Zon başına Duman ve Isı Tahliye ünitesi: 8 adet
        </li>
      </ul>
      <p>
      Sistemin Duman ve ısı Ventilasyonu için her 1600m² koruma alanı dikkate alınarak aerodinamik yüzey alanı belirlenir. Hesaplanan aerodinamik yüzey alanı kapak sayısına bölünür ve her bir duman ve ısı tahliye kapağının aerodinamik yüzey alanı bulunur. Belirlenen aerodinamik yüzey alanı o kapağın aslında yangın alanı içinde tahliye edeceği duman ısı miktarını belirler. Üretici verileri dikkate alınarak hesaplanan aerodinamik yüzey alanına göre kapak boyutu seçilir. Duman ve Isı Tahliye Sistemi hesaplamaları DIN 18232-2 standardı gereği yapılmalıdır. 

      </p>
      <p>
      Duman ve Isı Ventilasyon Sistemi kurulumu Sprinkler sistemi ile korunan bir sahada gerçekleştiriliyor ise otomatik açılma değeri 182°C seçilir. Böylece Doğal Duman ve Isı Ventilasyon sistemi aktive olmadan Sprinkler sistemi devreye girer ve işini yapar.  Eğer korunan alanda Sprinkler sistemi mevcut değil ise otomatik açma opsiyonu yerel otorite veya tasarımcı  tarafından belirlenmelidir.

      </p>
      <p>
      Duman Ventilasyon sistemi ile donatılan işletmelerde;

      </p>
      <ul>
        <li>
        Yangın çıkan alana giren personel veya itfaiye elemanı kurtarma, soğutma veya söndürme işlemi sırasında daha rahat çalışır.

        </li>
        <li>
        Bir noktada yangın nedeni ile açığa çıkan duman ve ısı diğer noktalara yayılmadan dışarı atılır. Hacim içinde yer alan ürünler, sistemler, yapı elemanları zarar görmeyecek veya hasar azalacaktır.

        </li>
        <li>
        Isının içeride birikerek artmasına engel olunur, yangının ivmesi düşürülür ve hacim içinde bulunan ürün ve sistemlerin tutuşma sıcaklığına ulaşmasına engel olunur.

        </li>
        <li>
        Toksik gazların içeride kalması engellenir.

        </li>
        <li>
        Biriken ısının taşıyıcı yapı elemanlarına etki etmesine fırsat vermeyerek, yapının statiğinin bozulmamasına katkı sağlanır.

        </li>
        <li>
        Yangınlardaki ölümlerin çoğu duman zehirlenmeleri nedeniyle oluşmaktadır. Dumanın hızla tahliyesi sağlanarak ortama yayılma hızı ve miktarı azaltılır. Böylece çalışanların daha az duman ve ısıya maruz kalmasına katkı sağlanır.

        </li>
        <li>
        Dumanın çatıdan atılması ısınında beraberinde atılmasını sağlar. Özellikle çelik binaların ısıdan dolayı ağır hasar alması ve yıkılması önlenebilir.

        </li>
        <li>
        Yangın sigorta primleri Duman ve Isı Tahliye Sistemleri ile donatılan binalarda düşer.

        </li>
        <li>
        Tek katlı yapılarda, atrium alanı olan tesislerde, kubbeli mimarilerde, pozitif basınçlandırması olmayan büyük şaft ve asansör kuyularında, yangının başlaması ile beraber ısı ve dumanın üst kotlarda birikmesi süratle gerçekleşir. 

        </li>

      </ul>
      <p style={{fontWeight:600}}>
      Duman ve Isı Tahliye Sistemlerinin Avantajları:

      </p>
      <ul>
        <li>
        Minimum bakım ile güvenilir kullanım.

        </li>
        <li>
        Doğal havalandırma amaçlı da kullanılabilir ve enerji tasarrufu sağlanır

        </li>
        <li>
        Nispeten yüksek sıcaklıklarda dahi işlevsel kurulum yapılır

        </li>
        <li>
        Hafif yapıları ile çatı üzerinde ilave yük oluşturmaz

        </li>
        <li>
        Su yalıtımları son derece etkilidir

        </li>
        <li>
        Duman atım verimlilikleri duman yoğunluğu ve sıcaklığının artması ile artar

        </li>
        <li>
        Duman ve Isı Tahliye Sistemlerinde kullanılan şeffaf kapaklar nedeni ile gün ışığından daha fazla faydalanılır. 

        </li>
      </ul>
    </div>






    </>)
}