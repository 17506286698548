import logo from './logo.svg';
import './App.css';
import Navbars from './component/Navbars'
import {HashRouter as Router, Route, Routes} from 'react-router-dom';
import Home from './pages/Anasayfa'
import About from './pages/Hakkımızda'
import Contact from './pages/Contact'
import System from './pages/System'
import Referans from './pages/Referans';
import Footer from './component/Footer';
import Missyon from './pages/ds/Missyon';

import Dispiritör from './pages/ds/Dispiritör';
import Sertifika from './pages/ds/Sertifika';
import Sirket from './pages/ds/Sirket';
import Brosür from './pages/ds/Brosür';
import Uygulamalarımız from './pages/Uygulamalarımız';
import Sartnameler from './pages/ds/Sartnameler';
import Hizmetlerimiz from './pages/ds/Hizmetlerimiz';

function App() {
  return (
    <div className="App">
   <Router>
            <Navbars/>
            <Routes>
                <Route index  path='/' element={<Home/>}/>
                <Route path='/about' element={<About></About>}/>
                <Route path='/contact' element={<Contact/>}/>
                <Route path='/system/:name' element={<System/>}/>
                <Route path='/misyonvevizyon' element={<Missyon/>}/>
                <Route path='/uygulamalarımız' element={<Uygulamalarımız/>}/>
   
              
                <Route path='/sartnameler' element={<Sartnameler/>}/>
                <Route path='/sirketprofili' element={<Sirket/>}/>
                <Route path='/distribütörlüklerimiz' element={<Dispiritör/>}/>
                <Route path='/referans' element={<Referans/>}/>
                <Route path='/brosür' element={<Brosür/>}/>
                <Route path='/sertifikavebelgeler' element={<Sertifika/>}/>
                <Route path='/hizmetlerimiz' element={<Hizmetlerimiz/>}/>
            </Routes>
            <Footer></Footer>
        </Router>
    </div>
  );
}

export default App;
