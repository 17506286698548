import divStyle from '../../../css/bannerCss';
import '../../../css/footer.css'
export default function Emniyet() {
    const containerStyle = {
       
      
        minHeight:1000,
        maxWidth: '800px',
   
        
        fontFamily: 'Arial, sans-serif',
      };
    
      const headingStyle = {
        fontSize: '24px',
        marginTop: '0',
        fontWeight: '800',
      };

    return (<>
  <div style={containerStyle}>
     <div  style={divStyle}>
     
     </div>
      <h2 style={headingStyle}>Basıç Düşürme Vanaları</h2>
      <div style={{minHeight:1, backgroundColor:'red',marginBottom:10,marginTop:10}}></div>
      <p>
      Basınç düşürücü vanalar yangından korunum sistemlerindeki uygulamalarda özellikle yüksek basıncı azaltmak için kullanılmaktadır. Basınç düşürücü vana, sistemde istenen basınç değerini sağlamak için otomatik olarak yüksek olan giriş basıncını azaltır ve yüksek basıncın sisteme veya ekipmanlara verebileceği zararı etkisiz hale getirmeyi sağlar.

      </p>
      <p>
      Basınç düşürücü vanalar, basınçla çalışan, kesintisiz diyafram, bağlantı trimleri, axial valflerden ve basınç kontrol pilotlarından oluşmaktadır.

      </p>
      <p>
      Basınç düşürücü vana kullanımı; basınç merkezine daha yakın ve alçakta kalan lokasyonlardaki yüksek basıncı önlemek için mevcut su akımını dengelemek için tercih edilir. Tüm sistem, talep edilen normal su basıncını dengelemek ve alçak lokasyonlarda oluşan yüksek basıncı ekarte etmek için belirli bir basınç oranına sabitlenir. Suyun valfe giriş basıncının ayarlanması suyun kullanıldığı noktada kolaylıkla modifiye edilebilir.

      </p>
    </div>






    </>)
}