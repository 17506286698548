import '../../../css/footer.css'
import divStyle from '../../../css/bannerCss';
export default function Puf() {
    const containerStyle = {
        
      
        maxWidth: '800px',
        
        
        fontFamily: 'Arial, sans-serif',
        height:1000
      };
    
      const headingStyle = {
        fontSize: '24px',
        marginTop: '0',
        fontWeight: '800',
      };
  
    return (<>
  <div style={containerStyle}>
     <div style={divStyle}>
      
     </div>
      <h2 style={headingStyle}>Puf Test ve Boru Sızdırmazlık Testi</h2>
      <div style={{minHeight:1, backgroundColor:'red',marginBottom:10,marginTop:10}}></div>
      <p>
      Bütün gazlı söndürme sistemlerinde Dor-Fan Test işlemi kadar <span style={{fontWeight:800}}>Puf Test ve Boru Sızdırmazlık Testi (Leak Test) </span> mutlaka uygulanmalıdır. 

      </p>
      <p>
      <span style={{fontWeight:800}}>Puf Test</span> ile boru ağı içinde kalan tesisat artıkları basınçlı hava veya Azot gazı ile süpürülür. Boru ağı içinde tesisat artıkları kalmadığından emin olunduktan sonra Puf Test bitirilir. 

      </p>
      <p>
      Put testi başarılı bir şekilde gerçekleştirdikten sonra Boru Sızdırmazlık Testine geçilir. Boru Sızdırmazlık Testi (LEAK TEST) için nozul bağlantı noktaları kör tapa sıkılarak kapatılır. Boru ağına 3bar basınç uygulanır. 10 dakika beklenir ve 10. dakikanın sonunda uygulama basınının maksimum %20 oranında düşmesi yani manometre basıncının 3 bardan – 2.4bar değerine kadar düşmesi kabul edilebilir. Bu şartlarda boru ağı sızdırmaz kabul edilir. Eğer 2.4bar değerinin altında bir basınç düşümü gözleniyor ise boru ağında iyileştirmeye gidilmelidir.

      </p>
    


    </div>






    </>)
}