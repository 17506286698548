
import banner from '../../../img/main/04- GAZLI SÖNDÜRME SİSTEMLERİ-YENİ/G-05  CO2 GAZLI SÖNDÜRME SİSTEMLERİ/Başlık.png'
export default function CO2() {
  const containerStyle = {
      
      maxWidth: '800px',
      
      fontFamily: 'Arial, sans-serif',
    };
  
    const headingStyle = {
      fontSize: '24px',
      marginTop: '0',
      fontWeight: '800',
    };
    const divStyle={
      height: '200px',
      width: '100%',
      backgroundImage:`url(${banner})`,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      padding: 0,
      fontSize: "3rem",
      color: " #ffffff",
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginBottom:20,
      textShadow: '4px 4px 4px rgba(0, 0, 0, 0.5)'
  }

  return (<>
<div style={containerStyle}>
<div style={divStyle}>
    
    </div>
    <h2 style={headingStyle}>CO2 (Karbondioksit) Söndürme Sistemi</h2>
    <div style={{minHeight:1, backgroundColor:'red',marginBottom:10,marginTop:10}}></div>
    <p>Atmosferik basınçta kokusuz, renksiz ve yalıtkan bir gaz olan Karbondioksit, korunan alana hızlı bir şekilde yayılır. Yoğunluğu havadan ağır olup, yüksek basınçlı silindirlerde veya düşük basınçlı tanklarda depolanır. Söndürme işlemini Oksijen seviyesini yanma sınırlarının altına çekerek ve soğutma yaparak gerçekleştirir.</p>
    <p  style={{fontWeight:600}}>CO2 Sistemleri Dizayn Edilirken;</p>
    <ul>
        <li>Dizayn Standardı</li>
        <li>Hacim Ölçüleri</li>
        <li>Tehlike Tipi</li>
        <li>Yangın Tipi</li>
        <li>Maksimum ve Minimum Sıcaklık</li>
        <li>Deniz Seviyesine göre konum</li>
        <li>Havalandırma Detayı</li>
        <li>Dizayn Konsantrasyonu</li>
        <li>Ön Hesaplama</li>
        <li>Kesin Hesaplama</li>
        <li>Net sonuç</li>
    </ul>
    <p>yukarıdaki temel maddeler esas alınarak ilerlenir.</p>
    <p  style={{fontWeight:600}}>CO2 Sisteminin Temel Özellikleri</p>
    <ul>
        <li>Güçlü Söndürücü</li>
        <li>Artık Bırakmaz</li>
        <li>Temizleme Sorunu Yok</li>
        <li>İletken Değildir</li>
        <li>Korozif Değildir</li>
        <li>Ekonomik</li>
        <li>Risk Alanında Mükemmel Dağılım</li>
        <li>Fiziksel olarak söndürme yapar</li>
        <li>Yüzey ve derinlemesine oluşan yangınlarda da kullanılabilir</li>
        <li>Bodrum Katlarda ek havalandırma gerektirir</li>
        <li>İnsanlı mekanlarda kontrollü kullanılabilir</li>
        <li>Düşük Yeniden Dolum Maliyeti</li>
        <li>Lokal Söndürme Amaçlı Kullanılabilir</li>
        <li>Boşaltma Zamanı 30 – 120 saniye Olarak Seçilebilir</li>
    </ul>
    <p>CO2 sisteminin insan bulunan mekanlarda kullanılması aşağıdaki şartların tamamının sağlanması ile mümkün olabilir. İnsan bulunulan mekanlarda sıklıkla sistemin otomatik tetiklenmesi istenmez.</p>
    <ul>
        <li>Pneumatik/Elektronik Geciktirme</li>
        <li>İzlenebilir Manual Kesme Vanası</li>
        <li>Sesli ve Işıklı Alarm</li>
        <li>Havalandırma</li>
        <li>Zeminin altında kalan alanlar için özel tedbirler</li>
        <li>Girilmeden önce ortamda O2 seviyesinin ölçülmesi</li>
        <li>Detaylı Personel Eğitimi</li>
    </ul>
    <p>Yukarıda belirtilen ön şartlar sağlanarak sistemin kullanılmasına izin verilebilir.</p>
    <p><strong>Kullanım Alanları</strong></p>
    <p>CO2 sistemleri Hacim Söndürme veya Lokal söndürme yapmak için kullandığımız bir söndürme sistemidir. Özellikle Lokal Söndürme söz konusu olduğunda CO2 kullanımından başka bir çözüm üretmek sıklıkla mümkün olmaz.</p>
    <ul>
        <li>UPS Odaları</li>
        <li>Trafo ve Elektrik Dağıtım Merkezleri</li>
        <li>Değerli Malzeme Depoları</li>
        <li>Telekomünikasyon Merkezleri</li>
        <li>Türbin Kabinleri</li>
        <li>Arşivler ve Müzeler</li>
        <li>Yanıcı ve Parlayıcı Malzeme Depoları</li>
        <li>Kablo ve Tesisat Galerileri</li>
        <li>Baskı Makineleri</li>
        <li>Toz Kaplama Üniteleri</li>
        <li>Kalıp Makineleri</li>
        <li>Özel Presler ve Haddehaneler</li>
        <li>Boyama Üniteleri</li>
        <li>Endüstriyel Pişiriciler</li>
        <li>Bacalar ve Filtreler vb.</li>
        <li>Yanıcı Sıvı Havuzları</li>
        <li>Boya daldırma üniteleri</li>
        <li>Off-Shore Uygulamalar</li>
        <li>Sivil, Askeri ve Marin Uygulamalar</li>
    </ul>
    <p>CO2 gazı normal şartlarda %75 sıvı ve %25 gaz formunda depolanır. Depolama basıncı ortalama 60 bar @ 20°C olarak kabul edilir. Buhar basıncı çok yüksektir. Ortam ısısına bağlı olarak basınç sıcaklık arttıkça artar. Bu nedenle manometre kullanılamaz. Zorunlu olarak “Tartı Mekanizması” gerektirir veya bakım esnasında bulunduğu ortama bağlı olarak minimum yılda 1 kez silindirlerin sistemden ayrılarak tartılması gerekir. Düşük Basınç Switchi kullanılamaz. İnsanlı mekanlarda özel tedbir gerektirir.</p>
    <p>CO2 Sistemleri tekli veya çoklu silindirlerden oluşabilmektedir. Silindirler 13.4l. – 26.8l. – 40.2 – 67l ve 80l. kapasiteyi içeren seçenekleri ile kullanıma sunulmaktadır. Tek sistem üzerinden Büyük kapasiteler söz konusu olduğunda düşük basınçlı konteyner sistemler ile koruma yapılabilmektedir. CO2 sistemleri UL, FM ve VdS onaylı sistem seçenekleri ile piyasa talepleri doğrultusunda üretilmektedir.</p>
    <p>CO2 sistemleri sıklıkla NFPA 12, BS 5036, VdS 2093 veya CEA 4007 Standartlarından biri kullanılarak tasarlanır. Tek sistem üzerinden çoklu bölgelerin korunması selektör vanalar kullanılarak sağlanabilir. Bunun için asıl sistemin %100 yedekli seçilmesini tavsiye ederiz.</p>
    <p>CO2 Söndürme Sistemleri ile koruma alanlarının boru ağına mutlaka <strong>“Puf Test”</strong> ve<strong> “Boru Sızdırmazlık Testi (Leak Test)”</strong> uygulanmalıdır.</p>
    <p>Söndürme Kontrol paneli ve aksesuarları ile birlikte kullanılır. Söndürmenin algılama kanadı korunacak mahalin gereklerine göre farklı dedektörler kullanılarak çözülebilir.</p>
    <p>AKSAY Yangın SIEX işbirliği ile CO2 Gazlı Sistemlerinin Tasarımı, Mühendislik Hesapları, Tedarik Edilmesi, <br />Montaj - Test ve Devreye Alma ve Kontratlı Bakım hizmetlerini yetişmiş Mühendis ve Teknisyen kadrosu ile gerçekleştirmektedir.</p>
  </div>






  </>)
}