import React, { useState } from 'react';
import { Button, Col, Container, Form, Row } from "react-bootstrap";

function Contact() {
  const [email, setEmail] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const mapStyle = {
    width: '500px',
    height: '400px',
  };
  const handleSendEmail = () => {
    const mailtoLink = `mailto:aksay@aksayyangin.com?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(message)}`;
    window.location.href = mailtoLink;
  };
  return (
    <Container style={{ marginBottom: 50, marginTop: 50, padding: 20 }}>
      <Row>
        <Col xs={12} md={12} lg={6}>
          <div>
            <h2>Perpa Ofisi </h2>
            <div style={{ minHeight: 1, backgroundColor: 'red', marginBottom: 10, marginTop: 10, maxWidth: 200 }}></div>
            <div className="gmap_canvas">
              <iframe
                title="My Google Map"
                src="https://maps.google.com/maps?q=H.%20R%C4%B1fat%20Pa%C5%9Fa%20Mahallesi%20Perpa%20Sokak%20No:62%20Perpa%20Ticaret%20Merkezi%20B%20Blok%20Kat:11%20No:1829,%2034384%20%C5%9Ei%C5%9Fli&amp;t=&amp;z=12&amp;ie=UTF8&amp;iwloc=&amp;output=embed"
                frameBorder="0"
                style={mapStyle}
                allowFullScreen
              ></iframe>
            </div>
          </div>
          <div>
            <h2>Çerkezköy Ofisi </h2>
            <div style={{ minHeight: 1, backgroundColor: 'red', marginBottom: 10, marginTop: 10, maxWidth: 200 }}></div>
            <div className="gmap_canvas">
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2996.770436102567!2d27.976610599999994!3d41.313857399999996!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14b52990843886e9%3A0xca44444b3fe12978!2sAksay%20Yang%C4%B1n%20ve%20G%C3%BCvenlik%20Sistemleri%20Sanayi%20ve%20Ticaret%20Limited%20%C5%9Eirketi!5e0!3m2!1str!2str!4v1713470075532!5m2!1str!2str" width="600" height="450" style= {mapStyle} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>
          </div>
        </Col>
        <Col xs={12} md={12} lg={6} style={{ padding: 10 }}>
          <h2>İletişim Bilgileri</h2>
          <div style={{ minHeight: 1, backgroundColor: 'red', marginBottom: 10, marginTop: 10 }}></div>
          <address style={{ marginTop: 20 }}>
            <p>Adres: <span>Perpa Ticaret Merkezi <br />Kat 13 No 1979. A Blok <br />Şişli / İstanbul</span></p>
            <p>Telefon: <span> +90 212 210 02 43 (Pbx)</span></p>
            <p>GSM: <span>+90 530 291 00 90/91</span></p>
            <p>Mail: <span>info@aksayyangin.com</span></p>

          </address>
          <hr></hr>
          <address style={{ marginTop: 20 }}>
            <p>Adres: İsmetpaşa Mah ÇOSB Ticaret Blokları <br></br>No 6G7, 59510 <br/> Çerkezköy / Tekirdağ </p>
            <p>Telefon: <span> +90 212 210 02 43 (Pbx)</span></p>
            <p>GSM: <span>+90 530 291 00 90/91</span></p>
            <p>Mail: <span>info@aksayyangin.com</span></p>

          </address>
          <h2>Bize Ulaşın</h2>
          <div style={{ minHeight: 1, backgroundColor: 'red', marginBottom: 10, marginTop: 10 }}></div>
          <div></div>
          <Form>
      <Form.Group controlId="formEmail" style={{ marginTop: 20 }}>
        <Form.Label>E-posta Adresiniz</Form.Label>
        <Form.Control type="email" placeholder="E-posta Adresiniz" value={email} onChange={(e) => setEmail(e.target.value)} required />
      </Form.Group>

      <Form.Group controlId="formSubject" style={{ marginTop: 20 }}>
        <Form.Label>Konu</Form.Label>
        <Form.Control type="text" placeholder="Konu" value={subject} onChange={(e) => setSubject(e.target.value)} required />
      </Form.Group>

      <Form.Group controlId="formMessage" style={{ marginTop: 20 }}>
        <Form.Label>Mesajınız</Form.Label>
        <Form.Control as="textarea" rows={5} placeholder="Mesajınız" value={message} onChange={(e) => setMessage(e.target.value)} required />
      </Form.Group>

      <Button variant="primary" onClick={handleSendEmail} style={{ marginTop: 20 }}>
        Gönder
      </Button>
    </Form>
        </Col>
      </Row>
    </Container>
  );
};
export default Contact