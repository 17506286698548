import '../../../css/footer.css'
import banner from '../../../img/main/03- KÖPÜK KONSANTRELERİ-YENİ/KK-07  EĞİTİM KÖPÜĞÜ/Başlık.jpg'

export default function Egitim() {
    const containerStyle = {
     
        maxWidth: '800px',
       
        fontFamily: 'Arial, sans-serif',
      };
    
      const headingStyle = {
        fontSize: '24px',
        marginTop: '0',
        fontWeight: '800',
      };
      const divStyle={
        height: '200px',
        width: '100%',
        backgroundImage:`url(${banner})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        padding: 0,
        fontSize: "3rem",
        color: " #ffffff",
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom:20,
        textShadow: '4px 4px 4px rgba(0, 0, 0, 0.5)'
    }
    return (<>
  <div style={containerStyle}>
     <div style={divStyle}>
      
     </div>
      <h2 style={headingStyle}>Eğitim Köpük Konsantreleri </h2>
      <div style={{minHeight:1, backgroundColor:'red',marginBottom:10,marginTop:10}}></div>
      <p>SOLBERG, köpük ekipmanlarının değerlendirilmesinin yanı sıra eğitim ve sergi amaçlı kullanılmak üzere özel olarak tasarlanmış bir dizi yenilikçi SOLBERG sentetik köpük bileşiği sunmaktadır. SOLBERG Eğitim Köpük konsantreleri, birçok açıdan işlevsel olarak Sınıf A ve Sınıf B köpüklere benzese de, gerçek yangın durumlarının yönetimine yönelik değildir. Bu çevresel açıdan sürdürülebilir köpük konsantreleri, floro yüzey aktif madde ve floropolimer içermez ve kolayca biyolojik olarak parçalanabilir.</p>

<p>SOLBERG Eğitim Köpük konsantreleri %1, %3 veya %6 geleneksel psödoplastik köpük konsantrelerin kullanımını simüle edecek şekilde tasarlanmıştır. Bu eğitim köpük konsantreleri, A ve B Sınıfı yangınlarda söndürme özelliğine sahiptir, ancak geri yanma dirençleri, aynı yangın havuzunda ardı ardına yapılan testlere imkan verecek şekilde kasıtlı olarak azaltılmıştır.</p>


    </div>






    </>)
}