import banner from '../../../img/main/013-ALGILAMA SİSTEMLERİ/003- KABLO TİPİ LİNEER ALGILAMA SİSTEMLERİ/Başlık.png'
export default function Lineer() {
    const containerStyle = {
        
      
        maxWidth: '800px',
        
        
        fontFamily: 'Arial, sans-serif',
      };
    
      const headingStyle = {
        fontSize: '24px',
        marginTop: '0',
        fontWeight: '800',
      };
      const divStyle={
        height: '200px',
        width: '100%',
        backgroundImage:`url(${banner})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        padding: 0,
        fontSize: "3rem",
        color: " #ffffff",
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom:20,
        textShadow: '4px 4px 4px rgba(0, 0, 0, 0.5)'
    }
    return (<>
  <div style={containerStyle}>
  <div style={divStyle}>
    
    </div>
      <h2 style={headingStyle}>Kablo Tipi Lineer Algılama Sistemleri</h2>
      <div style={{minHeight:1, backgroundColor:'red',marginBottom:10,marginTop:10}}></div>
      
    <p>Patol Lineer Isı Dedektörleri, Digital ve Anolog olmak üzere iki tip olarak üretilmektedir. Digital ürünler önceden belirlenmiş sabit sıcaklık değerlerine sahiptir. Alarm derecesine ve ortam sıcaklığına göre model belirlenir. Digital Lineer Sıcaklık Dedektörü, sabit sıcaklık detektörü gibi çalışır ve üreticinin önerdiği LDH Digital Kontrol paneli ile kullanılabileceği gibi standart algılama sistemlerine de entegre edilebilir.</p>

<p>Çalışma prensibi; iki tel arasındaki açık devrenin kapanmasına ve iki canlı uçun bir biri ile teması sonucu oluşan kısa devre mantığı ile çalışmaktadır. Bu kablolar normalde birbirinden ısı karşısında eriyen polimer koruyucu bir tabaka ile ayrılmıştır. Yalıtkan kaplamanın belli sıcaklıkta erimesiyle birbirine değen iki iletken tel, kısa devre oluşturarak yangın kontrol paneline sinyal yollar ve bu sinyal yangın veya tehlikeli ısı artışı olduğunu bildirir.</p>

<p style={{fontWeight:600} }>Digital Lineer Kablo Tipi Isı Dedektörü</p>
<p>Yaygın olarak kullanılmakta olan noktasal yangın algılama sistemlerinin, ortam koşulları nedeniyle uygulanmasının mümkün olamadığı yerlerde kullanılmak amacıyla üretilmiştir. Lineer dedektörler farklı algılama sıcaklıklarında 70°C, 90°C, 180°C vb. sabit sıcaklık değerlerinde üretilir ve yangına karşı korunacak sahanın temel gerekliliklerine göre seçilerek uygulanır. Lineer ısı detektörlerinin kimyasal ve fiziksel aşınmaya dayanıklı seçenekleri mevcuttur.</p>

<p style={{fontWeight:600} }>Anolog Kablo Tipi Sıcaklık Detektörü</p>
<p>Sabit ısı veya ısı artış dedektörü şeklinde çalıştırılabilir. Kablo ile birlikte bir LHD Anolog Kontrol Paneli de kullanılır. Bu cihaz üzerinden alarm sıcaklıkları ayarlanabilir. LHD Anolog Kontrol Paneli üzerinden (kablo uzunluğuna bağlı olarak) sahada kabul edilebilir değere set edilebilen ürünlerdir. LHD Anolog Kontrol Paneli üzerinden alınacak çıkışlarla diğer algılama sistemlerine entegre edilebilir.</p>

<p style={{fontWeight:600}} >Digital veya Anolog Lineer Isı Dedektörleri</p>
<p>LHD Kontrol Paneli ile birlikte özellikle Ex-Prnoof alanlarda Zener Bariyer veya Galvanik İzolatör kullanılarak güvenle algılama yapılmasını sağlar.</p>

<p style={{fontWeight:600} }>Digital LHD Sistemin Temel Özellikleri</p>
<ul>
    <li>Kablo ve Dedektör bir arada</li>
    <li>Farklı alarm sıcaklıklarına sahip alternatifli ürün</li>
    <li>Sıcaklık alarm toleransı = +/- 4 °C</li>
    <li>Dedektör Interface ünitesi alarm gelen bölgeyi tam olarak gösterir</li>
    <li>Alarm verme süresi maksimum 8 saniye</li>
    <li>Sıcaklık alarmı; kablo uzunluğu, ortam sıcaklığı ve diğer olumsuz sebeplerle değişmez</li>
    <li>Dedektör Interface Ünitesi veya LHD Kontrol Paneli için maksimum LHD kablo mesafesi 2000 metre</li>
    <li>Konvansiyonel veya adresli algılama panelleri ile kullanılabilir</li>
    <li>Tehlikeli mahaller için ergonomik ve ekonomik çözüm</li>
    <li>Dedektörün esnek olması nedeni ile uygulama kolaylığı</li>
    <li>Ex-proof sahalarda düşük maliyetli kurulum</li>
</ul>

<p style={{fontWeight:600}} >Kullanım Alanları</p>
<ul>
        <li>Yeraltı Kablo Galerileri</li>
        <li>Kablo Kanalları</li>
        <li>Kapalı Maden Ocakları</li>
        <li>Metro Tünelleri</li>
        <li>Karayolu Tünelleri</li>
        <li>Konveyörler</li>
        <li>Yürüyen Merdivenler ve Yollar</li>
        <li>Tarihi Binalarda Soğutma Kuleleri</li>
        <li>Elektrik Pano İçleri</li>
        <li>Trafolar</li>
        <li>Kimyasal Tanklar</li>
        <li>Uçak Hangarları</li>
        <li>Tüneller</li>
        <li>Sürekli Hava Akımı Olan Yerler</li>
        <li>Petrokimya Tesisleri</li>
        <li>Akaryakıt Dolum Tesisleri</li>
        <li>Ex-proof Ortamlar</li>
        <li>Tozlu ve Kirli Ortamlar</li>
    </ul>


    </div>






    </>)
}