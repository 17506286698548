import '../../../css/footer.css'
import banner from '../../../img/main/01- SULU SÖNDÜRME SİSTEMLERİ-YENİ/007-SOĞUTMA  NOZULLARI/Başlık.png'
import img from '../../../img/main/01- SULU SÖNDÜRME SİSTEMLERİ-YENİ/007-SOĞUTMA  NOZULLARI/sogutmabirlesik.png'
export default function Sprey() {
  const containerStyle = {
    
    
    maxWidth: '800px',
   
    
    fontFamily: 'Arial, sans-serif',
  };

  const headingStyle = {
    fontSize: '24px',
    marginTop: '0',
    fontWeight: '800',
  };
  const divStyle={
    height: '200px',
    width: '100%',
    backgroundImage:`url(${banner})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    padding: 0,
    fontSize: "3rem",
    color: " #ffffff",
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom:20,
    textShadow: '4px 4px 4px rgba(0, 0, 0, 0.5)'
}

  return (<>
    <div style={containerStyle}>
      <div style={divStyle}>

      </div>
      <h2 style={headingStyle}>Soğutma Nozulları</h2>
      <div style={{ minHeight: 1, backgroundColor: 'red', marginBottom: 10, marginTop: 10 }}></div>
      <p>
        Açık tip nozullar parlayıcı sıvı tehlikelerine karşı, yatay veya dikey depolama tankları, konveyorler, yatay kurutucular, trafolar, kablo galerileri, yürüyen merdivenler ve benzeri tehlikeleri korumak amacı ile kullanılır. Su spreyi ile koruma son derece önemlidir. Koruma planlanırken etkili söndürme veya soğutma gerçekleştirmek için;
        <ul>
          <li>Riskin Doğru Analizi</li>
          <li>Tasarım Alanını Belirlenmesi</li>
          <li>Su Uygulama Yoğunluğunun Belirlenmesi</li>
          <li>Su Talebini Hesaplanması</li>
          <li>Gerekli Nozulları Belirlenmesi</li>
          <li>Nozul K Faktörünü Belirlenmesi</li>
          <li>Nozul Çalışma Basıncının Belirlenmesi</li>
          <li>Püskürtme Açısının Belirlenmesi</li>
          <li>Nozul Yönünün Belirlenmesi</li>
          <li>Nozul Yerleşimi ve/veya Montaj Yüksekliğini Belirlenmesi</li>
          <li>Dış Sahada Uygulama Yapılıyor ise Rüzgar Etkisini Dikkate Alınması</li>
          <li>Yeterli Suyun Uygulama Alanına Verildiğinden Emin Olunması</li>
          <li>Gerekli Nozulların Sayısını ve Tipini Gözden Geçirilmesi</li>
          <li>K Faktörünü ve Püskürtme Açısının Ayarlanması</li>
          <li>Sistemin Yeniden Hesaplanması ve Tasarımın Sonlandırılması</li>
          <li>Mühendislik Hesaplarının Yapılarak Sistemin Kesinleştirilmesi</li>
        </ul>
        vb. değişken parametreleri dikkate alarak sistemleri boyutlandırılır. Her sistemde her bir nozulun gerekli boşalma özelliklerini yani kapasite, tarama alanı, sprey açısı vs. korunacak mevcut tehlike için hesaplanarak tasarlanır. Su Spray sistemler manual ve/veya otomatik kumandalı tasarlanabilir. Sistem tasarımı sıklıkla NFPA 15 veya API 2030 standartlarına göre yapılmaktadır. Tasarım standardına göre uygulama debisi belirlenmekte, üretici firma manuellerine göre spray nozulun minimum ve maksimum çalışma basınç aralığı dikkate alınarak sistemin mühendislik hesapları yapılmaktadır. Mutlaka her tasarladığımız sistem için mühendislik hesaplamalarını bağımsız olarak yaparız.
        
          Nozulları; MV Medium Velocity su püskürtme nozulları, HV High Velocity su püskürtme nozulları, Su Perdesi nozulları, Tank Soğutma nozulları, Reverse Action nozullar, Jumbo Perde nozulları ve Döner nozullardan oluşur. MV nozullar yangından korunma uygulamalarında sabit su püskürtme sistemlerinde kullanılan püskürtme nozullarıdır. Reverse Action su püskürtme nozulları da aynı amaca hizmet ederken, MV nozullardan farklı olarak montaj ters yönde yapılır. MV spray nozulları, etkili söndürme ve soğutma amacıyla gerekli olan, orta hızda su damlacıklarından oluşan su spreyini eşit şekilde doldurulmuş tarama alanına boşaltır. Yüksek hızlı nozullar (HV), esas olarak su püskürtme sistemlerinde, transformatörler ve devre kesiciler gibi sabit tehlikelerin korunması için kullanılır. Su perdesi nozulları suyu zemine kadar düz bir şekilde dağıtır. Su perdesi yangın altındaki alanın bitişikteki alanlar veya bulunduğu alandan ayrılması amacıyla üretilmekte ve kullanılmaktadır.
          
            Su Spray Sistemlerin Kullanım Alanları;
            <ul>
              <li>Akaryakıt Terminalleri</li>
              <li>Depolama Tankları</li>
              <li>Rafineriler</li>
              <li>Açık parlayıcı sıvı depolama ve proses ekipmanları</li>
              <li>Kablo ve Tesisat Galerileri</li>
              <li>Yürüyen Merdiven ve Eskalatörler</li>
              <li>Yatay sürekli kurutucular</li>
              <li>Trafolar ve Jeneratörler</li>
              <li>Haddeleme Üniteleri</li>
              <li>vb. endüstriyel uygulamalar</li>
            </ul>
            Kullanımınıza sunduğumuz nozullarımız UL Listeli ve/veya FM Onaylıdır. Onay bilgileri, teknik özellikler ve kullanım noktaları ve montaj şekli için lütfen bizimle irtibata geçiniz. Su Spray Sistemleri temel olarak endüstriyel tehlikeli bölgelerin yangın kontrolü, söndürülmesi, soğutulması, önlenmesi ve/veya maruziyetten korunması amacıyla kullanılır. AKSAY Yangın tasarım yapan mühendis kadrosu ve farklı sektörlerde yaptığı uygulamalar ile edindiği tecrübelerini kullanımınıza sunmak için hazırdır.
          </p>
          <img style={{width:400,height:400}} src= {img}></img>
          </div>






        </>)
}