import divStyle from '../../../css/bannerCss';
import img from '../../../img/main/AlevDetektör/birlesik.png'
export default function AlevDetec (){
    const containerStyle = {
        
      
        maxWidth: '800px',
      
        
        fontFamily: 'Arial, sans-serif',
      };
    
      const headingStyle = {
        fontSize: '24px',
        marginTop: '0',
        fontWeight: '800',
      };
  
    return(
   <>
  <div style={containerStyle}>
     <div style={divStyle}>
     
     </div>
      <h2 style={headingStyle}>Alev Detektörleri</h2>
      <div style={{minHeight:1, backgroundColor:'red',marginBottom:10,marginTop:10}}></div>
      <p style={{fontWeight:600}}>Alev Dedektörü Nedir ?</p>
      <p>
      Alev dedektörü yangın meydana gelme ihtimali olan ortamlarda alev kıvılcımlarından doğan ışımayı tespit ederek olası bir yangın ihtimalinde sinyal üreten cihazlardır. Alev dedektörleri dumansız yangın türüne karşı geliştirilen, gaz ve sıvı tetikleyicilerin olduğu ortamlarda güvenle çalışma ortamı kurulması için gerekli bir ekipmandır. Bu cihazlar olası bir kıvılcım ya da alev parıltısı durumunda alarm durumuna geçebilir ya da bağlantı şekli sayesinde yakıt tankerini durdurabilir, hatta yangın söndürme sistemini devreye sokabilir.

      </p>
      <p style={{fontWeight:600}}>Alev Dedektörü Çalışma Prensibi</p>
      <p>
      Alev dedektörleri alevlerden çıkan görünür bölge, kızılötesi ışınları(IR) veya ultraviole (UV) radyasyonu tespit etme prensibine bağlı olarak çalışır. Alev dedektörü yapısında ana bileşen olarak fotoelektrik devreleri, mikro işlemciler, sinyal koşullandırma devreleri ve I/O devrelerinden oluşur. Alev dedektöründeki sensörler alev tarafından gönderilen radyasyonu algılayarak bunu bir voltaj sinyaline dönüştürecek ve bu sinyal mikroişlemcide işlenip ve çıkış olarak iletilecektir. Bu çıkış alev dedektörünün bağlandığı sisteme göre bir alarma yada yangın söndürme sistemine dönüşebilir.

      </p>
      <p style={{fontWeight:600}}>Alev Dedektörü Çeşitleri</p>
<p>
Alev dedektörleri tespit ettikleri ışıma türlerine ve radyasyonların dalga boyuna göre farklı çeşitleri vardır. Bu çeşitleri şu şekilde sıralayabiliriz IR , IR/IR, IR3, UV, UV/IR.

</p>
<li style={{fontWeight:600}}>IR Alev Dedektörleri (Tek IR Sensörlü)</li>
<p>

4.3 μm ve 4.4 μm kızılötesi radrasyonu tespit etmek için kullanılır ve tepki süresi 3-4 saniyeyi geçmez.

</p>
<li style={{fontWeight:600}}> 
 IR/IR Alev Dedektörleri (Çift IR Sensörlü)

</li>
<p>
Sisteminde 2 tane sensör bulundurur, bunlardan biri CO2 emisyonunu tespiti için 4.4 μm radyasyonu tespit ederken diğer sensör ise referans değeri hidrojen yanmaları için uygun olan frekans değerine adapte edilebilir.

</p>
<li style={{fontWeight:600}}>IR3 Alev Dedektörleri (Üç IR Sensörlü) </li>
<p>
Sisteminde 3 tane sensör bulunan alev dedektörü tipidir. Bu sensörlerden biri 4.4 μm frekans değerine bakarken diğer sensörlerden biri bu değerin altına bir diğer sensör ise bu 4.4 μm nin üstünde ki frekans aralığına duyarlıdır. Bu, dedektörün alev olmayan IR kaynakları ile yanma sürecinde sıcak CO2 yayan gerçek alevler arasında ayrım yapmasını sağlar. Bu şekilde yanlış alarmlara karşı ciddi bir bağışıklık kazanılmış olur. Ortalama 60 metre alanda ki 0.1 metrekarelik alandaki yangınları ölçebilecek hassasiyete sahiptir.
Bu tip kızılötesi dedektörler güneş, kaynak, yıldırım, X-ışını gibi farklı ışımalarını karşı yanlış alarm vermezler. Ancak en iyi algılama için sensör görüş penceresini temiz tutmak önemlidir.

</p>
<li style={{fontWeight:600}}>Ultraviole (UV)Alev Dedektörleri</li>
<p>
1000 ile 3000 angstrom (metrenin 10 milyarda biri) aralığında yayılan radyasyonu tespit eden bir sensör tipi kullanılır. Güneşten dünyaya gelen UV radyasyonunun 2800 angstrom olduğu bilinmelidir. Bunlar göz önüne alındığında UV alev dedektörünün yerleşim yeri ve konumu itibariyle güneş ışığı maruziyeti yaşamaması yanlış alarmların engellenmesi açısından önemlidir. Genel olarak 1800-2500 angstrom arası modelleri yaygın olmakla beraber bu modelleri güneşe karşı duyarsızdır. Ayrıca tepki süreleri 10 milisaniyenin altına kadar düşer. Hidrokarbon, hidrojen ve metal alevlerine karşı duyarlıdır. Yıldırım, X-ışınları, kıvılcımlar yanlış alarma sebep verebilir.

</p>
<li style={{fontWeight:600}}>UV/IR Alev Dedektörleri</li>
<p>
Bu dedektörler hem UV hem de IR dalga boylarına duyarlıdır ve her iki aralığın eşik sinyalini karşılaştırarak alevi tespit eder. Bu, yanlış alarmları en aza indirmeye yardımcı olur. 500 milisaniyenin altında tepki verebilir. Yıldırım, X-ışınları, kıvılcımlar gibi dış etkenlere karşı duyarsızdır. Bazı gazlar ve gaz buharları çalışma şartlarını olumsuz etkileyebilir.

</p>
<p style={{fontWeight:600}}>Alev Detektörleri Kullanım Alanları</p>


<ul>
    <li>
    Akaryakıt Taşıma Yükleme Terminalleri

    </li>
    <li>
    Boru Hattı ve Pompa İstasyonları
 
    </li>
    <li>
    Rafineriler   
    </li>
    <li>
    Uçak Hangarları

    </li>
    <li>
    Otomotiv Boya Püskürtme Kabinleri

    </li>
    <li>
     Boya ve Vernik Üretim Alanları
    </li>
    <li>
 Kimya Tesisleri
    </li>
    <li>
    Mühimmat Üretim Tesisleri

    </li>
    <li>
    Jet Motoru Test Hücreleri

    </li>
    <li>
    Açık Deniz Sondaj ve Üretim Platformları

    </li>
</ul>
<p>
Alev detektörleri UL, FM, VdS veya LPCB sertifikalı olarak temin edilir.Özel alanlarda ATEX gereklerine uygun <br /> Ex-Proof modelleri tercih edilmektedir.

</p>

<img src= {img} alt="" />
    </div>






    </>
    )
}