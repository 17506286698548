import banner from '../../../img/main/05- DAVLUMBAZ SÖNDÜRME SİSTEMİ -YENİ/DAVLUMBAZ SÖNDÜRME SİSTEMİ - amerex/Başlık.png'
export default function Mutfak() {
    const containerStyle = {
    
        
        maxWidth: '800px',
        
        
        fontFamily: 'Arial, sans-serif',
      };
    
      const headingStyle = {
        fontSize: '24px',
        marginTop: '0',
        fontWeight: '800',
      };
  
      const divStyle={
        height: '200px',
        width: '100%',
        backgroundImage:`url(${banner})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        padding: 0,
        fontSize: "3rem",
        color: " #ffffff",
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom:20,
        textShadow: '4px 4px 4px rgba(0, 0, 0, 0.5)'
    }
    return (<>
  <div style={containerStyle}>
     <div style={divStyle}>
      
     </div>
      <h2 style={headingStyle}>Mutfak Davlumbaz Söndürme Sistemi</h2>
      <div style={{minHeight:1, backgroundColor:'red',marginBottom:10,marginTop:10}}></div>
      <p>
      Günümüz şartlarında endüstriyel veya büyük mutfaklarda oluşan yangınlara karşı işletmelerin korunması için geliştirilmiştir. Temel prensip minimum söndürücü madde kullanarak, maksimum koruma gerçekleştirmektir. Bu amaçla özel nozullar geliştirilmiştir. Kullanılan ıslak kimyasal –wet chemical madde kolay temizlenebilir özellikte olup, tekrar alev almayı engeller. Kullanılan sıvı söndürücü maddenin kolay temizlenmesi nedeni ile üretime çok hızlı bir şekilde geri dönülebilir. Mutfak Davlumbaz Söndürme Sistemi; silindirler, Potasyum bazlı Islak Kimyasal – Wet Chemical söndürücü madde, aktivasyon elemanları, borular ve boşaltma nozulları ile paket olarak kullanıma sunulmaktadır.
      </p>
      <p>
      Mutfak davlumbaz söndürme sistemleri ön mühendisliği yapılmış paket sistemler olup sağlıklı saha keşfi yapılmadan sistem seçimi ve montajı yapılamaz. Detaylı Keşfi yapılan mahalin “Davlumbaz Söndürme Sistemi” tasarımı NFPA 17 nomları esas alınarak yapılmaktadır. Tasarımda temel gereklilikler;
      </p>
       <ul>
            <li>
            Sistem, davlumbaz içindeki ergir metal dedektörler vasıtasıyla otomatik olarak veya kaçış yoluna yerleştirilecek elle çekme istasyonu ile devreye girebilmeli
  
            </li>
<li>
Sistem, mekanik veya elektriksel olarak bir vana ile ocaklarda kullanılan gaz hattını kapatmaya uygun olmalı

</li>
<li>
Sistem, merkezi yangın algılama ve ihbar sistemine sinyal gönderebilmeli 
</li>
<li>
Kurulacak sistem aşağıda adı geçen mahallerdeki yangını bastıracak nitelikte olmalıdır.
</li>
</ul>
<ul>
    <li>Bacalar</li>
    <li>Davlumbazlar</li>
    <li>Filtreler</li>
    <li>Ocaklar</li>
    <li>Fritözler</li>
    <li>Kuzineler</li>
    <li>Izgaralar</li>
    <li>vb. diğer pişirme elemanları</li>
</ul>
<p>
Riskin söz konusu olduğu her cihaz grubu ve davlumbazı için bağımsız bir sistem uygulanmalıdır.
</p>
<p>
Her sistem kendi içinde bir bütün olup özellikli tüm elemanlar aynı üreticinin ürünü olmalıdır. Sistem bütün bileşenleri ile bir bütündür ve yerine başka bir komponent veya ikame edebilecek bir eleman eklenemez.
</p>
<p>
AMEREX KP Davlumbaz Söndürme Sistemi aşağıda belirtilen ana elemanlardan oluşmaktadır.
</p>
<ul>
  <li>Söndürücü Sıvı Depolama Silindiri</li>
  <li>Boşaltma Vanası</li>
  <li>Söndürücü Sıvı</li>
  <li>Mekanik Boşaltma Düzeneği, Kontaklı</li>
  <li>İtici Azot Kartuşu</li>
  <li>Boşaltma nozulu</li>
  <li>Ergir Metal Detektörler</li>
  <li>Algılama Hattı</li>
  <li>Makaralı Dirsekler</li>
  <li>Manual Tetikleme İstasyonu</li>
  <li>Mekanik ve/veya elektriksel gaz kesme vanası</li>
</ul>
<p>
Sistem tasarımı, üretici firmanın öngördüğü hesaplama yöntemlerine uygun olarak ve sınırladığı adetler içinde kalınarak yapılmaktadır. 
</p>
<p>
Temel olarak Davlumbaz Söndürme Sisteminin kapasitesini belirleyen; Baca boyutu ve formu, Filtre tipi- boyutu-konumu, davlumbazın altıda yer alan tekli ocak, ikili ocak, dörtlü ocak, fritöz, izgara, devrilir tava vb. pişirme elemanlarının boyutları dikkate alınarak koruma işlemini gerçekleştirecek nozullar belirlenir. Üreticinin her bir ekipman için özel nozul geliştirmiştir. Geliştirilen her nozulun bir akış katsayısı vardır. Sistem boyutunu belirlerken tasarım yapılan Davlumbaz için bütün akış katsayıları toplanır ve bulunan değer üzerinden güvenli noktada kalınacak şekilde standart bir silindir seçilir.

</p>
<p>
Amerex Mutfak Davlumbaz Söndürme Sistemi 5 farklı kapasiteye sahip silindirler kullanılarak kurulumu gerçekleştirilir. 18 akış noktası geçildiğinde çoklu silindirler kullanılır.
</p>
<table style={{marginTop:20,marginBottom:20}} width="90%" border="1" align="center" cellpadding="0" cellspacing="0">
<tbody> <tr>
        <th>Silindir Modeli</th>
        <th>Silindir Hacmi</th>
        <th>Akış Katsayısı</th>
    </tr>
    <tr>
        <td>KP250</td>
        <td>2.50 Galon</td>
        <td>7</td>
    </tr>
    <tr>
        <td>KP275</td>
        <td>2.75 Galon</td>
        <td>8</td>
    </tr>
    <tr>
        <td>KP375</td>
        <td>3.75 Galon</td>
        <td>11</td>
    </tr>
    <tr>
        <td>KP475</td>
        <td>4.75 Galon</td>
        <td>14</td>
    </tr>
    <tr>
        <td>KP600</td>
        <td>6.0 Galon</td>
        <td>18</td>
    </tr>
    <tr>
        <td>2 x KP375</td>
        <td>2 x 3.75 Galon</td>
        <td>22</td>
    </tr>
    <tr>
        <td>2 x KP475</td>
        <td>2 x 4.75 Galon</td>
        <td>28</td>
    </tr>
                </tbody> 
                </table>  
                <p style={{fontWeight:600}}>Amerex KP Zone Defense Davlumbaz Söndürme Sistemi</p>

<p>AMEREX standart Davlumbaz Söndürme Sistemine alternatif bölgesel korumayı hedefleyen “KP Zone Defense” Davlumbaz Söndürme Sistemini de kullanımımıza sunmaktadır. Bu sistemde tek tip nozul kullanılmakta ve davlumbaz altında yer alan pişirme ünitelerine bağlı kalınmaksızın belirlenen aralıklarla montajı yapılır. Temel gereklilik kesintisiz koruma sağlamaktır. Ancak göreceli olarak hesaplanan akış katsayısı yüksek çıkmakta ve daha büyük kapasiteye sahip silindir gerektirebilmektedir.</p>

<p style={{fontWeight:600}}>Amerex KP Davlumbaz Söndürme Sistemleri Kullanım Alanları;</p>
<ul>
    <li>Restoranlar</li>
    <li>Otel Mutfakları</li>
    <li>Ticari Yemek Üretim Alanları</li>
    <li>Yolcu Gemileri</li>
    <li>Aşçılık Okulları</li>
    <li>Hızlı Yemek - Fast Food Zincirleri</li>
    <li>Sağlık Tesisleri</li>
    <li>Askeri Tesisler</li>
    <li>Okul Kafeteryaları</li>
    <li>Spor Kompleksleri</li>
</ul>

<p>Yukarıda belirtilen alanlarda güvenle kullanılabilir. Mutfak mahallerinde yer alan davlumbazlar için düşük PH değerli özel söndürme sıvısı ile söndürme yapılmaktadır. Söndürücü kimyasal Aaqueous Potassium Carbonate olup, söndürme sonrası kolayca temizlenebilmektedir.</p>

<p>AMEREX Davlumbaz Söndürme Sistemleri UL, ULC ve LPCB sertifikalı olarak üretilmektedir.</p>

<p>AKSAY Yangın AMEREX işbirliği ile KP Mutfak Davlumbaz Söndürme Sistemlerinin Tasarımı, Mühendislik Hesapları, Tedarik Edilmesi, Montaj - Test - Devreye Alma ve Kontratlı Bakım hizmetlerini yetişmiş Mühendis ve Teknisyen kadrosu ile gerçekleştirmektedir.</p>

    </div>
  
  
  
  
  
  
    </>)
  }