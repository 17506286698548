import '../../../css/footer.css'
import banner from '../../../img/main/04- GAZLI SÖNDÜRME SİSTEMLERİ-YENİ/G-04  INERT SİSTEMLER/Başlık.png'
import img from "../../../img/main/04- GAZLI SÖNDÜRME SİSTEMLERİ-YENİ/G-04  INERT SİSTEMLER/2.png"
export default function Inert() {
    const containerStyle = {
        
        maxWidth: '800px',
        fontFamily: 'Arial, sans-serif',
      };
    
      const headingStyle = {
        fontSize: '24px',
        marginTop: '0',
        fontWeight: '800',
      };
      const divStyle={
        height: '200px',
        width: '100%',
        backgroundImage:`url(${banner})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        padding: 0,
        fontSize: "3rem",
        color: " #ffffff",
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom:20,
        textShadow: '4px 4px 4px rgba(0, 0, 0, 0.5)'
    }
    return (<>
  <div style={containerStyle}>
  <div style={divStyle}>
    
    </div>
      <h2 style={headingStyle}>Inert Sistemler</h2>
      <div style={{minHeight:1, backgroundColor:'red',marginBottom:10,marginTop:10}}></div>
      <p>Atmosferde serbest olarak bulunan Azot ve Argon gazlarının karışımından oluşan <strong>"Temiz Gazlı - Clean Agent" </strong>sistemlerden biridir. Kimyasal olarak nötr, yalıtkan, renksiz ve kokusuzdur. Ozona zarar verme potansiyeli (ODP) ve küresel ısınmaya etkisi (GWP) sıfırdır. Uygulandığı alandaki oksijen seviyesini yanma sınırlarının altına indirerek söndürme yapar. 200 veya 300 bar sistemler tercih edilebilir.</p>
     
    <p style={{fontWeight:700}}>İnert Sistemleri Dizayn Ederken Dikkate Alınan Faktörler</p>
    <ul>
        <li>Dizayn Standardı</li>
        <li>Hacim Ölçüleri</li>
        <li>Tehlike Tipi</li>
        <li>Yangın Tipi</li>
        <li>Maksimum ve Minimum Sıcaklık</li>
        <li>Deniz Seviyesine Göre Konum</li>
        <li>Havalandırma Detayı</li>
        <li>Dizayn Konsantrasyonu</li>
        <li>Ön Hesaplama</li>
        <li>Kesin Hesaplama</li>
        <li>Net Sonuç</li>
    </ul>
    <p style={{fontWeight:700}}>İnert Sistemlerin Temel Özellikleri</p>
    <ul>
        <li>İnert Gazlar (Soy Gazlar) Atmosferden elde edilir.</li>
        <li>İletken Değildir</li>
        <li>Renksiz ve Kokusuzdur</li>
        <li>Korozif Değildir</li>
        <li>Termal Şok Yaratmaz</li>
        <li>Söndürme İşlemini Oksijen Seviyesini İndirerek Gerçekleştirir</li>
        <li>Boşaltım Sonrası Atık Bırakmaz, Temizlik Gerektirmez</li>
        <li>İnsan Bulunan Mekanlarda Güvenle Kullanılabilir</li>
        <li>Ozona Zarar Vermez</li>
        <li>Küresel Isınma Yaratmaz</li>
        <li>Boşaltıldığı Alanda Mükemmel Görünürlük</li>
        <li>Silindirler Risk Alanından Uzağa Konulabilir</li>
        <li>Boşaltım Zamanı 60 Saniyedir</li>
        <li>Yeniden Dolum Maliyeti Düşük</li>
        <li>Yaygın Olarak Bulunabilir</li>
        <li>Yerinde Kurulum</li>
    </ul>
    <p style={{fontWeight:700}}>İnert Sistemlerin Kullanım Alanları</p>
    <ul>
        <li>Bilgi İşlem Odaları</li>
        <li>UPS Odaları</li>
        <li>Elektrik Dağıtım Merkezleri</li>
        <li>Değerli Malzeme Depoları</li>
        <li>Telekomünikasyon Merkezleri</li>
        <li>Türbin Kabinleri</li>
        <li>Arşivler ve Müzeler</li>
        <li>Yanıcı ve Parlayıcı Malzeme Depoları</li>
        <li>Off-Shore Uygulamalar</li>
        <li>Sivil, Askeri ve Marin Uygulamalar</li>
    </ul>
    <p style={{fontWeight:700}}>İnert Sistemlerin Kullanılamayacağı Alanlar</p>
    <ul>
        <li>Nitroseluloz yangınları (Oksijen)</li>
        <li>Sodyum Klorat veya Sodyum Nitrat (Oksitleyici)</li>
        <li>Oksijenperoksitler</li>
        <li>Sodyum, Potasyum ve Magnezyum vb. Reaktif Metaller</li>
        <li>Uranyum ve Plütonyum Gibi Radyoaktif Metaller</li>
    </ul>
    <p>Risk bölgesindeki oksijen miktarını, yanma için gerekli olan minimum seviyenin altına düşürürler. Böylelikle, oksijen yetersizliğinden dolayı yangın devam edemez. İnert (Soygazlar), söndürme metotları bakımından CO2 ile aynı olmasına karşın, insanın bulunduğu aktif alanlar için oldukça güvenlidir.</p>
    <p>IG-541 ve IG-55 gazlarının yoğunluğu havanın yoğunluğuna yakın olduğundan dolayı, boşaltmadan sonra ortama yayılma ve alanı kapatma/kaplama yoğunluğu da havanın dağılımına yakın olur ve korunan bölgede homojen bir dağılım sağlanır. Sızıntılar korunan alanın üst ve alt seviyelerinden de gerçekleşebilir. Azot havadan daha hafif olduğundan, üst seviyelerdeki açıklıklardan sızma eğilimi gösterir. Argon gazı ise havadan ağır olduğundan dolayı, açıklıklardan olan kaçaklar daha çok alt seviyelerden gerçekleşir.</p>
    <p>İnert Gazlı (Soy Gazlı) Sistemleri üreticilere göre çalışma basınçları anlamında farklılıklar göstermektedir. Standart üretim kapsamında 80 litre veya 140 litre silindirler kullanılmaktadır. Bu silindirler için 200 bar veya 300 bar çalışma basıncına sahip UL, FM ve VdS onaylı sistem seçenekleri ile piyasa talepleri doğrultusunda üretilmektedir.</p>
    <p>İnert sistemlerin kurulumun yapılacağı alan veya alanlara yakın lokasyonda silindirlerini konumlandırmak gerekmez. Ciddi anlamda uzak kabul edilebilecek mesafelerde silindir odalarını belirlemenize olanak tanır.</p>
    <p>Tek sistem üzerinden çoklu bölgelerin korunması selektör vanalar kullanılarak sağlanabilir. Bunun için asıl sistemin %100 yedekli seçilmesini tavsiye ederiz.</p>
    <p>İnert Sistemleri uygulanmadan önce ilgili koruma alanlarının mutlaka <strong> “Dor-Fan Test”</strong>, <strong> “Puf Test”</strong> ve<strong>  “Boru Sızdırmazlık Testi (Leak Test)”</strong> uygulanmalıdır.</p>
    <p>Sistem tasarımı NFPA 2001 veya EN 15004 standardı gereği yapılmaktadır. Sahada sağlıklı bir mühendislik çalışması yapılarak korunacak mahalin tehlike sınıflaması yapılır.</p>
    <p style={{fontWeight:700}}>IG-01 Inert Sistemi Dizayn Konsantrasyonları</p>
    <ul>
        <li>Class A için %41.9</li>
        <li>Class A Higher için %49.2</li>
        <li>Class B için %51.7</li>
    </ul>
    <p style={{fontWeight:700}}>IG-55 Inert Sistemi Dizayn Konsantrasyonları</p>
    <ul>
        <li>Class A için %40.3</li>
        <li>Class A Higher için %45.2</li>
        <li>Class B için %47.6</li>
    </ul>
    <p style={{fontWeight:700}}>IG-100 Inert Sistemi Dizayn Konsantrasyonları</p>
    <ul>
        <li>Class A için %40.3</li>
        <li>Class A Higher için %45.2</li>
        <li>Class B için %47.6</li>
    </ul>
    <p style={{fontWeight:700}}>IG-541 Inert Sistemi Dizayn Konsantrasyonları</p>
    <ul>
        <li>Class A için %39.9</li>
        <li>Class A Higher için %45.7</li>
        <li>Class B için %48.1</li>
    </ul>
    <p>Söndürme konsantrasyonlarını kullanılarak tehlike sınıfına göre uygulama yoğunluğu, yani Flooding Factor belirlenir (Veriler EN 15004 standardı gereği verilmiştir). Belirlenen Flooding Factor kullanılarak gerekli söndürücü gaz miktarı hesaplanır.</p>
    <p>Tek sistem üzerinden çoklu bölgelerin korunması selektör vanalar kullanılarak sağlanabilir. Bunun için asıl sistemin %100 yedekli seçilmesini tavsiye ederiz.</p>
    <p>Söndürme kontrol paneli ve aksesuarları ile birlikte kullanılır. Söndürmenin algılama kanadı korunacak mahalin gereklerine göre farklı dedektörler kullanılarak çözülebilir.</p>
    <p>AKSAY Yangın SIEX işbirliği ile Inert Gazlı Sistemlerinin Tasarımı, Mühendislik Hesapları, Tedarik Edilmesi, Montaj, Test ve Devreye Alma ve Kontratlı Bakım hizmetlerini yetişmiş mühendis ve teknisyen kadrosu ile gerçekleştirmektedir.</p>
      
    </div>
    <img  style={{width:686,height:402}} src={img} alt="" />




    </>)
}